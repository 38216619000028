import React, { VFC } from 'react';
import Carousel from 'antd/lib/carousel';
import { nanoid } from 'nanoid';
import { UnauthorizedPageBannerTemplate } from './Template';
import { GenericBannersProps } from '../types';
import { useBannerAutoplaySpeed } from '../hooks/useBannerAutoplaySpeed';

export const UnauthorizedPageBanner: VFC<GenericBannersProps> = (props) => {
  const { banners } = props;
  const [autoplaySpeed, setAutoplaySpeed] = useBannerAutoplaySpeed(banners);

  return (
    <Carousel
      style={{ borderRadius: 8 }}
      autoplay
      dotPosition="right"
      verticalSwiping
      adaptiveHeight
      autoplaySpeed={autoplaySpeed}
      beforeChange={setAutoplaySpeed}
    >
      {banners.map((banner) => (
        <UnauthorizedPageBannerTemplate key={nanoid()} banner={banner} />
      ))}
    </Carousel>
  );
};
