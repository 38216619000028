import React from 'react';

const Clear = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7 8H17V20L7 20L7 8Z" stroke="currentColor" strokeWidth="2" />
    <rect x="0.5" y="-0.5" width="13" height="1" transform="matrix(1 0 0 -1 5 5)" stroke="currentColor" />
    <mask id="path-3-inside-1" fill="white">
      <path d="M9 4H15L13.5 2H10.5L9 4Z" />
    </mask>
    <path
      d="M9 4V6H5L7.4 2.8L9 4ZM15 4L16.6 2.8L19 6H15V4ZM13.5 2V0H14.5L15.1 0.8L13.5 2ZM10.5 2L8.9 0.8L9.5 0H10.5V2ZM9 2H15V6H9V2ZM13.4 5.2L11.9 3.2L15.1 0.8L16.6 2.8L13.4 5.2ZM13.5 4H10.5V0H13.5V4ZM12.1 3.2L10.6 5.2L7.4 2.8L8.9 0.8L12.1 3.2Z"
      fill="currentColor"
      mask="url(#path-3-inside-1)"
    />
  </svg>
);

export default Clear;
