import { DefaultTheme } from 'styled-components';

export const mediaScreen = (query: keyof DefaultTheme['screenWidth']) => (props: { theme: DefaultTheme }) =>
  props.theme.screenWidth[query];

export const getColor = (cb: (colors: DefaultTheme['colors']) => string) => (props: { theme: DefaultTheme }) =>
  cb(props.theme.colors);

export const mediaQuery = (query: keyof DefaultTheme['mediaQuery']) => (props: { theme: DefaultTheme }) =>
  `@media only screen and ${props.theme.mediaQuery[query]}`;
