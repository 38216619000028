import React, { FC } from 'react';
import { ModalWithButton } from './index';
import { ModalWithButtonProps } from './ModalWithButton';

const FinishModalNoReport: FC<ModalWithButtonProps> = ({ title, showModal, handleButtonClick, handleCloseModal }) => {
  return (
    <ModalWithButton
      title={title}
      description="Результаты будут отправлены специалисту, назначившему тестирование."
      buttonText="Завершить"
      showModal={showModal}
      handleButtonClick={handleButtonClick}
      handleCloseModal={handleCloseModal}
    />
  );
};

export default FinishModalNoReport;
