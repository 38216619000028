import React, { VFC } from 'react';
import Carousel from 'antd/lib/carousel';
import { useTheme } from 'styled-components';
import { useMediaQuery } from 'hooks/useMediaQuery';
import { nanoid } from 'nanoid';
import { PageBannerTemplate } from './Template';
import { GenericBannersProps } from '../types';
import { useBannerAutoplaySpeed } from '../hooks/useBannerAutoplaySpeed';

export const PageBanner: VFC<GenericBannersProps> = (props) => {
  const { banners } = props;
  const theme = useTheme();
  const match = useMediaQuery(theme.mediaQuery.sm);
  const [autoplaySpeed, setAutoplaySpeed] = useBannerAutoplaySpeed(banners);

  return match ? (
    <div style={{ borderRadius: 8 }}>
      <Carousel
        autoplay
        dotPosition="right"
        verticalSwiping
        adaptiveHeight
        autoplaySpeed={autoplaySpeed}
        beforeChange={setAutoplaySpeed}
      >
        {banners.map((banner) => (
          <PageBannerTemplate key={nanoid()} banner={banner} />
        ))}
      </Carousel>
    </div>
  ) : null;
};
