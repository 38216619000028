import React from 'react';
import styled from 'styled-components';

interface SpaceProps {
  className?: string;
  children: React.ReactNode;
  direction?: 'vertical' | 'horizontal';
  justify?: React.CSSProperties['justifyContent'];
  align?: React.CSSProperties['alignItems'];
}

const Space = styled.div<SpaceProps>`
  display: flex;
  ${(props) => (props.direction === 'vertical' ? 'flex-direction: column;' : 'flex-direction: row;')}
  ${(props) => props.justify && `justify-content: ${props.justify};`}
  ${(props) => props.align && `align-items: ${props.align};`}
`;

export default Space;
