import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';
import { Route } from '.';

const AuthLogin = lazy(() => import('../pages/Auth/Login'));
const AuthRegister = lazy(() => import('../pages/Auth/Register'));
const AuthAccess = lazy(() => import('../pages/Auth/Access'));
const AuthRecovery = lazy(() => import('../pages/Auth/Recovery'));
const AuthRecoveryConfirm = lazy(() => import('../pages/Auth/RecoveryConfirm'));
const AccesCheckerProxy = lazy(() => import('../features/AccessRightsChecker/ProxyComponent'));

export const authRoutes: Route[] = [
  {
    path: '/login',
    component: () => <AuthLogin />,
  },
  {
    path: '/register',
    component: () => <AuthRegister />,
  },
  {
    path: '/access',
    component: () => <AuthAccess />,
  },
  {
    path: '/recovery',
    component: () => <AuthRecovery />,
  },
  {
    path: '/reset-password-confirm',
    component: () => <AuthRecoveryConfirm />,
  },
  {
    path: '/t/:token',
    component: () => <AccesCheckerProxy />,
  },
  {
    path: '/',
    component: () => (
      <Redirect
        to={{
          pathname: '/login',
        }}
      />
    ),
  },
];
