import React, { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import { IconBurger, IconSemicircle } from '../../../components/Icons';

interface TestsSwitchProps {
  isLine: boolean;
  setIsLine: Dispatch<SetStateAction<boolean>>;
}

const TestsSwitchButton = styled.button<{ active: boolean }>`
  border: none;
  outline: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: ${(props) =>
    props.active ? props.theme.colors.elements.blueDefault : props.theme.colors.bg.extraLightBlue};
  border-radius: 4px;
  width: 32px;
  height: 32px;
  margin-left: 16px;
  color: ${(props) => (props.active ? props.theme.colors.elements.white : props.theme.colors.elements.blueDefault)};

  svg {
    fill: ${(props) => (props.active ? props.theme.colors.elements.white : props.theme.colors.elements.blueDefault)};
  }
`;

const TestsSwitch: React.FC<TestsSwitchProps> = ({ isLine, setIsLine }) => {
  const handleSetIsLineTrue = () => setIsLine(true);
  const handleSetIsLineFalse = () => setIsLine(false);

  return (
    <>
      <TestsSwitchButton type="button" active={isLine} onClick={handleSetIsLineTrue}>
        <IconBurger />
      </TestsSwitchButton>
      <TestsSwitchButton type="button" active={!isLine} onClick={handleSetIsLineFalse}>
        <IconSemicircle />
      </TestsSwitchButton>
    </>
  );
};

export default TestsSwitch;
