import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import { Form, FormItem, Button, MessageInfo, InputPassword } from '../../components';
import { Header } from '../../components/Typography';
import { useAuthStore } from './store';
import { useRecaptchaLoader } from '../ReCaptcha';

const ButtonsContainer = styled(FormItem)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const InputError = styled.p`
  margin-bottom: 4px;
  color: ${({ theme }) => theme.colors.elements.warning};

  &.success {
    color: ${({ theme }) => theme.colors.tests.green};
  }
`;

type Inputs = {
  password: string;
  passwordConfirm: string;
};

const RecoveryConfirmForm = observer(() => {
  const history = useHistory();
  const authStore = useAuthStore();
  const recaptchaLoader = useRecaptchaLoader();

  const [errorMsg, setError] = useState<string | null>(null);

  const isLoading = authStore.fetching;

  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { register, handleSubmit, errors, watch } = useForm<Inputs>();

  const password = watch('password');

  const onSubmit = useCallback(
    async (data: Inputs) => {
      try {
        const recaptcha = await recaptchaLoader.executeReCaptcha('submit');
        const token = new URLSearchParams(history.location.search).get('token');

        if (!recaptcha || !token) return;

        await authStore.recoveryConfirmPassword({ password: data.password, token, recaptcha });
      } catch (error) {
        setError('Что-то пошло не так, попробуйте ещё раз!');
      }
    },
    [authStore, history.location.search, recaptchaLoader],
  );

  const clearErrMsg = useCallback(() => {
    setError(null);
  }, []);

  useEffect(() => {
    if (authStore.isRecoveryConfirmed) {
      history.push('/login');
    }
  }, [authStore.isRecoveryConfirmed, history]);

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      {errorMsg && <MessageInfo variant="error" message={errorMsg} onHide={clearErrMsg} />}

      <FormItem>
        <Header variant="h1" color="blue">
          Придумайте новый пароль
        </Header>
      </FormItem>

      <FormItem>
        <InputPassword
          name="password"
          placeholder="Новый пароль"
          helper="Пароль должен быть не менее 8 символов"
          ref={register({ required: true, minLength: 8 })}
        />
      </FormItem>

      <FormItem>
        <InputPassword
          name="passwordConfirm"
          placeholder="Подтвердите пароль"
          ref={register({ required: true, validate: { sameAsPassword: (value) => value === password } })}
        />
        {errors.passwordConfirm && <InputError>Пароли не совпадают!</InputError>}
      </FormItem>

      <ButtonsContainer>
        <Button type="submit">{isLoading ? 'Сохранение...' : 'Сохранить'}</Button>
      </ButtonsContainer>
    </Form>
  );
});

export default RecoveryConfirmForm;
