import React, { useState, useCallback, VFC } from 'react';
import { useForm, Controller } from 'react-hook-form';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';

import axios from 'axios';
import {
  Form,
  FormItem,
  Select,
  Input,
  Button,
  LinkButton,
  Checkbox,
  MessageInfo,
  ErrorsBlock,
  InputPassword,
} from '../../components';
import { Circle } from '../../components/Loader';
import { Header } from '../../components/Typography';
import { ModalPrivacyPolicy } from '../../modals';

import { RequestRegistrationRequestTypeEnum } from '../../services';
import { getErrorDetail } from '../../utils/getErrorDetail';
import { useAuthStore } from './store';
import { usePolicyLoader } from '../PrivacyPolicy';
import { useRecaptchaLoader } from '../ReCaptcha';

interface SelectOption {
  label: string;
  value: RequestRegistrationRequestTypeEnum;
}

type FormValues = {
  selectActive: SelectOption;
  email: string;
  password: string;
  password2: string;
  consent: boolean;
};

const ErrorText = styled.p`
  color: ${({ theme }) => theme.colors.elements.warning};
  margin-bottom: 4px;
`;

const requestRoleOptions = [
  { value: RequestRegistrationRequestTypeEnum.Company, label: 'Купить тесты для компании' },
  { value: RequestRegistrationRequestTypeEnum.Consultant, label: 'Я консультант' },
];

// eslint-disable-next-line sonarjs/cognitive-complexity
const AccessForm: VFC = observer(() => {
  const authStore = useAuthStore();
  const policyLoader = usePolicyLoader();
  const errorPolicyLoadMessage = getErrorDetail(policyLoader.error);
  const recaptchaLoader = useRecaptchaLoader();

  const [showModal, setShowModal] = useState(false);
  const [errorMsg, setError] = useState<string | null>(null);
  const [showSuccessMsg, setShowSuccessMsg] = useState(false);

  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { register, handleSubmit, errors, control, reset: resetForm, watch } = useForm<FormValues>({
    defaultValues: {
      selectActive: requestRoleOptions[0],
    },
  });

  const watchAccessType = watch('selectActive');
  const newPassword = watch('password');

  const openPolicyHandler = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    setShowModal(true);
    try {
      await policyLoader.getNewPolicyByRole(watchAccessType.value);
    } catch {
      setShowModal(false);
    }
  };

  const onSubmit = useCallback(
    async (data: FormValues) => {
      try {
        const recaptcha = await recaptchaLoader.executeReCaptcha('submit');

        if (!recaptcha) return;

        const newRequestAccess = {
          email: data.email,
          requestType: data.selectActive.value,
          password: data.password,
          recaptcha,
        };

        await authStore.requestAccess(newRequestAccess);
        setShowSuccessMsg(true);
        resetForm();
      } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          setError(error.response.data.detail as string);
        }
      }
    },
    [recaptchaLoader, resetForm, authStore],
  );

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <FormItem>{errorMsg && <ErrorsBlock errors={errorMsg} />}</FormItem>

      <FormItem>
        <Header variant="h1">Запросить доступ</Header>
      </FormItem>

      <FormItem>
        {showSuccessMsg && <MessageInfo variant="success" message="Запрос отправлен!" />}
        {errors.selectActive && <ErrorText>Обязательное поле!</ErrorText>}

        <Controller
          rules={{ required: true }}
          control={control}
          name="selectActive"
          as={Select}
          options={requestRoleOptions}
        />
      </FormItem>

      <FormItem>
        <Input
          type="email"
          name="email"
          placeholder="Email"
          autoComplete="email"
          $error={Boolean(errors.email)}
          ref={register({ required: true })}
        />
      </FormItem>

      <FormItem>
        <InputPassword
          name="password"
          placeholder="Пароль"
          helper="Пароль должен быть не менее 8 символов"
          autoComplete="new-password"
          $error={Boolean(errors.password)}
          ref={register({ required: true, minLength: 8 })}
        />
      </FormItem>

      <FormItem>
        <InputPassword
          name="password2"
          placeholder="Подтвердите пароль"
          helper={
            errors.password2?.type === 'sameAsPassword'
              ? 'Пароли не совпадают'
              : 'Пароль должен быть не менее 8 символов'
          }
          autoComplete="new-password"
          $error={Boolean(errors.password2)}
          ref={register({
            required: true,
            validate: { sameAsPassword: (value) => value === newPassword },
          })}
        />
      </FormItem>

      <FormItem>
        <Button type="submit" disabled={authStore.fetching}>
          Запросить
        </Button>
      </FormItem>

      <FormItem>
        {errorPolicyLoadMessage && <MessageInfo variant="error" message={errorPolicyLoadMessage} />}
        <Checkbox name="consent" $error={Boolean(errors.consent)} ref={register({ required: true })}>
          Даю согласие на&nbsp;
          <LinkButton onClick={openPolicyHandler}>обработку персональных данных</LinkButton>
        </Checkbox>
      </FormItem>

      <ModalPrivacyPolicy
        showModal={showModal}
        handleCloseModal={() => setShowModal(false)}
        policyHTML={policyLoader.policyHTML}
      >
        {policyLoader.isLoading && <Circle />}
      </ModalPrivacyPolicy>
    </Form>
  );
});

export default AccessForm;
