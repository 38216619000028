import styled from 'styled-components';
import { mediaQuery } from '../utils/themeUtils';

const FiltersWrapper = styled.form`
  background-color: ${(props) => props.theme.colors.bg.extraLightBlue};
  border-radius: 4px;
  margin: 0 0 32px 0;
  padding: 32px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-row-gap: 15px;
  grid-column-gap: 28px;
  ${mediaQuery('md')} {
    grid-template-columns: repeat(2, 1fr);
  }
  ${mediaQuery('sm')} {
    padding: 0;
    grid-template-columns: repeat(1, 1fr);
    & > *:empty {
      display: none;
    }
  }
`;

export default FiltersWrapper;
