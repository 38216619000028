import styled from 'styled-components';

const TableWrapper = styled.div`
  > table {
    border-spacing: 0;
    width: 100%;
    margin-top: 32px;

    > thead {
      > tr {
        text-align: left;

        > th {
          background-color: ${(props) => props.theme.colors.elements.blueDefault};
          padding: 16px 0;

          color: ${(props) => props.theme.colors.font.white};
          font-size: 12px;
          font-weight: 600;

          &:first-child {
            border-top-left-radius: ${(props) => props.theme.borderRadius};
            border-bottom-left-radius: ${(props) => props.theme.borderRadius};
            padding-left: 32px;
          }

          &:last-child {
            border-top-right-radius: ${(props) => props.theme.borderRadius};
            border-bottom-right-radius: ${(props) => props.theme.borderRadius};
            padding-right: 32px;
          }
        }
      }
    }

    > tbody {
      > tr {
        border-bottom: 1px solid ${(props) => props.theme.colors.elements.divider};

        > td {
          padding: 16px 0;

          color: ${(props) => props.theme.colors.font.header};
          font-size: 14px;
          font-weight: 300;

          &:first-child {
            padding-left: 32px;
          }

          &:last-child {
            padding-right: 32px;
          }
        }
      }
    }
  }
`;

export default TableWrapper;
