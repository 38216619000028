import React, { useEffect } from 'react';
import { useAuthStore } from './store';

type Props = React.PropsWithChildren<unknown>;

const Logout = ({ children }: Props) => {
  const authStore = useAuthStore();
  useEffect(() => {
    authStore.logout();
  }, [authStore]);

  return <>{children}</>;
};

export default Logout;
