import React, { FC } from 'react';
import styled from 'styled-components';

const InfinityIcon: FC = () => {
  return <Wrapper>{'\u221E'}</Wrapper>;
};
const Wrapper = styled.span`
  font-family: u2000;
  font-size: 1.2rem;
`;

export default InfinityIcon;
