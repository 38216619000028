import React, { FC } from 'react';

const ClockIcon: FC<{ className?: string }> = (props) => {
  return (
    <svg
      className={props.className}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="8" cy="8" r="7.5" stroke="#F7004A" />
      <path d="M8.40039 3.80078V9.00078L11.2004 11.0008" stroke="#F7004A" />
    </svg>
  );
};

export default ClockIcon;
