import { GenericBannerTemplateProps } from 'features/Banners/types';
import React, { VFC } from 'react';
import { RootWrapper, Text, Title } from './styles';

export const UnauthorizedPageBannerTemplate: VFC<GenericBannerTemplateProps> = (props) => {
  const {
    banner: { header, content, template },
  } = props;

  return (
    <RootWrapper variant={template}>
      <Title>{header}</Title>
      <Text>{content}</Text>
    </RootWrapper>
  );
};
