import React from 'react';
import { nanoid } from 'nanoid';
import { Card } from './Card';
import { TableMainProps } from '../../TableMain/types';
import { CardsContainer } from '../../Card';

type Props<T> = TableMainProps<T>;

export function CardsSm<T>({
  columns,
  data = [],
  isPreviewMode,
  isEditingCard,
  onClickEditCard,
  onClickDeleteCard,
  onRowClick,
  rowClickTitle,
}: Props<T>) {
  return (
    <CardsContainer>
      {data.map((obj) => {
        return (
          <Card
            key={nanoid()}
            columns={columns}
            data={obj}
            isPreviewMode={isPreviewMode}
            isEditingCard={isEditingCard}
            onClickEditCard={onClickEditCard}
            onClickDeleteCard={onClickDeleteCard}
            onRowClick={onRowClick}
            rowClickTitle={rowClickTitle}
          />
        );
      })}
    </CardsContainer>
  );
}
