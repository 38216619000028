import React, { FC } from 'react';
import styled from 'styled-components';
import { Modal } from './index';
import { Modalprops } from './Modal/Modal';
import { BodyBig } from '../components/Typography';
import { Button } from '../components';

export type ModalWithButtonProps = Modalprops & {
  handleButtonClick(): void;
  description?: string;
  buttonText?: string;
};

const ModalWithButton: FC<ModalWithButtonProps> = ({
  showModal,
  handleCloseModal,
  title,
  handleButtonClick,
  description,
  buttonText,
}) => {
  return (
    <Modal showModal={showModal} handleCloseModal={handleCloseModal} title={title}>
      <ModalDescription paragraph>{description}</ModalDescription>
      <ModalButton onClick={handleButtonClick}>{buttonText}</ModalButton>
    </Modal>
  );
};

const ModalDescription = styled(BodyBig)`
  margin-bottom: 48px;
`;

const ModalButton = styled(Button)`
  max-width: 206px;
`;

export default ModalWithButton;
