import React, { createContext, useContext, useEffect } from 'react';

import { CoreApi } from 'services/api';

import AuthStore from './store';

const store = new AuthStore({ api: new CoreApi() });

const AuthStateContext = createContext<AuthStore>(store);

type AuthStateProviderProps = { children: React.ReactNode };

export const AuthStateProvider: React.FC<AuthStateProviderProps> = ({ children }) => {
  useEffect(() => {
    store.getProfile();
  }, []);

  return <AuthStateContext.Provider value={store}>{children}</AuthStateContext.Provider>;
};

export const useAuthStore = (): AuthStore => useContext(AuthStateContext);
