import React from 'react';

const Logo = ({ className }: { className?: string }) => (
  <span className={className}>
    <svg width="156" height="54" viewBox="0 0 156 54" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path d="M143.188 7.96143V12.0528H135.714V33.9156H130.494V12.0528H123.027V7.96143H143.188Z" />
      <path d="M121.272 7.96143V33.9156H114.889L105.919 16.4502L104.92 14.508H104.84V33.9156H99.8477V7.96143H106.264L110.708 16.6518L116.196 27.3762H116.264V7.96143H121.272Z" />
      <path d="M149.983 7.97046H144.754V33.9229H149.983V7.97046Z" />
      <path d="M70.1676 7.97046H64.9453V33.9229H70.1676V7.97046Z" />
      <path d="M61.2137 19.0494V33.0282C58.4417 33.7662 55.5686 34.3692 52.7713 34.3692C44.3307 34.3692 38.4688 30.1698 38.4688 21.3804C38.4688 21.0888 38.4687 20.8008 38.4868 20.5218C38.8283 12.1806 44.2115 7.51679 52.7623 7.51679C55.3528 7.48849 57.9326 7.85265 60.4132 8.59679L60.0717 12.9798C58.0623 12.051 55.4529 11.5974 53.3568 11.5974C47.8381 11.5974 44.5656 14.9652 44 19.5498C43.9409 20.0366 43.9113 20.5265 43.9115 21.0168C43.9115 26.2962 47.1948 30.2742 52.7659 30.2742C53.9218 30.2903 55.0753 30.1622 56.1992 29.8926V23.1318H50.6734V19.0494H61.2137Z" />
      <path d="M95.5508 7.97217V24.5466C95.5508 31.5036 91.6006 34.3692 84.9129 34.3692C78.2251 34.3692 74.2695 31.5 74.2695 24.5466V7.97217H79.4918V22.9518C79.4918 27.3762 80.923 30.2742 84.9129 30.2742C88.9028 30.2742 90.3339 27.3762 90.3339 22.9518V7.97217H95.5508Z" />
      <path d="M9.98077 43.1083H10.4813C11.2041 43.1083 11.8565 42.8707 11.8565 42.0283C11.8565 41.1859 11.2258 40.9033 10.4813 40.9033H9.98077V43.1083ZM8.53516 39.7765H10.3657C11.9396 39.7801 13.3725 40.2301 13.3725 41.9527C13.3725 43.6357 12.1384 44.2441 10.5879 44.2441H9.98077V46.9801H8.53516V39.7765Z" />
      <path d="M23.2666 41.0958C22.8029 40.8952 22.3031 40.7905 21.7975 40.788C21.3006 40.788 20.6573 41.004 20.6573 41.7888C20.6573 43.0362 23.8268 42.5088 23.8268 44.9244C23.8268 46.5012 22.5727 47.1006 21.1163 47.1006C20.3302 47.1006 19.9779 46.998 19.2623 46.8234L19.396 45.522C19.8972 45.8022 20.4603 45.9543 21.035 45.9648C21.5771 45.9648 22.318 45.6876 22.318 45.0378C22.318 43.6662 19.1484 44.2332 19.1484 41.8482C19.1484 40.239 20.4007 39.6522 21.6783 39.6522C22.2649 39.6445 22.8489 39.7314 23.4076 39.9096L23.2666 41.0958Z" />
      <path d="M34.531 39.7765L32.7909 42.7987L31.0832 39.7765H29.3828L32.0229 44.1289V46.9765H33.4757V44.1289L36.0634 39.7765H34.531Z" />
      <path d="M47.2472 46.8737C46.7083 47.0115 46.1554 47.0876 45.5992 47.1005C43.2591 47.1005 41.6328 45.9341 41.6328 43.5005C41.6328 41.0345 43.1453 39.6521 45.5992 39.6521C46.1585 39.6549 46.7129 39.7567 47.2364 39.9527L47.1334 41.1695C46.6568 40.9017 46.1154 40.7697 45.5685 40.7879C44.0777 40.7879 43.1453 41.9219 43.1453 43.3961C43.1453 44.8703 44.0488 45.9647 45.5992 45.9647C46.1429 45.9727 46.6826 45.871 47.1858 45.6659L47.2472 46.8737Z" />
      <path d="M57.6347 39.7765V42.6853H54.7344V39.7765H53.2852V46.9765H54.7344V43.8193H57.6347V46.9765H59.0857V39.7765H57.6347Z" />
      <path d="M68.9204 45.9647C70.4003 45.9647 70.9497 44.6543 70.9497 43.3565C70.9497 42.0857 70.3569 40.7879 68.9204 40.7879C67.4838 40.7879 66.8893 42.0857 66.8893 43.3565C66.8893 44.6543 67.4314 45.9647 68.9204 45.9647ZM68.9204 39.6521C71.1864 39.6521 72.4603 41.1785 72.4603 43.3565C72.4603 45.5345 71.2081 47.1005 68.9204 47.1005C66.6002 47.1005 65.375 45.5633 65.375 43.3565C65.375 41.1497 66.62 39.6521 68.9204 39.6521Z" />
      <path d="M84.5346 39.7765L82.8143 45.2341H82.7926L81.0741 39.7765H78.7539V46.9765H80.1417V41.0545L80.1634 41.0347L82.1095 46.9765H83.4973L85.4435 41.0347H85.467V46.9765H86.8548V39.7765H84.5346Z" />
      <path d="M93.5039 39.7765V46.9765H97.7901V45.8425H94.9531V43.8193H97.5299V42.6853H94.9531V40.9087H97.7685V39.7765H93.5039Z" />
      <path d="M103.473 39.7765V40.9087H105.542V46.9765H106.993V40.9087H109.064V39.7765H103.473Z" />
      <path d="M116.282 42.8509H116.76C117.483 42.8509 118.291 42.7465 118.291 41.8483C118.291 40.9825 117.485 40.9087 116.76 40.9087H116.282V42.8509ZM114.836 39.7765H116.401C117.955 39.7765 119.809 39.7243 119.809 41.7259C119.817 42.1405 119.668 42.5429 119.391 42.8528C119.114 43.1627 118.731 43.3574 118.316 43.3981V43.4179C118.71 43.4485 118.938 43.8409 119.082 44.1595L120.239 46.9801H118.623L117.752 44.7319C117.545 44.1919 117.36 43.9885 116.748 43.9885H116.282V46.9801H114.836V39.7765Z" />
      <path d="M127.578 39.7765H126.129V46.9765H127.578V39.7765Z" />
      <path d="M139.364 46.8737C138.826 47.0115 138.273 47.0876 137.718 47.1005C135.369 47.1005 133.742 45.9341 133.742 43.5005C133.742 41.0345 135.253 39.6521 137.718 39.6521C138.273 39.6561 138.824 39.7579 139.344 39.9527L139.241 41.1695C138.77 40.9053 138.236 40.7734 137.696 40.7879C136.203 40.7879 135.271 41.9219 135.271 43.3961C135.271 44.8703 136.183 45.9647 137.727 45.9647C138.269 45.9729 138.808 45.8712 139.31 45.6659L139.364 46.8737Z" />
      <path d="M149.284 41.0958C148.82 40.8955 148.32 40.7908 147.815 40.788C147.32 40.788 146.677 41.004 146.677 41.7888C146.677 43.0362 149.846 42.5088 149.846 44.9244C149.846 46.5012 148.594 47.1006 147.136 47.1006C146.346 47.1006 145.993 46.998 145.28 46.8234L145.413 45.522C145.914 45.8024 146.476 45.9546 147.051 45.9648C147.593 45.9648 148.335 45.6876 148.335 45.0378C148.335 43.6662 145.164 44.2332 145.164 41.8482C145.164 40.239 146.416 39.6522 147.694 39.6522C148.281 39.6443 148.865 39.7312 149.423 39.9096L149.284 41.0958Z" />
      <path d="M13.4414 23.9401C13.5824 25.3529 13.8331 26.7527 14.1913 28.1269C15.1526 27.75 16.1497 27.4714 17.1675 27.2953C15.5533 26.6717 14.2266 25.4771 13.4414 23.9401Z" />
      <path d="M9.74219 10.8C10.8289 11.8675 12.0771 12.7585 13.4412 13.4406C14.3194 10.368 15.7198 8.03157 17.4274 6.89937C17.4124 6.89796 17.3973 6.89796 17.3823 6.89937C14.1405 7.49517 11.6992 8.88297 9.74219 10.8Z" />
      <path d="M13.5251 16.9381C13.5052 17.1037 13.4853 17.2711 13.4727 17.4385C13.9012 16.5497 14.4331 15.7142 15.0574 14.9491C14.696 14.8267 14.3238 14.6935 13.9732 14.5441C13.7853 15.3001 13.6407 16.1083 13.5251 16.9381Z" />
      <path d="M25.9783 24.2281C25.2511 25.5953 24.1019 26.6934 22.7003 27.3601C23.596 27.5353 24.4741 27.7901 25.3241 28.1215C25.881 25.9072 26.1781 23.636 26.2096 21.3535H21.5547C22.4202 22.8763 23.9797 23.7997 25.9783 24.2281Z" />
      <path d="M12.4548 21.3462H5.42188C5.57909 24.6114 6.58379 27.1386 8.02037 29.169C8.43851 29.7693 8.90442 30.3351 9.41358 30.861L9.53826 30.996C10.6897 29.9583 11.9931 29.1013 13.4035 28.4544C12.7816 26.1355 12.4628 23.7464 12.4548 21.3462Z" />
      <path d="M25.4483 14.2434C25.4483 14.2236 25.4321 14.2434 25.414 14.2434C24.1534 14.655 23.0352 15.4131 22.1885 16.4304C21.4422 17.3574 20.7718 18.8784 21.2091 20.5704H26.2145C26.1849 18.4403 25.9281 16.3194 25.4483 14.2434Z" />
      <path d="M27.095 21.3462C27.0874 23.7483 26.7679 26.1393 26.1445 28.4598C27.5325 29.1001 28.8169 29.9427 29.9555 30.96C31.1222 29.7866 32.0711 28.4166 32.7582 26.9136C33.5685 25.1656 34.0154 23.2725 34.0719 21.348L27.095 21.3462Z" />
      <path d="M26.2578 14.0057C26.7926 16.1521 27.0728 18.3535 27.0927 20.5649H34.0804C34.0045 19.5155 33.9232 18.5417 33.6648 17.6849C33.1787 16.0649 31.93 14.9651 30.3832 14.3585C29.3225 13.9445 27.6113 13.7159 26.2578 14.0057Z" />
      <path d="M25.9187 29.2157C25.0676 31.8797 23.8027 33.9227 22.3047 35.0135C24.9543 34.5386 27.4125 33.3196 29.39 31.4999C28.348 30.5777 27.1791 29.8086 25.9187 29.2157Z" />
      <path d="M24.937 10.3625C25.0003 10.3625 25.0725 10.3499 25.1376 10.3499C25.499 10.3499 25.8749 10.3499 26.1875 10.3625C27.2327 10.4074 28.2689 10.5766 29.2739 10.8665C29.3642 10.8953 29.7509 11.0699 29.816 10.9835C29.8593 10.9295 29.722 10.8305 29.6859 10.8035C29.4907 10.6073 29.2648 10.4093 29.1076 10.2635C27.1402 8.51816 24.7237 7.35322 22.1289 6.89929C23.1987 7.61929 24.151 8.81989 24.937 10.3625Z" />
      <path d="M19.7724 6.92468C17.8443 6.92468 16.1096 8.72468 14.9133 11.6047C14.6369 12.3085 14.3947 13.0447 14.1797 13.7863C14.6242 13.9771 15.0832 14.1463 15.5476 14.2939C15.8006 14.0743 16.0319 13.8421 16.2831 13.6225C18.2455 11.9035 20.7572 10.7425 24.0352 10.3483C22.8895 8.21528 21.4005 6.92468 19.7724 6.92468Z" />
      <path d="M23.0056 33.2621C23.1014 33.1343 23.1863 33.0047 23.2839 32.8715C24.0934 31.7051 24.659 30.3515 25.1216 28.8755C21.6723 27.5044 17.8255 27.5083 14.3789 28.8863C14.6213 29.6519 14.9041 30.4041 15.2264 31.1399C16.4046 33.6005 18.0074 35.1215 19.7729 35.1215C20.9474 35.1143 22.0443 34.4357 23.0056 33.2621Z" />
      <path d="M13.6285 29.2103C12.3534 29.8085 11.172 30.5876 10.1211 31.5233C12.1267 33.3522 14.615 34.5724 17.2932 35.0405C15.7662 33.9551 14.4887 31.8977 13.6285 29.2103Z" />
      <path d="M12.4566 20.5651C12.4761 18.4275 12.738 16.299 13.2372 14.2201C11.7386 13.4894 10.3691 12.521 9.18228 11.3527C7.14939 13.6477 5.62788 16.4485 5.42188 20.5651H12.4566Z" />
    </svg>
  </span>
);

export default Logo;
