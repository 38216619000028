import React, { VFC } from 'react';
import styled from 'styled-components';
import { intervalToDuration, formatDuration } from 'date-fns';
import ru from 'date-fns/locale/ru';

import TimeLimit from './TimeLimit';

const Wrapper = styled.div`
  display: flex;
  align-items: center;

  p {
    &:not(:last-child) {
      margin-right: 4px;
    }
  }
`;

interface SubtractionDateProps {
  timeIsUp?: boolean;
  endTime?: string | null;
  maxDurationSec?: string | null;
}

const SubtractionDate: VFC<SubtractionDateProps> = (props) => {
  const { endTime, maxDurationSec, timeIsUp } = props;

  if (timeIsUp) {
    return <TimeLimit />;
  }

  if (!endTime || !maxDurationSec) {
    return null;
  }

  const duration = intervalToDuration({
    start: new Date(endTime),
    end: new Date(maxDurationSec),
  });

  const resultTime = formatDuration({ ...duration }, { locale: ru });

  return <Wrapper>{resultTime}</Wrapper>;
};

export default SubtractionDate;
