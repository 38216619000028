import React, { VFC } from 'react';
import LinkButton from 'components/LinkButton';
import { EmailPreview, EmailPreviewsWrap, InputError, StyledIconChevron } from '../TestAccessSettings/styles';
import { EmailListPreviewProps } from './types';

export const EmailListPreview: VFC<EmailListPreviewProps> = (props) => {
  const { emails, showError, onClick } = props;

  return (
    <EmailPreviewsWrap>
      {emails?.slice(0, 3).map((em) => (
        <EmailPreview key={em}>{em}</EmailPreview>
      ))}
      <LinkButton onClick={onClick} fontWeight={400}>
        {emails?.length ? 'Показать все' : 'Ввести адреса'} <StyledIconChevron />
      </LinkButton>
      {showError && <InputError isUnderField>Введите хотя бы один email!</InputError>}
    </EmailPreviewsWrap>
  );
};
