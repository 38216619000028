import React from 'react';

const EditOutline = () => (
  <svg width="24" height="24" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.5315 1.05064C18.0434 0.538677 18.8734 0.538677 19.3853 1.05064L24.9501 6.61623C25.462 7.12819 25.462 7.95825 24.9501 8.47022L10.1203 23.3021C9.96502 23.4574 9.77325 23.5712 9.56258 23.633L1.68063 25.9469C1.2205 26.082 0.723346 25.955 0.384349 25.6157C0.0453525 25.2765 -0.0813641 24.7792 0.053947 24.3191L2.3711 16.4396C2.43298 16.2292 2.54668 16.0377 2.70176 15.8826L17.5315 1.05064ZM18.4584 3.83162L5.48234 16.8095L9.19341 20.5211L22.1695 7.54322L18.4584 3.83162ZM6.70424 21.7396L4.2631 19.2981L3.24661 22.7546L6.70424 21.7396Z"
      fill="currentColor"
    />
  </svg>
);

export default EditOutline;
