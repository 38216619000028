import React, { useCallback } from 'react';
import styled from 'styled-components';
import { useAuthStore } from '../../features/Auth';
import { Button } from '../index';

const Btn = styled(Button)`
  padding-top: 0;
  padding-bottom: 0;
  height: 36px;
  line-height: 18px;
`;

export const LogoutButton = () => {
  const authStore = useAuthStore();

  const logout = useCallback(() => authStore.logout(), [authStore]);

  return (
    <Btn view="secondary" onClick={logout}>
      Выйти
    </Btn>
  );
};
