import React, { useCallback, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

import axios from 'axios';
import { useAuthStore } from './store';
import { usePolicyLoader } from '../PrivacyPolicy';
import { useRecaptchaLoader } from '../ReCaptcha';
import { getErrorDetail } from '../../utils/getErrorDetail';
import { User } from '../../services';

import {
  Form,
  FormItem,
  Input,
  Button,
  Checkbox,
  LinkButton,
  MessageInfo,
  ErrorsBlock,
  InputPassword,
} from '../../components';
import { Circle } from '../../components/Loader';
import { Header } from '../../components/Typography';
import { ModalWithButton, ModalPrivacyPolicy } from '../../modals';
import useQuery from './hooks';

type Inputs = Required<User> & {
  confirmPassword: string;
  consent: boolean;
};
/* eslint-disable  sonarjs/cognitive-complexity */
const RegisterForm: React.FC = observer(() => {
  const authStore = useAuthStore();
  const policyLoader = usePolicyLoader();
  const recaptchaLoader = useRecaptchaLoader();
  const query = useQuery();
  const history = useHistory();

  const token = query.get('token');
  const errorPolicyLoadMessage = getErrorDetail(policyLoader.error);

  const [errorMsg, setError] = useState<string | null>(null);
  const [showModal, setShowModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);

  const handleCloseErrorModal = useCallback(() => {
    setShowErrorModal(false);
    if (token) history.push(`login?token=${token}`);
    else history.push('login');
  }, [history, token]);

  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { register, watch, handleSubmit, errors, setError: formSetError } = useForm<Inputs>();
  const password = watch('password');

  const openPolicyHandler = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    if (token) {
      setShowModal(true);
      try {
        await policyLoader.getNewPolicyByToken(token);
      } catch {
        setShowModal(false);
      }
    }
  };

  const onSubmit = useCallback(
    async (data: Inputs) => {
      try {
        const recaptcha = await recaptchaLoader.executeReCaptcha('submit');

        if (!recaptcha) throw new Error('Missing recaptcha token');
        if (!token) throw new Error('Missing token');

        const newRequest: User = {
          ...data,
          recaptcha,
          token,
        };

        const { surveyId } = await authStore.signUp(newRequest);

        if (surveyId) {
          history.push(`/tests/${surveyId}`);
        } else {
          history.push('/');
        }
      } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
          const errorData = error.response.data as { detail?: string; password?: string[] };
          if (errorData?.detail === 'Превышен лимит регистраций') {
            setShowErrorModal(true);
            return;
          }
          if (errorData?.detail) {
            setError(errorData.detail);
            formSetError('email', { message: errorData.detail });
            return;
          }
          if (errorData?.password && errorData?.password?.length) {
            setError(errorData.password[0]);
            formSetError('password', { message: errorData.password[0] });
            formSetError('confirmPassword', { message: errorData.password[0] });
            return;
          }
          return;
        }
        setError('Что-то пошло не так, попробуйте чуть позже');
      }
    },
    [recaptchaLoader, token, authStore, history],
  );

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Header variant="h1">Регистрация</Header>
      {errorMsg && (
        <FormItem>
          <ErrorsBlock errors={errorMsg} />
        </FormItem>
      )}

      <FormItem>
        <Input
          type="email"
          name="email"
          placeholder="Email"
          autoComplete="email"
          $error={Boolean(errors.email)}
          ref={register({ required: true })}
        />
      </FormItem>

      <FormItem>
        <InputPassword
          name="password"
          placeholder="Пароль"
          helper="Пароль должен быть не менее 8 символов"
          autoComplete="new-password"
          ref={register({ required: true, minLength: 8 })}
          $error={Boolean(errors.password)}
        />
      </FormItem>

      <FormItem>
        <InputPassword
          name="confirmPassword"
          placeholder="Подтвердите пароль"
          helper={
            errors.confirmPassword?.type === 'sameAsPassword'
              ? 'Пароли не совпадают'
              : 'Пароль должен быть не менее 8 символов'
          }
          autoComplete="new-password"
          $error={Boolean(errors.confirmPassword)}
          ref={register({
            required: true,
            validate: { sameAsPassword: (value) => value === password },
          })}
        />
      </FormItem>

      <FormItem>
        <Button type="submit" disabled={authStore.fetching}>
          Зарегистрироваться
        </Button>
      </FormItem>

      <FormItem>
        {errorPolicyLoadMessage && <MessageInfo variant="error" message={errorPolicyLoadMessage} />}
        <Checkbox name="consent" $error={Boolean(errors.consent)} ref={register({ required: true })}>
          Даю согласие на&nbsp;
          <LinkButton onClick={openPolicyHandler}>обработку персональных данных</LinkButton>
        </Checkbox>
      </FormItem>

      <ModalPrivacyPolicy
        showModal={showModal}
        handleCloseModal={() => setShowModal(false)}
        policyHTML={policyLoader.policyHTML}
      >
        {policyLoader.isLoading && <Circle />}
      </ModalPrivacyPolicy>
      <ModalWithButton
        title="Превышен лимит регистраций"
        handleButtonClick={handleCloseErrorModal}
        showModal={showErrorModal}
        handleCloseModal={() => setShowErrorModal(false)}
        buttonText="ок"
        description="Обратитесь к администратору или сотруднику, который направил вам ссылку"
      />
    </Form>
  );
});
export default RegisterForm;
