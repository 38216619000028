import React, { FC } from 'react';
import { ModalWithButton } from './index';
import { ModalWithButtonProps } from './ModalWithButton';

const FinishModalWithReport: FC<ModalWithButtonProps> = ({ title, showModal, handleButtonClick, handleCloseModal }) => {
  return (
    <ModalWithButton
      title={title}
      description="Вы можете ознакомиться с отчетом"
      buttonText="Перейти"
      showModal={showModal}
      handleButtonClick={handleButtonClick}
      handleCloseModal={handleCloseModal}
    />
  );
};

export default FinishModalWithReport;
