import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import sanitize from 'sanitize-html';
import { ChoiceAnswers, ProfileMaritalStatusEnum, ProfileSexEnum, ProfileView, ProfileViewSexEnum } from 'services/api';
import { pollsApi } from 'utils/pollsApi';
import { useApiErrorMessages } from 'hooks/useApiErrorMessages';
import { mediaQuery } from 'utils/themeUtils';

const NOT_SELECTED = 'Не выбрано';

const Wrapper = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.elements.blueDefault};
  color: ${({ theme }) => theme.colors.font.white};
  border-radius: 24px;
  padding: 40px;
  cursor: pointer;
  ${mediaQuery('sm')} {
    padding: 19px;
  }
`;

const UserId = styled.dl`
  display: block;
  font-size: 14px;
  margin-bottom: 20px;

  dt {
    opacity: 0.5;
    display: inline;
  }
`;

const UserName = styled.h2`
  font-size: 48px;
  line-height: 56px;
  margin-bottom: 40px;
  color: ${(props) => props.theme.colors.font.white};
  ${mediaQuery('sm')} {
    margin-bottom: 20px;
  }
`;

const InfoWrapper = styled.dl`
  display: flex;
  flex-wrap: wrap;
  row-gap: 18px;
  ${mediaQuery('sm')} {
    display: grid;
    grid-row-gap: 18px;
    grid-template-columns: repeat(2, 1fr);
  }
  ${mediaQuery('xs')} {
    grid-template-columns: 1fr;
  }
`;

const UserInfoItem = styled.dl`
  display: flex;
  flex-direction: column;
  font-size: 16px;
  line-height: 24px;
  white-space: nowrap;
  padding-right: 24px;

  &:not(:last-child) {
    margin-right: 24px;
    border-right: 1px solid rgba(255, 255, 255, 0.2);
    ${mediaQuery('md')} {
      margin-right: 0;
      border: none;
    }
  }

  dt {
    font-weight: 300;
    font-size: 12px;
    opacity: 0.5;
    margin-bottom: 4px;
  }
`;

interface CurrentUserInfoProps {
  data: ProfileView | null;
}

const UniSex = 'UniSex';

const mapMaritalStatus: Record<ProfileSexEnum | typeof UniSex, Record<ProfileMaritalStatusEnum, string>> = {
  [ProfileSexEnum.Male]: {
    [ProfileMaritalStatusEnum.Single]: 'Холост',
    [ProfileMaritalStatusEnum.Married]: 'Женат',
  },
  [ProfileSexEnum.Female]: {
    [ProfileMaritalStatusEnum.Single]: 'Не Замужем',
    [ProfileMaritalStatusEnum.Married]: 'Замужем',
  },
  UniSex: { [ProfileMaritalStatusEnum.Single]: 'Не в браке', [ProfileMaritalStatusEnum.Married]: 'В браке' },
};

const UserInfo: React.FC<CurrentUserInfoProps> = ({ data }) => {
  const [educationOptions, setEducationOptions] = useState<ChoiceAnswers[]>([]);
  const [, setError] = useApiErrorMessages();
  const getGender = () => {
    switch (data?.sex) {
      case ProfileViewSexEnum.Male:
        return 'Мужской';
      case ProfileViewSexEnum.Female:
        return 'Женский';
      default:
        return NOT_SELECTED;
    }
  };

  useEffect(() => {
    pollsApi
      .pollsChoicesList({ templateIn: 'education' })
      .then((res) => {
        setEducationOptions(res.data.results);
      })
      .catch(setError);
  }, [setError]);

  const maritalStatus = useMemo(
    () => (data?.maritalStatus ? mapMaritalStatus[data?.sex || UniSex][data?.maritalStatus] : NOT_SELECTED),
    [data?.maritalStatus, data?.sex],
  );

  const education = useMemo(() => {
    if (!data?.education) return 'Не заполнено';
    return educationOptions.find((item) => item.id === data?.education.id)?.text;
  }, [data?.education, educationOptions]);

  return (
    <Wrapper>
      <UserId>
        <dt>ID: </dt>
        {data?.id}
      </UserId>
      <UserName>{data?.fullName}</UserName>

      <InfoWrapper>
        <UserInfoItem>
          <dt>Телефон</dt>
          {data?.phone !== '' ? data?.phone : 'Не заполнено'}
        </UserInfoItem>

        <UserInfoItem>
          <dt>Пол</dt>
          {getGender()}
        </UserInfoItem>

        <UserInfoItem>
          <dt>Email</dt>
          {data?.email}
        </UserInfoItem>

        <UserInfoItem>
          <dt>Образование</dt>
          {sanitize(education ?? '', { allowedTags: [] })}
        </UserInfoItem>

        <UserInfoItem>
          <dt>Семейное положение</dt>
          {maritalStatus}
        </UserInfoItem>
      </InfoWrapper>
    </Wrapper>
  );
};

export default UserInfo;
