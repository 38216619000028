import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';
import { Input, Select } from '../../../components';
import StaffViewModel from '../viewModels/StaffViewModel';
import { StaffData } from '../types';

const SelectWrap = styled.div`
  position: relative;
  width: 100%;
  max-width: 330px;
  padding-top: 20px;
  p {
    position: absolute;
    top: -20px;
    color: ${({ theme }) => theme.colors.elements.warning};
  }
`;

type Poll = {
  value?: number;
  label: string | undefined;
};

type StaffTableEditableCellPropsType = {
  column: keyof StaffData;
  rowId?: number;
  defaultValue: string | Poll[];
  pollsData?: Poll[];
  disabled?: boolean;
  staff: StaffViewModel;
};

enum lableEnum {
  email = 'Email',
  phone = 'Телефон',
  fullName = 'Имя',
}

const StaffTableEditableCell: React.FC<StaffTableEditableCellPropsType> = observer(
  ({ column, rowId, defaultValue, pollsData, disabled, staff }) => {
    const value = staff.getValue(column);
    useEffect(() => {
      if (rowId === staff.editableRowId) staff.change(column, defaultValue);
    }, [rowId, column, staff.editableRowId]);

    if (typeof value === 'string') {
      if (staff.editableRowId !== rowId) return <p>{defaultValue}</p>;
      return (
        <Input
          disabled={disabled}
          type={column === 'email' ? 'email' : 'text'}
          label={lableEnum[column as 'email' | 'phone' | 'fullName']}
          variant="small"
          value={value}
          onChange={(event) => {
            staff.change(column, event.target.value);
          }}
        />
      );
    }
    if (staff.editableRowId !== rowId) {
      return (
        <>
          {(defaultValue as Poll[]).map((item) => (
            <p key={item.value}>{item.label}</p>
          ))}
        </>
      );
    }
    return (
      <>
        <SelectWrap>
          <Select
            className="small"
            placeholder="Выберите методику"
            value={value}
            options={pollsData}
            onChange={(val: Poll[]) => {
              staff.change(column, val);
            }}
            isMulti
          />
        </SelectWrap>
      </>
    );
  },
);

export default StaffTableEditableCell;
