import { FC } from 'react';
import { QuestionChoice, SurveyInfoAnswerStyleEnum } from '../../services';
import { AnswerControlProps } from '../../types/common';
import ColoredAnswerControl from '../ColoredAnswerControl';
import StraightAnswerControl from '../StraightAnswerControl';
import { QuestionItemDirection } from '../QuestionItem/types';

export interface QuestionItemControlProps<T extends SurveyInfoAnswerStyleEnum> {
  decision: QuestionChoice['id'] | null;
  answerStyle: T;
  choices: Array<QuestionChoice>;
  disableAnswerControl: boolean;
  onAnswer(decision: QuestionChoice['id']): void;
  itemDirection: QuestionItemDirection;
}

export const answerControlVariants: Record<SurveyInfoAnswerStyleEnum, FC<AnswerControlProps>> = {
  [SurveyInfoAnswerStyleEnum.Color]: ColoredAnswerControl,
  [SurveyInfoAnswerStyleEnum.Default]: StraightAnswerControl,
  [SurveyInfoAnswerStyleEnum.Horizontal]: () => null,
  [SurveyInfoAnswerStyleEnum.Vertical]: () => null,
  [SurveyInfoAnswerStyleEnum.Image]: () => null,
  [SurveyInfoAnswerStyleEnum.Scale]: () => null,
  [SurveyInfoAnswerStyleEnum.Expanded]: () => null,
};
