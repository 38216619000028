import { useAuthStore } from 'features/Auth';
import { useEffect, useState } from 'react';
import { Banner } from 'services/api';
import { coreApi } from 'utils/coreApi';
import { BannersService } from './types';

export const useBannersService = (): BannersService => {
  const { profile } = useAuthStore();
  const [banners, setBanners] = useState<Banner[]>([]);

  useEffect(() => {
    coreApi.coreBannerList({ limit: -1, page: 1 }).then((res) => setBanners(res.data.results));
  }, [profile]);

  return {
    banners,
  };
};
