import React, { forwardRef } from 'react';
import Creatable, { Props } from 'react-select/creatable';
import styled from 'styled-components';

type OptionType = {
  [index: string]: string;
};
interface SelectProps extends Props<OptionType, boolean> {
  className?: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ReactSelect: React.FC<SelectProps> = forwardRef<any, SelectProps>(
  (
    {
      className,
      defaultValue,
      options,
      isSearchable,
      placeholder,
      onChange,
      value,
      isMulti,
      isValidNewOption,
      onCreateOption,
    },
    ref,
  ) => (
    <Creatable
      inputRef={ref}
      className={className}
      classNamePrefix="Select"
      defaultValue={defaultValue}
      options={options}
      isSearchable={isSearchable}
      isFocused={true}
      placeholder={placeholder}
      onChange={onChange}
      value={value}
      isMulti={isMulti}
      isClearable={true}
      isValidNewOption={isValidNewOption}
      onCreateOption={onCreateOption}
    />
  ),
);

const StyledCreatableSelect = styled(ReactSelect)`
  .Select {
    &__control {
      border-radius: ${(props) => props.theme.borderRadius};
      min-height: 56px;

      &--is-focused {
        border-color: ${(props) => props.theme.colors.elements.blueDefault};
      }
    }

    &__value-container {
      max-height: 350px;
      overflow-y: auto;
      padding: 2px 16px;
    }

    &__single-value {
      color: ${(props) => props.theme.colors.font.main};
      font-weight: 400;
    }

    &__menu {
      border-radius: 24px;
      margin-top: 16px;
      margin-bottom: 16px;
    }

    &__menu-list {
      padding-top: 20px;
      padding-bottom: 20px;
    }

    &__option {
      padding: 12px 32px;
    }

    &__multi-value {
      font-size: 14px;
      border-radius: 24px;
      background-color: ${(props) => props.theme.colors.elements.blueDefault};
      color: ${(props) => props.theme.colors.font.white};
      padding: 4px 12px;
    }

    &__multi-value__label {
      color: ${(props) => props.theme.colors.font.white};
    }
  }

  &.small {
    .Select__control {
      min-height: 40px;
      font-size: 14px;
      font-weight: 300;
    }
  }
`;

export default StyledCreatableSelect;
