import React, { FC, useCallback, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';

import { IconClear, IconDownload, IconProfile } from '../../components/Icons';
import { useAuthStore } from '../../features/Auth';
import storeProfile from '../../servicesProfile';
import { Modal } from '../index';
import { Button, ErrorsBlock, MessageInfo } from '../../components';
import { useApiErrorMessages } from '../../hooks/useApiErrorMessages';
import { Avatar, BtnRow, DeletePhotoBtn, InputFileWrap, PhotoControl, PhotoRow } from './styled';

interface ModalPhotoProps {
  closeModal: () => void;
}

const MAX_AVATAR_SIZE_BYTES = 1024 * 1024 * 2;

const ModalPhoto: FC<ModalPhotoProps> = observer(({ closeModal }) => {
  const authStore = useAuthStore();
  const avatar = authStore.profile?.photo ?? null;
  const isLoading = storeProfile.fetching;
  const [errorMessages, setError] = useApiErrorMessages();

  const [finished, setFinished] = useState(false);
  const [isSuccess, setIsSuccess] = useState<'upload' | 'delete' | null>(null);

  const [newAvatar, setNewAvatar] = useState<undefined | File>();

  const preview = useMemo(() => newAvatar && URL.createObjectURL(newAvatar), [newAvatar]);

  const onSubmit = useCallback(() => {
    setIsSuccess(null);

    if (newAvatar) {
      storeProfile
        .uploadAvatar(newAvatar)
        .then(() => {
          setIsSuccess('upload');
          setFinished(true);
          authStore.getProfile();
        })
        .catch((err) => {
          setFinished(true);
          setError(err);
        });
    }
  }, [authStore, newAvatar, setError]);

  const changeAvatar = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setNewAvatar(e.currentTarget.files?.[0]);
  }, []);

  const onDeleteAvatar = () => {
    setIsSuccess(null);

    storeProfile
      .deleteAvatar()
      .then(() => {
        setNewAvatar(undefined);
        setIsSuccess('delete');
        setFinished(true);
        authStore.getProfile();
      })
      .catch((err) => setError(err));
  };

  const imageIsTooBig = Boolean(newAvatar && newAvatar.size > MAX_AVATAR_SIZE_BYTES);
  const shownImg = preview ?? avatar;

  return (
    <Modal showModal handleCloseModal={closeModal} title="Загрузить фотографию">
      {finished && !errorMessages && isSuccess === 'upload' && (
        <MessageInfo variant="success" message="Фото сохранено!" />
      )}
      {finished && !errorMessages && isSuccess === 'delete' && (
        <MessageInfo variant="success" message="Фото удалено!" />
      )}
      {imageIsTooBig && (
        <MessageInfo variant="error" message="Размер изображения не должен превышать 2 мегабайта!" timeout={Infinity} />
      )}

      <PhotoRow>
        <Avatar>
          {shownImg ? <img src={shownImg} alt="Аватар пользователя" /> : <IconProfile color="blueDefault" />}
        </Avatar>

        <PhotoControl>
          <InputFileWrap>
            <input type="file" name="file" onChange={changeAvatar} accept="image/*" />
            <IconDownload />
            Выбрать фотографию
          </InputFileWrap>

          {avatar && (
            <DeletePhotoBtn type="button" onClick={onDeleteAvatar} disabled={imageIsTooBig}>
              <IconClear /> Удалить фотографию
            </DeletePhotoBtn>
          )}
        </PhotoControl>
      </PhotoRow>

      {errorMessages && <ErrorsBlock errors={errorMessages} />}

      <BtnRow>
        <Button disabled={isLoading} onClick={closeModal} view="secondary">
          Отменить
        </Button>
        <Button disabled={isLoading || !newAvatar || imageIsTooBig} type="submit" onClick={onSubmit}>
          {isLoading ? 'Сохранение...' : 'Сохранить'}
        </Button>
      </BtnRow>
    </Modal>
  );
});

export default ModalPhoto;
