/*  eslint react/jsx-props-no-spreading: 0 */
import React from 'react';
import { Modal } from '../../components';
import { Header } from '../../components/Typography';

const ConsentModal = (props: any) => (
  <Modal {...props}>
    <Header variant="h2">Политика обработки персональных данных</Header>
  </Modal>
);

export default ConsentModal;
