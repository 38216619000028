import React, { useState } from 'react';
import styled from 'styled-components';
import ModalEditUser from '../../../modals/ModalEditUser/ModalEditUser';
import { ProfileIcon } from '../../ProfileIcon/ProfileIcon';

const Wrapper = styled.div`
  border-radius: 100px;
  box-shadow: none;
  cursor: pointer;
  background: white;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border: 1px solid white;
  & > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const ProfileButton: React.FC = () => {
  const [showModal, setShowModal] = useState(false);

  const onShowModal = () => setShowModal(true);

  const onCloseModal = () => setShowModal(false);

  return (
    <>
      <Wrapper onClick={() => onShowModal()}>
        <ProfileIcon />
      </Wrapper>
      <ModalEditUser showModal={showModal} closeModal={onCloseModal} />
    </>
  );
};
