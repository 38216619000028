/* tslint:disable */
/* eslint-disable */
/**
 * giuntiApi
 * Api for giunti
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AdminClients
 */
export interface AdminClients {
    /**
     * 
     * @type {number}
     * @memberof AdminClients
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof AdminClients
     */
    groupType: string;
    /**
     * 
     * @type {string}
     * @memberof AdminClients
     */
    fullName: string;
    /**
     * 
     * @type {string}
     * @memberof AdminClients
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof AdminClients
     */
    company: string;
    /**
     * 
     * @type {number}
     * @memberof AdminClients
     */
    numberOfRegistrations: number;
    /**
     * 
     * @type {number}
     * @memberof AdminClients
     */
    surveyAttempts: number;
    /**
     * 
     * @type {number}
     * @memberof AdminClients
     */
    surveyAttemptsRemaining: number;
    /**
     * 
     * @type {number}
     * @memberof AdminClients
     */
    pollsDone: number;
    /**
     * 
     * @type {number}
     * @memberof AdminClients
     */
    inProgress: number;
    /**
     * 
     * @type {boolean}
     * @memberof AdminClients
     */
    unlimitedRegistrations: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AdminClients
     */
    unlimitedAttempts: boolean;
}
/**
 * 
 * @export
 * @interface AdminPoll
 */
export interface AdminPoll {
    /**
     * 
     * @type {number}
     * @memberof AdminPoll
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof AdminPoll
     */
    survey: string;
    /**
     * 
     * @type {number}
     * @memberof AdminPoll
     */
    numberOfRegistrations: number;
    /**
     * 
     * @type {number}
     * @memberof AdminPoll
     */
    surveyAttempts: number;
    /**
     * 
     * @type {number}
     * @memberof AdminPoll
     */
    surveyAttemptsRemaining: number;
    /**
     * 
     * @type {number}
     * @memberof AdminPoll
     */
    pollsDone: number;
}
/**
 * 
 * @export
 * @interface AdminTotalStats
 */
export interface AdminTotalStats {
    /**
     * 
     * @type {number}
     * @memberof AdminTotalStats
     */
    totalSurvey: number;
    /**
     * 
     * @type {number}
     * @memberof AdminTotalStats
     */
    numberOfRegistrations: number;
    /**
     * 
     * @type {number}
     * @memberof AdminTotalStats
     */
    surveyAttempts: number;
    /**
     * 
     * @type {number}
     * @memberof AdminTotalStats
     */
    surveyAttemptsRemaining: number;
    /**
     * 
     * @type {number}
     * @memberof AdminTotalStats
     */
    pollsDone: number;
    /**
     * 
     * @type {boolean}
     * @memberof AdminTotalStats
     */
    unlimitedRegistrations: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AdminTotalStats
     */
    unlimitedAttempts: boolean;
}
/**
 * 
 * @export
 * @interface Answer
 */
export interface Answer {
    /**
     * 
     * @type {number}
     * @memberof Answer
     */
    question: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof Answer
     */
    choices?: Array<number> | null;
    /**
     * 
     * @type {number}
     * @memberof Answer
     */
    elapsedTime?: number;
    /**
     * 
     * @type {string}
     * @memberof Answer
     */
    text?: string | null;
}
/**
 * 
 * @export
 * @interface Banner
 */
export interface Banner {
    /**
     * 
     * @type {number}
     * @memberof Banner
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Banner
     */
    header: string;
    /**
     * 
     * @type {string}
     * @memberof Banner
     */
    content?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Banner
     */
    type?: BannerTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof Banner
     */
    template?: BannerTemplateEnum;
    /**
     * 
     * @type {number}
     * @memberof Banner
     */
    order?: number;
    /**
     * 
     * @type {number}
     * @memberof Banner
     */
    switchingTime?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum BannerTypeEnum {
    Critical = 'CRITICAL',
    ForClients = 'FOR_CLIENTS',
    ForUsers = 'FOR_USERS'
}
/**
    * @export
    * @enum {string}
    */
export enum BannerTemplateEnum {
    Blue1 = 'BLUE_1',
    Pink = 'PINK',
    Orange = 'ORANGE',
    DarkBlue = 'DARK_BLUE',
    White = 'WHITE',
    Blue2 = 'BLUE_2'
}

/**
 * 
 * @export
 * @interface ChildClientPoll
 */
export interface ChildClientPoll {
    /**
     * 
     * @type {number}
     * @memberof ChildClientPoll
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof ChildClientPoll
     */
    parentId: number;
    /**
     * 
     * @type {string}
     * @memberof ChildClientPoll
     */
    survey: string;
    /**
     * 
     * @type {number}
     * @memberof ChildClientPoll
     */
    registrations: number;
    /**
     * 
     * @type {number}
     * @memberof ChildClientPoll
     */
    registrationsRemaining: number;
    /**
     * 
     * @type {number}
     * @memberof ChildClientPoll
     */
    surveyAttempts: number;
    /**
     * 
     * @type {number}
     * @memberof ChildClientPoll
     */
    surveyAttemptsRemaining: number;
    /**
     * 
     * @type {number}
     * @memberof ChildClientPoll
     */
    inProgress?: number;
    /**
     * 
     * @type {number}
     * @memberof ChildClientPoll
     */
    pollsDone: number;
    /**
     * 
     * @type {number}
     * @memberof ChildClientPoll
     */
    numberOfRegistrations: number;
}
/**
 * 
 * @export
 * @interface Choice
 */
export interface Choice {
    /**
     * 
     * @type {string}
     * @memberof Choice
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof Choice
     */
    name?: string;
}
/**
 * 
 * @export
 * @interface ChoiceAnswers
 */
export interface ChoiceAnswers {
    /**
     * 
     * @type {number}
     * @memberof ChoiceAnswers
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ChoiceAnswers
     */
    text: string;
    /**
     * 
     * @type {string}
     * @memberof ChoiceAnswers
     */
    slug?: string | null;
    /**
     * 
     * @type {Array<ChoiceOrder>}
     * @memberof ChoiceAnswers
     */
    template?: Array<ChoiceOrder>;
}
/**
 * 
 * @export
 * @interface ChoiceOrder
 */
export interface ChoiceOrder {
    /**
     * 
     * @type {number}
     * @memberof ChoiceOrder
     */
    order?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ChoiceOrder
     */
    slug: string;
}
/**
 * 
 * @export
 * @interface ClientConsultant
 */
export interface ClientConsultant {
    /**
     * 
     * @type {number}
     * @memberof ClientConsultant
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ClientConsultant
     */
    fullName: string;
    /**
     * 
     * @type {string}
     * @memberof ClientConsultant
     */
    phone: string;
    /**
     * 
     * @type {string}
     * @memberof ClientConsultant
     */
    email: string;
    /**
     * 
     * @type {Array<ChildClientPoll>}
     * @memberof ClientConsultant
     */
    polls: Array<ChildClientPoll>;
    /**
     * 
     * @type {number}
     * @memberof ClientConsultant
     */
    pollsTotal: number;
    /**
     * 
     * @type {string}
     * @memberof ClientConsultant
     */
    groupType: string;
    /**
     * 
     * @type {number}
     * @memberof ClientConsultant
     */
    numberOfRegistrations: number;
    /**
     * 
     * @type {number}
     * @memberof ClientConsultant
     */
    surveyAttempts: number;
    /**
     * 
     * @type {number}
     * @memberof ClientConsultant
     */
    surveyAttemptsRemaining: number;
    /**
     * 
     * @type {number}
     * @memberof ClientConsultant
     */
    pollsDone: number;
}
/**
 * 
 * @export
 * @interface ClientPollAnnotate
 */
export interface ClientPollAnnotate {
    /**
     * 
     * @type {number}
     * @memberof ClientPollAnnotate
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ClientPollAnnotate
     */
    survey: string;
    /**
     * 
     * @type {boolean}
     * @memberof ClientPollAnnotate
     */
    userCanDownloadReport: boolean;
    /**
     * 
     * @type {number}
     * @memberof ClientPollAnnotate
     */
    registrations: number;
    /**
     * 
     * @type {number}
     * @memberof ClientPollAnnotate
     */
    registrationsRemaining: number;
    /**
     * 
     * @type {number}
     * @memberof ClientPollAnnotate
     */
    surveyAttempts: number;
    /**
     * 
     * @type {number}
     * @memberof ClientPollAnnotate
     */
    surveyAttemptsRemaining: number;
    /**
     * 
     * @type {number}
     * @memberof ClientPollAnnotate
     */
    inProgress?: number;
    /**
     * 
     * @type {number}
     * @memberof ClientPollAnnotate
     */
    pollsDone: number;
    /**
     * 
     * @type {number}
     * @memberof ClientPollAnnotate
     */
    numberOfRegistrations: number;
    /**
     * 
     * @type {boolean}
     * @memberof ClientPollAnnotate
     */
    unlimitedRegistrations: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ClientPollAnnotate
     */
    unlimitedAttempts: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ClientPollAnnotate
     */
    clientCanDownloadResearchReport?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ClientPollAnnotate
     */
    researchReportViewed?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ClientPollAnnotate
     */
    createdOn?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ClientPollAnnotate
     */
    isDante?: boolean;
}
/**
 * 
 * @export
 * @interface ClientStat
 */
export interface ClientStat {
    /**
     * 
     * @type {number}
     * @memberof ClientStat
     */
    total: number;
    /**
     * 
     * @type {number}
     * @memberof ClientStat
     */
    numberOfRegistrations: number;
    /**
     * 
     * @type {number}
     * @memberof ClientStat
     */
    surveyAttempts: number;
    /**
     * 
     * @type {number}
     * @memberof ClientStat
     */
    surveyAttemptsRemaining: number;
    /**
     * 
     * @type {number}
     * @memberof ClientStat
     */
    pollsDone: number;
    /**
     * 
     * @type {boolean}
     * @memberof ClientStat
     */
    unlimitedRegistrations: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ClientStat
     */
    unlimitedAttempts: boolean;
}
/**
 * 
 * @export
 * @interface CreateFeedback
 */
export interface CreateFeedback {
    /**
     * 
     * @type {string}
     * @memberof CreateFeedback
     */
    theme?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateFeedback
     */
    content: string;
}
/**
 * 
 * @export
 * @interface CreateStaff
 */
export interface CreateStaff {
    /**
     * 
     * @type {number}
     * @memberof CreateStaff
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateStaff
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof CreateStaff
     */
    fullName?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateStaff
     */
    phone: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof CreateStaff
     */
    polls: Array<number>;
}
/**
 * 
 * @export
 * @interface CreateUserResponse
 */
export interface CreateUserResponse {
    /**
     * 
     * @type {number}
     * @memberof CreateUserResponse
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateUserResponse
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserResponse
     */
    fullName: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserResponse
     */
    phone: string;
}
/**
 * 
 * @export
 * @interface DanteInfo
 */
export interface DanteInfo {
    /**
     * 
     * @type {string}
     * @memberof DanteInfo
     */
    questionnaireId: string;
    /**
     * 
     * @type {string}
     * @memberof DanteInfo
     */
    url: string;
}
/**
 * 
 * @export
 * @interface EducationChoice
 */
export interface EducationChoice {
    /**
     * 
     * @type {number}
     * @memberof EducationChoice
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof EducationChoice
     */
    slug?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EducationChoice
     */
    text: string;
}
/**
 * 
 * @export
 * @interface InlineResponse200
 */
export interface InlineResponse200 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse200
     */
    count: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse200
     */
    next?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse200
     */
    previous?: string | null;
    /**
     * 
     * @type {Array<Banner>}
     * @memberof InlineResponse200
     */
    results: Array<Banner>;
}
/**
 * 
 * @export
 * @interface InlineResponse2001
 */
export interface InlineResponse2001 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse2001
     */
    count: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse2001
     */
    next?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse2001
     */
    previous?: string | null;
    /**
     * 
     * @type {Array<AdminClients>}
     * @memberof InlineResponse2001
     */
    results: Array<AdminClients>;
}
/**
 * 
 * @export
 * @interface InlineResponse20010
 */
export interface InlineResponse20010 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20010
     */
    count: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20010
     */
    next?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20010
     */
    previous?: string | null;
    /**
     * 
     * @type {Array<UserAccess>}
     * @memberof InlineResponse20010
     */
    results: Array<UserAccess>;
}
/**
 * 
 * @export
 * @interface InlineResponse20011
 */
export interface InlineResponse20011 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20011
     */
    count: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20011
     */
    next?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20011
     */
    previous?: string | null;
    /**
     * 
     * @type {Array<UserEmailAccess>}
     * @memberof InlineResponse20011
     */
    results: Array<UserEmailAccess>;
}
/**
 * 
 * @export
 * @interface InlineResponse2002
 */
export interface InlineResponse2002 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse2002
     */
    count: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse2002
     */
    next?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse2002
     */
    previous?: string | null;
    /**
     * 
     * @type {Array<AdminPoll>}
     * @memberof InlineResponse2002
     */
    results: Array<AdminPoll>;
}
/**
 * 
 * @export
 * @interface InlineResponse2003
 */
export interface InlineResponse2003 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse2003
     */
    count: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse2003
     */
    next?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse2003
     */
    previous?: string | null;
    /**
     * 
     * @type {Array<ClientPollAnnotate>}
     * @memberof InlineResponse2003
     */
    results: Array<ClientPollAnnotate>;
}
/**
 * 
 * @export
 * @interface InlineResponse2004
 */
export interface InlineResponse2004 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse2004
     */
    count: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse2004
     */
    next?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse2004
     */
    previous?: string | null;
    /**
     * 
     * @type {Array<ClientConsultant>}
     * @memberof InlineResponse2004
     */
    results: Array<ClientConsultant>;
}
/**
 * 
 * @export
 * @interface InlineResponse2005
 */
export interface InlineResponse2005 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse2005
     */
    count: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse2005
     */
    next?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse2005
     */
    previous?: string | null;
    /**
     * 
     * @type {Array<UserPolls>}
     * @memberof InlineResponse2005
     */
    results: Array<UserPolls>;
}
/**
 * 
 * @export
 * @interface InlineResponse2006
 */
export interface InlineResponse2006 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse2006
     */
    count: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse2006
     */
    next?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse2006
     */
    previous?: string | null;
    /**
     * 
     * @type {Array<Choice>}
     * @memberof InlineResponse2006
     */
    results: Array<Choice>;
}
/**
 * 
 * @export
 * @interface InlineResponse2007
 */
export interface InlineResponse2007 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse2007
     */
    count: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse2007
     */
    next?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse2007
     */
    previous?: string | null;
    /**
     * 
     * @type {Array<Poll>}
     * @memberof InlineResponse2007
     */
    results: Array<Poll>;
}
/**
 * 
 * @export
 * @interface InlineResponse2008
 */
export interface InlineResponse2008 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse2008
     */
    count: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse2008
     */
    next?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse2008
     */
    previous?: string | null;
    /**
     * 
     * @type {Array<ChoiceAnswers>}
     * @memberof InlineResponse2008
     */
    results: Array<ChoiceAnswers>;
}
/**
 * 
 * @export
 * @interface InlineResponse2009
 */
export interface InlineResponse2009 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse2009
     */
    count: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse2009
     */
    next?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse2009
     */
    previous?: string | null;
    /**
     * 
     * @type {Array<QuestionGroup>}
     * @memberof InlineResponse2009
     */
    results: Array<QuestionGroup>;
}
/**
 * 
 * @export
 * @interface PaginatePoll
 */
export interface PaginatePoll {
    /**
     * 
     * @type {number}
     * @memberof PaginatePoll
     */
    count: number;
    /**
     * 
     * @type {string}
     * @memberof PaginatePoll
     */
    next?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PaginatePoll
     */
    previous?: string | null;
    /**
     * 
     * @type {Array<Poll>}
     * @memberof PaginatePoll
     */
    results?: Array<Poll>;
}
/**
 * 
 * @export
 * @interface PhotoUpload
 */
export interface PhotoUpload {
    /**
     * 
     * @type {string}
     * @memberof PhotoUpload
     */
    photo?: string | null;
}
/**
 * 
 * @export
 * @interface Poll
 */
export interface Poll {
    /**
     * 
     * @type {number}
     * @memberof Poll
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Poll
     */
    surveyId?: string;
    /**
     * 
     * @type {string}
     * @memberof Poll
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof Poll
     */
    state?: PollStateEnum;
    /**
     * 
     * @type {string}
     * @memberof Poll
     */
    startTime?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Poll
     */
    endTime?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Poll
     */
    sessionStartTime?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Poll
     */
    timeIsUp?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Poll
     */
    userCanDownloadReport: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Poll
     */
    clientCanDownloadReport: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Poll
     */
    clientCanDownloadResearchReport?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Poll
     */
    maxTime: number;
    /**
     * 
     * @type {number}
     * @memberof Poll
     */
    userSurveyId: number;
    /**
     * 
     * @type {boolean}
     * @memberof Poll
     */
    userCanViewReport?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Poll
     */
    reportState?: PollReportStateEnum;
    /**
     * 
     * @type {string}
     * @memberof Poll
     */
    structureType: PollStructureTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof Poll
     */
    reportViewed: boolean;
    /**
     * 
     * @type {number}
     * @memberof Poll
     */
    questionsTotalCount?: number;
    /**
     * 
     * @type {number}
     * @memberof Poll
     */
    answersTotalCount?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Poll
     */
    isDante?: boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum PollStateEnum {
    New = 'NEW',
    InProgress = 'IN_PROGRESS',
    OnPause = 'ON_PAUSE',
    Done = 'DONE'
}
/**
    * @export
    * @enum {string}
    */
export enum PollReportStateEnum {
    NonExist = 'NON_EXIST',
    InProgress = 'IN_PROGRESS',
    Error = 'ERROR',
    Done = 'DONE'
}
/**
    * @export
    * @enum {string}
    */
export enum PollStructureTypeEnum {
    SingleSurvey = 'SINGLE_SURVEY',
    SurveyGroup = 'SURVEY_GROUP'
}

/**
 * 
 * @export
 * @interface PrivacyPolicy
 */
export interface PrivacyPolicy {
    /**
     * 
     * @type {string}
     * @memberof PrivacyPolicy
     */
    text: string;
}
/**
 * 
 * @export
 * @interface Profile
 */
export interface Profile {
    /**
     * 
     * @type {number}
     * @memberof Profile
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    role?: ProfileRoleEnum;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    groupType?: ProfileGroupTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    createdBy?: string;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    privacyPolicy?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Profile
     */
    canAddStaff?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Profile
     */
    canAccessDashboard?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    company?: string;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    fullName?: string;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    phone?: string;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    sex?: ProfileSexEnum;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    maritalStatus?: ProfileMaritalStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    photo?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Profile
     */
    notificationTestEmail?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Profile
     */
    notificationPromotionEmail?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Profile
     */
    notificationPromotionSystem?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Profile
     */
    notificationSaleEmail?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Profile
     */
    notificationSaleSystem?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Profile
     */
    education?: number | null;
}

/**
    * @export
    * @enum {string}
    */
export enum ProfileRoleEnum {
    Admin = 'ADMIN',
    Client = 'CLIENT',
    User = 'USER'
}
/**
    * @export
    * @enum {string}
    */
export enum ProfileGroupTypeEnum {
    ClientConsultant = 'CLIENT_CONSULTANT',
    ClientCompany = 'CLIENT_COMPANY',
    Staff = 'STAFF',
    Admin = 'ADMIN',
    User = 'USER'
}
/**
    * @export
    * @enum {string}
    */
export enum ProfileSexEnum {
    Male = 'MALE',
    Female = 'FEMALE'
}
/**
    * @export
    * @enum {string}
    */
export enum ProfileMaritalStatusEnum {
    Single = 'SINGLE',
    Married = 'MARRIED'
}

/**
 * 
 * @export
 * @interface ProfileView
 */
export interface ProfileView {
    /**
     * 
     * @type {number}
     * @memberof ProfileView
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ProfileView
     */
    role?: ProfileViewRoleEnum;
    /**
     * 
     * @type {string}
     * @memberof ProfileView
     */
    groupType?: ProfileViewGroupTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ProfileView
     */
    createdBy?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileView
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileView
     */
    privacyPolicy?: string;
    /**
     * 
     * @type {EducationChoice}
     * @memberof ProfileView
     */
    education: EducationChoice;
    /**
     * 
     * @type {string}
     * @memberof ProfileView
     */
    photo?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ProfileView
     */
    canAddStaff?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProfileView
     */
    canAccessDashboard?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ProfileView
     */
    company?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileView
     */
    fullName?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileView
     */
    phone?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileView
     */
    sex?: ProfileViewSexEnum;
    /**
     * 
     * @type {string}
     * @memberof ProfileView
     */
    maritalStatus?: ProfileViewMaritalStatusEnum;
    /**
     * 
     * @type {boolean}
     * @memberof ProfileView
     */
    notificationTestEmail?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProfileView
     */
    notificationPromotionEmail?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProfileView
     */
    notificationPromotionSystem?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProfileView
     */
    notificationSaleEmail?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProfileView
     */
    notificationSaleSystem?: boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum ProfileViewRoleEnum {
    Admin = 'ADMIN',
    Client = 'CLIENT',
    User = 'USER'
}
/**
    * @export
    * @enum {string}
    */
export enum ProfileViewGroupTypeEnum {
    ClientConsultant = 'CLIENT_CONSULTANT',
    ClientCompany = 'CLIENT_COMPANY',
    Staff = 'STAFF',
    Admin = 'ADMIN',
    User = 'USER'
}
/**
    * @export
    * @enum {string}
    */
export enum ProfileViewSexEnum {
    Male = 'MALE',
    Female = 'FEMALE'
}
/**
    * @export
    * @enum {string}
    */
export enum ProfileViewMaritalStatusEnum {
    Single = 'SINGLE',
    Married = 'MARRIED'
}

/**
 * 
 * @export
 * @interface ProjectSettings
 */
export interface ProjectSettings {
    /**
     * 
     * @type {string}
     * @memberof ProjectSettings
     */
    salesLink?: string | null;
}
/**
 * 
 * @export
 * @interface Question
 */
export interface Question {
    /**
     * 
     * @type {number}
     * @memberof Question
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof Question
     */
    text?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Question
     */
    answerCountType?: QuestionAnswerCountTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof Question
     */
    answerStyle?: QuestionAnswerStyleEnum;
    /**
     * 
     * @type {Array<QuestionChoice>}
     * @memberof Question
     */
    choices?: Array<QuestionChoice>;
    /**
     * 
     * @type {number}
     * @memberof Question
     */
    number: number;
    /**
     * 
     * @type {number}
     * @memberof Question
     */
    minAnswerCount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Question
     */
    maxAnswerCount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Question
     */
    group?: number | null;
}

/**
    * @export
    * @enum {string}
    */
export enum QuestionAnswerCountTypeEnum {
    OnlyOne = 'ONLY_ONE',
    Multiple = 'MULTIPLE'
}
/**
    * @export
    * @enum {string}
    */
export enum QuestionAnswerStyleEnum {
    Color = 'COLOR',
    Default = 'DEFAULT',
    Horizontal = 'HORIZONTAL',
    Vertical = 'VERTICAL',
    Image = 'IMAGE',
    Scale = 'SCALE',
    Expanded = 'EXPANDED'
}

/**
 * 
 * @export
 * @interface QuestionChoice
 */
export interface QuestionChoice {
    /**
     * 
     * @type {number}
     * @memberof QuestionChoice
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof QuestionChoice
     */
    text: string;
    /**
     * 
     * @type {string}
     * @memberof QuestionChoice
     */
    slug?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof QuestionChoice
     */
    expanded?: boolean;
}
/**
 * 
 * @export
 * @interface QuestionGroup
 */
export interface QuestionGroup {
    /**
     * 
     * @type {number}
     * @memberof QuestionGroup
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof QuestionGroup
     */
    text?: string | null;
    /**
     * 
     * @type {number}
     * @memberof QuestionGroup
     */
    cols?: number;
    /**
     * 
     * @type {boolean}
     * @memberof QuestionGroup
     */
    hideSameChoices?: boolean;
    /**
     * 
     * @type {number}
     * @memberof QuestionGroup
     */
    survey?: number | null;
    /**
     * 
     * @type {number}
     * @memberof QuestionGroup
     */
    subSurvey?: number | null;
    /**
     * 
     * @type {number}
     * @memberof QuestionGroup
     */
    questionsNumber: number | null;
}
/**
 * 
 * @export
 * @interface QuestionnaireJSONSchema
 */
export interface QuestionnaireJSONSchema {
    /**
     * 
     * @type {string}
     * @memberof QuestionnaireJSONSchema
     */
    type?: string;
    /**
     * 
     * @type {object}
     * @memberof QuestionnaireJSONSchema
     */
    properties?: object;
    /**
     * 
     * @type {Array<string>}
     * @memberof QuestionnaireJSONSchema
     */
    required?: Array<string>;
    /**
     * 
     * @type {object}
     * @memberof QuestionnaireJSONSchema
     */
    $customView?: object;
}
/**
 * 
 * @export
 * @interface RecaptchaEmail
 */
export interface RecaptchaEmail {
    /**
     * 
     * @type {string}
     * @memberof RecaptchaEmail
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof RecaptchaEmail
     */
    recaptcha: string;
}
/**
 * 
 * @export
 * @interface RecaptchaPasswordToken
 */
export interface RecaptchaPasswordToken {
    /**
     * 
     * @type {string}
     * @memberof RecaptchaPasswordToken
     */
    password: string;
    /**
     * 
     * @type {string}
     * @memberof RecaptchaPasswordToken
     */
    token: string;
    /**
     * 
     * @type {string}
     * @memberof RecaptchaPasswordToken
     */
    recaptcha: string;
}
/**
 * 
 * @export
 * @interface RequestRegistration
 */
export interface RequestRegistration {
    /**
     * 
     * @type {string}
     * @memberof RequestRegistration
     */
    requestType: RequestRegistrationRequestTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof RequestRegistration
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof RequestRegistration
     */
    password: string;
    /**
     * 
     * @type {boolean}
     * @memberof RequestRegistration
     */
    isActive?: boolean;
    /**
     * 
     * @type {string}
     * @memberof RequestRegistration
     */
    recaptcha: string;
}

/**
    * @export
    * @enum {string}
    */
export enum RequestRegistrationRequestTypeEnum {
    Consultant = 'CLIENT_CONSULTANT',
    Company = 'CLIENT_COMPANY'
}

/**
 * 
 * @export
 * @interface RequestRegistrationResponse
 */
export interface RequestRegistrationResponse {
    /**
     * 
     * @type {string}
     * @memberof RequestRegistrationResponse
     */
    email: string;
    /**
     * Отметьте, если пользователь должен считаться активным. Уберите эту отметку вместо удаления учётной записи.
     * @type {boolean}
     * @memberof RequestRegistrationResponse
     */
    isActive?: boolean;
}
/**
 * 
 * @export
 * @interface SubSurvey
 */
export interface SubSurvey {
    /**
     * 
     * @type {string}
     * @memberof SubSurvey
     */
    surveyName: string;
    /**
     * 
     * @type {string}
     * @memberof SubSurvey
     */
    surveyFullTextName: string;
    /**
     * 
     * @type {string}
     * @memberof SubSurvey
     */
    surveyDescription: string;
    /**
     * 
     * @type {number}
     * @memberof SubSurvey
     */
    minimalAnswersCount: number;
    /**
     * 
     * @type {string}
     * @memberof SubSurvey
     */
    startTime: string;
    /**
     * 
     * @type {string}
     * @memberof SubSurvey
     */
    endTime?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SubSurvey
     */
    timeForTesting?: number | null;
    /**
     * 
     * @type {string}
     * @memberof SubSurvey
     */
    sessionStartTime?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof SubSurvey
     */
    timeIsUp?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SubSurvey
     */
    canPauseTesting: boolean;
    /**
     * 
     * @type {Array<SubSurveyQuestion>}
     * @memberof SubSurvey
     */
    questions?: Array<SubSurveyQuestion>;
}
/**
 * 
 * @export
 * @interface SubSurveyAnswer
 */
export interface SubSurveyAnswer {
    /**
     * 
     * @type {number}
     * @memberof SubSurveyAnswer
     */
    question: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof SubSurveyAnswer
     */
    choices?: Array<number> | null;
    /**
     * 
     * @type {number}
     * @memberof SubSurveyAnswer
     */
    elapsedTime?: number;
    /**
     * 
     * @type {string}
     * @memberof SubSurveyAnswer
     */
    text?: string | null;
}
/**
 * 
 * @export
 * @interface SubSurveyInfo
 */
export interface SubSurveyInfo {
    /**
     * 
     * @type {number}
     * @memberof SubSurveyInfo
     */
    surveyId: number;
    /**
     * 
     * @type {string}
     * @memberof SubSurveyInfo
     */
    surveyName: string;
    /**
     * 
     * @type {string}
     * @memberof SubSurveyInfo
     */
    surveyFullTextName: string;
    /**
     * 
     * @type {string}
     * @memberof SubSurveyInfo
     */
    surveyDescription: string;
    /**
     * 
     * @type {string}
     * @memberof SubSurveyInfo
     */
    state?: SubSurveyInfoStateEnum;
    /**
     * 
     * @type {string}
     * @memberof SubSurveyInfo
     */
    answerStyle: SubSurveyInfoAnswerStyleEnum;
    /**
     * 
     * @type {string}
     * @memberof SubSurveyInfo
     */
    startTime?: string;
    /**
     * 
     * @type {string}
     * @memberof SubSurveyInfo
     */
    endTime?: string;
    /**
     * 
     * @type {number}
     * @memberof SubSurveyInfo
     */
    timeForTesting?: number;
    /**
     * 
     * @type {string}
     * @memberof SubSurveyInfo
     */
    sessionStartTime?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SubSurveyInfo
     */
    timeIsUp?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SubSurveyInfo
     */
    canPauseTesting: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SubSurveyInfo
     */
    canRetryTest: boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum SubSurveyInfoStateEnum {
    New = 'NEW',
    InProgress = 'IN_PROGRESS',
    OnPause = 'ON_PAUSE',
    Done = 'DONE'
}
/**
    * @export
    * @enum {string}
    */
export enum SubSurveyInfoAnswerStyleEnum {
    Color = 'COLOR',
    Default = 'DEFAULT',
    Horizontal = 'HORIZONTAL',
    Vertical = 'VERTICAL',
    Image = 'IMAGE',
    Scale = 'SCALE',
    Expanded = 'EXPANDED'
}

/**
 * 
 * @export
 * @interface SubSurveyQuestion
 */
export interface SubSurveyQuestion {
    /**
     * 
     * @type {number}
     * @memberof SubSurveyQuestion
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof SubSurveyQuestion
     */
    text?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SubSurveyQuestion
     */
    answerCountType?: SubSurveyQuestionAnswerCountTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof SubSurveyQuestion
     */
    answerStyle?: SubSurveyQuestionAnswerStyleEnum;
    /**
     * 
     * @type {Array<QuestionChoice>}
     * @memberof SubSurveyQuestion
     */
    choices?: Array<QuestionChoice>;
    /**
     * 
     * @type {number}
     * @memberof SubSurveyQuestion
     */
    number: number;
    /**
     * 
     * @type {number}
     * @memberof SubSurveyQuestion
     */
    minAnswerCount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SubSurveyQuestion
     */
    maxAnswerCount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SubSurveyQuestion
     */
    group?: number | null;
}

/**
    * @export
    * @enum {string}
    */
export enum SubSurveyQuestionAnswerCountTypeEnum {
    OnlyOne = 'ONLY_ONE',
    Multiple = 'MULTIPLE'
}
/**
    * @export
    * @enum {string}
    */
export enum SubSurveyQuestionAnswerStyleEnum {
    Color = 'COLOR',
    Default = 'DEFAULT',
    Horizontal = 'HORIZONTAL',
    Vertical = 'VERTICAL',
    Image = 'IMAGE',
    Scale = 'SCALE',
    Expanded = 'EXPANDED'
}

/**
 * 
 * @export
 * @interface SubSurveyRelated
 */
export interface SubSurveyRelated {
    /**
     * 
     * @type {number}
     * @memberof SubSurveyRelated
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof SubSurveyRelated
     */
    surveyName: string;
    /**
     * 
     * @type {string}
     * @memberof SubSurveyRelated
     */
    surveyFullTextName: string;
    /**
     * 
     * @type {string}
     * @memberof SubSurveyRelated
     */
    state?: SubSurveyRelatedStateEnum;
    /**
     * 
     * @type {boolean}
     * @memberof SubSurveyRelated
     */
    canRetryTest: boolean;
    /**
     * 
     * @type {number}
     * @memberof SubSurveyRelated
     */
    questionsCount: number;
    /**
     * 
     * @type {number}
     * @memberof SubSurveyRelated
     */
    answersCount: number;
}

/**
    * @export
    * @enum {string}
    */
export enum SubSurveyRelatedStateEnum {
    New = 'NEW',
    InProgress = 'IN_PROGRESS',
    OnPause = 'ON_PAUSE',
    Done = 'DONE'
}

/**
 * 
 * @export
 * @interface SubSurveyState
 */
export interface SubSurveyState {
    /**
     * 
     * @type {string}
     * @memberof SubSurveyState
     */
    state: SubSurveyStateStateEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum SubSurveyStateStateEnum {
    InProgress = 'IN_PROGRESS',
    OnPause = 'ON_PAUSE',
    Done = 'DONE'
}

/**
 * 
 * @export
 * @interface Survey
 */
export interface Survey {
    /**
     * 
     * @type {string}
     * @memberof Survey
     */
    surveyName: string;
    /**
     * 
     * @type {string}
     * @memberof Survey
     */
    surveyFullTextName: string;
    /**
     * 
     * @type {string}
     * @memberof Survey
     */
    surveyDescription: string;
    /**
     * 
     * @type {number}
     * @memberof Survey
     */
    minimalAnswersCount: number;
    /**
     * 
     * @type {string}
     * @memberof Survey
     */
    startTime: string;
    /**
     * 
     * @type {string}
     * @memberof Survey
     */
    endTime?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Survey
     */
    sessionStartTime?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Survey
     */
    timeForTesting?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof Survey
     */
    timeIsUp?: boolean;
    /**
     * 
     * @type {Array<Question>}
     * @memberof Survey
     */
    questions?: Array<Question>;
    /**
     * 
     * @type {string}
     * @memberof Survey
     */
    structureType: SurveyStructureTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof Survey
     */
    canPauseTesting: boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum SurveyStructureTypeEnum {
    SingleSurvey = 'SINGLE_SURVEY',
    SurveyGroup = 'SURVEY_GROUP'
}

/**
 * 
 * @export
 * @interface SurveyAdditional
 */
export interface SurveyAdditional {
    /**
     * 
     * @type {number}
     * @memberof SurveyAdditional
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof SurveyAdditional
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof SurveyAdditional
     */
    fullTextName: string;
    /**
     * 
     * @type {string}
     * @memberof SurveyAdditional
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof SurveyAdditional
     */
    state?: SurveyAdditionalStateEnum;
    /**
     * 
     * @type {number}
     * @memberof SurveyAdditional
     */
    next?: number | null;
}

/**
    * @export
    * @enum {string}
    */
export enum SurveyAdditionalStateEnum {
    New = 'NEW',
    Done = 'DONE'
}

/**
 * 
 * @export
 * @interface SurveyAdditionalInfo
 */
export interface SurveyAdditionalInfo {
    /**
     * 
     * @type {number}
     * @memberof SurveyAdditionalInfo
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof SurveyAdditionalInfo
     */
    surveyName: string;
    /**
     * 
     * @type {string}
     * @memberof SurveyAdditionalInfo
     */
    surveyFullTextName: string;
    /**
     * 
     * @type {string}
     * @memberof SurveyAdditionalInfo
     */
    surveyDescription: string;
    /**
     * 
     * @type {string}
     * @memberof SurveyAdditionalInfo
     */
    state?: SurveyAdditionalInfoStateEnum;
    /**
     * 
     * @type {number}
     * @memberof SurveyAdditionalInfo
     */
    next?: number | null;
}

/**
    * @export
    * @enum {string}
    */
export enum SurveyAdditionalInfoStateEnum {
    New = 'NEW',
    Done = 'DONE'
}

/**
 * 
 * @export
 * @interface SurveyAdditionalState
 */
export interface SurveyAdditionalState {
    /**
     * 
     * @type {string}
     * @memberof SurveyAdditionalState
     */
    state: SurveyAdditionalStateStateEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum SurveyAdditionalStateStateEnum {
    Done = 'DONE'
}

/**
 * 
 * @export
 * @interface SurveyId
 */
export interface SurveyId {
    /**
     * 
     * @type {number}
     * @memberof SurveyId
     */
    surveyId: number | null;
}
/**
 * 
 * @export
 * @interface SurveyInfo
 */
export interface SurveyInfo {
    /**
     * 
     * @type {number}
     * @memberof SurveyInfo
     */
    surveyId: number;
    /**
     * 
     * @type {string}
     * @memberof SurveyInfo
     */
    surveyName: string;
    /**
     * 
     * @type {string}
     * @memberof SurveyInfo
     */
    surveyFullTextName: string;
    /**
     * 
     * @type {string}
     * @memberof SurveyInfo
     */
    surveyDescription: string;
    /**
     * 
     * @type {string}
     * @memberof SurveyInfo
     */
    state?: SurveyInfoStateEnum;
    /**
     * 
     * @type {Array<SubSurveyRelated>}
     * @memberof SurveyInfo
     */
    subStates?: Array<SubSurveyRelated>;
    /**
     * 
     * @type {string}
     * @memberof SurveyInfo
     */
    startTime?: string;
    /**
     * 
     * @type {string}
     * @memberof SurveyInfo
     */
    endTime?: string;
    /**
     * 
     * @type {number}
     * @memberof SurveyInfo
     */
    timeForTesting?: number;
    /**
     * 
     * @type {string}
     * @memberof SurveyInfo
     */
    sessionStartTime?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SurveyInfo
     */
    timeIsUp?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SurveyInfo
     */
    answerStyle: SurveyInfoAnswerStyleEnum;
    /**
     * 
     * @type {SurveyAdditional}
     * @memberof SurveyInfo
     */
    questionnaires?: SurveyAdditional;
    /**
     * 
     * @type {boolean}
     * @memberof SurveyInfo
     */
    questionnairesCompleted?: boolean;
    /**
     * 
     * @type {number}
     * @memberof SurveyInfo
     */
    questionnairesQuestions?: number;
    /**
     * 
     * @type {number}
     * @memberof SurveyInfo
     */
    questionnairesAnswers?: number;
    /**
     * 
     * @type {string}
     * @memberof SurveyInfo
     */
    structureType: SurveyInfoStructureTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof SurveyInfo
     */
    canPauseTesting: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SurveyInfo
     */
    isDante?: boolean;
    /**
     * 
     * @type {DanteInfo}
     * @memberof SurveyInfo
     */
    dante?: DanteInfo;
}

/**
    * @export
    * @enum {string}
    */
export enum SurveyInfoStateEnum {
    New = 'NEW',
    InProgress = 'IN_PROGRESS',
    OnPause = 'ON_PAUSE',
    Done = 'DONE'
}
/**
    * @export
    * @enum {string}
    */
export enum SurveyInfoAnswerStyleEnum {
    Color = 'COLOR',
    Default = 'DEFAULT',
    Horizontal = 'HORIZONTAL',
    Vertical = 'VERTICAL',
    Image = 'IMAGE',
    Scale = 'SCALE',
    Expanded = 'EXPANDED'
}
/**
    * @export
    * @enum {string}
    */
export enum SurveyInfoStructureTypeEnum {
    SingleSurvey = 'SINGLE_SURVEY',
    SurveyGroup = 'SURVEY_GROUP'
}

/**
 * 
 * @export
 * @interface SurveyState
 */
export interface SurveyState {
    /**
     * 
     * @type {string}
     * @memberof SurveyState
     */
    state: SurveyStateStateEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum SurveyStateStateEnum {
    InProgress = 'IN_PROGRESS',
    OnPause = 'ON_PAUSE',
    Done = 'DONE'
}

/**
 * 
 * @export
 * @interface SurveyStatus
 */
export interface SurveyStatus {
    /**
     * 
     * @type {number}
     * @memberof SurveyStatus
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof SurveyStatus
     */
    status?: string;
    /**
     * 
     * @type {string}
     * @memberof SurveyStatus
     */
    text?: string;
}
/**
 * 
 * @export
 * @interface TokenInfo
 */
export interface TokenInfo {
    /**
     * 
     * @type {string}
     * @memberof TokenInfo
     */
    token: string;
    /**
     * 
     * @type {string}
     * @memberof TokenInfo
     */
    status?: TokenInfoStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof TokenInfo
     */
    groupType?: TokenInfoGroupTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof TokenInfo
     */
    surveyId?: number | null;
}

/**
    * @export
    * @enum {string}
    */
export enum TokenInfoStatusEnum {
    RegisterToGetAccess = 'REGISTER_TO_GET_ACCESS',
    LoginToGetAccess = 'LOGIN_TO_GET_ACCESS',
    AccessGranted = 'ACCESS_GRANTED',
    UserAccessDenied = 'USER_ACCESS_DENIED'
}
/**
    * @export
    * @enum {string}
    */
export enum TokenInfoGroupTypeEnum {
    ClientConsultant = 'CLIENT_CONSULTANT',
    ClientCompany = 'CLIENT_COMPANY',
    Staff = 'STAFF',
    Admin = 'ADMIN',
    User = 'USER',
    UserAccessDenied = 'USER_ACCESS_DENIED'
}

/**
 * 
 * @export
 * @interface UpdateStaff
 */
export interface UpdateStaff {
    /**
     * 
     * @type {string}
     * @memberof UpdateStaff
     */
    fullName?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateStaff
     */
    phone?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof UpdateStaff
     */
    polls?: Array<number>;
}
/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {string}
     * @memberof User
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    password: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    token?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    recaptcha: string;
}
/**
 * 
 * @export
 * @interface UserAccess
 */
export interface UserAccess {
    /**
     * 
     * @type {number}
     * @memberof UserAccess
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof UserAccess
     */
    token?: string;
    /**
     * 
     * @type {string}
     * @memberof UserAccess
     */
    accessType?: UserAccessAccessTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof UserAccess
     */
    clientLimit: number;
    /**
     * 
     * @type {boolean}
     * @memberof UserAccess
     */
    canAccessDashboard?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserAccess
     */
    userCanViewReport?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserAccess
     */
    dateStart?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserAccess
     */
    dateEnd?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserAccess
     */
    comment?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserAccess
     */
    domain?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserAccess
     */
    emails?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof UserAccess
     */
    pollsTotal?: number;
    /**
     * 
     * @type {number}
     * @memberof UserAccess
     */
    pollsDone?: number;
    /**
     * 
     * @type {number}
     * @memberof UserAccess
     */
    pollsInProgress?: number;
    /**
     * 
     * @type {number}
     * @memberof UserAccess
     */
    pollsNew?: number;
    /**
     * 
     * @type {number}
     * @memberof UserAccess
     */
    surveyAttemptsRemaining?: number;
    /**
     * 
     * @type {string}
     * @memberof UserAccess
     */
    limitCreatedOn?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum UserAccessAccessTypeEnum {
    Free = 'FREE',
    ByEmail = 'BY_EMAIL',
    ByDomain = 'BY_DOMAIN'
}

/**
 * 
 * @export
 * @interface UserAccessInline
 */
export interface UserAccessInline {
    /**
     * 
     * @type {string}
     * @memberof UserAccessInline
     */
    accessType?: UserAccessInlineAccessTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof UserAccessInline
     */
    comment?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserAccessInline
     */
    token?: string;
    /**
     * 
     * @type {string}
     * @memberof UserAccessInline
     */
    dateStart?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserAccessInline
     */
    dateEnd?: string | null;
}

/**
    * @export
    * @enum {string}
    */
export enum UserAccessInlineAccessTypeEnum {
    Free = 'FREE',
    ByEmail = 'BY_EMAIL',
    ByDomain = 'BY_DOMAIN'
}

/**
 * 
 * @export
 * @interface UserEmailAccess
 */
export interface UserEmailAccess {
    /**
     * 
     * @type {number}
     * @memberof UserEmailAccess
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof UserEmailAccess
     */
    email: string;
    /**
     * 
     * @type {number}
     * @memberof UserEmailAccess
     */
    userAccess: number;
}
/**
 * 
 * @export
 * @interface UserPassword
 */
export interface UserPassword {
    /**
     * 
     * @type {string}
     * @memberof UserPassword
     */
    password: string;
    /**
     * 
     * @type {string}
     * @memberof UserPassword
     */
    newPassword: string;
}
/**
 * 
 * @export
 * @interface UserPolls
 */
export interface UserPolls {
    /**
     * 
     * @type {number}
     * @memberof UserPolls
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof UserPolls
     */
    surveyId?: string;
    /**
     * 
     * @type {string}
     * @memberof UserPolls
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof UserPolls
     */
    state?: UserPollsStateEnum;
    /**
     * 
     * @type {boolean}
     * @memberof UserPolls
     */
    timeIsUp?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserPolls
     */
    userCanDownloadReport: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserPolls
     */
    clientCanDownloadReport: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserPolls
     */
    clientCanDownloadResearchReport?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserPolls
     */
    userCanViewReport?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserPolls
     */
    reportState?: UserPollsReportStateEnum;
    /**
     * 
     * @type {boolean}
     * @memberof UserPolls
     */
    reportViewed: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserPolls
     */
    staff?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserPolls
     */
    limitCreatedOn: string;
    /**
     * 
     * @type {number}
     * @memberof UserPolls
     */
    userId: number;
    /**
     * 
     * @type {string}
     * @memberof UserPolls
     */
    fullName: string;
    /**
     * 
     * @type {string}
     * @memberof UserPolls
     */
    email: string;
    /**
     * 
     * @type {UserAccessInline}
     * @memberof UserPolls
     */
    createdWithAccess?: UserAccessInline;
    /**
     * 
     * @type {boolean}
     * @memberof UserPolls
     */
    isDante?: boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum UserPollsStateEnum {
    New = 'NEW',
    InProgress = 'IN_PROGRESS',
    OnPause = 'ON_PAUSE',
    Done = 'DONE'
}
/**
    * @export
    * @enum {string}
    */
export enum UserPollsReportStateEnum {
    NonExist = 'NON_EXIST',
    InProgress = 'IN_PROGRESS',
    Error = 'ERROR',
    Done = 'DONE'
}

/**
 * 
 * @export
 * @interface UserResetStatus
 */
export interface UserResetStatus {
    /**
     * 
     * @type {string}
     * @memberof UserResetStatus
     */
    status?: string;
}
/**
 * 
 * @export
 * @interface UserStat
 */
export interface UserStat {
    /**
     * 
     * @type {number}
     * @memberof UserStat
     */
    total: number;
    /**
     * 
     * @type {number}
     * @memberof UserStat
     */
    remaining: number;
    /**
     * 
     * @type {number}
     * @memberof UserStat
     */
    inProgress: number;
    /**
     * 
     * @type {number}
     * @memberof UserStat
     */
    done: number;
}

/**
 * CoreApi - axios parameter creator
 * @export
 */
export const CoreApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {TokenInfo} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coreAuthAccessByToken: async (data: TokenInfo, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            if (data === null || data === undefined) {
                throw new RequiredError('data','Required parameter data was null or undefined when calling coreAuthAccessByToken.');
            }
            const localVarPath = `/core/auth/access_by_token/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof data !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(data !== undefined ? data : {})
                : (data || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UserPassword} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coreAuthChangePassword: async (data: UserPassword, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            if (data === null || data === undefined) {
                throw new RequiredError('data','Required parameter data was null or undefined when calling coreAuthChangePassword.');
            }
            const localVarPath = `/core/auth/change_password/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof data !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(data !== undefined ? data : {})
                : (data || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {User} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coreAuthLogin: async (data: User, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            if (data === null || data === undefined) {
                throw new RequiredError('data','Required parameter data was null or undefined when calling coreAuthLogin.');
            }
            const localVarPath = `/core/auth/login/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof data !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(data !== undefined ? data : {})
                : (data || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coreAuthLogout: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/core/auth/logout/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RecaptchaPasswordToken} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coreAuthPasswordResetConfirmCreate: async (data: RecaptchaPasswordToken, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            if (data === null || data === undefined) {
                throw new RequiredError('data','Required parameter data was null or undefined when calling coreAuthPasswordResetConfirmCreate.');
            }
            const localVarPath = `/core/auth/password_reset/confirm/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof data !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(data !== undefined ? data : {})
                : (data || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RecaptchaEmail} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coreAuthPasswordResetCreate: async (data: RecaptchaEmail, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            if (data === null || data === undefined) {
                throw new RequiredError('data','Required parameter data was null or undefined when calling coreAuthPasswordResetCreate.');
            }
            const localVarPath = `/core/auth/password_reset/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof data !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(data !== undefined ? data : {})
                : (data || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RequestRegistration} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coreAuthRequestRegistration: async (data: RequestRegistration, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            if (data === null || data === undefined) {
                throw new RequiredError('data','Required parameter data was null or undefined when calling coreAuthRequestRegistration.');
            }
            const localVarPath = `/core/auth/request_registration/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof data !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(data !== undefined ? data : {})
                : (data || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {User} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coreAuthSignup: async (data: User, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            if (data === null || data === undefined) {
                throw new RequiredError('data','Required parameter data was null or undefined when calling coreAuthSignup.');
            }
            const localVarPath = `/core/auth/signup/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof data !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(data !== undefined ? data : {})
                : (data || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coreBannerList: async (page?: number, limit?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/core/banner/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Баннер.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coreBannerRead: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling coreBannerRead.');
            }
            const localVarPath = `/core/banner/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} groupType acceptable: CLIENT_CONSULTANT | CLIENT_COMPANY | STAFF
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        corePolicyRead: async (groupType: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'groupType' is not null or undefined
            if (groupType === null || groupType === undefined) {
                throw new RequiredError('groupType','Required parameter groupType was null or undefined when calling corePolicyRead.');
            }
            const localVarPath = `/core/policy/{group_type}/`
                .replace(`{${"group_type"}}`, encodeURIComponent(String(groupType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Profile} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coreProfilePartialUpdate: async (data: Profile, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            if (data === null || data === undefined) {
                throw new RequiredError('data','Required parameter data was null or undefined when calling coreProfilePartialUpdate.');
            }
            const localVarPath = `/core/profile/update/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof data !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(data !== undefined ? data : {})
                : (data || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coreProfilePhoto: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/core/profile/photo/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this пользовательский профиль.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coreProfileRead: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling coreProfileRead.');
            }
            const localVarPath = `/core/profile/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Profile} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coreProfileUpdate: async (data: Profile, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            if (data === null || data === undefined) {
                throw new RequiredError('data','Required parameter data was null or undefined when calling coreProfileUpdate.');
            }
            const localVarPath = `/core/profile/update/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof data !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(data !== undefined ? data : {})
                : (data || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {any} [photo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coreProfileUploadPhoto: async (photo?: any, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/core/profile/upload_photo/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Basic required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


            if (photo !== undefined) { 
                localVarFormParams.append('photo', photo as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coreProfileView: async (page?: number, limit?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/core/profile/view/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coreSettingsRead: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/core/settings/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CoreApi - functional programming interface
 * @export
 */
export const CoreApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {TokenInfo} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async coreAuthAccessByToken(data: TokenInfo, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TokenInfo>> {
            const localVarAxiosArgs = await CoreApiAxiosParamCreator(configuration).coreAuthAccessByToken(data, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {UserPassword} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async coreAuthChangePassword(data: UserPassword, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await CoreApiAxiosParamCreator(configuration).coreAuthChangePassword(data, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {User} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async coreAuthLogin(data: User, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SurveyId>> {
            const localVarAxiosArgs = await CoreApiAxiosParamCreator(configuration).coreAuthLogin(data, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async coreAuthLogout(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await CoreApiAxiosParamCreator(configuration).coreAuthLogout(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {RecaptchaPasswordToken} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async coreAuthPasswordResetConfirmCreate(data: RecaptchaPasswordToken, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserResetStatus>> {
            const localVarAxiosArgs = await CoreApiAxiosParamCreator(configuration).coreAuthPasswordResetConfirmCreate(data, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {RecaptchaEmail} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async coreAuthPasswordResetCreate(data: RecaptchaEmail, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserResetStatus>> {
            const localVarAxiosArgs = await CoreApiAxiosParamCreator(configuration).coreAuthPasswordResetCreate(data, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {RequestRegistration} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async coreAuthRequestRegistration(data: RequestRegistration, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RequestRegistrationResponse>> {
            const localVarAxiosArgs = await CoreApiAxiosParamCreator(configuration).coreAuthRequestRegistration(data, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {User} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async coreAuthSignup(data: User, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SurveyId>> {
            const localVarAxiosArgs = await CoreApiAxiosParamCreator(configuration).coreAuthSignup(data, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async coreBannerList(page?: number, limit?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200>> {
            const localVarAxiosArgs = await CoreApiAxiosParamCreator(configuration).coreBannerList(page, limit, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Баннер.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async coreBannerRead(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Banner>> {
            const localVarAxiosArgs = await CoreApiAxiosParamCreator(configuration).coreBannerRead(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} groupType acceptable: CLIENT_CONSULTANT | CLIENT_COMPANY | STAFF
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async corePolicyRead(groupType: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PrivacyPolicy>> {
            const localVarAxiosArgs = await CoreApiAxiosParamCreator(configuration).corePolicyRead(groupType, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {Profile} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async coreProfilePartialUpdate(data: Profile, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Profile>> {
            const localVarAxiosArgs = await CoreApiAxiosParamCreator(configuration).coreProfilePartialUpdate(data, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async coreProfilePhoto(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await CoreApiAxiosParamCreator(configuration).coreProfilePhoto(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this пользовательский профиль.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async coreProfileRead(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await CoreApiAxiosParamCreator(configuration).coreProfileRead(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {Profile} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async coreProfileUpdate(data: Profile, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Profile>> {
            const localVarAxiosArgs = await CoreApiAxiosParamCreator(configuration).coreProfileUpdate(data, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {any} [photo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async coreProfileUploadPhoto(photo?: any, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PhotoUpload>> {
            const localVarAxiosArgs = await CoreApiAxiosParamCreator(configuration).coreProfileUploadPhoto(photo, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async coreProfileView(page?: number, limit?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProfileView>> {
            const localVarAxiosArgs = await CoreApiAxiosParamCreator(configuration).coreProfileView(page, limit, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async coreSettingsRead(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectSettings>> {
            const localVarAxiosArgs = await CoreApiAxiosParamCreator(configuration).coreSettingsRead(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * CoreApi - factory interface
 * @export
 */
export const CoreApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {TokenInfo} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coreAuthAccessByToken(data: TokenInfo, options?: any): AxiosPromise<TokenInfo> {
            return CoreApiFp(configuration).coreAuthAccessByToken(data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UserPassword} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coreAuthChangePassword(data: UserPassword, options?: any): AxiosPromise<void> {
            return CoreApiFp(configuration).coreAuthChangePassword(data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {User} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coreAuthLogin(data: User, options?: any): AxiosPromise<SurveyId> {
            return CoreApiFp(configuration).coreAuthLogin(data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coreAuthLogout(options?: any): AxiosPromise<void> {
            return CoreApiFp(configuration).coreAuthLogout(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RecaptchaPasswordToken} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coreAuthPasswordResetConfirmCreate(data: RecaptchaPasswordToken, options?: any): AxiosPromise<UserResetStatus> {
            return CoreApiFp(configuration).coreAuthPasswordResetConfirmCreate(data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RecaptchaEmail} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coreAuthPasswordResetCreate(data: RecaptchaEmail, options?: any): AxiosPromise<UserResetStatus> {
            return CoreApiFp(configuration).coreAuthPasswordResetCreate(data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RequestRegistration} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coreAuthRequestRegistration(data: RequestRegistration, options?: any): AxiosPromise<RequestRegistrationResponse> {
            return CoreApiFp(configuration).coreAuthRequestRegistration(data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {User} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coreAuthSignup(data: User, options?: any): AxiosPromise<SurveyId> {
            return CoreApiFp(configuration).coreAuthSignup(data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coreBannerList(page?: number, limit?: number, options?: any): AxiosPromise<InlineResponse200> {
            return CoreApiFp(configuration).coreBannerList(page, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Баннер.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coreBannerRead(id: number, options?: any): AxiosPromise<Banner> {
            return CoreApiFp(configuration).coreBannerRead(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} groupType acceptable: CLIENT_CONSULTANT | CLIENT_COMPANY | STAFF
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        corePolicyRead(groupType: string, options?: any): AxiosPromise<PrivacyPolicy> {
            return CoreApiFp(configuration).corePolicyRead(groupType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Profile} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coreProfilePartialUpdate(data: Profile, options?: any): AxiosPromise<Profile> {
            return CoreApiFp(configuration).coreProfilePartialUpdate(data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coreProfilePhoto(options?: any): AxiosPromise<void> {
            return CoreApiFp(configuration).coreProfilePhoto(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this пользовательский профиль.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coreProfileRead(id: number, options?: any): AxiosPromise<void> {
            return CoreApiFp(configuration).coreProfileRead(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Profile} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coreProfileUpdate(data: Profile, options?: any): AxiosPromise<Profile> {
            return CoreApiFp(configuration).coreProfileUpdate(data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {any} [photo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coreProfileUploadPhoto(photo?: any, options?: any): AxiosPromise<PhotoUpload> {
            return CoreApiFp(configuration).coreProfileUploadPhoto(photo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coreProfileView(page?: number, limit?: number, options?: any): AxiosPromise<ProfileView> {
            return CoreApiFp(configuration).coreProfileView(page, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coreSettingsRead(options?: any): AxiosPromise<ProjectSettings> {
            return CoreApiFp(configuration).coreSettingsRead(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for coreAuthAccessByToken operation in CoreApi.
 * @export
 * @interface CoreApiCoreAuthAccessByTokenRequest
 */
export interface CoreApiCoreAuthAccessByTokenRequest {
    /**
     * 
     * @type {TokenInfo}
     * @memberof CoreApiCoreAuthAccessByToken
     */
    readonly data: TokenInfo
}

/**
 * Request parameters for coreAuthChangePassword operation in CoreApi.
 * @export
 * @interface CoreApiCoreAuthChangePasswordRequest
 */
export interface CoreApiCoreAuthChangePasswordRequest {
    /**
     * 
     * @type {UserPassword}
     * @memberof CoreApiCoreAuthChangePassword
     */
    readonly data: UserPassword
}

/**
 * Request parameters for coreAuthLogin operation in CoreApi.
 * @export
 * @interface CoreApiCoreAuthLoginRequest
 */
export interface CoreApiCoreAuthLoginRequest {
    /**
     * 
     * @type {User}
     * @memberof CoreApiCoreAuthLogin
     */
    readonly data: User
}

/**
 * Request parameters for coreAuthPasswordResetConfirmCreate operation in CoreApi.
 * @export
 * @interface CoreApiCoreAuthPasswordResetConfirmCreateRequest
 */
export interface CoreApiCoreAuthPasswordResetConfirmCreateRequest {
    /**
     * 
     * @type {RecaptchaPasswordToken}
     * @memberof CoreApiCoreAuthPasswordResetConfirmCreate
     */
    readonly data: RecaptchaPasswordToken
}

/**
 * Request parameters for coreAuthPasswordResetCreate operation in CoreApi.
 * @export
 * @interface CoreApiCoreAuthPasswordResetCreateRequest
 */
export interface CoreApiCoreAuthPasswordResetCreateRequest {
    /**
     * 
     * @type {RecaptchaEmail}
     * @memberof CoreApiCoreAuthPasswordResetCreate
     */
    readonly data: RecaptchaEmail
}

/**
 * Request parameters for coreAuthRequestRegistration operation in CoreApi.
 * @export
 * @interface CoreApiCoreAuthRequestRegistrationRequest
 */
export interface CoreApiCoreAuthRequestRegistrationRequest {
    /**
     * 
     * @type {RequestRegistration}
     * @memberof CoreApiCoreAuthRequestRegistration
     */
    readonly data: RequestRegistration
}

/**
 * Request parameters for coreAuthSignup operation in CoreApi.
 * @export
 * @interface CoreApiCoreAuthSignupRequest
 */
export interface CoreApiCoreAuthSignupRequest {
    /**
     * 
     * @type {User}
     * @memberof CoreApiCoreAuthSignup
     */
    readonly data: User
}

/**
 * Request parameters for coreBannerList operation in CoreApi.
 * @export
 * @interface CoreApiCoreBannerListRequest
 */
export interface CoreApiCoreBannerListRequest {
    /**
     * A page number within the paginated result set.
     * @type {number}
     * @memberof CoreApiCoreBannerList
     */
    readonly page?: number

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof CoreApiCoreBannerList
     */
    readonly limit?: number
}

/**
 * Request parameters for coreBannerRead operation in CoreApi.
 * @export
 * @interface CoreApiCoreBannerReadRequest
 */
export interface CoreApiCoreBannerReadRequest {
    /**
     * A unique integer value identifying this Баннер.
     * @type {number}
     * @memberof CoreApiCoreBannerRead
     */
    readonly id: number
}

/**
 * Request parameters for corePolicyRead operation in CoreApi.
 * @export
 * @interface CoreApiCorePolicyReadRequest
 */
export interface CoreApiCorePolicyReadRequest {
    /**
     * acceptable: CLIENT_CONSULTANT | CLIENT_COMPANY | STAFF
     * @type {string}
     * @memberof CoreApiCorePolicyRead
     */
    readonly groupType: string
}

/**
 * Request parameters for coreProfilePartialUpdate operation in CoreApi.
 * @export
 * @interface CoreApiCoreProfilePartialUpdateRequest
 */
export interface CoreApiCoreProfilePartialUpdateRequest {
    /**
     * 
     * @type {Profile}
     * @memberof CoreApiCoreProfilePartialUpdate
     */
    readonly data: Profile
}

/**
 * Request parameters for coreProfileRead operation in CoreApi.
 * @export
 * @interface CoreApiCoreProfileReadRequest
 */
export interface CoreApiCoreProfileReadRequest {
    /**
     * A unique integer value identifying this пользовательский профиль.
     * @type {number}
     * @memberof CoreApiCoreProfileRead
     */
    readonly id: number
}

/**
 * Request parameters for coreProfileUpdate operation in CoreApi.
 * @export
 * @interface CoreApiCoreProfileUpdateRequest
 */
export interface CoreApiCoreProfileUpdateRequest {
    /**
     * 
     * @type {Profile}
     * @memberof CoreApiCoreProfileUpdate
     */
    readonly data: Profile
}

/**
 * Request parameters for coreProfileUploadPhoto operation in CoreApi.
 * @export
 * @interface CoreApiCoreProfileUploadPhotoRequest
 */
export interface CoreApiCoreProfileUploadPhotoRequest {
    /**
     * 
     * @type {any}
     * @memberof CoreApiCoreProfileUploadPhoto
     */
    readonly photo?: any
}

/**
 * Request parameters for coreProfileView operation in CoreApi.
 * @export
 * @interface CoreApiCoreProfileViewRequest
 */
export interface CoreApiCoreProfileViewRequest {
    /**
     * A page number within the paginated result set.
     * @type {number}
     * @memberof CoreApiCoreProfileView
     */
    readonly page?: number

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof CoreApiCoreProfileView
     */
    readonly limit?: number
}

/**
 * CoreApi - object-oriented interface
 * @export
 * @class CoreApi
 * @extends {BaseAPI}
 */
export class CoreApi extends BaseAPI {
    /**
     * 
     * @param {CoreApiCoreAuthAccessByTokenRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoreApi
     */
    public coreAuthAccessByToken(requestParameters: CoreApiCoreAuthAccessByTokenRequest, options?: any) {
        return CoreApiFp(this.configuration).coreAuthAccessByToken(requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CoreApiCoreAuthChangePasswordRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoreApi
     */
    public coreAuthChangePassword(requestParameters: CoreApiCoreAuthChangePasswordRequest, options?: any) {
        return CoreApiFp(this.configuration).coreAuthChangePassword(requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CoreApiCoreAuthLoginRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoreApi
     */
    public coreAuthLogin(requestParameters: CoreApiCoreAuthLoginRequest, options?: any) {
        return CoreApiFp(this.configuration).coreAuthLogin(requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoreApi
     */
    public coreAuthLogout(options?: any) {
        return CoreApiFp(this.configuration).coreAuthLogout(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CoreApiCoreAuthPasswordResetConfirmCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoreApi
     */
    public coreAuthPasswordResetConfirmCreate(requestParameters: CoreApiCoreAuthPasswordResetConfirmCreateRequest, options?: any) {
        return CoreApiFp(this.configuration).coreAuthPasswordResetConfirmCreate(requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CoreApiCoreAuthPasswordResetCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoreApi
     */
    public coreAuthPasswordResetCreate(requestParameters: CoreApiCoreAuthPasswordResetCreateRequest, options?: any) {
        return CoreApiFp(this.configuration).coreAuthPasswordResetCreate(requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CoreApiCoreAuthRequestRegistrationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoreApi
     */
    public coreAuthRequestRegistration(requestParameters: CoreApiCoreAuthRequestRegistrationRequest, options?: any) {
        return CoreApiFp(this.configuration).coreAuthRequestRegistration(requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CoreApiCoreAuthSignupRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoreApi
     */
    public coreAuthSignup(requestParameters: CoreApiCoreAuthSignupRequest, options?: any) {
        return CoreApiFp(this.configuration).coreAuthSignup(requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CoreApiCoreBannerListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoreApi
     */
    public coreBannerList(requestParameters: CoreApiCoreBannerListRequest = {}, options?: any) {
        return CoreApiFp(this.configuration).coreBannerList(requestParameters.page, requestParameters.limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CoreApiCoreBannerReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoreApi
     */
    public coreBannerRead(requestParameters: CoreApiCoreBannerReadRequest, options?: any) {
        return CoreApiFp(this.configuration).coreBannerRead(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CoreApiCorePolicyReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoreApi
     */
    public corePolicyRead(requestParameters: CoreApiCorePolicyReadRequest, options?: any) {
        return CoreApiFp(this.configuration).corePolicyRead(requestParameters.groupType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CoreApiCoreProfilePartialUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoreApi
     */
    public coreProfilePartialUpdate(requestParameters: CoreApiCoreProfilePartialUpdateRequest, options?: any) {
        return CoreApiFp(this.configuration).coreProfilePartialUpdate(requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoreApi
     */
    public coreProfilePhoto(options?: any) {
        return CoreApiFp(this.configuration).coreProfilePhoto(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CoreApiCoreProfileReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoreApi
     */
    public coreProfileRead(requestParameters: CoreApiCoreProfileReadRequest, options?: any) {
        return CoreApiFp(this.configuration).coreProfileRead(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CoreApiCoreProfileUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoreApi
     */
    public coreProfileUpdate(requestParameters: CoreApiCoreProfileUpdateRequest, options?: any) {
        return CoreApiFp(this.configuration).coreProfileUpdate(requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CoreApiCoreProfileUploadPhotoRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoreApi
     */
    public coreProfileUploadPhoto(requestParameters: CoreApiCoreProfileUploadPhotoRequest = {}, options?: any) {
        return CoreApiFp(this.configuration).coreProfileUploadPhoto(requestParameters.photo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CoreApiCoreProfileViewRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoreApi
     */
    public coreProfileView(requestParameters: CoreApiCoreProfileViewRequest = {}, options?: any) {
        return CoreApiFp(this.configuration).coreProfileView(requestParameters.page, requestParameters.limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoreApi
     */
    public coreSettingsRead(options?: any) {
        return CoreApiFp(this.configuration).coreSettingsRead(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DashboardApi - axios parameter creator
 * @export
 */
export const DashboardApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [id] 
         * @param {string} [groupType] 
         * @param {string} [email] 
         * @param {string} [fullName] 
         * @param {string} [company] 
         * @param {number} [numberOfRegistrations] 
         * @param {number} [surveyAttempts] 
         * @param {number} [surveyAttemptsRemaining] 
         * @param {number} [pollsDone] 
         * @param {any} [dateStart] 
         * @param {any} [dateEnd] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardAdminClients: async (page?: number, limit?: number, id?: number, groupType?: string, email?: string, fullName?: string, company?: string, numberOfRegistrations?: number, surveyAttempts?: number, surveyAttemptsRemaining?: number, pollsDone?: number, dateStart?: any, dateEnd?: any, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/dashboard/admin_clients/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (groupType !== undefined) {
                localVarQueryParameter['group_type'] = groupType;
            }

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }

            if (fullName !== undefined) {
                localVarQueryParameter['full_name'] = fullName;
            }

            if (company !== undefined) {
                localVarQueryParameter['company'] = company;
            }

            if (numberOfRegistrations !== undefined) {
                localVarQueryParameter['number_of_registrations'] = numberOfRegistrations;
            }

            if (surveyAttempts !== undefined) {
                localVarQueryParameter['survey_attempts'] = surveyAttempts;
            }

            if (surveyAttemptsRemaining !== undefined) {
                localVarQueryParameter['survey_attempts_remaining'] = surveyAttemptsRemaining;
            }

            if (pollsDone !== undefined) {
                localVarQueryParameter['polls_done'] = pollsDone;
            }

            if (dateStart !== undefined) {
                localVarQueryParameter['date_start'] = dateStart;
            }

            if (dateEnd !== undefined) {
                localVarQueryParameter['date_end'] = dateEnd;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {string} [surveyName] 
         * @param {number} [numberOfRegistrations] 
         * @param {number} [surveyAttempts] 
         * @param {number} [surveyAttemptsRemaining] 
         * @param {number} [pollsDone] 
         * @param {any} [dateStart] 
         * @param {any} [dateEnd] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardAdminPolls: async (page?: number, limit?: number, surveyName?: string, numberOfRegistrations?: number, surveyAttempts?: number, surveyAttemptsRemaining?: number, pollsDone?: number, dateStart?: any, dateEnd?: any, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/dashboard/admin_polls/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (surveyName !== undefined) {
                localVarQueryParameter['survey_name'] = surveyName;
            }

            if (numberOfRegistrations !== undefined) {
                localVarQueryParameter['number_of_registrations'] = numberOfRegistrations;
            }

            if (surveyAttempts !== undefined) {
                localVarQueryParameter['survey_attempts'] = surveyAttempts;
            }

            if (surveyAttemptsRemaining !== undefined) {
                localVarQueryParameter['survey_attempts_remaining'] = surveyAttemptsRemaining;
            }

            if (pollsDone !== undefined) {
                localVarQueryParameter['polls_done'] = pollsDone;
            }

            if (dateStart !== undefined) {
                localVarQueryParameter['date_start'] = dateStart;
            }

            if (dateEnd !== undefined) {
                localVarQueryParameter['date_end'] = dateEnd;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {any} [dateStart] 
         * @param {any} [dateEnd] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardAdminStats: async (page?: number, limit?: number, dateStart?: any, dateEnd?: any, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/dashboard/admin_stats/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (dateStart !== undefined) {
                localVarQueryParameter['date_start'] = dateStart;
            }

            if (dateEnd !== undefined) {
                localVarQueryParameter['date_end'] = dateEnd;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateStaff} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardClientAddStaff: async (data: CreateStaff, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            if (data === null || data === undefined) {
                throw new RequiredError('data','Required parameter data was null or undefined when calling dashboardClientAddStaff.');
            }
            const localVarPath = `/dashboard/client_add_staff/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof data !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(data !== undefined ? data : {})
                : (data || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this пользователь.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardClientDeleteStaff: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling dashboardClientDeleteStaff.');
            }
            const localVarPath = `/dashboard/{id}/client_delete_staff/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<CreateStaff>} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardClientMultipleAddStaff: async (data: Array<CreateStaff>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            if (data === null || data === undefined) {
                throw new RequiredError('data','Required parameter data was null or undefined when calling dashboardClientMultipleAddStaff.');
            }
            const localVarPath = `/dashboard/client_multiple_add_staff/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof data !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(data !== undefined ? data : {})
                : (data || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {string} [surveyName] 
         * @param {number} [numberOfRegistrations] 
         * @param {number} [inProgress] 
         * @param {number} [pollsDone] 
         * @param {boolean} [unviewedReports] 
         * @param {number} [surveyAttempts] 
         * @param {number} [surveyAttemptsRemaining] 
         * @param {number} [registrations] 
         * @param {number} [registrationsRemaining] 
         * @param {any} [dateStart] 
         * @param {any} [dateEnd] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardClientPolls: async (page?: number, limit?: number, surveyName?: string, numberOfRegistrations?: number, inProgress?: number, pollsDone?: number, unviewedReports?: boolean, surveyAttempts?: number, surveyAttemptsRemaining?: number, registrations?: number, registrationsRemaining?: number, dateStart?: any, dateEnd?: any, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/dashboard/client_polls/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (surveyName !== undefined) {
                localVarQueryParameter['survey_name'] = surveyName;
            }

            if (numberOfRegistrations !== undefined) {
                localVarQueryParameter['number_of_registrations'] = numberOfRegistrations;
            }

            if (inProgress !== undefined) {
                localVarQueryParameter['in_progress'] = inProgress;
            }

            if (pollsDone !== undefined) {
                localVarQueryParameter['polls_done'] = pollsDone;
            }

            if (unviewedReports !== undefined) {
                localVarQueryParameter['unviewed_reports'] = unviewedReports;
            }

            if (surveyAttempts !== undefined) {
                localVarQueryParameter['survey_attempts'] = surveyAttempts;
            }

            if (surveyAttemptsRemaining !== undefined) {
                localVarQueryParameter['survey_attempts_remaining'] = surveyAttemptsRemaining;
            }

            if (registrations !== undefined) {
                localVarQueryParameter['registrations'] = registrations;
            }

            if (registrationsRemaining !== undefined) {
                localVarQueryParameter['registrations_remaining'] = registrationsRemaining;
            }

            if (dateStart !== undefined) {
                localVarQueryParameter['date_start'] = dateStart;
            }

            if (dateEnd !== undefined) {
                localVarQueryParameter['date_end'] = dateEnd;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardClientStaff: async (page?: number, limit?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/dashboard/client_staff/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {any} [dateStart] 
         * @param {any} [dateEnd] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardClientStats: async (page?: number, limit?: number, dateStart?: any, dateEnd?: any, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/dashboard/client_stats/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (dateStart !== undefined) {
                localVarQueryParameter['date_start'] = dateStart;
            }

            if (dateEnd !== undefined) {
                localVarQueryParameter['date_end'] = dateEnd;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this пользователь.
         * @param {UpdateStaff} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardClientUpdateStaff: async (id: number, data: UpdateStaff, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling dashboardClientUpdateStaff.');
            }
            // verify required parameter 'data' is not null or undefined
            if (data === null || data === undefined) {
                throw new RequiredError('data','Required parameter data was null or undefined when calling dashboardClientUpdateStaff.');
            }
            const localVarPath = `/dashboard/{id}/client_update_staff/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof data !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(data !== undefined ? data : {})
                : (data || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this пользователь.
         * @param {number} [page] 
         * @param {string} [limit] 
         * @param {Array<number>} [idIn] 
         * @param {Array<number>} [surveyIdIn] 
         * @param {string} [state] 
         * @param {string} [surveyName] 
         * @param {string} [endDate] 
         * @param {boolean} [reportViewed] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardClientUserPolls: async (id: number, page?: number, limit?: string, idIn?: Array<number>, surveyIdIn?: Array<number>, state?: string, surveyName?: string, endDate?: string, reportViewed?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling dashboardClientUserPolls.');
            }
            const localVarPath = `/dashboard/{id}/client_user_polls/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (idIn) {
                localVarQueryParameter['id__in'] = idIn.join(COLLECTION_FORMATS.csv);
            }

            if (surveyIdIn) {
                localVarQueryParameter['survey_id__in'] = surveyIdIn.join(COLLECTION_FORMATS.csv);
            }

            if (state !== undefined) {
                localVarQueryParameter['state'] = state;
            }

            if (surveyName !== undefined) {
                localVarQueryParameter['survey_name'] = surveyName;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['end_date'] = endDate;
            }

            if (reportViewed !== undefined) {
                localVarQueryParameter['report_viewed'] = reportViewed;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this пользователь.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardClientUserProfile: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling dashboardClientUserProfile.');
            }
            const localVarPath = `/dashboard/{id}/client_user_profile/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this пользователь.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardClientUserStats: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling dashboardClientUserStats.');
            }
            const localVarPath = `/dashboard/{id}/client_user_stats/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [search] A search term.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [id] 
         * @param {number} [userId] 
         * @param {string} [email] 
         * @param {string} [fullName] 
         * @param {string} [polls] 
         * @param {boolean} [reportViewed] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardClientUsers: async (search?: string, page?: number, limit?: number, id?: number, userId?: number, email?: string, fullName?: string, polls?: string, reportViewed?: boolean, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/dashboard/client_users/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (userId !== undefined) {
                localVarQueryParameter['user_id'] = userId;
            }

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }

            if (fullName !== undefined) {
                localVarQueryParameter['full_name'] = fullName;
            }

            if (polls !== undefined) {
                localVarQueryParameter['polls'] = polls;
            }

            if (reportViewed !== undefined) {
                localVarQueryParameter['report_viewed'] = reportViewed;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [search] A search term.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardSuggestPollsList: async (search?: string, page?: number, limit?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/dashboard/suggest/polls/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {Array<number>} [idIn] 
         * @param {Array<number>} [surveyIdIn] 
         * @param {string} [state] 
         * @param {string} [surveyName] 
         * @param {string} [endDate] 
         * @param {boolean} [reportViewed] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardUserPolls: async (page?: number, limit?: number, idIn?: Array<number>, surveyIdIn?: Array<number>, state?: string, surveyName?: string, endDate?: string, reportViewed?: boolean, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/dashboard/user_polls/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (idIn) {
                localVarQueryParameter['id__in'] = idIn.join(COLLECTION_FORMATS.csv);
            }

            if (surveyIdIn) {
                localVarQueryParameter['survey_id__in'] = surveyIdIn.join(COLLECTION_FORMATS.csv);
            }

            if (state !== undefined) {
                localVarQueryParameter['state'] = state;
            }

            if (surveyName !== undefined) {
                localVarQueryParameter['survey_name'] = surveyName;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['end_date'] = endDate;
            }

            if (reportViewed !== undefined) {
                localVarQueryParameter['report_viewed'] = reportViewed;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardUserStats: async (page?: number, limit?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/dashboard/user_stats/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DashboardApi - functional programming interface
 * @export
 */
export const DashboardApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [id] 
         * @param {string} [groupType] 
         * @param {string} [email] 
         * @param {string} [fullName] 
         * @param {string} [company] 
         * @param {number} [numberOfRegistrations] 
         * @param {number} [surveyAttempts] 
         * @param {number} [surveyAttemptsRemaining] 
         * @param {number} [pollsDone] 
         * @param {any} [dateStart] 
         * @param {any} [dateEnd] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dashboardAdminClients(page?: number, limit?: number, id?: number, groupType?: string, email?: string, fullName?: string, company?: string, numberOfRegistrations?: number, surveyAttempts?: number, surveyAttemptsRemaining?: number, pollsDone?: number, dateStart?: any, dateEnd?: any, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2001>> {
            const localVarAxiosArgs = await DashboardApiAxiosParamCreator(configuration).dashboardAdminClients(page, limit, id, groupType, email, fullName, company, numberOfRegistrations, surveyAttempts, surveyAttemptsRemaining, pollsDone, dateStart, dateEnd, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {string} [surveyName] 
         * @param {number} [numberOfRegistrations] 
         * @param {number} [surveyAttempts] 
         * @param {number} [surveyAttemptsRemaining] 
         * @param {number} [pollsDone] 
         * @param {any} [dateStart] 
         * @param {any} [dateEnd] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dashboardAdminPolls(page?: number, limit?: number, surveyName?: string, numberOfRegistrations?: number, surveyAttempts?: number, surveyAttemptsRemaining?: number, pollsDone?: number, dateStart?: any, dateEnd?: any, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2002>> {
            const localVarAxiosArgs = await DashboardApiAxiosParamCreator(configuration).dashboardAdminPolls(page, limit, surveyName, numberOfRegistrations, surveyAttempts, surveyAttemptsRemaining, pollsDone, dateStart, dateEnd, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {any} [dateStart] 
         * @param {any} [dateEnd] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dashboardAdminStats(page?: number, limit?: number, dateStart?: any, dateEnd?: any, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminTotalStats>> {
            const localVarAxiosArgs = await DashboardApiAxiosParamCreator(configuration).dashboardAdminStats(page, limit, dateStart, dateEnd, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {CreateStaff} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dashboardClientAddStaff(data: CreateStaff, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateUserResponse>> {
            const localVarAxiosArgs = await DashboardApiAxiosParamCreator(configuration).dashboardClientAddStaff(data, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this пользователь.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dashboardClientDeleteStaff(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await DashboardApiAxiosParamCreator(configuration).dashboardClientDeleteStaff(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {Array<CreateStaff>} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dashboardClientMultipleAddStaff(data: Array<CreateStaff>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CreateUserResponse>>> {
            const localVarAxiosArgs = await DashboardApiAxiosParamCreator(configuration).dashboardClientMultipleAddStaff(data, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {string} [surveyName] 
         * @param {number} [numberOfRegistrations] 
         * @param {number} [inProgress] 
         * @param {number} [pollsDone] 
         * @param {boolean} [unviewedReports] 
         * @param {number} [surveyAttempts] 
         * @param {number} [surveyAttemptsRemaining] 
         * @param {number} [registrations] 
         * @param {number} [registrationsRemaining] 
         * @param {any} [dateStart] 
         * @param {any} [dateEnd] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dashboardClientPolls(page?: number, limit?: number, surveyName?: string, numberOfRegistrations?: number, inProgress?: number, pollsDone?: number, unviewedReports?: boolean, surveyAttempts?: number, surveyAttemptsRemaining?: number, registrations?: number, registrationsRemaining?: number, dateStart?: any, dateEnd?: any, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2003>> {
            const localVarAxiosArgs = await DashboardApiAxiosParamCreator(configuration).dashboardClientPolls(page, limit, surveyName, numberOfRegistrations, inProgress, pollsDone, unviewedReports, surveyAttempts, surveyAttemptsRemaining, registrations, registrationsRemaining, dateStart, dateEnd, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dashboardClientStaff(page?: number, limit?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2004>> {
            const localVarAxiosArgs = await DashboardApiAxiosParamCreator(configuration).dashboardClientStaff(page, limit, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {any} [dateStart] 
         * @param {any} [dateEnd] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dashboardClientStats(page?: number, limit?: number, dateStart?: any, dateEnd?: any, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientStat>> {
            const localVarAxiosArgs = await DashboardApiAxiosParamCreator(configuration).dashboardClientStats(page, limit, dateStart, dateEnd, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this пользователь.
         * @param {UpdateStaff} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dashboardClientUpdateStaff(id: number, data: UpdateStaff, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateStaff>> {
            const localVarAxiosArgs = await DashboardApiAxiosParamCreator(configuration).dashboardClientUpdateStaff(id, data, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this пользователь.
         * @param {number} [page] 
         * @param {string} [limit] 
         * @param {Array<number>} [idIn] 
         * @param {Array<number>} [surveyIdIn] 
         * @param {string} [state] 
         * @param {string} [surveyName] 
         * @param {string} [endDate] 
         * @param {boolean} [reportViewed] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dashboardClientUserPolls(id: number, page?: number, limit?: string, idIn?: Array<number>, surveyIdIn?: Array<number>, state?: string, surveyName?: string, endDate?: string, reportViewed?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatePoll>> {
            const localVarAxiosArgs = await DashboardApiAxiosParamCreator(configuration).dashboardClientUserPolls(id, page, limit, idIn, surveyIdIn, state, surveyName, endDate, reportViewed, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this пользователь.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dashboardClientUserProfile(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProfileView>> {
            const localVarAxiosArgs = await DashboardApiAxiosParamCreator(configuration).dashboardClientUserProfile(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this пользователь.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dashboardClientUserStats(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserStat>> {
            const localVarAxiosArgs = await DashboardApiAxiosParamCreator(configuration).dashboardClientUserStats(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [search] A search term.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [id] 
         * @param {number} [userId] 
         * @param {string} [email] 
         * @param {string} [fullName] 
         * @param {string} [polls] 
         * @param {boolean} [reportViewed] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dashboardClientUsers(search?: string, page?: number, limit?: number, id?: number, userId?: number, email?: string, fullName?: string, polls?: string, reportViewed?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2005>> {
            const localVarAxiosArgs = await DashboardApiAxiosParamCreator(configuration).dashboardClientUsers(search, page, limit, id, userId, email, fullName, polls, reportViewed, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [search] A search term.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dashboardSuggestPollsList(search?: string, page?: number, limit?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2006>> {
            const localVarAxiosArgs = await DashboardApiAxiosParamCreator(configuration).dashboardSuggestPollsList(search, page, limit, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {Array<number>} [idIn] 
         * @param {Array<number>} [surveyIdIn] 
         * @param {string} [state] 
         * @param {string} [surveyName] 
         * @param {string} [endDate] 
         * @param {boolean} [reportViewed] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dashboardUserPolls(page?: number, limit?: number, idIn?: Array<number>, surveyIdIn?: Array<number>, state?: string, surveyName?: string, endDate?: string, reportViewed?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2007>> {
            const localVarAxiosArgs = await DashboardApiAxiosParamCreator(configuration).dashboardUserPolls(page, limit, idIn, surveyIdIn, state, surveyName, endDate, reportViewed, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dashboardUserStats(page?: number, limit?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserStat>> {
            const localVarAxiosArgs = await DashboardApiAxiosParamCreator(configuration).dashboardUserStats(page, limit, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * DashboardApi - factory interface
 * @export
 */
export const DashboardApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [id] 
         * @param {string} [groupType] 
         * @param {string} [email] 
         * @param {string} [fullName] 
         * @param {string} [company] 
         * @param {number} [numberOfRegistrations] 
         * @param {number} [surveyAttempts] 
         * @param {number} [surveyAttemptsRemaining] 
         * @param {number} [pollsDone] 
         * @param {any} [dateStart] 
         * @param {any} [dateEnd] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardAdminClients(page?: number, limit?: number, id?: number, groupType?: string, email?: string, fullName?: string, company?: string, numberOfRegistrations?: number, surveyAttempts?: number, surveyAttemptsRemaining?: number, pollsDone?: number, dateStart?: any, dateEnd?: any, options?: any): AxiosPromise<InlineResponse2001> {
            return DashboardApiFp(configuration).dashboardAdminClients(page, limit, id, groupType, email, fullName, company, numberOfRegistrations, surveyAttempts, surveyAttemptsRemaining, pollsDone, dateStart, dateEnd, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {string} [surveyName] 
         * @param {number} [numberOfRegistrations] 
         * @param {number} [surveyAttempts] 
         * @param {number} [surveyAttemptsRemaining] 
         * @param {number} [pollsDone] 
         * @param {any} [dateStart] 
         * @param {any} [dateEnd] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardAdminPolls(page?: number, limit?: number, surveyName?: string, numberOfRegistrations?: number, surveyAttempts?: number, surveyAttemptsRemaining?: number, pollsDone?: number, dateStart?: any, dateEnd?: any, options?: any): AxiosPromise<InlineResponse2002> {
            return DashboardApiFp(configuration).dashboardAdminPolls(page, limit, surveyName, numberOfRegistrations, surveyAttempts, surveyAttemptsRemaining, pollsDone, dateStart, dateEnd, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {any} [dateStart] 
         * @param {any} [dateEnd] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardAdminStats(page?: number, limit?: number, dateStart?: any, dateEnd?: any, options?: any): AxiosPromise<AdminTotalStats> {
            return DashboardApiFp(configuration).dashboardAdminStats(page, limit, dateStart, dateEnd, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateStaff} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardClientAddStaff(data: CreateStaff, options?: any): AxiosPromise<CreateUserResponse> {
            return DashboardApiFp(configuration).dashboardClientAddStaff(data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this пользователь.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardClientDeleteStaff(id: number, options?: any): AxiosPromise<void> {
            return DashboardApiFp(configuration).dashboardClientDeleteStaff(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<CreateStaff>} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardClientMultipleAddStaff(data: Array<CreateStaff>, options?: any): AxiosPromise<Array<CreateUserResponse>> {
            return DashboardApiFp(configuration).dashboardClientMultipleAddStaff(data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {string} [surveyName] 
         * @param {number} [numberOfRegistrations] 
         * @param {number} [inProgress] 
         * @param {number} [pollsDone] 
         * @param {boolean} [unviewedReports] 
         * @param {number} [surveyAttempts] 
         * @param {number} [surveyAttemptsRemaining] 
         * @param {number} [registrations] 
         * @param {number} [registrationsRemaining] 
         * @param {any} [dateStart] 
         * @param {any} [dateEnd] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardClientPolls(page?: number, limit?: number, surveyName?: string, numberOfRegistrations?: number, inProgress?: number, pollsDone?: number, unviewedReports?: boolean, surveyAttempts?: number, surveyAttemptsRemaining?: number, registrations?: number, registrationsRemaining?: number, dateStart?: any, dateEnd?: any, options?: any): AxiosPromise<InlineResponse2003> {
            return DashboardApiFp(configuration).dashboardClientPolls(page, limit, surveyName, numberOfRegistrations, inProgress, pollsDone, unviewedReports, surveyAttempts, surveyAttemptsRemaining, registrations, registrationsRemaining, dateStart, dateEnd, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardClientStaff(page?: number, limit?: number, options?: any): AxiosPromise<InlineResponse2004> {
            return DashboardApiFp(configuration).dashboardClientStaff(page, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {any} [dateStart] 
         * @param {any} [dateEnd] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardClientStats(page?: number, limit?: number, dateStart?: any, dateEnd?: any, options?: any): AxiosPromise<ClientStat> {
            return DashboardApiFp(configuration).dashboardClientStats(page, limit, dateStart, dateEnd, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this пользователь.
         * @param {UpdateStaff} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardClientUpdateStaff(id: number, data: UpdateStaff, options?: any): AxiosPromise<UpdateStaff> {
            return DashboardApiFp(configuration).dashboardClientUpdateStaff(id, data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this пользователь.
         * @param {number} [page] 
         * @param {string} [limit] 
         * @param {Array<number>} [idIn] 
         * @param {Array<number>} [surveyIdIn] 
         * @param {string} [state] 
         * @param {string} [surveyName] 
         * @param {string} [endDate] 
         * @param {boolean} [reportViewed] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardClientUserPolls(id: number, page?: number, limit?: string, idIn?: Array<number>, surveyIdIn?: Array<number>, state?: string, surveyName?: string, endDate?: string, reportViewed?: boolean, options?: any): AxiosPromise<PaginatePoll> {
            return DashboardApiFp(configuration).dashboardClientUserPolls(id, page, limit, idIn, surveyIdIn, state, surveyName, endDate, reportViewed, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this пользователь.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardClientUserProfile(id: number, options?: any): AxiosPromise<ProfileView> {
            return DashboardApiFp(configuration).dashboardClientUserProfile(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this пользователь.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardClientUserStats(id: number, options?: any): AxiosPromise<UserStat> {
            return DashboardApiFp(configuration).dashboardClientUserStats(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [search] A search term.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [id] 
         * @param {number} [userId] 
         * @param {string} [email] 
         * @param {string} [fullName] 
         * @param {string} [polls] 
         * @param {boolean} [reportViewed] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardClientUsers(search?: string, page?: number, limit?: number, id?: number, userId?: number, email?: string, fullName?: string, polls?: string, reportViewed?: boolean, options?: any): AxiosPromise<InlineResponse2005> {
            return DashboardApiFp(configuration).dashboardClientUsers(search, page, limit, id, userId, email, fullName, polls, reportViewed, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [search] A search term.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardSuggestPollsList(search?: string, page?: number, limit?: number, options?: any): AxiosPromise<InlineResponse2006> {
            return DashboardApiFp(configuration).dashboardSuggestPollsList(search, page, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {Array<number>} [idIn] 
         * @param {Array<number>} [surveyIdIn] 
         * @param {string} [state] 
         * @param {string} [surveyName] 
         * @param {string} [endDate] 
         * @param {boolean} [reportViewed] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardUserPolls(page?: number, limit?: number, idIn?: Array<number>, surveyIdIn?: Array<number>, state?: string, surveyName?: string, endDate?: string, reportViewed?: boolean, options?: any): AxiosPromise<InlineResponse2007> {
            return DashboardApiFp(configuration).dashboardUserPolls(page, limit, idIn, surveyIdIn, state, surveyName, endDate, reportViewed, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardUserStats(page?: number, limit?: number, options?: any): AxiosPromise<UserStat> {
            return DashboardApiFp(configuration).dashboardUserStats(page, limit, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for dashboardAdminClients operation in DashboardApi.
 * @export
 * @interface DashboardApiDashboardAdminClientsRequest
 */
export interface DashboardApiDashboardAdminClientsRequest {
    /**
     * A page number within the paginated result set.
     * @type {number}
     * @memberof DashboardApiDashboardAdminClients
     */
    readonly page?: number

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof DashboardApiDashboardAdminClients
     */
    readonly limit?: number

    /**
     * 
     * @type {number}
     * @memberof DashboardApiDashboardAdminClients
     */
    readonly id?: number

    /**
     * 
     * @type {string}
     * @memberof DashboardApiDashboardAdminClients
     */
    readonly groupType?: string

    /**
     * 
     * @type {string}
     * @memberof DashboardApiDashboardAdminClients
     */
    readonly email?: string

    /**
     * 
     * @type {string}
     * @memberof DashboardApiDashboardAdminClients
     */
    readonly fullName?: string

    /**
     * 
     * @type {string}
     * @memberof DashboardApiDashboardAdminClients
     */
    readonly company?: string

    /**
     * 
     * @type {number}
     * @memberof DashboardApiDashboardAdminClients
     */
    readonly numberOfRegistrations?: number

    /**
     * 
     * @type {number}
     * @memberof DashboardApiDashboardAdminClients
     */
    readonly surveyAttempts?: number

    /**
     * 
     * @type {number}
     * @memberof DashboardApiDashboardAdminClients
     */
    readonly surveyAttemptsRemaining?: number

    /**
     * 
     * @type {number}
     * @memberof DashboardApiDashboardAdminClients
     */
    readonly pollsDone?: number

    /**
     * 
     * @type {any}
     * @memberof DashboardApiDashboardAdminClients
     */
    readonly dateStart?: any

    /**
     * 
     * @type {any}
     * @memberof DashboardApiDashboardAdminClients
     */
    readonly dateEnd?: any
}

/**
 * Request parameters for dashboardAdminPolls operation in DashboardApi.
 * @export
 * @interface DashboardApiDashboardAdminPollsRequest
 */
export interface DashboardApiDashboardAdminPollsRequest {
    /**
     * A page number within the paginated result set.
     * @type {number}
     * @memberof DashboardApiDashboardAdminPolls
     */
    readonly page?: number

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof DashboardApiDashboardAdminPolls
     */
    readonly limit?: number

    /**
     * 
     * @type {string}
     * @memberof DashboardApiDashboardAdminPolls
     */
    readonly surveyName?: string

    /**
     * 
     * @type {number}
     * @memberof DashboardApiDashboardAdminPolls
     */
    readonly numberOfRegistrations?: number

    /**
     * 
     * @type {number}
     * @memberof DashboardApiDashboardAdminPolls
     */
    readonly surveyAttempts?: number

    /**
     * 
     * @type {number}
     * @memberof DashboardApiDashboardAdminPolls
     */
    readonly surveyAttemptsRemaining?: number

    /**
     * 
     * @type {number}
     * @memberof DashboardApiDashboardAdminPolls
     */
    readonly pollsDone?: number

    /**
     * 
     * @type {any}
     * @memberof DashboardApiDashboardAdminPolls
     */
    readonly dateStart?: any

    /**
     * 
     * @type {any}
     * @memberof DashboardApiDashboardAdminPolls
     */
    readonly dateEnd?: any
}

/**
 * Request parameters for dashboardAdminStats operation in DashboardApi.
 * @export
 * @interface DashboardApiDashboardAdminStatsRequest
 */
export interface DashboardApiDashboardAdminStatsRequest {
    /**
     * A page number within the paginated result set.
     * @type {number}
     * @memberof DashboardApiDashboardAdminStats
     */
    readonly page?: number

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof DashboardApiDashboardAdminStats
     */
    readonly limit?: number

    /**
     * 
     * @type {any}
     * @memberof DashboardApiDashboardAdminStats
     */
    readonly dateStart?: any

    /**
     * 
     * @type {any}
     * @memberof DashboardApiDashboardAdminStats
     */
    readonly dateEnd?: any
}

/**
 * Request parameters for dashboardClientAddStaff operation in DashboardApi.
 * @export
 * @interface DashboardApiDashboardClientAddStaffRequest
 */
export interface DashboardApiDashboardClientAddStaffRequest {
    /**
     * 
     * @type {CreateStaff}
     * @memberof DashboardApiDashboardClientAddStaff
     */
    readonly data: CreateStaff
}

/**
 * Request parameters for dashboardClientDeleteStaff operation in DashboardApi.
 * @export
 * @interface DashboardApiDashboardClientDeleteStaffRequest
 */
export interface DashboardApiDashboardClientDeleteStaffRequest {
    /**
     * A unique integer value identifying this пользователь.
     * @type {number}
     * @memberof DashboardApiDashboardClientDeleteStaff
     */
    readonly id: number
}

/**
 * Request parameters for dashboardClientMultipleAddStaff operation in DashboardApi.
 * @export
 * @interface DashboardApiDashboardClientMultipleAddStaffRequest
 */
export interface DashboardApiDashboardClientMultipleAddStaffRequest {
    /**
     * 
     * @type {Array<CreateStaff>}
     * @memberof DashboardApiDashboardClientMultipleAddStaff
     */
    readonly data: Array<CreateStaff>
}

/**
 * Request parameters for dashboardClientPolls operation in DashboardApi.
 * @export
 * @interface DashboardApiDashboardClientPollsRequest
 */
export interface DashboardApiDashboardClientPollsRequest {
    /**
     * A page number within the paginated result set.
     * @type {number}
     * @memberof DashboardApiDashboardClientPolls
     */
    readonly page?: number

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof DashboardApiDashboardClientPolls
     */
    readonly limit?: number

    /**
     * 
     * @type {string}
     * @memberof DashboardApiDashboardClientPolls
     */
    readonly surveyName?: string

    /**
     * 
     * @type {number}
     * @memberof DashboardApiDashboardClientPolls
     */
    readonly numberOfRegistrations?: number

    /**
     * 
     * @type {number}
     * @memberof DashboardApiDashboardClientPolls
     */
    readonly inProgress?: number

    /**
     * 
     * @type {number}
     * @memberof DashboardApiDashboardClientPolls
     */
    readonly pollsDone?: number

    /**
     * 
     * @type {boolean}
     * @memberof DashboardApiDashboardClientPolls
     */
    readonly unviewedReports?: boolean

    /**
     * 
     * @type {number}
     * @memberof DashboardApiDashboardClientPolls
     */
    readonly surveyAttempts?: number

    /**
     * 
     * @type {number}
     * @memberof DashboardApiDashboardClientPolls
     */
    readonly surveyAttemptsRemaining?: number

    /**
     * 
     * @type {number}
     * @memberof DashboardApiDashboardClientPolls
     */
    readonly registrations?: number

    /**
     * 
     * @type {number}
     * @memberof DashboardApiDashboardClientPolls
     */
    readonly registrationsRemaining?: number

    /**
     * 
     * @type {any}
     * @memberof DashboardApiDashboardClientPolls
     */
    readonly dateStart?: any

    /**
     * 
     * @type {any}
     * @memberof DashboardApiDashboardClientPolls
     */
    readonly dateEnd?: any
}

/**
 * Request parameters for dashboardClientStaff operation in DashboardApi.
 * @export
 * @interface DashboardApiDashboardClientStaffRequest
 */
export interface DashboardApiDashboardClientStaffRequest {
    /**
     * A page number within the paginated result set.
     * @type {number}
     * @memberof DashboardApiDashboardClientStaff
     */
    readonly page?: number

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof DashboardApiDashboardClientStaff
     */
    readonly limit?: number
}

/**
 * Request parameters for dashboardClientStats operation in DashboardApi.
 * @export
 * @interface DashboardApiDashboardClientStatsRequest
 */
export interface DashboardApiDashboardClientStatsRequest {
    /**
     * A page number within the paginated result set.
     * @type {number}
     * @memberof DashboardApiDashboardClientStats
     */
    readonly page?: number

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof DashboardApiDashboardClientStats
     */
    readonly limit?: number

    /**
     * 
     * @type {any}
     * @memberof DashboardApiDashboardClientStats
     */
    readonly dateStart?: any

    /**
     * 
     * @type {any}
     * @memberof DashboardApiDashboardClientStats
     */
    readonly dateEnd?: any
}

/**
 * Request parameters for dashboardClientUpdateStaff operation in DashboardApi.
 * @export
 * @interface DashboardApiDashboardClientUpdateStaffRequest
 */
export interface DashboardApiDashboardClientUpdateStaffRequest {
    /**
     * A unique integer value identifying this пользователь.
     * @type {number}
     * @memberof DashboardApiDashboardClientUpdateStaff
     */
    readonly id: number

    /**
     * 
     * @type {UpdateStaff}
     * @memberof DashboardApiDashboardClientUpdateStaff
     */
    readonly data: UpdateStaff
}

/**
 * Request parameters for dashboardClientUserPolls operation in DashboardApi.
 * @export
 * @interface DashboardApiDashboardClientUserPollsRequest
 */
export interface DashboardApiDashboardClientUserPollsRequest {
    /**
     * A unique integer value identifying this пользователь.
     * @type {number}
     * @memberof DashboardApiDashboardClientUserPolls
     */
    readonly id: number

    /**
     * 
     * @type {number}
     * @memberof DashboardApiDashboardClientUserPolls
     */
    readonly page?: number

    /**
     * 
     * @type {string}
     * @memberof DashboardApiDashboardClientUserPolls
     */
    readonly limit?: string

    /**
     * 
     * @type {Array<number>}
     * @memberof DashboardApiDashboardClientUserPolls
     */
    readonly idIn?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof DashboardApiDashboardClientUserPolls
     */
    readonly surveyIdIn?: Array<number>

    /**
     * 
     * @type {string}
     * @memberof DashboardApiDashboardClientUserPolls
     */
    readonly state?: string

    /**
     * 
     * @type {string}
     * @memberof DashboardApiDashboardClientUserPolls
     */
    readonly surveyName?: string

    /**
     * 
     * @type {string}
     * @memberof DashboardApiDashboardClientUserPolls
     */
    readonly endDate?: string

    /**
     * 
     * @type {boolean}
     * @memberof DashboardApiDashboardClientUserPolls
     */
    readonly reportViewed?: boolean
}

/**
 * Request parameters for dashboardClientUserProfile operation in DashboardApi.
 * @export
 * @interface DashboardApiDashboardClientUserProfileRequest
 */
export interface DashboardApiDashboardClientUserProfileRequest {
    /**
     * A unique integer value identifying this пользователь.
     * @type {number}
     * @memberof DashboardApiDashboardClientUserProfile
     */
    readonly id: number
}

/**
 * Request parameters for dashboardClientUserStats operation in DashboardApi.
 * @export
 * @interface DashboardApiDashboardClientUserStatsRequest
 */
export interface DashboardApiDashboardClientUserStatsRequest {
    /**
     * A unique integer value identifying this пользователь.
     * @type {number}
     * @memberof DashboardApiDashboardClientUserStats
     */
    readonly id: number
}

/**
 * Request parameters for dashboardClientUsers operation in DashboardApi.
 * @export
 * @interface DashboardApiDashboardClientUsersRequest
 */
export interface DashboardApiDashboardClientUsersRequest {
    /**
     * A search term.
     * @type {string}
     * @memberof DashboardApiDashboardClientUsers
     */
    readonly search?: string

    /**
     * A page number within the paginated result set.
     * @type {number}
     * @memberof DashboardApiDashboardClientUsers
     */
    readonly page?: number

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof DashboardApiDashboardClientUsers
     */
    readonly limit?: number

    /**
     * 
     * @type {number}
     * @memberof DashboardApiDashboardClientUsers
     */
    readonly id?: number

    /**
     * 
     * @type {number}
     * @memberof DashboardApiDashboardClientUsers
     */
    readonly userId?: number

    /**
     * 
     * @type {string}
     * @memberof DashboardApiDashboardClientUsers
     */
    readonly email?: string

    /**
     * 
     * @type {string}
     * @memberof DashboardApiDashboardClientUsers
     */
    readonly fullName?: string

    /**
     * 
     * @type {string}
     * @memberof DashboardApiDashboardClientUsers
     */
    readonly polls?: string

    /**
     * 
     * @type {boolean}
     * @memberof DashboardApiDashboardClientUsers
     */
    readonly reportViewed?: boolean
}

/**
 * Request parameters for dashboardSuggestPollsList operation in DashboardApi.
 * @export
 * @interface DashboardApiDashboardSuggestPollsListRequest
 */
export interface DashboardApiDashboardSuggestPollsListRequest {
    /**
     * A search term.
     * @type {string}
     * @memberof DashboardApiDashboardSuggestPollsList
     */
    readonly search?: string

    /**
     * A page number within the paginated result set.
     * @type {number}
     * @memberof DashboardApiDashboardSuggestPollsList
     */
    readonly page?: number

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof DashboardApiDashboardSuggestPollsList
     */
    readonly limit?: number
}

/**
 * Request parameters for dashboardUserPolls operation in DashboardApi.
 * @export
 * @interface DashboardApiDashboardUserPollsRequest
 */
export interface DashboardApiDashboardUserPollsRequest {
    /**
     * A page number within the paginated result set.
     * @type {number}
     * @memberof DashboardApiDashboardUserPolls
     */
    readonly page?: number

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof DashboardApiDashboardUserPolls
     */
    readonly limit?: number

    /**
     * 
     * @type {Array<number>}
     * @memberof DashboardApiDashboardUserPolls
     */
    readonly idIn?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof DashboardApiDashboardUserPolls
     */
    readonly surveyIdIn?: Array<number>

    /**
     * 
     * @type {string}
     * @memberof DashboardApiDashboardUserPolls
     */
    readonly state?: string

    /**
     * 
     * @type {string}
     * @memberof DashboardApiDashboardUserPolls
     */
    readonly surveyName?: string

    /**
     * 
     * @type {string}
     * @memberof DashboardApiDashboardUserPolls
     */
    readonly endDate?: string

    /**
     * 
     * @type {boolean}
     * @memberof DashboardApiDashboardUserPolls
     */
    readonly reportViewed?: boolean
}

/**
 * Request parameters for dashboardUserStats operation in DashboardApi.
 * @export
 * @interface DashboardApiDashboardUserStatsRequest
 */
export interface DashboardApiDashboardUserStatsRequest {
    /**
     * A page number within the paginated result set.
     * @type {number}
     * @memberof DashboardApiDashboardUserStats
     */
    readonly page?: number

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof DashboardApiDashboardUserStats
     */
    readonly limit?: number
}

/**
 * DashboardApi - object-oriented interface
 * @export
 * @class DashboardApi
 * @extends {BaseAPI}
 */
export class DashboardApi extends BaseAPI {
    /**
     * 
     * @param {DashboardApiDashboardAdminClientsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApi
     */
    public dashboardAdminClients(requestParameters: DashboardApiDashboardAdminClientsRequest = {}, options?: any) {
        return DashboardApiFp(this.configuration).dashboardAdminClients(requestParameters.page, requestParameters.limit, requestParameters.id, requestParameters.groupType, requestParameters.email, requestParameters.fullName, requestParameters.company, requestParameters.numberOfRegistrations, requestParameters.surveyAttempts, requestParameters.surveyAttemptsRemaining, requestParameters.pollsDone, requestParameters.dateStart, requestParameters.dateEnd, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DashboardApiDashboardAdminPollsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApi
     */
    public dashboardAdminPolls(requestParameters: DashboardApiDashboardAdminPollsRequest = {}, options?: any) {
        return DashboardApiFp(this.configuration).dashboardAdminPolls(requestParameters.page, requestParameters.limit, requestParameters.surveyName, requestParameters.numberOfRegistrations, requestParameters.surveyAttempts, requestParameters.surveyAttemptsRemaining, requestParameters.pollsDone, requestParameters.dateStart, requestParameters.dateEnd, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DashboardApiDashboardAdminStatsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApi
     */
    public dashboardAdminStats(requestParameters: DashboardApiDashboardAdminStatsRequest = {}, options?: any) {
        return DashboardApiFp(this.configuration).dashboardAdminStats(requestParameters.page, requestParameters.limit, requestParameters.dateStart, requestParameters.dateEnd, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DashboardApiDashboardClientAddStaffRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApi
     */
    public dashboardClientAddStaff(requestParameters: DashboardApiDashboardClientAddStaffRequest, options?: any) {
        return DashboardApiFp(this.configuration).dashboardClientAddStaff(requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DashboardApiDashboardClientDeleteStaffRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApi
     */
    public dashboardClientDeleteStaff(requestParameters: DashboardApiDashboardClientDeleteStaffRequest, options?: any) {
        return DashboardApiFp(this.configuration).dashboardClientDeleteStaff(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DashboardApiDashboardClientMultipleAddStaffRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApi
     */
    public dashboardClientMultipleAddStaff(requestParameters: DashboardApiDashboardClientMultipleAddStaffRequest, options?: any) {
        return DashboardApiFp(this.configuration).dashboardClientMultipleAddStaff(requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DashboardApiDashboardClientPollsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApi
     */
    public dashboardClientPolls(requestParameters: DashboardApiDashboardClientPollsRequest = {}, options?: any) {
        return DashboardApiFp(this.configuration).dashboardClientPolls(requestParameters.page, requestParameters.limit, requestParameters.surveyName, requestParameters.numberOfRegistrations, requestParameters.inProgress, requestParameters.pollsDone, requestParameters.unviewedReports, requestParameters.surveyAttempts, requestParameters.surveyAttemptsRemaining, requestParameters.registrations, requestParameters.registrationsRemaining, requestParameters.dateStart, requestParameters.dateEnd, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DashboardApiDashboardClientStaffRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApi
     */
    public dashboardClientStaff(requestParameters: DashboardApiDashboardClientStaffRequest = {}, options?: any) {
        return DashboardApiFp(this.configuration).dashboardClientStaff(requestParameters.page, requestParameters.limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DashboardApiDashboardClientStatsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApi
     */
    public dashboardClientStats(requestParameters: DashboardApiDashboardClientStatsRequest = {}, options?: any) {
        return DashboardApiFp(this.configuration).dashboardClientStats(requestParameters.page, requestParameters.limit, requestParameters.dateStart, requestParameters.dateEnd, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DashboardApiDashboardClientUpdateStaffRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApi
     */
    public dashboardClientUpdateStaff(requestParameters: DashboardApiDashboardClientUpdateStaffRequest, options?: any) {
        return DashboardApiFp(this.configuration).dashboardClientUpdateStaff(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DashboardApiDashboardClientUserPollsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApi
     */
    public dashboardClientUserPolls(requestParameters: DashboardApiDashboardClientUserPollsRequest, options?: any) {
        return DashboardApiFp(this.configuration).dashboardClientUserPolls(requestParameters.id, requestParameters.page, requestParameters.limit, requestParameters.idIn, requestParameters.surveyIdIn, requestParameters.state, requestParameters.surveyName, requestParameters.endDate, requestParameters.reportViewed, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DashboardApiDashboardClientUserProfileRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApi
     */
    public dashboardClientUserProfile(requestParameters: DashboardApiDashboardClientUserProfileRequest, options?: any) {
        return DashboardApiFp(this.configuration).dashboardClientUserProfile(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DashboardApiDashboardClientUserStatsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApi
     */
    public dashboardClientUserStats(requestParameters: DashboardApiDashboardClientUserStatsRequest, options?: any) {
        return DashboardApiFp(this.configuration).dashboardClientUserStats(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DashboardApiDashboardClientUsersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApi
     */
    public dashboardClientUsers(requestParameters: DashboardApiDashboardClientUsersRequest = {}, options?: any) {
        return DashboardApiFp(this.configuration).dashboardClientUsers(requestParameters.search, requestParameters.page, requestParameters.limit, requestParameters.id, requestParameters.userId, requestParameters.email, requestParameters.fullName, requestParameters.polls, requestParameters.reportViewed, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DashboardApiDashboardSuggestPollsListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApi
     */
    public dashboardSuggestPollsList(requestParameters: DashboardApiDashboardSuggestPollsListRequest = {}, options?: any) {
        return DashboardApiFp(this.configuration).dashboardSuggestPollsList(requestParameters.search, requestParameters.page, requestParameters.limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DashboardApiDashboardUserPollsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApi
     */
    public dashboardUserPolls(requestParameters: DashboardApiDashboardUserPollsRequest = {}, options?: any) {
        return DashboardApiFp(this.configuration).dashboardUserPolls(requestParameters.page, requestParameters.limit, requestParameters.idIn, requestParameters.surveyIdIn, requestParameters.state, requestParameters.surveyName, requestParameters.endDate, requestParameters.reportViewed, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DashboardApiDashboardUserStatsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApi
     */
    public dashboardUserStats(requestParameters: DashboardApiDashboardUserStatsRequest = {}, options?: any) {
        return DashboardApiFp(this.configuration).dashboardUserStats(requestParameters.page, requestParameters.limit, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FeedbackApi - axios parameter creator
 * @export
 */
export const FeedbackApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateFeedback} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        feedbackUserFeedbackCreate: async (data: CreateFeedback, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            if (data === null || data === undefined) {
                throw new RequiredError('data','Required parameter data was null or undefined when calling feedbackUserFeedbackCreate.');
            }
            const localVarPath = `/feedback/user_feedback/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof data !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(data !== undefined ? data : {})
                : (data || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FeedbackApi - functional programming interface
 * @export
 */
export const FeedbackApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateFeedback} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async feedbackUserFeedbackCreate(data: CreateFeedback, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateFeedback>> {
            const localVarAxiosArgs = await FeedbackApiAxiosParamCreator(configuration).feedbackUserFeedbackCreate(data, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * FeedbackApi - factory interface
 * @export
 */
export const FeedbackApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {CreateFeedback} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        feedbackUserFeedbackCreate(data: CreateFeedback, options?: any): AxiosPromise<CreateFeedback> {
            return FeedbackApiFp(configuration).feedbackUserFeedbackCreate(data, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for feedbackUserFeedbackCreate operation in FeedbackApi.
 * @export
 * @interface FeedbackApiFeedbackUserFeedbackCreateRequest
 */
export interface FeedbackApiFeedbackUserFeedbackCreateRequest {
    /**
     * 
     * @type {CreateFeedback}
     * @memberof FeedbackApiFeedbackUserFeedbackCreate
     */
    readonly data: CreateFeedback
}

/**
 * FeedbackApi - object-oriented interface
 * @export
 * @class FeedbackApi
 * @extends {BaseAPI}
 */
export class FeedbackApi extends BaseAPI {
    /**
     * 
     * @param {FeedbackApiFeedbackUserFeedbackCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedbackApi
     */
    public feedbackUserFeedbackCreate(requestParameters: FeedbackApiFeedbackUserFeedbackCreateRequest, options?: any) {
        return FeedbackApiFp(this.configuration).feedbackUserFeedbackCreate(requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PollsApi - axios parameter creator
 * @export
 */
export const PollsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id A unique integer value identifying this Статус теста у пользователя.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        danteStateDone: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling danteStateDone.');
            }
            const localVarPath = `/polls/dante/{id}/done/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Статус теста у пользователя.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDanteLink: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getDanteLink.');
            }
            const localVarPath = `/polls/dante/{id}/dante-entry-url/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Статус теста у пользователя.
         * @param {Array<Answer>} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pollsAnswersPatch: async (id: number, data: Array<Answer>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling pollsAnswersPatch.');
            }
            // verify required parameter 'data' is not null or undefined
            if (data === null || data === undefined) {
                throw new RequiredError('data','Required parameter data was null or undefined when calling pollsAnswersPatch.');
            }
            const localVarPath = `/polls/{id}/answers/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof data !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(data !== undefined ? data : {})
                : (data || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Статус теста у пользователя.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pollsAnswersRead: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling pollsAnswersRead.');
            }
            const localVarPath = `/polls/{id}/answers/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [templateIn] Несколько значений могут быть разделены запятыми.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pollsChoicesList: async (templateIn?: string, page?: number, limit?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/polls/choices/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }

            if (templateIn !== undefined) {
                localVarQueryParameter['template_in'] = templateIn;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [survey] 
         * @param {number} [subSurvey] 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pollsQuestionGroupsList: async (survey?: number, subSurvey?: number, page?: number, limit?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/polls/question_groups/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }

            if (survey !== undefined) {
                localVarQueryParameter['survey'] = survey;
            }

            if (subSurvey !== undefined) {
                localVarQueryParameter['sub_survey'] = subSurvey;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Статус паспортички у пользователя.
         * @param {object} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pollsQuestionnairesAnswersCreate: async (id: number, data: object, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling pollsQuestionnairesAnswersCreate.');
            }
            // verify required parameter 'data' is not null or undefined
            if (data === null || data === undefined) {
                throw new RequiredError('data','Required parameter data was null or undefined when calling pollsQuestionnairesAnswersCreate.');
            }
            const localVarPath = `/polls/questionnaires/{id}/answers/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof data !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(data !== undefined ? data : {})
                : (data || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Статус паспортички у пользователя.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pollsQuestionnairesAnswersRead: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling pollsQuestionnairesAnswersRead.');
            }
            const localVarPath = `/polls/questionnaires/{id}/answers/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Статус паспортички у пользователя.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pollsQuestionnairesQuestionsRead: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling pollsQuestionnairesQuestionsRead.');
            }
            const localVarPath = `/polls/questionnaires/{id}/questions/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Статус паспортички у пользователя.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pollsQuestionnairesRead: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling pollsQuestionnairesRead.');
            }
            const localVarPath = `/polls/questionnaires/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Статус паспортички у пользователя.
         * @param {SurveyAdditionalState} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pollsQuestionnairesStatePartialUpdate: async (id: number, data: SurveyAdditionalState, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling pollsQuestionnairesStatePartialUpdate.');
            }
            // verify required parameter 'data' is not null or undefined
            if (data === null || data === undefined) {
                throw new RequiredError('data','Required parameter data was null or undefined when calling pollsQuestionnairesStatePartialUpdate.');
            }
            const localVarPath = `/polls/questionnaires/{id}/state/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof data !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(data !== undefined ? data : {})
                : (data || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Статус теста у пользователя.
         * @param {boolean} [withoutAnswers] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pollsQuestionsRead: async (id: number, withoutAnswers?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling pollsQuestionsRead.');
            }
            const localVarPath = `/polls/{id}/questions/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }

            if (withoutAnswers !== undefined) {
                localVarQueryParameter['without_answers'] = withoutAnswers;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Статус теста у пользователя.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pollsRead: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling pollsRead.');
            }
            const localVarPath = `/polls/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Статус теста у пользователя.
         * @param {SurveyState} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pollsStatePartialUpdate: async (id: number, data: SurveyState, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling pollsStatePartialUpdate.');
            }
            // verify required parameter 'data' is not null or undefined
            if (data === null || data === undefined) {
                throw new RequiredError('data','Required parameter data was null or undefined when calling pollsStatePartialUpdate.');
            }
            const localVarPath = `/polls/{id}/state/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof data !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(data !== undefined ? data : {})
                : (data || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Статус теста у пользователя.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pollsStatusRead: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling pollsStatusRead.');
            }
            const localVarPath = `/polls/status/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Статус СубТеста у пользователя.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pollsSubSurveysRead: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling pollsSubSurveysRead.');
            }
            const localVarPath = `/polls/sub_surveys/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Статус СубТеста у пользователя.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pollsSubSurveysStatusRead: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling pollsSubSurveysStatusRead.');
            }
            const localVarPath = `/polls/sub_surveys_status/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UserAccess} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pollsUserAccessesCreate: async (data: UserAccess, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            if (data === null || data === undefined) {
                throw new RequiredError('data','Required parameter data was null or undefined when calling pollsUserAccessesCreate.');
            }
            const localVarPath = `/polls/user_accesses/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof data !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(data !== undefined ? data : {})
                : (data || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Ограничение для новых пользователей на тест.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pollsUserAccessesDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling pollsUserAccessesDelete.');
            }
            const localVarPath = `/polls/user_accesses/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [accessType] 
         * @param {number} [clientLimit] 
         * @param {string} [search] A search term.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pollsUserAccessesList: async (accessType?: string, clientLimit?: number, search?: string, page?: number, limit?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/polls/user_accesses/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }

            if (accessType !== undefined) {
                localVarQueryParameter['access_type'] = accessType;
            }

            if (clientLimit !== undefined) {
                localVarQueryParameter['client_limit'] = clientLimit;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Ограничение для новых пользователей на тест.
         * @param {UserAccess} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pollsUserAccessesPartialUpdate: async (id: number, data: UserAccess, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling pollsUserAccessesPartialUpdate.');
            }
            // verify required parameter 'data' is not null or undefined
            if (data === null || data === undefined) {
                throw new RequiredError('data','Required parameter data was null or undefined when calling pollsUserAccessesPartialUpdate.');
            }
            const localVarPath = `/polls/user_accesses/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof data !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(data !== undefined ? data : {})
                : (data || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Ограничение для новых пользователей на тест.
         * @param {UserAccess} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pollsUserAccessesUpdate: async (id: number, data: UserAccess, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling pollsUserAccessesUpdate.');
            }
            // verify required parameter 'data' is not null or undefined
            if (data === null || data === undefined) {
                throw new RequiredError('data','Required parameter data was null or undefined when calling pollsUserAccessesUpdate.');
            }
            const localVarPath = `/polls/user_accesses/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof data !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(data !== undefined ? data : {})
                : (data || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UserEmailAccess} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pollsUserEmailAccessesCreate: async (data: UserEmailAccess, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            if (data === null || data === undefined) {
                throw new RequiredError('data','Required parameter data was null or undefined when calling pollsUserEmailAccessesCreate.');
            }
            const localVarPath = `/polls/user_email_accesses/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof data !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(data !== undefined ? data : {})
                : (data || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Доступ к тесту по почте.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pollsUserEmailAccessesDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling pollsUserEmailAccessesDelete.');
            }
            const localVarPath = `/polls/user_email_accesses/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [email] 
         * @param {number} [userAccess] 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pollsUserEmailAccessesList: async (email?: string, userAccess?: number, page?: number, limit?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/polls/user_email_accesses/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }

            if (userAccess !== undefined) {
                localVarQueryParameter['user_access'] = userAccess;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Статус СубТеста у пользователя.
         * @param {Array<SubSurveyAnswer>} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subSurveyAnswersPatch: async (id: number, data: Array<SubSurveyAnswer>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling subSurveyAnswersPatch.');
            }
            // verify required parameter 'data' is not null or undefined
            if (data === null || data === undefined) {
                throw new RequiredError('data','Required parameter data was null or undefined when calling subSurveyAnswersPatch.');
            }
            const localVarPath = `/polls/sub_surveys/{id}/answers/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof data !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(data !== undefined ? data : {})
                : (data || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Статус СубТеста у пользователя.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subSurveyAnswersRead: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling subSurveyAnswersRead.');
            }
            const localVarPath = `/polls/sub_surveys/{id}/answers/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Статус СубТеста у пользователя.
         * @param {boolean} [withoutAnswers] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subSurveyQuestionsRead: async (id: number, withoutAnswers?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling subSurveyQuestionsRead.');
            }
            const localVarPath = `/polls/sub_surveys/{id}/questions/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }

            if (withoutAnswers !== undefined) {
                localVarQueryParameter['without_answers'] = withoutAnswers;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Статус СубТеста у пользователя.
         * @param {SubSurveyState} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subSurveyStatePartialUpdate: async (id: number, data: SubSurveyState, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling subSurveyStatePartialUpdate.');
            }
            // verify required parameter 'data' is not null or undefined
            if (data === null || data === undefined) {
                throw new RequiredError('data','Required parameter data was null or undefined when calling subSurveyStatePartialUpdate.');
            }
            const localVarPath = `/polls/sub_surveys/{id}/state/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof data !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(data !== undefined ? data : {})
                : (data || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PollsApi - functional programming interface
 * @export
 */
export const PollsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id A unique integer value identifying this Статус теста у пользователя.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async danteStateDone(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SurveyState>> {
            const localVarAxiosArgs = await PollsApiAxiosParamCreator(configuration).danteStateDone(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Статус теста у пользователя.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDanteLink(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DanteInfo>> {
            const localVarAxiosArgs = await PollsApiAxiosParamCreator(configuration).getDanteLink(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Статус теста у пользователя.
         * @param {Array<Answer>} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pollsAnswersPatch(id: number, data: Array<Answer>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Answer>>> {
            const localVarAxiosArgs = await PollsApiAxiosParamCreator(configuration).pollsAnswersPatch(id, data, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Статус теста у пользователя.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pollsAnswersRead(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Answer>>> {
            const localVarAxiosArgs = await PollsApiAxiosParamCreator(configuration).pollsAnswersRead(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [templateIn] Несколько значений могут быть разделены запятыми.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pollsChoicesList(templateIn?: string, page?: number, limit?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2008>> {
            const localVarAxiosArgs = await PollsApiAxiosParamCreator(configuration).pollsChoicesList(templateIn, page, limit, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} [survey] 
         * @param {number} [subSurvey] 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pollsQuestionGroupsList(survey?: number, subSurvey?: number, page?: number, limit?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2009>> {
            const localVarAxiosArgs = await PollsApiAxiosParamCreator(configuration).pollsQuestionGroupsList(survey, subSurvey, page, limit, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Статус паспортички у пользователя.
         * @param {object} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pollsQuestionnairesAnswersCreate(id: number, data: object, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Answer>>> {
            const localVarAxiosArgs = await PollsApiAxiosParamCreator(configuration).pollsQuestionnairesAnswersCreate(id, data, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Статус паспортички у пользователя.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pollsQuestionnairesAnswersRead(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Answer>>> {
            const localVarAxiosArgs = await PollsApiAxiosParamCreator(configuration).pollsQuestionnairesAnswersRead(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Статус паспортички у пользователя.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pollsQuestionnairesQuestionsRead(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QuestionnaireJSONSchema>> {
            const localVarAxiosArgs = await PollsApiAxiosParamCreator(configuration).pollsQuestionnairesQuestionsRead(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Статус паспортички у пользователя.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pollsQuestionnairesRead(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SurveyAdditionalInfo>> {
            const localVarAxiosArgs = await PollsApiAxiosParamCreator(configuration).pollsQuestionnairesRead(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Статус паспортички у пользователя.
         * @param {SurveyAdditionalState} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pollsQuestionnairesStatePartialUpdate(id: number, data: SurveyAdditionalState, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SurveyAdditionalState>> {
            const localVarAxiosArgs = await PollsApiAxiosParamCreator(configuration).pollsQuestionnairesStatePartialUpdate(id, data, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Статус теста у пользователя.
         * @param {boolean} [withoutAnswers] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pollsQuestionsRead(id: number, withoutAnswers?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Survey>> {
            const localVarAxiosArgs = await PollsApiAxiosParamCreator(configuration).pollsQuestionsRead(id, withoutAnswers, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Статус теста у пользователя.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pollsRead(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SurveyInfo>> {
            const localVarAxiosArgs = await PollsApiAxiosParamCreator(configuration).pollsRead(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Статус теста у пользователя.
         * @param {SurveyState} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pollsStatePartialUpdate(id: number, data: SurveyState, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SurveyState>> {
            const localVarAxiosArgs = await PollsApiAxiosParamCreator(configuration).pollsStatePartialUpdate(id, data, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Статус теста у пользователя.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pollsStatusRead(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SurveyStatus>> {
            const localVarAxiosArgs = await PollsApiAxiosParamCreator(configuration).pollsStatusRead(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Статус СубТеста у пользователя.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pollsSubSurveysRead(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SubSurveyInfo>> {
            const localVarAxiosArgs = await PollsApiAxiosParamCreator(configuration).pollsSubSurveysRead(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Статус СубТеста у пользователя.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pollsSubSurveysStatusRead(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SurveyStatus>> {
            const localVarAxiosArgs = await PollsApiAxiosParamCreator(configuration).pollsSubSurveysStatusRead(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {UserAccess} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pollsUserAccessesCreate(data: UserAccess, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserAccess>> {
            const localVarAxiosArgs = await PollsApiAxiosParamCreator(configuration).pollsUserAccessesCreate(data, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Ограничение для новых пользователей на тест.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pollsUserAccessesDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await PollsApiAxiosParamCreator(configuration).pollsUserAccessesDelete(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [accessType] 
         * @param {number} [clientLimit] 
         * @param {string} [search] A search term.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pollsUserAccessesList(accessType?: string, clientLimit?: number, search?: string, page?: number, limit?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20010>> {
            const localVarAxiosArgs = await PollsApiAxiosParamCreator(configuration).pollsUserAccessesList(accessType, clientLimit, search, page, limit, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Ограничение для новых пользователей на тест.
         * @param {UserAccess} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pollsUserAccessesPartialUpdate(id: number, data: UserAccess, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserAccess>> {
            const localVarAxiosArgs = await PollsApiAxiosParamCreator(configuration).pollsUserAccessesPartialUpdate(id, data, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Ограничение для новых пользователей на тест.
         * @param {UserAccess} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pollsUserAccessesUpdate(id: number, data: UserAccess, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserAccess>> {
            const localVarAxiosArgs = await PollsApiAxiosParamCreator(configuration).pollsUserAccessesUpdate(id, data, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {UserEmailAccess} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pollsUserEmailAccessesCreate(data: UserEmailAccess, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserEmailAccess>> {
            const localVarAxiosArgs = await PollsApiAxiosParamCreator(configuration).pollsUserEmailAccessesCreate(data, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Доступ к тесту по почте.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pollsUserEmailAccessesDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await PollsApiAxiosParamCreator(configuration).pollsUserEmailAccessesDelete(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [email] 
         * @param {number} [userAccess] 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pollsUserEmailAccessesList(email?: string, userAccess?: number, page?: number, limit?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse20011>> {
            const localVarAxiosArgs = await PollsApiAxiosParamCreator(configuration).pollsUserEmailAccessesList(email, userAccess, page, limit, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Статус СубТеста у пользователя.
         * @param {Array<SubSurveyAnswer>} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async subSurveyAnswersPatch(id: number, data: Array<SubSurveyAnswer>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SubSurveyAnswer>>> {
            const localVarAxiosArgs = await PollsApiAxiosParamCreator(configuration).subSurveyAnswersPatch(id, data, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Статус СубТеста у пользователя.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async subSurveyAnswersRead(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SubSurveyAnswer>>> {
            const localVarAxiosArgs = await PollsApiAxiosParamCreator(configuration).subSurveyAnswersRead(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Статус СубТеста у пользователя.
         * @param {boolean} [withoutAnswers] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async subSurveyQuestionsRead(id: number, withoutAnswers?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SubSurvey>> {
            const localVarAxiosArgs = await PollsApiAxiosParamCreator(configuration).subSurveyQuestionsRead(id, withoutAnswers, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Статус СубТеста у пользователя.
         * @param {SubSurveyState} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async subSurveyStatePartialUpdate(id: number, data: SubSurveyState, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SubSurveyState>> {
            const localVarAxiosArgs = await PollsApiAxiosParamCreator(configuration).subSurveyStatePartialUpdate(id, data, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * PollsApi - factory interface
 * @export
 */
export const PollsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {number} id A unique integer value identifying this Статус теста у пользователя.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        danteStateDone(id: number, options?: any): AxiosPromise<SurveyState> {
            return PollsApiFp(configuration).danteStateDone(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Статус теста у пользователя.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDanteLink(id: number, options?: any): AxiosPromise<DanteInfo> {
            return PollsApiFp(configuration).getDanteLink(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Статус теста у пользователя.
         * @param {Array<Answer>} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pollsAnswersPatch(id: number, data: Array<Answer>, options?: any): AxiosPromise<Array<Answer>> {
            return PollsApiFp(configuration).pollsAnswersPatch(id, data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Статус теста у пользователя.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pollsAnswersRead(id: number, options?: any): AxiosPromise<Array<Answer>> {
            return PollsApiFp(configuration).pollsAnswersRead(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [templateIn] Несколько значений могут быть разделены запятыми.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pollsChoicesList(templateIn?: string, page?: number, limit?: number, options?: any): AxiosPromise<InlineResponse2008> {
            return PollsApiFp(configuration).pollsChoicesList(templateIn, page, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [survey] 
         * @param {number} [subSurvey] 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pollsQuestionGroupsList(survey?: number, subSurvey?: number, page?: number, limit?: number, options?: any): AxiosPromise<InlineResponse2009> {
            return PollsApiFp(configuration).pollsQuestionGroupsList(survey, subSurvey, page, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Статус паспортички у пользователя.
         * @param {object} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pollsQuestionnairesAnswersCreate(id: number, data: object, options?: any): AxiosPromise<Array<Answer>> {
            return PollsApiFp(configuration).pollsQuestionnairesAnswersCreate(id, data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Статус паспортички у пользователя.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pollsQuestionnairesAnswersRead(id: number, options?: any): AxiosPromise<Array<Answer>> {
            return PollsApiFp(configuration).pollsQuestionnairesAnswersRead(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Статус паспортички у пользователя.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pollsQuestionnairesQuestionsRead(id: number, options?: any): AxiosPromise<QuestionnaireJSONSchema> {
            return PollsApiFp(configuration).pollsQuestionnairesQuestionsRead(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Статус паспортички у пользователя.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pollsQuestionnairesRead(id: number, options?: any): AxiosPromise<SurveyAdditionalInfo> {
            return PollsApiFp(configuration).pollsQuestionnairesRead(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Статус паспортички у пользователя.
         * @param {SurveyAdditionalState} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pollsQuestionnairesStatePartialUpdate(id: number, data: SurveyAdditionalState, options?: any): AxiosPromise<SurveyAdditionalState> {
            return PollsApiFp(configuration).pollsQuestionnairesStatePartialUpdate(id, data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Статус теста у пользователя.
         * @param {boolean} [withoutAnswers] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pollsQuestionsRead(id: number, withoutAnswers?: boolean, options?: any): AxiosPromise<Survey> {
            return PollsApiFp(configuration).pollsQuestionsRead(id, withoutAnswers, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Статус теста у пользователя.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pollsRead(id: number, options?: any): AxiosPromise<SurveyInfo> {
            return PollsApiFp(configuration).pollsRead(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Статус теста у пользователя.
         * @param {SurveyState} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pollsStatePartialUpdate(id: number, data: SurveyState, options?: any): AxiosPromise<SurveyState> {
            return PollsApiFp(configuration).pollsStatePartialUpdate(id, data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Статус теста у пользователя.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pollsStatusRead(id: number, options?: any): AxiosPromise<SurveyStatus> {
            return PollsApiFp(configuration).pollsStatusRead(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Статус СубТеста у пользователя.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pollsSubSurveysRead(id: number, options?: any): AxiosPromise<SubSurveyInfo> {
            return PollsApiFp(configuration).pollsSubSurveysRead(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Статус СубТеста у пользователя.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pollsSubSurveysStatusRead(id: number, options?: any): AxiosPromise<SurveyStatus> {
            return PollsApiFp(configuration).pollsSubSurveysStatusRead(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UserAccess} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pollsUserAccessesCreate(data: UserAccess, options?: any): AxiosPromise<UserAccess> {
            return PollsApiFp(configuration).pollsUserAccessesCreate(data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Ограничение для новых пользователей на тест.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pollsUserAccessesDelete(id: number, options?: any): AxiosPromise<void> {
            return PollsApiFp(configuration).pollsUserAccessesDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [accessType] 
         * @param {number} [clientLimit] 
         * @param {string} [search] A search term.
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pollsUserAccessesList(accessType?: string, clientLimit?: number, search?: string, page?: number, limit?: number, options?: any): AxiosPromise<InlineResponse20010> {
            return PollsApiFp(configuration).pollsUserAccessesList(accessType, clientLimit, search, page, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Ограничение для новых пользователей на тест.
         * @param {UserAccess} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pollsUserAccessesPartialUpdate(id: number, data: UserAccess, options?: any): AxiosPromise<UserAccess> {
            return PollsApiFp(configuration).pollsUserAccessesPartialUpdate(id, data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Ограничение для новых пользователей на тест.
         * @param {UserAccess} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pollsUserAccessesUpdate(id: number, data: UserAccess, options?: any): AxiosPromise<UserAccess> {
            return PollsApiFp(configuration).pollsUserAccessesUpdate(id, data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UserEmailAccess} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pollsUserEmailAccessesCreate(data: UserEmailAccess, options?: any): AxiosPromise<UserEmailAccess> {
            return PollsApiFp(configuration).pollsUserEmailAccessesCreate(data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Доступ к тесту по почте.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pollsUserEmailAccessesDelete(id: number, options?: any): AxiosPromise<void> {
            return PollsApiFp(configuration).pollsUserEmailAccessesDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [email] 
         * @param {number} [userAccess] 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pollsUserEmailAccessesList(email?: string, userAccess?: number, page?: number, limit?: number, options?: any): AxiosPromise<InlineResponse20011> {
            return PollsApiFp(configuration).pollsUserEmailAccessesList(email, userAccess, page, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Статус СубТеста у пользователя.
         * @param {Array<SubSurveyAnswer>} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subSurveyAnswersPatch(id: number, data: Array<SubSurveyAnswer>, options?: any): AxiosPromise<Array<SubSurveyAnswer>> {
            return PollsApiFp(configuration).subSurveyAnswersPatch(id, data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Статус СубТеста у пользователя.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subSurveyAnswersRead(id: number, options?: any): AxiosPromise<Array<SubSurveyAnswer>> {
            return PollsApiFp(configuration).subSurveyAnswersRead(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Статус СубТеста у пользователя.
         * @param {boolean} [withoutAnswers] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subSurveyQuestionsRead(id: number, withoutAnswers?: boolean, options?: any): AxiosPromise<SubSurvey> {
            return PollsApiFp(configuration).subSurveyQuestionsRead(id, withoutAnswers, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Статус СубТеста у пользователя.
         * @param {SubSurveyState} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subSurveyStatePartialUpdate(id: number, data: SubSurveyState, options?: any): AxiosPromise<SubSurveyState> {
            return PollsApiFp(configuration).subSurveyStatePartialUpdate(id, data, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for danteStateDone operation in PollsApi.
 * @export
 * @interface PollsApiDanteStateDoneRequest
 */
export interface PollsApiDanteStateDoneRequest {
    /**
     * A unique integer value identifying this Статус теста у пользователя.
     * @type {number}
     * @memberof PollsApiDanteStateDone
     */
    readonly id: number
}

/**
 * Request parameters for getDanteLink operation in PollsApi.
 * @export
 * @interface PollsApiGetDanteLinkRequest
 */
export interface PollsApiGetDanteLinkRequest {
    /**
     * A unique integer value identifying this Статус теста у пользователя.
     * @type {number}
     * @memberof PollsApiGetDanteLink
     */
    readonly id: number
}

/**
 * Request parameters for pollsAnswersPatch operation in PollsApi.
 * @export
 * @interface PollsApiPollsAnswersPatchRequest
 */
export interface PollsApiPollsAnswersPatchRequest {
    /**
     * A unique integer value identifying this Статус теста у пользователя.
     * @type {number}
     * @memberof PollsApiPollsAnswersPatch
     */
    readonly id: number

    /**
     * 
     * @type {Array<Answer>}
     * @memberof PollsApiPollsAnswersPatch
     */
    readonly data: Array<Answer>
}

/**
 * Request parameters for pollsAnswersRead operation in PollsApi.
 * @export
 * @interface PollsApiPollsAnswersReadRequest
 */
export interface PollsApiPollsAnswersReadRequest {
    /**
     * A unique integer value identifying this Статус теста у пользователя.
     * @type {number}
     * @memberof PollsApiPollsAnswersRead
     */
    readonly id: number
}

/**
 * Request parameters for pollsChoicesList operation in PollsApi.
 * @export
 * @interface PollsApiPollsChoicesListRequest
 */
export interface PollsApiPollsChoicesListRequest {
    /**
     * Несколько значений могут быть разделены запятыми.
     * @type {string}
     * @memberof PollsApiPollsChoicesList
     */
    readonly templateIn?: string

    /**
     * A page number within the paginated result set.
     * @type {number}
     * @memberof PollsApiPollsChoicesList
     */
    readonly page?: number

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof PollsApiPollsChoicesList
     */
    readonly limit?: number
}

/**
 * Request parameters for pollsQuestionGroupsList operation in PollsApi.
 * @export
 * @interface PollsApiPollsQuestionGroupsListRequest
 */
export interface PollsApiPollsQuestionGroupsListRequest {
    /**
     * 
     * @type {number}
     * @memberof PollsApiPollsQuestionGroupsList
     */
    readonly survey?: number

    /**
     * 
     * @type {number}
     * @memberof PollsApiPollsQuestionGroupsList
     */
    readonly subSurvey?: number

    /**
     * A page number within the paginated result set.
     * @type {number}
     * @memberof PollsApiPollsQuestionGroupsList
     */
    readonly page?: number

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof PollsApiPollsQuestionGroupsList
     */
    readonly limit?: number
}

/**
 * Request parameters for pollsQuestionnairesAnswersCreate operation in PollsApi.
 * @export
 * @interface PollsApiPollsQuestionnairesAnswersCreateRequest
 */
export interface PollsApiPollsQuestionnairesAnswersCreateRequest {
    /**
     * A unique integer value identifying this Статус паспортички у пользователя.
     * @type {number}
     * @memberof PollsApiPollsQuestionnairesAnswersCreate
     */
    readonly id: number

    /**
     * 
     * @type {object}
     * @memberof PollsApiPollsQuestionnairesAnswersCreate
     */
    readonly data: object
}

/**
 * Request parameters for pollsQuestionnairesAnswersRead operation in PollsApi.
 * @export
 * @interface PollsApiPollsQuestionnairesAnswersReadRequest
 */
export interface PollsApiPollsQuestionnairesAnswersReadRequest {
    /**
     * A unique integer value identifying this Статус паспортички у пользователя.
     * @type {number}
     * @memberof PollsApiPollsQuestionnairesAnswersRead
     */
    readonly id: number
}

/**
 * Request parameters for pollsQuestionnairesQuestionsRead operation in PollsApi.
 * @export
 * @interface PollsApiPollsQuestionnairesQuestionsReadRequest
 */
export interface PollsApiPollsQuestionnairesQuestionsReadRequest {
    /**
     * A unique integer value identifying this Статус паспортички у пользователя.
     * @type {number}
     * @memberof PollsApiPollsQuestionnairesQuestionsRead
     */
    readonly id: number
}

/**
 * Request parameters for pollsQuestionnairesRead operation in PollsApi.
 * @export
 * @interface PollsApiPollsQuestionnairesReadRequest
 */
export interface PollsApiPollsQuestionnairesReadRequest {
    /**
     * A unique integer value identifying this Статус паспортички у пользователя.
     * @type {number}
     * @memberof PollsApiPollsQuestionnairesRead
     */
    readonly id: number
}

/**
 * Request parameters for pollsQuestionnairesStatePartialUpdate operation in PollsApi.
 * @export
 * @interface PollsApiPollsQuestionnairesStatePartialUpdateRequest
 */
export interface PollsApiPollsQuestionnairesStatePartialUpdateRequest {
    /**
     * A unique integer value identifying this Статус паспортички у пользователя.
     * @type {number}
     * @memberof PollsApiPollsQuestionnairesStatePartialUpdate
     */
    readonly id: number

    /**
     * 
     * @type {SurveyAdditionalState}
     * @memberof PollsApiPollsQuestionnairesStatePartialUpdate
     */
    readonly data: SurveyAdditionalState
}

/**
 * Request parameters for pollsQuestionsRead operation in PollsApi.
 * @export
 * @interface PollsApiPollsQuestionsReadRequest
 */
export interface PollsApiPollsQuestionsReadRequest {
    /**
     * A unique integer value identifying this Статус теста у пользователя.
     * @type {number}
     * @memberof PollsApiPollsQuestionsRead
     */
    readonly id: number

    /**
     * 
     * @type {boolean}
     * @memberof PollsApiPollsQuestionsRead
     */
    readonly withoutAnswers?: boolean
}

/**
 * Request parameters for pollsRead operation in PollsApi.
 * @export
 * @interface PollsApiPollsReadRequest
 */
export interface PollsApiPollsReadRequest {
    /**
     * A unique integer value identifying this Статус теста у пользователя.
     * @type {number}
     * @memberof PollsApiPollsRead
     */
    readonly id: number
}

/**
 * Request parameters for pollsStatePartialUpdate operation in PollsApi.
 * @export
 * @interface PollsApiPollsStatePartialUpdateRequest
 */
export interface PollsApiPollsStatePartialUpdateRequest {
    /**
     * A unique integer value identifying this Статус теста у пользователя.
     * @type {number}
     * @memberof PollsApiPollsStatePartialUpdate
     */
    readonly id: number

    /**
     * 
     * @type {SurveyState}
     * @memberof PollsApiPollsStatePartialUpdate
     */
    readonly data: SurveyState
}

/**
 * Request parameters for pollsStatusRead operation in PollsApi.
 * @export
 * @interface PollsApiPollsStatusReadRequest
 */
export interface PollsApiPollsStatusReadRequest {
    /**
     * A unique integer value identifying this Статус теста у пользователя.
     * @type {number}
     * @memberof PollsApiPollsStatusRead
     */
    readonly id: number
}

/**
 * Request parameters for pollsSubSurveysRead operation in PollsApi.
 * @export
 * @interface PollsApiPollsSubSurveysReadRequest
 */
export interface PollsApiPollsSubSurveysReadRequest {
    /**
     * A unique integer value identifying this Статус СубТеста у пользователя.
     * @type {number}
     * @memberof PollsApiPollsSubSurveysRead
     */
    readonly id: number
}

/**
 * Request parameters for pollsSubSurveysStatusRead operation in PollsApi.
 * @export
 * @interface PollsApiPollsSubSurveysStatusReadRequest
 */
export interface PollsApiPollsSubSurveysStatusReadRequest {
    /**
     * A unique integer value identifying this Статус СубТеста у пользователя.
     * @type {number}
     * @memberof PollsApiPollsSubSurveysStatusRead
     */
    readonly id: number
}

/**
 * Request parameters for pollsUserAccessesCreate operation in PollsApi.
 * @export
 * @interface PollsApiPollsUserAccessesCreateRequest
 */
export interface PollsApiPollsUserAccessesCreateRequest {
    /**
     * 
     * @type {UserAccess}
     * @memberof PollsApiPollsUserAccessesCreate
     */
    readonly data: UserAccess
}

/**
 * Request parameters for pollsUserAccessesDelete operation in PollsApi.
 * @export
 * @interface PollsApiPollsUserAccessesDeleteRequest
 */
export interface PollsApiPollsUserAccessesDeleteRequest {
    /**
     * A unique integer value identifying this Ограничение для новых пользователей на тест.
     * @type {number}
     * @memberof PollsApiPollsUserAccessesDelete
     */
    readonly id: number
}

/**
 * Request parameters for pollsUserAccessesList operation in PollsApi.
 * @export
 * @interface PollsApiPollsUserAccessesListRequest
 */
export interface PollsApiPollsUserAccessesListRequest {
    /**
     * 
     * @type {string}
     * @memberof PollsApiPollsUserAccessesList
     */
    readonly accessType?: string

    /**
     * 
     * @type {number}
     * @memberof PollsApiPollsUserAccessesList
     */
    readonly clientLimit?: number

    /**
     * A search term.
     * @type {string}
     * @memberof PollsApiPollsUserAccessesList
     */
    readonly search?: string

    /**
     * A page number within the paginated result set.
     * @type {number}
     * @memberof PollsApiPollsUserAccessesList
     */
    readonly page?: number

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof PollsApiPollsUserAccessesList
     */
    readonly limit?: number
}

/**
 * Request parameters for pollsUserAccessesPartialUpdate operation in PollsApi.
 * @export
 * @interface PollsApiPollsUserAccessesPartialUpdateRequest
 */
export interface PollsApiPollsUserAccessesPartialUpdateRequest {
    /**
     * A unique integer value identifying this Ограничение для новых пользователей на тест.
     * @type {number}
     * @memberof PollsApiPollsUserAccessesPartialUpdate
     */
    readonly id: number

    /**
     * 
     * @type {UserAccess}
     * @memberof PollsApiPollsUserAccessesPartialUpdate
     */
    readonly data: UserAccess
}

/**
 * Request parameters for pollsUserAccessesUpdate operation in PollsApi.
 * @export
 * @interface PollsApiPollsUserAccessesUpdateRequest
 */
export interface PollsApiPollsUserAccessesUpdateRequest {
    /**
     * A unique integer value identifying this Ограничение для новых пользователей на тест.
     * @type {number}
     * @memberof PollsApiPollsUserAccessesUpdate
     */
    readonly id: number

    /**
     * 
     * @type {UserAccess}
     * @memberof PollsApiPollsUserAccessesUpdate
     */
    readonly data: UserAccess
}

/**
 * Request parameters for pollsUserEmailAccessesCreate operation in PollsApi.
 * @export
 * @interface PollsApiPollsUserEmailAccessesCreateRequest
 */
export interface PollsApiPollsUserEmailAccessesCreateRequest {
    /**
     * 
     * @type {UserEmailAccess}
     * @memberof PollsApiPollsUserEmailAccessesCreate
     */
    readonly data: UserEmailAccess
}

/**
 * Request parameters for pollsUserEmailAccessesDelete operation in PollsApi.
 * @export
 * @interface PollsApiPollsUserEmailAccessesDeleteRequest
 */
export interface PollsApiPollsUserEmailAccessesDeleteRequest {
    /**
     * A unique integer value identifying this Доступ к тесту по почте.
     * @type {number}
     * @memberof PollsApiPollsUserEmailAccessesDelete
     */
    readonly id: number
}

/**
 * Request parameters for pollsUserEmailAccessesList operation in PollsApi.
 * @export
 * @interface PollsApiPollsUserEmailAccessesListRequest
 */
export interface PollsApiPollsUserEmailAccessesListRequest {
    /**
     * 
     * @type {string}
     * @memberof PollsApiPollsUserEmailAccessesList
     */
    readonly email?: string

    /**
     * 
     * @type {number}
     * @memberof PollsApiPollsUserEmailAccessesList
     */
    readonly userAccess?: number

    /**
     * A page number within the paginated result set.
     * @type {number}
     * @memberof PollsApiPollsUserEmailAccessesList
     */
    readonly page?: number

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof PollsApiPollsUserEmailAccessesList
     */
    readonly limit?: number
}

/**
 * Request parameters for subSurveyAnswersPatch operation in PollsApi.
 * @export
 * @interface PollsApiSubSurveyAnswersPatchRequest
 */
export interface PollsApiSubSurveyAnswersPatchRequest {
    /**
     * A unique integer value identifying this Статус СубТеста у пользователя.
     * @type {number}
     * @memberof PollsApiSubSurveyAnswersPatch
     */
    readonly id: number

    /**
     * 
     * @type {Array<SubSurveyAnswer>}
     * @memberof PollsApiSubSurveyAnswersPatch
     */
    readonly data: Array<SubSurveyAnswer>
}

/**
 * Request parameters for subSurveyAnswersRead operation in PollsApi.
 * @export
 * @interface PollsApiSubSurveyAnswersReadRequest
 */
export interface PollsApiSubSurveyAnswersReadRequest {
    /**
     * A unique integer value identifying this Статус СубТеста у пользователя.
     * @type {number}
     * @memberof PollsApiSubSurveyAnswersRead
     */
    readonly id: number
}

/**
 * Request parameters for subSurveyQuestionsRead operation in PollsApi.
 * @export
 * @interface PollsApiSubSurveyQuestionsReadRequest
 */
export interface PollsApiSubSurveyQuestionsReadRequest {
    /**
     * A unique integer value identifying this Статус СубТеста у пользователя.
     * @type {number}
     * @memberof PollsApiSubSurveyQuestionsRead
     */
    readonly id: number

    /**
     * 
     * @type {boolean}
     * @memberof PollsApiSubSurveyQuestionsRead
     */
    readonly withoutAnswers?: boolean
}

/**
 * Request parameters for subSurveyStatePartialUpdate operation in PollsApi.
 * @export
 * @interface PollsApiSubSurveyStatePartialUpdateRequest
 */
export interface PollsApiSubSurveyStatePartialUpdateRequest {
    /**
     * A unique integer value identifying this Статус СубТеста у пользователя.
     * @type {number}
     * @memberof PollsApiSubSurveyStatePartialUpdate
     */
    readonly id: number

    /**
     * 
     * @type {SubSurveyState}
     * @memberof PollsApiSubSurveyStatePartialUpdate
     */
    readonly data: SubSurveyState
}

/**
 * PollsApi - object-oriented interface
 * @export
 * @class PollsApi
 * @extends {BaseAPI}
 */
export class PollsApi extends BaseAPI {
    /**
     * 
     * @param {PollsApiDanteStateDoneRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PollsApi
     */
    public danteStateDone(requestParameters: PollsApiDanteStateDoneRequest, options?: any) {
        return PollsApiFp(this.configuration).danteStateDone(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PollsApiGetDanteLinkRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PollsApi
     */
    public getDanteLink(requestParameters: PollsApiGetDanteLinkRequest, options?: any) {
        return PollsApiFp(this.configuration).getDanteLink(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PollsApiPollsAnswersPatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PollsApi
     */
    public pollsAnswersPatch(requestParameters: PollsApiPollsAnswersPatchRequest, options?: any) {
        return PollsApiFp(this.configuration).pollsAnswersPatch(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PollsApiPollsAnswersReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PollsApi
     */
    public pollsAnswersRead(requestParameters: PollsApiPollsAnswersReadRequest, options?: any) {
        return PollsApiFp(this.configuration).pollsAnswersRead(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PollsApiPollsChoicesListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PollsApi
     */
    public pollsChoicesList(requestParameters: PollsApiPollsChoicesListRequest = {}, options?: any) {
        return PollsApiFp(this.configuration).pollsChoicesList(requestParameters.templateIn, requestParameters.page, requestParameters.limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PollsApiPollsQuestionGroupsListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PollsApi
     */
    public pollsQuestionGroupsList(requestParameters: PollsApiPollsQuestionGroupsListRequest = {}, options?: any) {
        return PollsApiFp(this.configuration).pollsQuestionGroupsList(requestParameters.survey, requestParameters.subSurvey, requestParameters.page, requestParameters.limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PollsApiPollsQuestionnairesAnswersCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PollsApi
     */
    public pollsQuestionnairesAnswersCreate(requestParameters: PollsApiPollsQuestionnairesAnswersCreateRequest, options?: any) {
        return PollsApiFp(this.configuration).pollsQuestionnairesAnswersCreate(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PollsApiPollsQuestionnairesAnswersReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PollsApi
     */
    public pollsQuestionnairesAnswersRead(requestParameters: PollsApiPollsQuestionnairesAnswersReadRequest, options?: any) {
        return PollsApiFp(this.configuration).pollsQuestionnairesAnswersRead(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PollsApiPollsQuestionnairesQuestionsReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PollsApi
     */
    public pollsQuestionnairesQuestionsRead(requestParameters: PollsApiPollsQuestionnairesQuestionsReadRequest, options?: any) {
        return PollsApiFp(this.configuration).pollsQuestionnairesQuestionsRead(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PollsApiPollsQuestionnairesReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PollsApi
     */
    public pollsQuestionnairesRead(requestParameters: PollsApiPollsQuestionnairesReadRequest, options?: any) {
        return PollsApiFp(this.configuration).pollsQuestionnairesRead(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PollsApiPollsQuestionnairesStatePartialUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PollsApi
     */
    public pollsQuestionnairesStatePartialUpdate(requestParameters: PollsApiPollsQuestionnairesStatePartialUpdateRequest, options?: any) {
        return PollsApiFp(this.configuration).pollsQuestionnairesStatePartialUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PollsApiPollsQuestionsReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PollsApi
     */
    public pollsQuestionsRead(requestParameters: PollsApiPollsQuestionsReadRequest, options?: any) {
        return PollsApiFp(this.configuration).pollsQuestionsRead(requestParameters.id, requestParameters.withoutAnswers, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PollsApiPollsReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PollsApi
     */
    public pollsRead(requestParameters: PollsApiPollsReadRequest, options?: any) {
        return PollsApiFp(this.configuration).pollsRead(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PollsApiPollsStatePartialUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PollsApi
     */
    public pollsStatePartialUpdate(requestParameters: PollsApiPollsStatePartialUpdateRequest, options?: any) {
        return PollsApiFp(this.configuration).pollsStatePartialUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PollsApiPollsStatusReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PollsApi
     */
    public pollsStatusRead(requestParameters: PollsApiPollsStatusReadRequest, options?: any) {
        return PollsApiFp(this.configuration).pollsStatusRead(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PollsApiPollsSubSurveysReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PollsApi
     */
    public pollsSubSurveysRead(requestParameters: PollsApiPollsSubSurveysReadRequest, options?: any) {
        return PollsApiFp(this.configuration).pollsSubSurveysRead(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PollsApiPollsSubSurveysStatusReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PollsApi
     */
    public pollsSubSurveysStatusRead(requestParameters: PollsApiPollsSubSurveysStatusReadRequest, options?: any) {
        return PollsApiFp(this.configuration).pollsSubSurveysStatusRead(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PollsApiPollsUserAccessesCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PollsApi
     */
    public pollsUserAccessesCreate(requestParameters: PollsApiPollsUserAccessesCreateRequest, options?: any) {
        return PollsApiFp(this.configuration).pollsUserAccessesCreate(requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PollsApiPollsUserAccessesDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PollsApi
     */
    public pollsUserAccessesDelete(requestParameters: PollsApiPollsUserAccessesDeleteRequest, options?: any) {
        return PollsApiFp(this.configuration).pollsUserAccessesDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PollsApiPollsUserAccessesListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PollsApi
     */
    public pollsUserAccessesList(requestParameters: PollsApiPollsUserAccessesListRequest = {}, options?: any) {
        return PollsApiFp(this.configuration).pollsUserAccessesList(requestParameters.accessType, requestParameters.clientLimit, requestParameters.search, requestParameters.page, requestParameters.limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PollsApiPollsUserAccessesPartialUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PollsApi
     */
    public pollsUserAccessesPartialUpdate(requestParameters: PollsApiPollsUserAccessesPartialUpdateRequest, options?: any) {
        return PollsApiFp(this.configuration).pollsUserAccessesPartialUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PollsApiPollsUserAccessesUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PollsApi
     */
    public pollsUserAccessesUpdate(requestParameters: PollsApiPollsUserAccessesUpdateRequest, options?: any) {
        return PollsApiFp(this.configuration).pollsUserAccessesUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PollsApiPollsUserEmailAccessesCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PollsApi
     */
    public pollsUserEmailAccessesCreate(requestParameters: PollsApiPollsUserEmailAccessesCreateRequest, options?: any) {
        return PollsApiFp(this.configuration).pollsUserEmailAccessesCreate(requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PollsApiPollsUserEmailAccessesDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PollsApi
     */
    public pollsUserEmailAccessesDelete(requestParameters: PollsApiPollsUserEmailAccessesDeleteRequest, options?: any) {
        return PollsApiFp(this.configuration).pollsUserEmailAccessesDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PollsApiPollsUserEmailAccessesListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PollsApi
     */
    public pollsUserEmailAccessesList(requestParameters: PollsApiPollsUserEmailAccessesListRequest = {}, options?: any) {
        return PollsApiFp(this.configuration).pollsUserEmailAccessesList(requestParameters.email, requestParameters.userAccess, requestParameters.page, requestParameters.limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PollsApiSubSurveyAnswersPatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PollsApi
     */
    public subSurveyAnswersPatch(requestParameters: PollsApiSubSurveyAnswersPatchRequest, options?: any) {
        return PollsApiFp(this.configuration).subSurveyAnswersPatch(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PollsApiSubSurveyAnswersReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PollsApi
     */
    public subSurveyAnswersRead(requestParameters: PollsApiSubSurveyAnswersReadRequest, options?: any) {
        return PollsApiFp(this.configuration).subSurveyAnswersRead(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PollsApiSubSurveyQuestionsReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PollsApi
     */
    public subSurveyQuestionsRead(requestParameters: PollsApiSubSurveyQuestionsReadRequest, options?: any) {
        return PollsApiFp(this.configuration).subSurveyQuestionsRead(requestParameters.id, requestParameters.withoutAnswers, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PollsApiSubSurveyStatePartialUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PollsApi
     */
    public subSurveyStatePartialUpdate(requestParameters: PollsApiSubSurveyStatePartialUpdateRequest, options?: any) {
        return PollsApiFp(this.configuration).subSurveyStatePartialUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ReportsApi - axios parameter creator
 * @export
 */
export const ReportsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id A unique integer value identifying this Статус теста у пользователя.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        danteReportPdf: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling danteReportPdf.');
            }
            const localVarPath = `/reports/dante/{id}/pdf-report/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Статус теста у пользователя.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        danteTitlePagePdf: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling danteTitlePagePdf.');
            }
            const localVarPath = `/reports/dante/{id}/pdf/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Статус теста у пользователя.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        danteTitlePageTemplate: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling danteTitlePageTemplate.');
            }
            const localVarPath = `/reports/dante/{id}/template/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportsPdfRead: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling reportsPdfRead.');
            }
            const localVarPath = `/reports/pdf/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Начисление тестов клиенту.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportsResearchReportRead: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling reportsResearchReportRead.');
            }
            const localVarPath = `/reports/research-report/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Статус теста у пользователя.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportsTemplateRead: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling reportsTemplateRead.');
            }
            const localVarPath = `/reports/template/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReportsApi - functional programming interface
 * @export
 */
export const ReportsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id A unique integer value identifying this Статус теста у пользователя.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async danteReportPdf(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ReportsApiAxiosParamCreator(configuration).danteReportPdf(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Статус теста у пользователя.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async danteTitlePagePdf(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ReportsApiAxiosParamCreator(configuration).danteTitlePagePdf(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Статус теста у пользователя.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async danteTitlePageTemplate(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ReportsApiAxiosParamCreator(configuration).danteTitlePageTemplate(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportsPdfRead(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ReportsApiAxiosParamCreator(configuration).reportsPdfRead(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Начисление тестов клиенту.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportsResearchReportRead(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ReportsApiAxiosParamCreator(configuration).reportsResearchReportRead(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Статус теста у пользователя.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportsTemplateRead(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ReportsApiAxiosParamCreator(configuration).reportsTemplateRead(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ReportsApi - factory interface
 * @export
 */
export const ReportsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {number} id A unique integer value identifying this Статус теста у пользователя.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        danteReportPdf(id: number, options?: any): AxiosPromise<void> {
            return ReportsApiFp(configuration).danteReportPdf(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Статус теста у пользователя.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        danteTitlePagePdf(id: number, options?: any): AxiosPromise<void> {
            return ReportsApiFp(configuration).danteTitlePagePdf(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Статус теста у пользователя.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        danteTitlePageTemplate(id: number, options?: any): AxiosPromise<void> {
            return ReportsApiFp(configuration).danteTitlePageTemplate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportsPdfRead(id: string, options?: any): AxiosPromise<void> {
            return ReportsApiFp(configuration).reportsPdfRead(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Начисление тестов клиенту.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportsResearchReportRead(id: number, options?: any): AxiosPromise<void> {
            return ReportsApiFp(configuration).reportsResearchReportRead(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Статус теста у пользователя.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportsTemplateRead(id: number, options?: any): AxiosPromise<void> {
            return ReportsApiFp(configuration).reportsTemplateRead(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for danteReportPdf operation in ReportsApi.
 * @export
 * @interface ReportsApiDanteReportPdfRequest
 */
export interface ReportsApiDanteReportPdfRequest {
    /**
     * A unique integer value identifying this Статус теста у пользователя.
     * @type {number}
     * @memberof ReportsApiDanteReportPdf
     */
    readonly id: number
}

/**
 * Request parameters for danteTitlePagePdf operation in ReportsApi.
 * @export
 * @interface ReportsApiDanteTitlePagePdfRequest
 */
export interface ReportsApiDanteTitlePagePdfRequest {
    /**
     * A unique integer value identifying this Статус теста у пользователя.
     * @type {number}
     * @memberof ReportsApiDanteTitlePagePdf
     */
    readonly id: number
}

/**
 * Request parameters for danteTitlePageTemplate operation in ReportsApi.
 * @export
 * @interface ReportsApiDanteTitlePageTemplateRequest
 */
export interface ReportsApiDanteTitlePageTemplateRequest {
    /**
     * A unique integer value identifying this Статус теста у пользователя.
     * @type {number}
     * @memberof ReportsApiDanteTitlePageTemplate
     */
    readonly id: number
}

/**
 * Request parameters for reportsPdfRead operation in ReportsApi.
 * @export
 * @interface ReportsApiReportsPdfReadRequest
 */
export interface ReportsApiReportsPdfReadRequest {
    /**
     * 
     * @type {string}
     * @memberof ReportsApiReportsPdfRead
     */
    readonly id: string
}

/**
 * Request parameters for reportsResearchReportRead operation in ReportsApi.
 * @export
 * @interface ReportsApiReportsResearchReportReadRequest
 */
export interface ReportsApiReportsResearchReportReadRequest {
    /**
     * A unique integer value identifying this Начисление тестов клиенту.
     * @type {number}
     * @memberof ReportsApiReportsResearchReportRead
     */
    readonly id: number
}

/**
 * Request parameters for reportsTemplateRead operation in ReportsApi.
 * @export
 * @interface ReportsApiReportsTemplateReadRequest
 */
export interface ReportsApiReportsTemplateReadRequest {
    /**
     * A unique integer value identifying this Статус теста у пользователя.
     * @type {number}
     * @memberof ReportsApiReportsTemplateRead
     */
    readonly id: number
}

/**
 * ReportsApi - object-oriented interface
 * @export
 * @class ReportsApi
 * @extends {BaseAPI}
 */
export class ReportsApi extends BaseAPI {
    /**
     * 
     * @param {ReportsApiDanteReportPdfRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public danteReportPdf(requestParameters: ReportsApiDanteReportPdfRequest, options?: any) {
        return ReportsApiFp(this.configuration).danteReportPdf(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ReportsApiDanteTitlePagePdfRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public danteTitlePagePdf(requestParameters: ReportsApiDanteTitlePagePdfRequest, options?: any) {
        return ReportsApiFp(this.configuration).danteTitlePagePdf(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ReportsApiDanteTitlePageTemplateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public danteTitlePageTemplate(requestParameters: ReportsApiDanteTitlePageTemplateRequest, options?: any) {
        return ReportsApiFp(this.configuration).danteTitlePageTemplate(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ReportsApiReportsPdfReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public reportsPdfRead(requestParameters: ReportsApiReportsPdfReadRequest, options?: any) {
        return ReportsApiFp(this.configuration).reportsPdfRead(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ReportsApiReportsResearchReportReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public reportsResearchReportRead(requestParameters: ReportsApiReportsResearchReportReadRequest, options?: any) {
        return ReportsApiFp(this.configuration).reportsResearchReportRead(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ReportsApiReportsTemplateReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public reportsTemplateRead(requestParameters: ReportsApiReportsTemplateReadRequest, options?: any) {
        return ReportsApiFp(this.configuration).reportsTemplateRead(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * VersionApi - axios parameter creator
 * @export
 */
export const VersionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        versionList: async (page?: number, limit?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/version/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Basic required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VersionApi - functional programming interface
 * @export
 */
export const VersionApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async versionList(page?: number, limit?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await VersionApiAxiosParamCreator(configuration).versionList(page, limit, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * VersionApi - factory interface
 * @export
 */
export const VersionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [limit] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        versionList(page?: number, limit?: number, options?: any): AxiosPromise<void> {
            return VersionApiFp(configuration).versionList(page, limit, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for versionList operation in VersionApi.
 * @export
 * @interface VersionApiVersionListRequest
 */
export interface VersionApiVersionListRequest {
    /**
     * A page number within the paginated result set.
     * @type {number}
     * @memberof VersionApiVersionList
     */
    readonly page?: number

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof VersionApiVersionList
     */
    readonly limit?: number
}

/**
 * VersionApi - object-oriented interface
 * @export
 * @class VersionApi
 * @extends {BaseAPI}
 */
export class VersionApi extends BaseAPI {
    /**
     * 
     * @param {VersionApiVersionListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VersionApi
     */
    public versionList(requestParameters: VersionApiVersionListRequest = {}, options?: any) {
        return VersionApiFp(this.configuration).versionList(requestParameters.page, requestParameters.limit, options).then((request) => request(this.axios, this.basePath));
    }
}


