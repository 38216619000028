import { makeAutoObservable } from 'mobx';

import { AxiosError } from 'axios';
import { CoreApi, User, RequestRegistration, RecaptchaEmail, RecaptchaPasswordToken, ProfileView } from 'services/api';

type NullableError = AxiosError<Record<string, string[] | string>> | null;

type NullableProfile = ProfileView | null;

type AuthStoreProps = {
  api: CoreApi;
};

export default class AuthStore {
  fetching = false;
  isRecoveryConfirmed = false;
  initialized = false;

  error: NullableError = null;
  profile: NullableProfile = null;

  private api: CoreApi;

  constructor({ api }: AuthStoreProps) {
    this.api = api;

    makeAutoObservable(this);
  }

  async loadProfile() {
    const { data } = await this.api.coreProfileView();
    this.setProfile(data);
  }

  async login(credentials: User) {
    this.setError(null);
    this.setFetching(true);

    try {
      const { data } = await this.api.coreAuthLogin({ data: credentials });
      await this.loadProfile();

      return data;
    } finally {
      this.setFetching(false);
    }
  }

  logout() {
    this.setError(null);
    this.setFetching(true);

    this.api
      .coreAuthLogout()
      .then(() => this.setProfile(null))
      .catch((err) => this.setError(err))
      .finally(() => this.setFetching(false));
  }

  async signUp(credentials: User) {
    this.setError(null);
    this.setFetching(true);

    try {
      const { data } = await this.api.coreAuthSignup({ data: credentials });
      await this.loadProfile();

      return data;
    } finally {
      this.setFetching(false);
    }
  }

  setError(value: NullableError): void {
    this.error = value;
  }

  setIsRecoveryConfirmed(value: boolean): void {
    this.isRecoveryConfirmed = value;
  }

  setFetching(value: boolean): void {
    this.fetching = value;
  }

  setProfile(value: NullableProfile): void {
    this.profile = value;
  }

  getProfile(): void {
    this.setError(null);
    // в API ProfileView
    this.api
      .coreProfileView()
      .then((res) => {
        this.setProfile(res.data);
      })
      .catch((err) => this.setError(err))
      .finally(() => this.setInitialized(true));
  }

  setInitialized(value: boolean): void {
    this.initialized = value;
  }

  requestAccess(data: RequestRegistration): Promise<void> {
    this.setError(null);
    this.setFetching(true);

    return this.api
      .coreAuthRequestRegistration({ data })
      .then(() => {
        this.setFetching(false);
      })
      .finally(() => this.setFetching(false));
  }

  recoveryPassword(data: RecaptchaEmail) {
    this.setError(null);
    this.setFetching(true);

    return this.api.coreAuthPasswordResetCreate({ data }).finally(() => this.setFetching(false));
  }

  recoveryConfirmPassword(data: RecaptchaPasswordToken): Promise<void> {
    this.setError(null);
    this.setFetching(true);

    return this.api
      .coreAuthPasswordResetConfirmCreate({ data })
      .then(() => {
        this.setIsRecoveryConfirmed(true);
      })
      .finally(() => this.setFetching(false));
  }
}
