import React, { VFC } from 'react';
import { Checkbox } from 'components';
import styled from 'styled-components';
import DatePicker, { ReactDatePickerProps } from 'react-datepicker';
import { mediaQuery } from 'utils/themeUtils';
import selectSVG from 'assets/images/select.svg';

export const Filter = styled.form`
  background-color: ${(props) => props.theme.colors.bg.extraLightBlue};
  border-radius: 4px;
  margin: 0 0 32px 0;
  padding: 32px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-row-gap: 15px;
  grid-column-gap: 28px;

  ${mediaQuery('md')} {
    grid-template-columns: repeat(2, 1fr);
  }

  ${mediaQuery('sm')} {
    padding: 0;
    grid-template-columns: repeat(1, 1fr);
    & > *:empty {
      display: none;
    }
  }
`;

export const FilterItem = styled.div`
  width: 100%;
  display: flex;
  align-items: center;

  & > * {
    flex-grow: 1;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  button {
    width: 100%;

    &:first-child {
      margin-right: 32px;
    }
  }
`;

export const FilterCheckbox = styled(Checkbox)`
  align-items: center;
  line-height: normal;
  font-weight: 400;
  font-size: 14px;
  color: #484848;
`;

const DatePickerWrapper = styled.div`
  .react-datepicker-wrapper {
    position: relative;
    width: 100%;

    &:after {
      pointer-events: none;
      position: absolute;
      content: '';
      right: 0;
      top: 0;
      left: 0;
      bottom: 0;
      background: url(${selectSVG}) no-repeat 95% 50%;
    }
  }

  input {
    width: 100%;
    border: none;
    outline: none;
    font-family: inherit;
    min-height: 40px;
    border-radius: ${({ theme }) => theme.borderRadius};
    padding: 8px 15px;
    background-color: #ffffff;
    border: 1px solid #cdcdcd;

    &::placeholder {
      font-family: inherit;
      font-weight: 300;
      font-size: 14px;
      color: ${(props) => props.theme.colors.font.secondary};
    }

    &.react-datepicker-ignore-onclickoutside {
      outline: 2px solid ${(props) => props.theme.colors.elements.blue};
      border: none;
    }
  }
`;

export const FilterDatePicker: VFC<ReactDatePickerProps> = (props) => (
  <DatePickerWrapper>
    <DatePicker {...props} />
  </DatePickerWrapper>
);
