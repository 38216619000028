import styled from 'styled-components';
import { getColor } from '../../../utils/themeUtils';

export const EditableRow = styled.div`
  display: flex;
  column-gap: 30px;
`;

export const EditableButton = styled.button<{ color?: 'primary' | 'danger' }>`
  outline: none;
  border: none;
  background: transparent;
  box-shadow: none;
  cursor: pointer;
  padding: 0;
  color: ${(props) => {
    switch (props.color) {
      case 'primary':
        return getColor(({ elements }) => elements.darkBlue);
      case 'danger':
        return getColor(({ elements }) => elements.warning);
      default:
        return getColor(({ font }) => font.secondary);
    }
  }};
`;
