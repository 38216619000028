import React from 'react';
import { ProfileView } from '../services/api';
import { ProfileRoleEnum } from '../services';

import { routes as customerRoutes } from './Customer';
import { routes as userRoutes } from './User';
import { routes as adminRoutes } from './Admin';

type Roles = ProfileRoleEnum;

export type Route = {
  path: string;
  exact?: boolean;
  component?: React.FC;
  icon?: React.ReactNode;
  name?: string;
  nonVirtual?: boolean;
  permissions?: Array<keyof ProfileView>;
  hideOnMobile?: boolean;
  showOnMobileOnly?: boolean;
};

export const routes: Record<Roles, Route[]> = {
  [ProfileRoleEnum.Client]: customerRoutes,
  [ProfileRoleEnum.User]: userRoutes,
  [ProfileRoleEnum.Admin]: adminRoutes,
};
