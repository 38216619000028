import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Modal, ModalContact, ModalNewPassword, ModalNotification, ModalPhoto } from '../index';
import { Button } from '../../components';
import { BtnRow } from './styled';

interface ModalPhotoProps {
  showModal: boolean;
  closeModal: () => void;
}

type ShowModalType = 'avatar' | 'password' | 'feedback' | 'notifications';

const ModalEditUser: React.FC<ModalPhotoProps> = observer((props) => {
  const { showModal, closeModal } = props;

  const [showChildModal, setShowChildModal] = useState<ShowModalType | null>(null);

  const onCloseChildModal = () => setShowChildModal(null);

  return (
    <>
      <Modal showModal={showModal} handleCloseModal={closeModal}>
        <BtnRow>
          <Button onClick={() => setShowChildModal('avatar')} view="secondary">
            Изменить изображение
          </Button>
          <Button onClick={() => setShowChildModal('password')} view="secondary">
            Изменить пароль
          </Button>
          <Button onClick={() => setShowChildModal('feedback')} view="secondary">
            Связаться с администратором
          </Button>
          <Button onClick={() => setShowChildModal('notifications')} view="secondary">
            Настроить оповещения
          </Button>
        </BtnRow>
      </Modal>

      {showChildModal === 'avatar' && <ModalPhoto closeModal={onCloseChildModal} />}
      <ModalNewPassword
        showModal={showChildModal === 'password'}
        closeModal={onCloseChildModal}
        title="Придумайте новый пароль"
      />
      <ModalNotification
        showModal={showChildModal === 'notifications'}
        closeModal={onCloseChildModal}
        title="Настройка оповещений"
      />
      <ModalContact
        showModal={showChildModal === 'feedback'}
        closeModal={onCloseChildModal}
        title="Связаться с администратором"
      />
    </>
  );
});

export default ModalEditUser;
