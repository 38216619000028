import React, { FC } from 'react';

interface EyeProps {
  onClick?: () => void;
}

const Eye: FC<EyeProps> = (props) => (
  <svg {...props} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M23 12C23 12 18.0751 18 12 18C5.92487 18 1 12 1 12C1 12 5.92487 6 12 6C18.0751 6 23 12 23 12Z"
      stroke="currentColor"
    />
    <circle cx="12" cy="12" r="3.5" stroke="currentColor" />
  </svg>
);

export default Eye;
