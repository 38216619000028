import React, { FC, useCallback } from 'react';
import styled from 'styled-components';
import { IconRightChevron, LeftIcon } from './Icons';

interface TestControlProps {
  totalPages: number;
  currentPage: number;
  separator: string;
  changeCurrentPage: (pageNumber: number) => void;
}

const Pagination: FC<TestControlProps> = ({ totalPages, currentPage, separator, changeCurrentPage }) => {
  const showAfterFirst = currentPage < 5;
  const showBeforeLast = currentPage > totalPages - 4;

  const createArrayButtons = (quantity: number, startNumber: number): number[] => {
    const newArr = [...Array.from({ length: quantity })];
    return newArr.map((n, i) => startNumber + i);
  };

  const buttonsToRender =
    totalPages > 8
      ? [
          1,
          showAfterFirst && createArrayButtons(4, 2),
          !showAfterFirst && separator,
          !(showAfterFirst || showBeforeLast) && createArrayButtons(3, currentPage - 1),
          !showBeforeLast && separator,
          showBeforeLast && createArrayButtons(4, totalPages - 4),
          totalPages,
        ]
          .flat()
          .filter(Boolean)
      : createArrayButtons(totalPages, 1);

  const onClickHandler = useCallback(
    (event: React.MouseEvent) => {
      const element = event.target as HTMLElement;
      const pageNumber = Number(element.dataset.page);
      if (element.tagName === 'BUTTON') changeCurrentPage(pageNumber);
    },
    [changeCurrentPage],
  );

  return (
    <Wrapper onClick={onClickHandler}>
      <GoBack data-page={currentPage - 1} disabled={currentPage === 1} type="button">
        <LeftIcon />
        Назад
      </GoBack>

      <NumbersPage>
        {buttonsToRender.map((number, index) =>
          number === separator ? (
            <Separator key={index}>{separator}</Separator>
          ) : (
            <button key={index} type="button" data-page={number} className={number === currentPage ? 'active' : ''}>
              {number}
            </button>
          ),
        )}
      </NumbersPage>

      <GoNext data-page={currentPage + 1} disabled={currentPage === totalPages} type="button">
        Далее
        <IconRightChevron />
      </GoNext>
    </Wrapper>
  );
};

export default Pagination;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const GoBack = styled.button`
  border: none;
  outline: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  background: transparent;
  font-weight: 400;
  font-size: 16px;
  color: ${({ theme }) => theme.colors.font.blue};
  transition: opacity 0.3s linear;
  padding: 0;
  margin-right: 16px;

  &:hover {
    opacity: 0.6;
  }

  &:disabled {
    opacity: 0.3;
  }

  svg {
    margin-right: 10px;
  }
`;

const NumbersPage = styled.div`
  display: flex;
  align-items: center;

  button {
    border: none;
    background-color: transparent;
    outline: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: inherit;
    font-size: 14px;
    color: ${({ theme }) => theme.colors.font.main};
    transition: color 0.3s linear;

    &.active {
      color: ${({ theme }) => theme.colors.tests.orange};
    }

    &:hover {
      color: ${({ theme }) => theme.colors.tests.orange};
    }
  }
`;

const Separator = styled.div`
  color: black;
`;

const GoNext = styled(GoBack)`
  margin-right: 0;
  margin-left: 16px;

  svg {
    margin-right: 0;
    margin-left: 10px;
  }
`;
