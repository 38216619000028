import React from 'react';
import styled from 'styled-components';

interface FormProps {
  className?: string;
  onSubmit: () => void;
  children?: React.ReactNode;
}

const Form = styled.form<FormProps>`
  display: flex;
  flex-direction: column;
`;

export const FormItem = styled.div`
  &:not(:last-child) {
    margin-bottom: 32px;
  }
`;

export const FormRow = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export default Form;
