import { makeAutoObservable } from 'mobx';

interface WindowWithCaptcha extends Window {
  grecaptcha?: {
    execute(
      siteKey: string,
      action: {
        action: string;
      },
    ): PromiseLike<string>;

    ready(callback: () => PromiseLike<string> | undefined): PromiseLike<string> | undefined;
  };
}

export default class ReCaptchaLoader {
  private readonly reCaptchaKey: string;
  private window: WindowWithCaptcha = window;

  constructor() {
    this.reCaptchaKey = process.env.REACT_APP_RE_CAPTCHA_KEY_PUBLIC || '';
    makeAutoObservable(this);
  }

  loadReCaptcha() {
    const gcaptchaScript = document.createElement('script');
    if (this.reCaptchaKey) {
      gcaptchaScript.src = `https://www.google.com/recaptcha/api.js?render=${this.reCaptchaKey}`;
      document.body.appendChild(gcaptchaScript);
    }
  }

  executeReCaptcha(action: string) {
    return this.window.grecaptcha?.execute(this.reCaptchaKey, { action });
  }
}
