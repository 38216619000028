import React, { lazy } from 'react';
import { IconColleague, IconExit, IconHome, IconTest, IconUsers } from '../components/Icons';
import { LOGOUT_PATH } from './const';
import LogOut from '../features/Auth/LogOut';
import { Route } from './index';

const CustomerHome = lazy(() => import('../pages/Customer/Home'));
const CustomerTests = lazy(() => import('../pages/Customer/Tests'));
const CustomerUsers = lazy(() => import('../pages/Customer/Users'));
const CustomerStaff = lazy(() => import('../pages/Customer/Staff'));
const CustomerUser = lazy(() => import('../pages/Customer/User'));
const AccesCheckerProxy = lazy(() => import('../features/AccessRightsChecker/ProxyComponent'));

export const routes: Route[] = [
  {
    path: '/',
    exact: true,
    component: () => <CustomerHome />,
    icon: <IconHome />,
    name: 'Главная',
  },
  {
    path: '/tests',
    component: () => <CustomerTests />,
    icon: <IconTest />,
    name: 'Методики',
  },
  {
    path: '/users/:id/',
    component: () => <CustomerUser />,
  },
  {
    path: '/users/',
    component: () => <CustomerUsers />,
    icon: <IconUsers />,
    name: 'Пользователи',
  },
  {
    path: '/staff',
    component: () => <CustomerStaff />,
    icon: <IconColleague />,
    name: 'Сотрудники',
  },
  {
    path: LOGOUT_PATH,
    component: () => <LogOut />,
    icon: <IconExit />,
    hideOnMobile: true,
    name: 'Выйти',
  },
  // {
  //   path: LOGOUT_PATH, // TODO: make logic
  //   component: () => null,
  //   icon: <ProfileIcon />,
  //   showOnMobileOnly: true,
  //   name: 'MOBILE_ONLY',
  // },
  {
    path: '/t/:token',
    component: () => <AccesCheckerProxy />,
  },
];
