import React, { FC } from 'react';

interface SemicircleProps {
  width?: number;
  height?: number;
}

const Semicircle: FC<SemicircleProps> = ({ width = 22, height = 4 }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 22 4" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 2C5.10457 2 6 2.89543 6 4H7H8H9C9 2.89543 9.89543 2 11 2C12.1046 2 13 2.89543 13 4H14H15H16C16 2.89543 16.8954 2 18 2C19.1046 2 20 2.89543 20 4H22C22 1.79086 20.2091 0 18 0C16.494 0 15.1824 0.832288 14.5 2.062C13.8176 0.832288 12.506 0 11 0C9.49399 0 8.18236 0.832288 7.5 2.062C6.81764 0.832288 5.50601 0 4 0C1.79086 0 0 1.79086 0 4H2C2 2.89543 2.89543 2 4 2Z"
      />
    </svg>
  );
};

export default Semicircle;
