import React, { useMemo } from 'react';
import styled, { DefaultTheme } from 'styled-components';
import { Body } from '../components/Typography';
import { mediaQuery } from '../utils/themeUtils';
import InfinityIcon from './Infinity';

interface CircleProgressProps {
  color: keyof DefaultTheme['colors']['tests'];
  dashArray: number;
  dashOffset: number;
}

interface CircleChartProps {
  className?: string;
  size?: number;
  stroke?: number;
  value: number;
  max: number;
  label: string;
  color: keyof DefaultTheme['colors']['tests'];
  unlimited?: boolean;
}

const CircleBackground = styled.circle`
  fill: none;
  stroke: ${(props) => props.theme.colors.elements.gray};
`;

const CircleProgress = styled.circle<CircleProgressProps>`
  fill: none;
  stroke: ${(props) => props.theme.colors.tests[props.color]};
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-dasharray: ${(props) => props.dashArray};
  stroke-dashoffset: ${(props) => props.dashOffset};
`;

const CircleInner = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

export const CircleChartWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

const CircleLabel = styled.div`
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.font.secondary};
  ${mediaQuery('lg')} {
    font-size: 14px;
  }
  ${mediaQuery('xs')} {
    font-size: 12px;
  }
`;

const Value = styled.span`
  font-size: 48px;
  line-height: 50px;
  color: ${({ theme }) => theme.colors.font.blue};
  ${mediaQuery('sm')} {
    line-height: 48px;
  }
  ${mediaQuery('sm')} {
    font-size: 28px;
    line-height: 28px;
  }
`;

const CircleChart: React.FC<CircleChartProps> = ({
  className,
  size = 190,
  stroke = 12,
  value,
  max,
  label,
  color,
  unlimited,
}) => {
  const percentage = useMemo(() => (100 * Math.min(value, max)) / (max === 0 || unlimited ? Infinity : max), [
    value,
    max,
  ]);
  const radius = useMemo(() => (size - stroke) / 2, [size, stroke]);
  const viewBox = useMemo(() => `0 0 ${size} ${size}`, [size]);
  const dashArray = useMemo(() => radius * Math.PI * 2, [radius]);
  const dashOffset = useMemo(() => dashArray - (dashArray * percentage) / 100, [dashArray, percentage]);

  return (
    <CircleChartWrapper>
      <svg className={className} viewBox={viewBox}>
        <CircleBackground cx={size / 2} cy={size / 2} r={radius} strokeWidth={`${stroke}px`} />
        <CircleProgress
          cx={size / 2}
          cy={size / 2}
          r={radius}
          strokeWidth={`${stroke}px`}
          transform={`rotate(-90 ${size / 2} ${size / 2})`}
          color={color}
          dashArray={dashArray}
          dashOffset={dashOffset}
        />
      </svg>
      <CircleInner>
        <Body color="secondary">
          <Value>{value}</Value>/{unlimited ? <InfinityIcon /> : max}
        </Body>
        <CircleLabel>{label}</CircleLabel>
      </CircleInner>
    </CircleChartWrapper>
  );
};

export default CircleChart;
