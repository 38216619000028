import React, { useMemo } from 'react';
import Dropdown from 'rc-dropdown';
import styled from 'styled-components';
import 'rc-dropdown/assets/index.css';

interface DropdownOptionProps {
  label: string;
  onClick?: (event: React.MouseEvent) => void;
}

interface DropdownProps {
  className?: string;
  children: React.ReactElement;
  options: DropdownOptionProps[];
}

const DropdownOption = styled.div`
  cursor: pointer;
  padding: 12px 32px;
  font-size: 16px;
  white-space: nowrap;
  transition: 0.2s ease-out;

  &:hover {
    background-color: ${(props) => props.theme.colors.bg.extraLightBlue};
  }
`;

const DropdownOverlay = styled.div`
  background-color: ${(props) => props.theme.colors.bg.white};
  border-radius: 24px;
  margin-top: 24px;
  padding: 20px 0;
  box-shadow: 0 25px 40px rgba(0, 0, 0, 0.15);
  position: absolute;
  right: 0;
  top: 0;
`;

const DropdownButton = styled.button`
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
`;

const CustomDropdown: React.FC<DropdownProps> = ({ className, children, options }) => {
  const overlay = useMemo(
    () => (
      <DropdownOverlay>
        {options.map((option, index) => (
          <DropdownOption key={index} onClick={option.onClick}>
            {option.label}
          </DropdownOption>
        ))}
      </DropdownOverlay>
    ),
    [options],
  );

  return (
    <Dropdown overlayClassName={className} trigger={['click']} overlay={overlay}>
      <DropdownButton>{children}</DropdownButton>
    </Dropdown>
  );
};

export default CustomDropdown;
