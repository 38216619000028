import React from 'react';
import { IconRightChevron, LeftIcon } from '../../Icons';

type Props = {
  className?: string;
  onClick: () => void;
  isOpen: boolean;
};

export const ToggleButton: React.FC<Props> = ({ className, onClick, isOpen }) => (
  <button className={className} type="button" onClick={onClick}>
    {isOpen ? <LeftIcon /> : <IconRightChevron color="white" />}
    {isOpen && 'Свернуть меню'}
  </button>
);
