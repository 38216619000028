import React, { FC } from 'react';
import styled from 'styled-components';
import { Modal } from './index';
import { BodyBig } from '../components/Typography';
import { Button } from '../components';
import { ModalTwoButtonsProps } from '../types/common';
import { mediaQuery } from '../utils/themeUtils';

const ModalTwoButtons: FC<ModalTwoButtonsProps> = ({
  showModal,
  handleCloseModal,
  title,
  handleMainBtnClick,
  handleSecondaryBtnClick,
  description,
  mainBtnText,
  secondaryBtnText,
  children,
  floatWidth,
}) => {
  return (
    <Modal showModal={showModal} handleCloseModal={handleCloseModal} title={title} floatWidth={floatWidth}>
      {description?.map((paragraph, id) => (
        <ModalDescription key={id} paragraph>
          {paragraph}
        </ModalDescription>
      ))}
      {children}
      <ButtonsWrapper floatWidth={floatWidth}>
        <ModalButton
          floatWidth={floatWidth}
          view="secondary"
          onClick={(event: React.MouseEvent<HTMLElement>) => {
            handleSecondaryBtnClick(event);
          }}
        >
          {secondaryBtnText}
        </ModalButton>
        <ModalButton
          onClick={(event: React.MouseEvent<HTMLElement>) => {
            handleMainBtnClick(event);
          }}
        >
          {mainBtnText}
        </ModalButton>
      </ButtonsWrapper>
    </Modal>
  );
};

const ModalDescription = styled(BodyBig)`
  margin-bottom: 48px;
`;

interface ButtonsProps {
  floatWidth?: boolean;
}

const ModalButton = styled(Button)<ButtonsProps>`
  max-width: 206px;
  margin-right: ${({ floatWidth }) => (floatWidth ? '32px' : '0')};
  ${mediaQuery('sm')} {
    margin-right: 0;
    width: 100%;
    max-width: none;
  }
`;

const ButtonsWrapper = styled.div<ButtonsProps>`
  display: flex;
  justify-content: ${({ floatWidth }) => (floatWidth ? 'flex-start' : 'space-between')};
  ${mediaQuery('sm')} {
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 13px;
  }
`;

export default ModalTwoButtons;
