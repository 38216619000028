import React, { forwardRef } from 'react';
import styled, { css } from 'styled-components';

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  className?: string;
  $error?: boolean;
  icon?: React.ReactNode;
  helper?: string;
  variant?: 'default' | 'small';
  label?: string;
  disabled?: boolean;
}

export const Label = styled.span<{ className?: string; children: string; $error?: boolean }>`
  padding-left: 16px;
  color: ${(props) => (props.$error ? props.theme.colors.elements.warning : props.theme.colors.font.secondary)};
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 16px;
  margin: 2px 0;
  display: block;
`;

const InputWrapper = forwardRef<HTMLInputElement, InputProps>(({ className, icon, ...inputProps }, ref) => (
  <div className={className}>
    <input {...inputProps} ref={ref} />
    {icon && <EyeSpan>{icon}</EyeSpan>}
  </div>
));

const StyledInputWrapper = styled(InputWrapper)`
  display: flex;
  background-color: ${(props) => props.theme.colors.bg.white};
  border: 1px solid
    ${(props) => (props.$error ? props.theme.colors.elements.warning : props.theme.colors.elements.border)};
  border-radius: ${(props) => props.theme.borderRadius};

  svg {
    cursor: pointer;
  }

  input {
    box-sizing: border-box;
    border: none;
    border-radius: ${(props) => props.theme.borderRadius};
    appearance: none;
    outline: none;

    width: 100%;
    padding: 15px;
    color: ${(props) => props.theme.colors.font.main};
    font-style: normal;
    font-weight: normal;
    font-family: inherit;
    font-size: 16px;
    line-height: 24px;

    &::placeholder {
      color: ${(props) => props.theme.colors.font.secondary};
    }

    &[type='number']::-webkit-outer-spin-button,
    &[type='number']::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    ${(props) =>
      props.variant === 'small' &&
      css`
      padding: 8px 15px;

      &::placeholder {
        font-size: 14px;
        font-weight: 300;
    `}
  }

  span {
    display: flex;
    width: 64px;

    svg {
      margin: auto;
    }
  }

  transition: 0.2s ease-out;

  &:hover,
  &:focus {
    border-color: ${(props) => props.theme.colors.elements.blueDefault};
  }
`;

const EyeSpan = styled.span`
  display: flex !important; // fixed conflict from menu selector
`;

const Input = forwardRef<HTMLInputElement, InputProps>(({ label, helper, className, ...props }, ref) => (
  <label htmlFor={props.name} className={className}>
    {label && <Label $error={props.$error}>{label}</Label>}
    <StyledInputWrapper {...props} ref={ref} />
    {helper && <Label $error={props.$error}>{helper}</Label>}
  </label>
));

export default Input;
