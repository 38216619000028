import React, { VFC } from 'react';
import Carousel from 'antd/lib/carousel';
import { nanoid } from 'nanoid';
import { useTheme } from 'styled-components';
import { useMediaQuery } from 'hooks/useMediaQuery';
import { SideBarMenuBannerTemplate } from './Template';
import { GenericBannersProps } from '../types';
import { useBannerAutoplaySpeed } from '../hooks/useBannerAutoplaySpeed';

export const SideBarMenuBanner: VFC<GenericBannersProps> = (props) => {
  const { banners } = props;
  const theme = useTheme();
  const match = useMediaQuery(theme.mediaQuery.sm);
  const [autoplaySpeed, setAutoplaySpeed] = useBannerAutoplaySpeed(banners);

  return !match ? (
    <div style={{ borderRadius: 8, margin: '32px 35px' }}>
      <Carousel
        autoplay
        autoplaySpeed={autoplaySpeed}
        dotPosition="right"
        verticalSwiping
        adaptiveHeight
        beforeChange={setAutoplaySpeed}
      >
        {banners.map((banner) => (
          <SideBarMenuBannerTemplate key={nanoid()} banner={banner} />
        ))}
      </Carousel>
    </div>
  ) : null;
};
