import { observer } from 'mobx-react-lite';
import React, { Context, createContext, FC, useContext } from 'react';

import { BannersService } from './types';
import { useBannersService } from './useBannersService';

const BannersServiceContext = createContext<BannersService | null>(null);

export const BannersServiceProvider: FC = observer(({ children }) => {
  const bannersService = useBannersService();

  return <BannersServiceContext.Provider value={bannersService}>{children}</BannersServiceContext.Provider>;
});

export const useBannersContext = () => useContext(BannersServiceContext as Context<BannersService>);
