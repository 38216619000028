import { ProfileView } from '../services';

export const getCookie = (name: string): string | null => {
  if (document.cookie.length === 0) return null;

  const cookie = document.cookie.split(';').find((el) => {
    return el.trim().substring(0, name.length + 1) === `${name}=`;
  });

  return cookie ? decodeURIComponent(cookie.trim().substring(name.length + 1)) : null;
};

// https://stackoverflow.com/a/201378 -- full RFC email validation
// eslint-disable-next-line no-control-regex,max-len
export const emailPattern = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/i;

export const emailValidator = (inputValue: string): boolean => {
  return emailPattern.test(inputValue);
};

export const getEmailsFromString = (inputValue: string): string[] => {
  const globalEmailRegexp = new RegExp(`${emailPattern.source}`, 'ig');
  return Array.from(inputValue.matchAll(globalEmailRegexp), (match) => match[0]);
};

export const profileToQuestionnaire = (profile: ProfileView | null) =>
  profile
    ? {
        name: profile.fullName,
        gender: profile.sex,
        status: profile.maritalStatus ? profile.maritalStatus !== 'SINGLE' : profile.maritalStatus,
        email: profile.email,
        education: profile.education?.slug ?? '',
        tel: profile.phone,
      }
    : {};
