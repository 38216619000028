import React from 'react';

const Pdf = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.07142 3H14.1748L19 8.04677V19.875C19 20.4966 18.52 21 17.9286 21H5.07142C4.47997 21 4 20.4966 4 19.875V4.12499C4 3.50345 4.48003 3 5.07142 3Z"
      fill="#FF88AD"
    />
    <path
      d="M15.6789 10.7688C15.8945 10.7688 16 10.5737 16 10.3847C16 10.189 15.8899 10 15.6789 10H14.4512C14.2112 10 14.0773 10.2064 14.0773 10.4341V13.5659C14.0773 13.8451 14.2305 14 14.4377 14C14.6436 14 14.7974 13.8451 14.7974 13.5659V12.7063H15.5399C15.7703 12.7063 15.8855 12.5106 15.8855 12.3163C15.8855 12.1259 15.7703 11.9369 15.5399 11.9369H14.7974V10.7688H15.6789ZM11.5293 10H10.631C10.3871 10 10.214 10.1737 10.214 10.4314V13.5686C10.214 13.8885 10.463 13.9887 10.6412 13.9887H11.5839C12.6996 13.9887 13.4364 13.2266 13.4364 12.0504C13.4358 10.8068 12.7422 10 11.5293 10ZM11.5724 13.2153H11.0248V10.7734H11.5183C12.2654 10.7734 12.5903 11.2938 12.5903 12.0117C12.5903 12.6836 12.2711 13.2153 11.5724 13.2153ZM8.28177 10H7.39188C7.14028 10 7 10.1723 7 10.4341V13.5659C7 13.8451 7.16085 14 7.37703 14C7.59321 14 7.75407 13.8451 7.75407 13.5659V12.6515H8.31193C9.00041 12.6515 9.56859 12.1452 9.56859 11.3311C9.56866 10.5343 9.02045 10 8.28177 10ZM8.26699 11.9169H7.75413V10.7354H8.26699C8.58357 10.7354 8.78497 10.9918 8.78497 11.3264C8.7843 11.6604 8.58357 11.9169 8.26699 11.9169Z"
      fill="white"
    />
  </svg>
);

export default Pdf;
