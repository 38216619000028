import styled from 'styled-components';
import { getColor, mediaQuery } from '../../utils/themeUtils';

export const Container = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-areas: 'grid-nav grid-content';
  height: 100%;
  ${mediaQuery('sm')} {
    display: block;
  }
`;

export const Navbar = styled.div`
  ${mediaQuery('sm')} {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2;
  }
`;

export const Content = styled.div`
  background: ${getColor(({ bg }) => bg.extraLightBlue)};
  padding-bottom: 32px;
  min-width: 0;
  height: 100%;
  flex-grow: 1;
  ${mediaQuery('sm')} {
    padding-bottom: 53px;
  }
`;
