import React, { FC } from 'react';
import { SurveyInfoAnswerStyleEnum } from '../../services';
import { answerControlVariants, QuestionItemControlProps } from './types';
import { ItemControl } from './styles';

const QuestionItemControl: FC<QuestionItemControlProps<SurveyInfoAnswerStyleEnum>> = ({
  decision,
  onAnswer,
  answerStyle,
  choices,
  disableAnswerControl,
  itemDirection,
}) => {
  const AnswerControl = answerControlVariants[answerStyle];

  return (
    <ItemControl direction={itemDirection}>
      <AnswerControl disable={disableAnswerControl} decision={decision} choices={choices} onAnswer={onAnswer} />
    </ItemControl>
  );
};

export default QuestionItemControl;
