import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

const StyledLink = styled(NavLink)`
  text-decoration: none;
  font-size: 14px;
  font-family: inherit;
  font-weight: 300;
  color: ${({ theme }) => theme.colors.font.header};
  transition: opacity 0.3s linear;

  &:hover {
    opacity: 0.7;
  }
`;
interface LinkToUserProps {
  id: number;
  name: string;
}

const LinkToUser: React.FC<LinkToUserProps> = ({ id, name }) => {
  return <StyledLink to={`/users/${id}/`}>{name}</StyledLink>;
};

export default LinkToUser;
