import React, { useCallback, useMemo, useState } from 'react';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { Banner } from 'features/Banners';
import { useTheme } from 'styled-components';
import { useMediaQuery } from 'hooks/useMediaQuery';
import { Route } from '../../routes';
import { useAuthStore } from '../../features/Auth';
import { ProfileView } from '../../services';
import {
  ExtraNavBtns,
  Logo,
  MenuInner,
  MenuWrapper,
  StyledAnchorLink,
  StyledNavLink,
  StyledToggleButton,
} from './styled';
import { CLASS_SM_HIDDEN, CLASS_SM_ONLY } from '../../constants/classNames';
import { useProjectSettingsStore } from '../../features/ProjectSettings';
import { ButtonPurchase } from './PurchaseButton/ButtonPurchase';
import { ProfileButton } from './ProfileButton/ProfileButton';

type LinkProps = Pick<
  Route,
  'name' | 'path' | 'exact' | 'icon' | 'nonVirtual' | 'permissions' | 'hideOnMobile' | 'showOnMobileOnly'
>;

interface MenuProps {
  className?: string;
  links: LinkProps[];
  defaultClose?: boolean;
}

const Menu: React.FC<MenuProps> = observer(({ className, links, defaultClose = false }) => {
  const [isOpen, setOpen] = useState(!defaultClose);
  const store = useAuthStore();
  const settingsStore = useProjectSettingsStore();
  const profile = store?.profile;
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.mediaQuery.sm);

  const salesLink = useMemo(() => settingsStore.projectSettings.salesLink, [settingsStore.projectSettings.salesLink]);

  const checkPermissions = useCallback(
    (permissions: Array<keyof ProfileView> = []): boolean => {
      if (!profile) return true;

      return permissions.every((permission) => Boolean(profile[permission]));
    },
    [profile],
  );

  return (
    <MenuWrapper className={className} isOpen={isOpen}>
      <MenuInner isOpen={isOpen}>
        <Logo isOpen={isOpen} />
        <nav>
          <ul>
            {links.map((link, index) => {
              const LinkComponent = link.nonVirtual ? StyledAnchorLink : StyledNavLink;
              const liClassName = clsx(
                link.hideOnMobile ? CLASS_SM_HIDDEN : '',
                link.showOnMobileOnly ? CLASS_SM_ONLY : '',
              );

              return (
                link.name &&
                checkPermissions(link.permissions) && (
                  <li key={index} className={liClassName}>
                    <LinkComponent href={link.path} to={link.path} exact={link.exact} activeClassName="selected">
                      {link.icon}
                      <span>{isOpen && link.name}</span>
                    </LinkComponent>
                  </li>
                )
              );
            })}
            <li className={CLASS_SM_HIDDEN}>
              <StyledToggleButton onClick={() => setOpen(!isOpen)} isOpen={isOpen} />
            </li>
            {isSmallScreen && (
              <li>
                <ProfileButton />
              </li>
            )}
          </ul>
        </nav>
        <ExtraNavBtns opened={isOpen}>{salesLink && <ButtonPurchase to={salesLink} isOpen={isOpen} />}</ExtraNavBtns>
        {isOpen && <Banner type="sidebar" />}
      </MenuInner>
    </MenuWrapper>
  );
});

export default Menu;
