import React, { VFC } from 'react';
import { observer } from 'mobx-react-lite';
import { Menu } from '../components';
import { routes } from '../routes';
import { useAuthStore } from './Auth';

interface NavigationProps {
  defaultClose?: boolean;
}

const Navigation: VFC<NavigationProps> = observer((props) => {
  const { defaultClose } = props;
  const store = useAuthStore();
  const role = store.profile?.role;

  return role ? <Menu links={routes[role]} defaultClose={defaultClose} /> : null;
});

export default Navigation;
