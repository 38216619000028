import React, { FC, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { Actions, Filter, FilterCheckbox, FilterItem } from 'components/TableFilter';
import { Button, Input, Select } from 'components';
import { ClientPollAnnotate } from 'services/api';
import { QueriesType } from '../../TestsTable';
import { optionalNumber } from '../../utils/optionalNumber';

interface FiltersProps {
  pollsData: ClientPollAnnotate[];
  setQueries: (params: QueriesType) => void;
  queries: QueriesType;
}

const FiltersTests: FC<FiltersProps> = ({ pollsData, setQueries, queries }) => {
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { register, handleSubmit, control, reset } = useForm<QueriesType>({
    defaultValues: {
      polls: queries?.polls ?? null,
      registrations: queries?.registrations,
      registrationsRemaining: queries?.registrationsRemaining,
      inProgress: queries?.inProgress,
      allReportsNotViewed: queries?.allReportsNotViewed ?? false,
    },
  });

  const options = useMemo(
    () =>
      pollsData.map((item) => {
        return {
          value: item.id,
          label: item.survey,
        };
      }),
    [pollsData],
  );

  const onClearForm = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();

    reset({ polls: null });
    setQueries({});
  };

  return (
    <Filter onSubmit={handleSubmit(setQueries)}>
      <FilterItem>
        <Controller
          control={control}
          name="polls"
          as={Select}
          className="small"
          placeholder="Выберите методику"
          options={options}
          defaultValue={null}
        />
      </FilterItem>

      <FilterItem>
        <Input
          placeholder="Кол-во регистраций"
          variant="small"
          type="number"
          name="registrations"
          min={0}
          ref={register({ setValueAs: optionalNumber, min: 0 })}
        />
      </FilterItem>
      <FilterItem>
        <Input
          placeholder="Кол-во оставшихся"
          variant="small"
          type="number"
          name="registrationsRemaining"
          min={0}
          ref={register({ setValueAs: optionalNumber, min: 0 })}
        />
      </FilterItem>

      <FilterItem>
        <Input
          placeholder="Кол-во в процессе"
          variant="small"
          type="number"
          name="inProgress"
          min={0}
          ref={register({ setValueAs: optionalNumber, min: 0 })}
        />
      </FilterItem>

      <FilterItem>
        <FilterCheckbox name="allReportsNotViewed" ref={register()}>
          Только новые отчёты
        </FilterCheckbox>
      </FilterItem>

      <FilterItem>
        <Actions>
          <Button size="small" type="submit">
            Найти
          </Button>

          <Button onClick={onClearForm} view="secondary" size="small">
            Очистить
          </Button>
        </Actions>
      </FilterItem>
    </Filter>
  );
};

export default FiltersTests;
