import React, { createContext, useContext, useEffect } from 'react';
import { ProfileViewRoleEnum } from 'services/api';
import { useAuthStore } from 'features/Auth';

import { observer } from 'mobx-react-lite';
import ProjectSettingsStore from './store';

const store = new ProjectSettingsStore();

const ProjectSettingsContext = createContext<ProjectSettingsStore>(store);

export const ProjectSettingsProvider: React.FC = observer(({ children }) => {
  const authStore = useAuthStore();

  useEffect(() => {
    if (!authStore.profile?.role || authStore.profile.role === ProfileViewRoleEnum.User) return;

    store.getProjectSettings();
  }, [authStore.profile?.role]);

  return <ProjectSettingsContext.Provider value={store}>{children}</ProjectSettingsContext.Provider>;
});

export const useProjectSettingsStore = (): ProjectSettingsStore => useContext(ProjectSettingsContext);
