import styled from 'styled-components';
import { mediaScreen } from '../../utils/themeUtils';

export const PhotoRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 32px;

  ${mediaScreen('sm')} {
    flex-direction: column;
  }
`;

export const Avatar = styled.div`
  width: 168px;
  height: 168px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.bg.extraLightBlue};
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  margin-right: 22px;

  img {
    width: 168px;
    height: 168px;
    object-fit: cover;
  }

  svg {
    width: 64px;
    height: 64px;
  }
`;

export const PhotoControl = styled.div`
  color: ${({ theme }) => theme.colors.font.blue};

  & > :first-child {
    margin-bottom: 16px;
  }
`;

export const InputFileWrap = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: opacity 0.3s linear;

  &:hover {
    opacity: 0.5;
  }

  input {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }

  ${mediaScreen('sm')} {
    margin: 24px auto;
  }
`;

export const DeletePhotoBtn = styled.button`
  border: none;
  background: transparent;
  outline: none;
  color: inherit;
  font-family: inherit;
  padding: 0;
  cursor: pointer;

  display: flex;
  align-items: center;
  font-size: 16px;

  transition: opacity 0.3s linear;

  &:disabled {
    cursor: not-allowed;
    color: ${({ theme }) => theme.colors.font.disable};
  }

  &:hover {
    opacity: 0.5;
  }

  ${mediaScreen('sm')} {
    margin: 24px auto;
  }
`;

export const BtnRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  button {
    width: 100%;

    &:first-child {
      margin-right: 32px;
      
      ${mediaScreen('sm')} {
        margin-right: 0;
        margin-top: 16px; /* because of column-reverse! */
      }
    }
}
  }

  ${mediaScreen('sm')} {
    flex-direction: column-reverse;
  }
`;
