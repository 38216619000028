import { DefaultTheme } from 'styled-components';

const desktop = 1366;
const tablet = 992;
const mobile = 576;

export const defaultTheme: DefaultTheme = {
  borderRadius: '8px',

  // Rudiment, use a mediaQuery
  screenWidth: {
    lg: `@media only screen and (min-width: ${desktop}px)`,
    md: `@media only screen and (max-width: ${desktop - 1}px)`,
    sm: `@media only screen and (max-width: ${tablet - 1}px)`,
  },

  mediaQuery: {
    lg: `(min-width: ${desktop}px)`,
    md: `(max-width: ${desktop - 1}px)`,
    sm: `(max-width: ${tablet - 1}px)`,
    xs: `(max-width: ${mobile - 1}px)`,
  },

  zindex: {
    circle: 2,
    tooltip: 5,
    menu: 11,
  },

  colors: {
    font: {
      white: '#FFFFFF',
      secondary: '#878787',
      main: '#484848',
      header: '#121212',
      blue: '#004379',
      disable: '#C2C2C2',
    },
    bg: {
      white: '#FFFFFF',
      superLightGray: '#F7F7F7',
      extraLightBlue: '#E9F0FA',
      popupDark: 'rgba(20, 21, 22, 0.5)',
      disable: '#F7F7F7',
    },
    buttonSecondary: {
      lightBlueDefault: '#E9F0FA',
      lightBluePressed: '#B6CCEB',
      hover: '#D2E0F4',
    },
    tests: {
      lightBlue: '#86D0F5',
      orange: '#FFB78E',
      green: '#86F5A5',
      pink: '#FF88AD',
    },
    elements: {
      white: '#FFFFFF',
      divider: '#E6E6E6',
      gray: '#E7E7E7',
      border: '#CDCDCD',
      secondary: '#858585',
      borderActive: '#9C9C9C',
      blueDefault: '#006EB6',
      blueHover: '#005F9D',
      bluePressed: '#015186',
      darkBlue: '#006EB6',
      darkBlueHover: '#005F9D',
      darkBluePressed: '#015186',
      warning: '#F7004A',
      disable: '#DBDBDB',
      answerCheck: '#28AA4C',
      golden: '#D98B00',
      lightGreen: '#49C66C',
      blue: '#2E99CE',
    },
  },
};
