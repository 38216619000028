import React, { useState } from 'react';
import styled from 'styled-components';
import { CircleChart, DateInterval, ErrorsBlock } from '.';
import { BodySmall } from './Typography';
import { AdminTotalStats, ClientStat } from '../services';
import { ErrorMessages } from '../types/common';
import { mediaQuery } from '../utils/themeUtils';
import { H1, H2 } from './Typography/Header';
import { CircleChartWrapper } from './CircleChart';
import { ExpandButton } from './ExpandButton';

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  ${mediaQuery('md')} {
    display: flex;
    flex-direction: column-reverse;
  }
`;

const StatsAside = styled.div`
  border-radius: 24px 0 0 24px;
  overflow: hidden;
  display: grid;
  grid-template-rows: 185px 1fr;
  h1,
  span {
    color: ${({ theme }) => theme.colors.font.white};
  }
  ${mediaQuery('md')} {
    border-radius: 0 0 24px 24px;
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-template-rows: 124px;
  }
  ${mediaQuery('xs')} {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const StatsCircles = styled.div`
  display: grid;
  padding: 16px 0;
  ${mediaQuery('md')} {
    padding: 0;
  }
`;

const SummaryStat = styled(BodySmall)`
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 16px 20px;
  ${mediaQuery('md')} {
    padding: 16px 40px;
  }
`;

const GlobalTests = styled(SummaryStat)`
  background-color: ${(props) => props.theme.colors.elements.blueDefault};
`;

const GlobalAccesses = styled(SummaryStat)`
  background-color: ${(props) => props.theme.colors.font.blue};
`;

const CirclesWrap = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  align-self: center;
  column-gap: 8px;
  ${CircleChartWrapper} {
    max-width: 178px;
  }
`;

const TogglerTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  button {
    display: none;
    ${mediaQuery('sm')} {
      display: flex;
    }
  }
`;

const StatsBlock = styled.div<{ expanded: boolean }>`
  display: ${(props) => (props.expanded ? 'grid' : 'block')};
  grid-template-rows: auto auto 1fr;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.bg.white};
  border-radius: 0 24px 24px 0;
  padding: 40px 24px 0 24px;
  ${mediaQuery('md')} {
    padding: 24px 18px 24px;
    border-radius: ${(props) => (props.expanded ? '24px 24px 0 0' : '24px')};
    grid-template-rows: auto 90px 1fr;
  }
  ${mediaQuery('sm')} {
    grid-template-rows: auto 110px 1fr;
  }
`;

const StatsBlockHeading = styled(H2)`
  margin-bottom: 0;
`;

interface StatisticsProps {
  errorMessages?: ErrorMessages | null;
  data?: ClientStat | AdminTotalStats | null;
  total?: number;
  dateInterval?: {
    dateStart: string;
    dateEnd: string;
  };
  dateChangeHandlers?: {
    setDateStart: (dateString: string) => void;
    setDateEnd: (dateString: string) => void;
  };
}

const GeneralTestStatistics: React.FC<StatisticsProps> = ({
  errorMessages,
  data,
  total,
  dateInterval,
  dateChangeHandlers,
}) => {
  const [showStats, setShowStats] = useState(true);

  const showStatsToggle = () => setShowStats((prevState) => !prevState);

  if (errorMessages) {
    return <ErrorsBlock errors={errorMessages} />;
  }

  if (data === null || total === 0) {
    return null;
  }

  return (
    <Wrapper>
      {showStats && (
        <StatsAside>
          <GlobalTests>
            <H1>{total}</H1> методик всего
          </GlobalTests>
          <GlobalAccesses>
            <H1>{data?.surveyAttempts}</H1> тестов
          </GlobalAccesses>
        </StatsAside>
      )}
      <StatsBlock expanded={showStats}>
        <TogglerTitle>
          <StatsBlockHeading>Статистика общая</StatsBlockHeading>
          <ExpandButton onClick={showStatsToggle} expanded={showStats} />
        </TogglerTitle>
        {showStats && (
          <>
            <DateInterval dateInterval={dateInterval} dateChangeHandlers={dateChangeHandlers} />
            <StatsCircles>
              <CirclesWrap>
                <CircleChart
                  unlimited={data?.unlimitedRegistrations}
                  value={data?.numberOfRegistrations ?? 0}
                  max={data?.surveyAttempts ?? 0}
                  label="Регистраций"
                  color="lightBlue"
                />
                <CircleChart
                  unlimited={data?.unlimitedAttempts}
                  value={data?.pollsDone ?? 0}
                  max={data?.surveyAttempts ?? 0}
                  label="Пройдено"
                  color="pink"
                />
                <CircleChart
                  unlimited={data?.unlimitedAttempts}
                  value={data?.surveyAttemptsRemaining ?? 0}
                  max={data?.surveyAttempts ?? 0}
                  label="Осталось"
                  color="green"
                />
              </CirclesWrap>
            </StatsCircles>
          </>
        )}
      </StatsBlock>
    </Wrapper>
  );
};

export default GeneralTestStatistics;
