import React, { useMemo } from 'react';
import { ErrorText } from './index';

export function ErrorsBlock(props: { errors: Record<string, string | string[]> | string }) {
  const error = useMemo(() => {
    if (typeof props.errors === 'string') {
      return <ErrorText>{props.errors}</ErrorText>;
    }
    return Object.entries(props.errors).map(([k, err]) => <ErrorText key={k}>{err}</ErrorText>);
  }, [props.errors]);

  return <>{error}</>;
}
