import styled, { css } from 'styled-components';
import { NavLink } from 'react-router-dom';
import { IconLogo } from '../Icons';
import { ToggleButton } from './ToggleButton/ToggleButton';
import { getColor, mediaScreen } from '../../utils/mediaQuery';
import { CLASS_SM_HIDDEN, CLASS_SM_ONLY } from '../../constants/classNames';
import { mediaQuery } from '../../utils/themeUtils';

const SHOW_HIDE_DURATION = '0.2s';

const MENU_HEIGHT_SM = '53px';

interface StateProps {
  className?: string;
  isOpen: boolean;
}

export const FillerImg = styled.img`
  margin-top: auto;
  padding: 0 36px 32px 32px;
  transition: opacity ${SHOW_HIDE_DURATION};

  ${mediaScreen('sm')} {
    display: none;
  }
`;

const LinkStyle = css`
  text-decoration: none;

  display: flex;
  border-top-left-radius: 32px;
  border-bottom-left-radius: 32px;
  margin-left: 14px;
  padding: 16px 20px;

  color: ${(props) => props.theme.colors.font.white};
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;

  transition: ${SHOW_HIDE_DURATION} ease-out;

  svg {
    vertical-align: middle;
    margin-right: 10px;
    min-width: 24px;
    transition: ${SHOW_HIDE_DURATION} ease-out;
  }

  &:hover,
  &.selected {
    background-color: ${(props) => props.theme.colors.bg.extraLightBlue};
    color: ${(props) => props.theme.colors.font.blue};

    svg {
      color: ${(props) => props.theme.colors.font.blue} !important;
    }
  }

  ${mediaScreen('sm')} {
    display: inline;
    border-radius: 100px;
    margin: 0;
    padding: 8px 16px;

    svg {
      margin: auto;
    }
  }
`;

export const StyledNavLink = styled(NavLink)`
  ${LinkStyle}
`;
export const StyledAnchorLink = styled.a`
  ${LinkStyle}
`;

export const MenuInner = styled.div<StateProps>`
  position: fixed;
  left: 0;
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.colors.elements.blueDefault};
  width: ${(props) => (props.isOpen ? '240px' : '88px')};

  .${CLASS_SM_ONLY} {
    display: none;
  }

  overflow: hidden;
  height: 100vh;
  transition: width ${SHOW_HIDE_DURATION} ease-out;

  ${FillerImg} {
    opacity: ${(props) => (props.isOpen ? 1 : 0)};
  }

  ${mediaQuery('sm')} {
    position: static;
    width: 100%;
    height: 100%;
    display: block;

    & > nav,
    & > nav > ul > li {
      height: 100%;
    }

    & > nav > ul {
      height: 100%;
      display: flex;
      justify-content: space-around;
      align-items: center;
    }

    & > nav > ul > li {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 11px 0;

      span {
        display: none;
      }
    }

    li.${CLASS_SM_HIDDEN} {
      display: none;
    }

    a {
      line-height: 1;
      padding-top: 0;
      padding-bottom: 0;
      height: 100%;
      display: flex;
      align-items: center;
      text-align: center;
    }

    img {
      width: 24px;
      border-radius: 50%;
    }

    /* Profile icon */
    li.${CLASS_SM_ONLY} {
      display: block;

      a {
        padding: 6px;
        border-radius: 50%;
        background: transparent;
      }

      svg {
        background: ${getColor(({ bg }) => bg.white)};
        border-radius: 50%;
      }
    }
  }
`;

export const MenuWrapper = styled.div<StateProps>`
  position: relative;
  min-width: ${(props) => (props.isOpen ? '240px' : '88px')};
  transition: min-width ${SHOW_HIDE_DURATION} ease-out;

  ${mediaQuery('sm')} {
    height: ${MENU_HEIGHT_SM};
    min-width: auto;
    width: 100%;
  }
`;

export const Logo = styled(IconLogo)<StateProps>`
  color: ${(props) => props.theme.colors.font.white};

  svg {
    margin: 15px 24px 16px;

    path:nth-child(-n + 19) {
      ${(props) => !props.isOpen && 'display: none;'}
    }
  }

  ${mediaScreen('sm')} {
    display: none;
  }
`;

export const StyledToggleButton = styled(ToggleButton)`
  border: none;
  outline: none;
  cursor: pointer;
  background: transparent;

  width: 100%;
  display: flex;
  align-items: center;
  font-size: 16px;
  color: ${(props) => props.theme.colors.font.white};
  padding: 16px 20px 16px 30px;
  transition: ${SHOW_HIDE_DURATION} ease-out;
  white-space: nowrap;

  svg {
    min-width: 24px;
    fill: ${(props) => props.theme.colors.font.white};
    margin-right: 14px;
    transition: ${SHOW_HIDE_DURATION} ease-out;
  }

  ${mediaScreen('sm')} {
    display: none;
  }
`;

export const ProfileItem = styled.li`
  display: none;
  ${mediaQuery('sm')} {
    display: block;
  }
`;
export const ExtraNavBtns = styled.div<{ opened: boolean }>`
  padding: ${(props) => (props.opened ? '0 34px' : '0 16px')};
  margin: 42px 0 auto 0;
`;
