import React from 'react';

const PlusIcon = () => {
  return (
    <svg viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="8.375" y="0.875" width="1.25" height="16.25" rx="0.625" fill="currentColor" />
      <rect
        x="0.875"
        y="9.625"
        width="1.25"
        height="16.25"
        rx="0.625"
        transform="rotate(-90 0.875 9.625)"
        fill="currentColor"
      />
    </svg>
  );
};

export default PlusIcon;
