import React, { useMemo } from 'react';
import styled from 'styled-components';

import LogoShopping from '../../../assets/images/shopping.svg';

interface Props {
  opened: boolean;
}
const StyledButton = styled.a<Props>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px;
  border: 1px solid ${({ theme }) => theme.colors.elements.white};
  color: white;
  border-radius: 8px;
  text-decoration: none;
  white-space: nowrap;
  overflow: hidden;
  &:hover {
    background-color: ${({ theme }) => theme.colors.elements.blueHover};
    color: ${({ theme }) => theme.colors.elements.white};
  }
`;

export const ButtonPurchase: React.FC<{ to: string; isOpen: boolean }> = (props) => {
  const { to, isOpen } = props;

  return useMemo(
    () => (
      <StyledButton title="Купить тесты" href={to} opened={isOpen}>
        {isOpen ? 'Купить тесты' : <img height={20} src={LogoShopping} alt="shopping-logo" />}
      </StyledButton>
    ),
    [isOpen, to],
  );
};
