import React from 'react';

const RightChevron = ({ color = '#878787', className = '' }) => {
  return (
    <svg className={className} width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill={color}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.70718 11.707L0.292969 10.2928L4.58586 5.99992L0.292969 1.70703L1.70718 0.292818L7.41429 5.99992L1.70718 11.707Z"
      />
    </svg>
  );
};

export default RightChevron;
