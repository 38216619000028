import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { mediaQuery } from 'utils/themeUtils';
import { ItemButtonColored } from './ColoredAnswerControl';
import { AnswerControlProps } from '../types/common';

const StraightAnswerControl: FC<AnswerControlProps> = ({ decision, onAnswer, choices, disable }) => {
  return (
    <>
      {choices.map((choice) => (
        <ItemButtonStraight
          disabled={disable}
          key={choice.id}
          decision={decision}
          choice={choice.id}
          onClick={() => onAnswer(choice.id)}
          isSelected={decision === choice.id}
        >
          <ButtonStraightDescription>{choice.text}</ButtonStraightDescription>
        </ItemButtonStraight>
      ))}
    </>
  );
};

export default StraightAnswerControl;

const ItemButtonStraight = styled(ItemButtonColored)`
  width: auto;
  height: auto;
  padding: 12px 14px;
  font-size: 14px;
  line-height: 16px;
  min-width: 52px;
  margin: 0;

  ${({ theme, isSelected }) => {
    return css`
      background-color: ${isSelected
        ? theme.colors.buttonSecondary.lightBluePressed
        : theme.colors.buttonSecondary.lightBlueDefault};
      pointer-events: ${isSelected ? 'none' : 'auto'};

      &:hover {
        background-color: ${isSelected
          ? theme.colors.buttonSecondary.lightBluePressed
          : theme.colors.buttonSecondary.hover};
      }

      ${mediaQuery('lg')} {
        &:active {
          background-color: ${theme.colors.buttonSecondary.lightBluePressed};
        }
      }

      &:disabled {
        background-color: ${theme.colors.elements.disable};
      }
    `;
  }}

  ${mediaQuery('md')} {
    padding-left: 20px;
    padding-right: 20px;
  }
`;

const ButtonStraightDescription = styled.span`
  color: ${({ theme }) => theme.colors.font.blue};
  font-weight: 600;
  font-size: inherit;
  ${mediaQuery('md')} {
    font-size: 14px;
    line-height: 16px;
  }
`;
