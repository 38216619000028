import { useState, useEffect } from 'react';

const getMatches = (query: string): boolean => {
  if (window) {
    return window.matchMedia(query).matches;
  }
  return false;
};

export const useMediaQuery = (query: string): boolean => {
  const [matches, setMatches] = useState<boolean>(getMatches(query));

  useEffect(() => {
    const mql = window.matchMedia(query);

    const listener = () => {
      setMatches(getMatches(query));
    };

    mql.addEventListener('change', listener);

    return () => {
      mql.removeEventListener('change', listener);
    };
  }, [query]);

  return matches;
};
