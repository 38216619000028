import React, { FC } from 'react';
import { useForm } from 'react-hook-form';

import { Modal } from '../index';
import { Button, Textarea } from '../../components';
import { feedbackApi } from '../../utils/feedbackApi';
import { BtnRow, FormRow } from './styled';

interface ModalPhotoProps {
  showModal: boolean;
  closeModal: () => void;
  title?: string;
}

const ModalContact: FC<ModalPhotoProps> = ({ showModal, closeModal, title }) => {
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { register, handleSubmit, reset } = useForm({});

  const onSubmit = (data: { theme: string; content: string }) => {
    feedbackApi.feedbackUserFeedbackCreate({ data }).then(closeModal);
    reset();
  };

  const onClearForm = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    reset();
    closeModal();
  };

  return (
    <Modal showModal={showModal} handleCloseModal={closeModal} title={title}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormRow>
          <Textarea name="content" placeholder="Введите сообщение" ref={register()} required />
        </FormRow>

        <BtnRow>
          <Button view="secondary" onClick={onClearForm}>
            Отменить
          </Button>
          <Button type="submit">Отправить</Button>
        </BtnRow>
      </form>
    </Modal>
  );
};

export default ModalContact;
