import React from 'react';
import styled, { useTheme } from 'styled-components';
import ColorLogo from 'assets/images/logo_color.svg';
import illustration from 'assets/images/auth_1.svg';
import { mediaQuery } from 'utils/themeUtils';
import { useMediaQuery } from 'hooks/useMediaQuery';

interface AuthWrapperProps {
  form: React.ReactNode;
  banner?: React.ReactNode;
  button?: React.ReactNode;
}

const ButtonWrapper = styled.div``;

const Container = styled.div`
  display: grid;
  background-color: ${({ theme }) => theme.colors.bg.white};
  min-height: 100vh;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  ${mediaQuery('xs')} {
    background: ${({ theme }) => theme.colors.bg.extraLightBlue};
  }
`;

const Header = styled.div`
  width: 100%;
  padding: 0 40px;
  margin-top: 22px;
  display: flex;
  height: 65px;
  justify-content: space-between;
  grid-row: 1;
  grid-column: 1;
  z-index: 1;

  ${mediaQuery('xs')} {
    flex-direction: column;
    align-items: center;
    row-gap: 18px;
    grid-row: auto;
    height: auto;
    a.button {
      padding-top: 8px;
      padding-bottom: 8px;
    }
  }
`;

const Content = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 50%);
  align-items: center;
  grid-row: 1/3;
  grid-column: 1;

  ${mediaQuery('sm')} {
    grid-template-columns: 100%;
  }

  ${mediaQuery('xs')} {
    grid-row: auto;
  }
`;

const Main = styled.div`
  background: ${({ theme }) => theme.colors.bg.extraLightBlue};
  height: 100%;
  border-radius: 0 178px 178px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 100px 50px;

  ${mediaQuery('sm')} {
    width: 100%;
    border-radius: 0;
    padding: 100px 20px;
  }

  ${mediaQuery('xs')} {
    width: 100%;
    justify-content: flex-start;
    padding-top: 32px;
  }
`;

const Poster = styled.div`
  width: 100%;

  & > img {
    width: 100%;
  }

  ${mediaQuery('xs')} {
    display: none;
  }

  ${mediaQuery('sm')} {
    display: none;
  }
`;

const BannerWrapper = styled.div`
  width: 100%;
  max-width: 500px;
  margin-bottom: 32px;
`;

const ResponsiveBannerWrapper = styled.div`
  margin: 32px;
`;

const FormWrapper = styled.div`
  max-width: 500px;
  width: 100%;
`;

const AuthWrapper: React.FunctionComponent<AuthWrapperProps> = ({ form, button, banner }) => {
  const theme = useTheme();
  const match = useMediaQuery(theme.mediaQuery.sm);

  return (
    <Container>
      <Header>
        <img src={ColorLogo} alt="logo" />
        <ButtonWrapper>{button}</ButtonWrapper>
      </Header>
      <Content>
        <Main>
          {banner && match && <BannerWrapper>{banner}</BannerWrapper>}

          <FormWrapper>{form}</FormWrapper>
        </Main>
        <Poster>
          {banner && !match ? (
            <ResponsiveBannerWrapper>{banner}</ResponsiveBannerWrapper>
          ) : (
            <img src={illustration} alt="illustration" />
          )}
        </Poster>
      </Content>
    </Container>
  );
};

const StyledAuthWrapper = styled(AuthWrapper)`
  height: 100vh;
  width: 100vw;
`;

export default StyledAuthWrapper;
