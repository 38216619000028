import React, { forwardRef } from 'react';
import styled from 'styled-components';

interface TextareaProps {
  className?: string;
  label?: string;
  rows?: number;
  name: string;
  placeholder?: string;
  $error?: boolean;
  helper?: string;
  required?: boolean;
}

const Label = styled.span<{ className?: string; children: string; $error?: boolean }>`
  padding-left: 16px;
  color: ${(props) => (props.$error ? props.theme.colors.elements.warning : props.theme.colors.font.secondary)};
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 16px;
`;

const TextareaWrapper = forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({ className, rows, name, placeholder, required }, ref) => (
    <textarea className={className} rows={rows} name={name} placeholder={placeholder} ref={ref} required={required} />
  ),
);

const StyledTextareaWrapper = styled(TextareaWrapper)`
  appearance: none;
  outline: none;
  resize: vertical;

  width: 100%;
  min-height: 160px;
  background-color: ${(props) => props.theme.colors.bg.white};
  border: 1px solid
    ${(props) => (props.$error ? props.theme.colors.elements.warning : props.theme.colors.elements.border)};
  border-radius: ${(props) => props.theme.borderRadius};
  color: ${(props) => props.theme.colors.font.main};
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  padding: 15px;

  &::placeholder {
    color: ${(props) => props.theme.colors.font.secondary};
  }

  transition: 0.2s ease-out;

  &:hover,
  &:focus {
    border-color: ${(props) => props.theme.colors.elements.blueDefault};
  }
`;

const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({ className, label, rows, name, placeholder, $error, helper, required }, ref) => (
    <label className={className}>
      {label && <Label $error={$error}>{label}</Label>}
      <StyledTextareaWrapper
        rows={rows}
        name={name}
        placeholder={placeholder}
        $error={$error}
        ref={ref}
        required={required}
      />
      {helper && <Label $error={$error}>{helper}</Label>}
    </label>
  ),
);

export default Textarea;
