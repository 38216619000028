import React, { FC, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Button, Select } from 'components';
import { Actions, Filter, FilterCheckbox, FilterItem, FilterDatePicker } from 'components/TableFilter';
import { Poll, PollStateEnum } from 'services/api';

import 'react-datepicker/dist/react-datepicker.css';
import { QueriesType } from '../UserPollsTable';

const optionsStatus = [
  { value: PollStateEnum.New, label: 'Новый' },
  { value: PollStateEnum.InProgress, label: 'В процессе' },
  { value: PollStateEnum.Done, label: 'Завершён' },
];

interface FiltersCurrentUserProps {
  pollsData?: Poll[];
  setQueries: (params: QueriesType) => void;
  queries: QueriesType;
}

const FiltersUserPolls: FC<FiltersCurrentUserProps> = ({ pollsData, setQueries, queries }) => {
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { handleSubmit, control, register, reset } = useForm<QueriesType>({
    defaultValues: queries,
  });

  const optionsPolls = useMemo(
    () =>
      pollsData?.map((item) => {
        return {
          value: item.surveyId,
          label: item.name,
        };
      }) ?? [],
    [pollsData],
  );

  const onClearForm = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();

    reset({ polls: null, state: null, endDate: '' });
    setQueries({});
  };

  return (
    <Filter onSubmit={handleSubmit(setQueries)}>
      <FilterItem>
        <Controller
          control={control}
          name="polls"
          className="small"
          placeholder="Выберите методику"
          options={optionsPolls}
          as={Select}
          isMulti
        />
      </FilterItem>

      <FilterItem>
        <Controller
          control={control}
          name="state"
          className="small"
          placeholder="Статус"
          options={optionsStatus}
          as={Select}
        />
      </FilterItem>

      <FilterItem>
        <Controller
          control={control}
          name="endDate"
          render={({ onChange, value }) => (
            <FilterDatePicker
              onChange={onChange}
              // ts: Unsafe assignment of an any value
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
              selected={value}
              placeholderText="Дата прохождения"
              popperPlacement="right-start"
              name="endDate"
            />
          )}
          defaultValue=""
        />
      </FilterItem>

      <FilterCheckbox name="reportNotViewed" ref={register()}>
        Только новые отчёты
      </FilterCheckbox>

      <FilterItem>
        <Actions>
          <Button type="submit" size="small">
            Найти
          </Button>

          <Button onClick={onClearForm} view="secondary" size="small">
            Очистить
          </Button>
        </Actions>
      </FilterItem>
    </Filter>
  );
};

export default FiltersUserPolls;
