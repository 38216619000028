import React, { useState, forwardRef, useCallback } from 'react';
import { Input } from '.';
import { IconEye } from './Icons';

interface InputPasswordProps {
  name: string;
  placeholder?: string;
  helper?: string;
  autoComplete?: string;
  disabled?: boolean;
  $error?: boolean;
}

const InputPassword = forwardRef<HTMLInputElement, InputPasswordProps>(
  ({ name, placeholder, helper, autoComplete, disabled, $error }, ref) => {
    const [isVisible, setIsVisible] = useState(false);

    const toggleIsVisible = useCallback(() => {
      setIsVisible((prev) => !prev);
    }, [setIsVisible]);

    return (
      <Input
        type={isVisible ? 'text' : 'password'}
        name={name}
        placeholder={placeholder}
        autoComplete={autoComplete}
        helper={helper}
        disabled={disabled}
        icon={<IconEye onClick={toggleIsVisible} />}
        ref={ref}
        $error={$error}
      />
    );
  },
);

export default InputPassword;
