import React, { FC, useState } from 'react';
import styled from 'styled-components';
import { Controller, useForm } from 'react-hook-form';
import { Button, ErrorsBlock, Input, MessageInfo, Select } from 'components';
import { dashboardApi } from 'utils/dashboardApi';
import { IconPlus } from 'components/Icons';
import { useApiErrorMessages } from 'hooks/useApiErrorMessages';
import { mediaQuery } from 'utils/themeUtils';
import { CreateStaff, ClientPollAnnotate } from '../../../services';

const ErrorWrapper = styled.div`
  margin-bottom: 32px;
`;

const Form = styled.form`
  margin-bottom: 32px;
`;

const FormInnerWrapper = styled.div`
  padding: 16px 32px 16px 102px;
  background-color: ${(props) => props.theme.colors.bg.extraLightBlue};
  border-radius: 4px;
  ${mediaQuery('md')} {
    padding: 21px;
  }
  ${mediaQuery('sm')} {
    padding-left: 0;
    padding-right: 0;
  }
`;

const RowInputs = styled.div`
  display: flex;
  align-items: flex-end;
  margin-bottom: 20px;
  column-gap: 15px;

  ${mediaQuery('md')} {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-row-gap: 15px;
  }
  ${mediaQuery('sm')} {
    display: grid;
    grid-template-columns: 1fr;
  }
`;

const SelectWrap = styled.div`
  position: relative;
  width: 100%;
  .Select__control.Select__control--menu-is-open + div {
    z-index: 2;
  }

  p {
    position: absolute;
    top: -20px;
    color: ${({ theme }) => theme.colors.elements.warning};
  }
`;

const AddNewRow = styled.button`
  outline: none;
  background: transparent;
  border: none;
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.font.blue};
  cursor: pointer;
  transition: color 0.3s linear;
  margin-top: 20px;

  &:hover {
    color: ${({ theme }) => theme.colors.font.secondary};
  }

  span {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${({ theme }) => theme.colors.elements.white};
    border-radius: 50%;
    margin-right: 16px;

    svg {
      width: 18px;
      height: 18px;
    }
  }
`;

const BtnsControl = styled.div`
  display: flex;
  align-items: center;
  margin-top: 32px;
  column-gap: 32px;
  ${mediaQuery('sm')} {
    margin-top: 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 16px;
    button {
      min-width: 0;
      width: 100%;
    }
  }
`;

interface FormCreateNewStaffProps {
  pollsData: ClientPollAnnotate[];
  getStaffs: () => void;
}

interface OptionType {
  label: string;
  value: number;
}

type AlertType = {
  message: string;
  variant: 'error' | 'success';
};

type UserType = {
  email: string;
  fullName: string;
  phone: string;
  polls: OptionType[];
};

const FormCreateNewStaff: FC<FormCreateNewStaffProps> = ({ pollsData, getStaffs }) => {
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { register, handleSubmit, errors, control, reset } = useForm({});
  const [alert, setAlert] = useState<AlertType | null>(null);
  const [errorMessages, setError] = useApiErrorMessages();
  const [staffRowsCnt, setStaffRowsCnt] = useState(1);

  const options = pollsData.map((item) => {
    return {
      value: item.id,
      label: item.survey,
    };
  });

  const defaultValues = {
    users: [
      {
        fullName: '',
        email: '',
        phone: '',
        polls: '',
      },
    ],
  };

  const onSubmit = (data: { users: Array<UserType> }) => {
    setError(undefined);
    setAlert(null);
    const newUsers: CreateStaff[] = data.users.map((user) => ({
      ...user,
      polls: user.polls.map((item) => Number(item.value)),
    }));

    dashboardApi
      .dashboardClientMultipleAddStaff({ data: newUsers })
      .then(() => {
        setAlert({ message: 'Сотрудник добавлен', variant: 'success' });
        getStaffs();
        setStaffRowsCnt(1);
        reset({ ...defaultValues });
      })
      .catch(setError);
  };

  const onClearForm = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    setStaffRowsCnt(1);
    reset({ ...defaultValues });
  };

  const handleAddRow = () => {
    setStaffRowsCnt((staffRowsCnt) => staffRowsCnt + 1);
  };

  return (
    <>
      {alert !== null && <MessageInfo message={alert.message} variant={alert.variant} />}

      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormInnerWrapper>
          {Array.from({ length: staffRowsCnt })
            .fill(0)
            .map((item, index) => (
              <RowInputs key={index}>
                <Input
                  type="text"
                  name={`users[${index}][fullName]`}
                  label="Имя"
                  placeholder="Имя"
                  variant="small"
                  ref={register()}
                  required
                />

                <Input
                  type="tel"
                  name={`users[${index}][phone]`}
                  label="Телефон"
                  placeholder="Телефон"
                  variant="small"
                  ref={register()}
                  required
                />

                <Input
                  type="email"
                  name={`users[${index}][email]`}
                  label="Email"
                  placeholder="Email"
                  variant="small"
                  ref={register()}
                  required
                />

                <SelectWrap>
                  <Controller
                    control={control}
                    rules={{ required: true }}
                    name={`users[${index}][polls]`}
                    render={(props) => (
                      <Select
                        className="small"
                        placeholder="Выберите методику"
                        // esLint: 'Unsafe assignment of an any value'
                        /* eslint-disable-next-line */
                        value={props.value}
                        options={options}
                        onChange={props.onChange}
                        inputRef={register()}
                        isMulti
                      />
                    )}
                  />
                  {errors.polls && <p>Выберите хотя бы один тест!</p>}
                </SelectWrap>
              </RowInputs>
            ))}

          <AddNewRow type="button" onClick={handleAddRow}>
            <span>
              <IconPlus />
            </span>
            Добавить ещё
          </AddNewRow>
          {errorMessages && (
            <ErrorWrapper>
              <ErrorsBlock errors={errorMessages} />
            </ErrorWrapper>
          )}
        </FormInnerWrapper>

        <BtnsControl>
          <Button onClick={(e: React.MouseEvent<HTMLElement>) => onClearForm(e)} view="secondary">
            Отменить
          </Button>
          <Button type="submit">Сохранить</Button>
        </BtnsControl>
      </Form>
    </>
  );
};

export default FormCreateNewStaff;
