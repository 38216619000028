import React, { FC } from 'react';
import styled from 'styled-components';
import { CircularProgressbarWithChildren } from 'react-circular-progressbar';

interface ProgressSemicircleChartProps {
  max: number;
  value: number;
  label?: string;
  className?: string;
  color: string;
}

const ProgressValues = styled.div`
  width: 100%;
  color: ${({ theme }) => theme.colors.font.secondary};
  text-align: center;
  margin-top: auto;

  strong {
    color: ${({ theme }) => theme.colors.font.main};
    font-weight: normal;
    font-size: 1.5em;
  }
`;

const Wrapper = styled.div`
  display: flex;
  max-width: 150px;
  height: 80px;

  svg {
    width: 100%;
    overflow: hidden;
    align-self: flex-start;

    .CircularProgressbar-trail {
      stroke: #e7e7e7;
    }

    .CircularProgressbar-trail,
    .CircularProgressbar-path {
      stroke-linecap: round;
      transform: rotate(-0.25turn);
      transform-origin: center center;
    }
  }
`;

const SemicircleChart: FC<ProgressSemicircleChartProps> = ({ label, color, value, max, className }) => {
  return (
    <Wrapper className={className}>
      <CircularProgressbarWithChildren
        value={Math.min(value, max)}
        maxValue={max === 0 ? Infinity : max}
        circleRatio={0.5}
        styles={{
          path: {
            stroke: color,
          },
        }}
      >
        <ProgressValues>
          <strong>{value}</strong>/{max} <br />
          {label}
        </ProgressValues>
      </CircularProgressbarWithChildren>
    </Wrapper>
  );
};

export default SemicircleChart;
