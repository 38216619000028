import React from 'react';

const Link = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0)">
      <path
        d="M18.402 8.55162C18.1927 8.55162 17.9832 8.63133 17.824 8.79173C17.5044 9.11133 17.5044 9.62948 17.824 9.94909L20.7311 12.8563C21.786 13.9014 22.3665 15.2978 22.3665 16.7924C22.3665 19.8643 19.8672 22.3636 16.7952 22.3636C15.3007 22.3636 13.9042 21.7844 12.8613 20.7317L9.95195 17.8221C9.63235 17.5025 9.11419 17.5025 8.79439 17.8221C8.47479 18.1419 8.47479 18.66 8.79439 18.9796L11.7018 21.8868C13.0501 23.2495 14.861 24 16.794 24C20.7683 24 24.0017 20.7666 24.0017 16.7924C24.0017 14.8582 23.25 13.0472 21.8853 11.6957L18.9803 8.79173C18.8199 8.63133 18.6116 8.55162 18.402 8.55162Z"
        fill="currentColor"
      />
      <path
        d="M7.20763 2.19345e-05C3.23338 2.19345e-05 0 3.2334 0 7.20765C0 9.14185 0.751665 10.9526 2.11637 12.3043L5.02155 15.2083C5.34115 15.5279 5.85931 15.5279 6.17891 15.2083C6.49851 14.8887 6.49851 14.3705 6.17891 14.0509L3.27173 11.1436C2.21564 10.0997 1.63636 8.70319 1.63636 7.20765C1.63636 4.13568 4.13565 1.63639 7.20763 1.63639C8.70217 1.63639 10.0986 2.21566 11.1415 3.26836L14.0509 6.17773C14.3705 6.49734 14.8887 6.49734 15.2083 6.17773C15.5281 5.85813 15.5281 5.33998 15.2083 5.02037L12.3011 2.11299C10.9516 0.750488 9.14082 2.19345e-05 7.20763 2.19345e-05Z"
        fill="currentColor"
      />
      <path
        d="M7.63809 6.81824C7.42876 6.81824 7.21922 6.89794 7.06001 7.05814C6.74041 7.37794 6.74041 7.8961 7.06001 8.2157L15.7872 16.943C16.107 17.2626 16.6251 17.2626 16.9447 16.943C17.2643 16.6232 17.2643 16.1051 16.9447 15.7855L8.21737 7.05814C8.05697 6.89794 7.84763 6.81824 7.63809 6.81824Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="24" height="24" fill="white" transform="matrix(1 0 0 -1 0 24)" />
      </clipPath>
    </defs>
  </svg>
);

export default Link;
