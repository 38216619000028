import React from 'react';

const Download = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 4V18M12 18L8 14M12 18L16 14" stroke="currentColor" strokeWidth="2" />
    <path d="M5 20H19" stroke="currentColor" strokeWidth="2" />
  </svg>
);

export default Download;
