import React, { createElement } from 'react';
import styled, { DefaultTheme } from 'styled-components';
import { getColor, mediaQuery } from '../../utils/themeUtils';

export const H1 = styled.h1`
  font-size: 48px;
  line-height: 56px;
  color: ${({ theme }) => theme.colors.font.blue};
  ${mediaQuery('sm')} {
    font-size: 28px;
    line-height: 32px;
  }
`;

export const H2 = styled.h2`
  font-size: 32px;
  line-height: 48px;
  color: ${({ theme }) => theme.colors.font.blue};
  ${mediaQuery('md')} {
    font-size: 26px;
    line-height: 48px;
  }
  ${mediaQuery('sm')} {
    font-size: 20px;
    line-height: 22px;
  }
`;

interface HeaderProps {
  className?: string;
  children: React.ReactNode;
  variant: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'span';
  color?: keyof DefaultTheme['colors']['font'];
}

const HeaderTypography: React.FC<HeaderProps> = ({ className, children, variant }) =>
  createElement(variant, { className }, children);

const HeaderTypographyColor = styled(HeaderTypography)`
  ${(props) => props.color && `color: ${props.theme.colors.font[props.color]};`}
  font-style: normal;
  font-weight: normal;
  letter-spacing: -0.02em;
`;

export const HeaderBig = styled(HeaderTypographyColor)`
  font-size: 56px;
  line-height: 64px;
  ${mediaQuery('sm')} {
    font-size: 28px;
    line-height: 32px;
  }
`;

export const HeaderMedium = styled(HeaderTypographyColor)`
  font-size: 48px;
  line-height: 56px;
  ${mediaQuery('sm')} {
    font-size: 28px;
    line-height: 32px;
  }
`;

const Header = styled(HeaderTypographyColor)`
  color: ${getColor(({ font }) => font.blue)};
  font-size: 32px;
  line-height: normal;
  margin-bottom: 32px;
  ${mediaQuery('md')} {
    font-size: 26px;
    margin-bottom: 26px;
  }
  ${mediaQuery('sm')} {
    font-size: 20px;
    margin-bottom: 20px;
  }
`;

export const HeaderSmall = styled(HeaderTypographyColor)`
  font-size: 24px;
  line-height: 32px;
`;

export default Header;
