import { makeAutoObservable } from 'mobx';
import { AxiosError } from 'axios';
import { CoreApi } from '../../services';

type NulableError = AxiosError<Record<string, string>> | null;
type HTMLString = string;

export default class PolicyLoader {
  policyHTML?: HTMLString;
  groupType?: string;

  error: NulableError = null;
  isLoading = false;

  constructor(private api: CoreApi) {
    makeAutoObservable(this);
  }

  fetchNewPrivacyPolicy() {
    if (this.groupType) {
      return this.api.corePolicyRead({ groupType: this.groupType }).then((res) => this.setPolicyHTML(res.data.text));
    }
    return Promise.reject(new Error('Роль не определена'));
  }

  fetchGroupType(token: string) {
    return this.api.coreAuthAccessByToken({ data: { token } }).then((res) => this.setGroupType(res.data.groupType));
  }

  getNewPolicyByRole(groupType: string) {
    this.setError(null);
    this.setPolicyHTML('');
    this.setLoading(true);
    this.setGroupType(groupType);
    return this.fetchNewPrivacyPolicy()
      .then(() => this.setLoading(false))
      .catch((err) => {
        this.setError(err);
        throw new Error(err);
      });
  }

  getNewPolicyByToken(token: string) {
    this.setError(null);
    this.setPolicyHTML('');
    this.setLoading(true);
    return this.fetchGroupType(token)
      .then(() => this.fetchNewPrivacyPolicy())
      .then(() => this.setLoading(false))
      .catch((err) => {
        this.setError(err);
        throw new Error(err);
      });
  }

  setPolicyHTML(policy: HTMLString) {
    this.policyHTML = policy;
  }

  setGroupType(groupType?: string) {
    this.groupType = groupType;
  }

  setError(error: NulableError) {
    this.error = error;
  }

  setLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }
}
