import styled from 'styled-components';
import { getColor, mediaQuery } from '../utils/themeUtils';

const Card = styled.div`
  background-color: ${(props) => props.theme.colors.bg.white};
  border-radius: 24px;
  padding: 40px 48px;
  ${mediaQuery('md')} {
    padding: 30px;
  }
  ${mediaQuery('sm')} {
    padding: 0;
  }
`;

export const CardsContainer = styled.div`
  display: grid;
  ${mediaQuery('sm')} {
    grid-gap: 14px;
  }
  .hover-info {
    right: auto;
    left: 0;
  }
`;

export const CardEntry = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CardTitle = styled.span`
  color: ${getColor(({ font }) => font.secondary)};
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  padding-bottom: 8px;
`;

export const CardText = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  white-space: normal;
  word-break: break-word;
`;

export const CardContainer = styled.div`
  display: grid;
  grid-gap: 25px;
  background: white;
  &:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.elements.divider};
  }
  padding: 16px 0;
  ${mediaQuery('sm')} {
    border-radius: 12px;
    border: none;
    padding: 20px;
  }
`;

export const CardItemList = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 25px;

  ${mediaQuery('sm')} {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
  }
`;

export const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export default Card;
