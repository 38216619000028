import styled from 'styled-components';

const LinkButton = styled.button<{ fontWeight?: number }>`
  cursor: pointer;
  padding: 0;
  border: none;
  font: inherit;
  font-weight: ${({ fontWeight }) => fontWeight ?? 'inherit'};
  color: ${({ theme }) => theme.colors.font.blue};
  background-color: transparent;
  display: flex;
  align-items: center;

  &:hover {
    text-decoration-line: underline;
  }
`;

export default LinkButton;
