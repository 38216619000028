import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div<Pick<PollsStatusProps, 'color'>>`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.font.blue};
  font-weight: 300;
  font-size: 14px;

  color: ${({ theme, color }) => theme.colors.elements[color]};

  span {
    margin-right: 8px;
    background-color: ${({ theme, color }) => theme.colors.elements[color]};
  }
`;

const CircleIcon = styled.span`
  width: 6px;
  height: 6px;
  border-radius: 50%;
`;

interface PollsStatusProps {
  label?: string;
  color: 'blue' | 'golden' | 'lightGreen';
}

const PollsStatus: React.FC<PollsStatusProps> = ({ label, color }) => {
  return (
    <Wrapper color={color}>
      <CircleIcon />
      {label}
    </Wrapper>
  );
};

export default PollsStatus;
