import React from 'react';
import styled, { css } from 'styled-components';

interface ButtonProps {
  className?: string;
  children?: React.ReactNode;
  type?: 'submit' | 'reset';
  view?: 'secondary' | 'link';
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  size?: 'small';
  bigRadius?: boolean;
}

const StyledButton = styled.button<ButtonProps>`
  border: none;
  outline: none;
  cursor: pointer;

  background-color: ${(props) =>
    props.view === 'secondary'
      ? props.theme.colors.buttonSecondary.lightBlueDefault
      : props.theme.colors.elements.blueDefault};
  border-radius: ${(props) => props.theme.borderRadius};
  min-width: 200px;
  padding: 16px;

  color: ${(props) => (props.view === 'secondary' ? props.theme.colors.font.blue : props.theme.colors.font.white)};
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;

  transition: 0.2s ease-out;

  &:hover {
    background-color: ${(props) =>
      props.view === 'secondary' ? props.theme.colors.buttonSecondary.hover : props.theme.colors.elements.blueHover};
    box-shadow: ${(props) =>
      props.view === 'secondary' ? '0px 4px 10px rgba(182, 204, 235, 0.25)' : '0px 4px 10px rgba(0, 95, 157, 0.25)'};
  }

  &:focus {
    background-color: ${(props) =>
      props.view === 'secondary'
        ? props.theme.colors.buttonSecondary.lightBluePressed
        : props.theme.colors.elements.bluePressed};
  }

  &:disabled {
    background-color: ${(props) => props.theme.colors.elements.disable};
    color: ${(props) => props.theme.colors.font.disable};
    cursor: not-allowed;
  }

  ${(props) =>
    props.size === 'small' &&
    css`
      font-weight: normal;
      font-size: 14px;
      min-width: auto;
      padding: 8px 16px;
    `}

  ${(props) =>
    props.bigRadius &&
    css`
      border-radius: 28px;
    `}
`;

export default StyledButton;
