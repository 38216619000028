import React, { FC } from 'react';
import { defaultTheme } from 'theme';
import { IconEye, IconEyeCrossedOut } from 'components/Icons';
import Tooltip from 'antd/lib/tooltip';
import { SurveyPaginationEyeProps } from './types';
import { EyeButton, EyeButtonInner } from './styles';

export const EyeCheckbox: FC<SurveyPaginationEyeProps> = (props) => {
  const { handleToggle, state, title } = props;
  return (
    <EyeButton onClick={handleToggle} role="button">
      <Tooltip placement="top" title={title} color={defaultTheme.colors.elements.darkBlue}>
        <EyeButtonInner>{state ? <IconEyeCrossedOut /> : <IconEye />}</EyeButtonInner>
      </Tooltip>
    </EyeButton>
  );
};
