import React, { FC, ReactNode } from 'react';
import styled, { css } from 'styled-components';
import { mediaScreen } from '../utils/themeUtils';
import HorisontalScrollWrapper from './HorisontalScrollWrapper';
import { H1, H2 } from './Typography/Header';

interface FormWrapperProps {
  title?: string;
  subtitle?: string;
  extra?: ReactNode;
  bigPadding?: boolean;
  justifyText?: boolean;
}

const Wrapper = styled.div<FormWrapperProps>`
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.bg.white};
  border-radius: 24px;
  padding: 26px;

  ${(props) => {
    return (
      props.justifyText &&
      css`
        text-align: justify;
      `
    );
  }}

  ${(props) => {
    return (
      props.bigPadding &&
      css`
        ${mediaScreen('lg')} {
          padding: 100px;
        }
        ${mediaScreen('md')} {
          padding: 100px 60px;
        }
        ${mediaScreen('sm')} {
          padding: 26px;
        }
      `
    );
  }}
`;

const SubTitle = styled.div`
  font-weight: 300;
  font-size: 18px;
  color: ${({ theme }) => theme.colors.font.secondary};
  text-transform: uppercase;
  margin-bottom: 16px;
`;

const Title = styled(H1)`
  margin-bottom: 24px;
`;

const ExtraTitle = styled(H2)`
  color: #121212;
  margin-bottom: 40px;
`;

const TitlesWrapper = styled.div`
  ${Title}:last-child {
    margin-bottom: 48px;
  }
`;

const FormWrapper: FC<FormWrapperProps> = (props) => {
  const { children, title, subtitle, extra, bigPadding, justifyText } = props;

  return (
    <Wrapper bigPadding={bigPadding} justifyText={justifyText}>
      <HorisontalScrollWrapper bottomPadded={80}>
        <TitlesWrapper>
          {subtitle && <SubTitle>{subtitle}</SubTitle>}
          {title && <Title>{title}</Title>}
          {extra && <ExtraTitle>{extra}</ExtraTitle>}
        </TitlesWrapper>
        {children}
      </HorisontalScrollWrapper>
    </Wrapper>
  );
};

export default FormWrapper;
