import React, { FC, ReactNode, CSSProperties } from 'react';
import styled from 'styled-components';

const ItemWrapper = styled.label`
  display: flex;
  align-items: start;
  min-height: 24px;

  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #484848;

  /* &:not(:last-child) {
    margin-right: 32px;
  } */

  input {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }

  input + span {
    position: relative;
    font-size: 16px;
    font-weight: 400;
    color: ${(props) => props.theme.colors.font.main};
    padding-left: 34px;
    cursor: pointer;
    width: 24px;
    height: 24px;
  }

  input + span:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0px;
    width: 24px;
    height: 24px;
    border: 1px solid ${(props) => props.theme.colors.elements.border};
    border-radius: 100%;
    background: ${(props) => props.theme.colors.bg.white};
  }

  input + span:after {
    content: '';
    width: 12px;
    height: 12px;
    background: ${({ theme }) => theme.colors.elements.blueDefault};
    position: absolute;
    top: 6px;
    left: 6px;
    border-radius: 100%;
    transition: all 0.2s ease;
  }

  input:not(:checked) + span:after {
    opacity: 0;
    transform: scale(0);
  }

  input:checked + span:after {
    opacity: 1;
    transform: scale(1);
  }
`;

interface RadioButtonProps {
  label?: ReactNode;
  value: string;
  id: string;
  name?: string;
  defaultChecked?: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  checked?: boolean;
  className?: string;
  style?: CSSProperties;
  disable?: boolean;
}

const RadioButton: FC<RadioButtonProps> = ({
  label,
  value,
  onChange,
  name,
  checked,
  id,
  className,
  style,
  disable,
}) => (
  <ItemWrapper htmlFor={id} className={className} style={style}>
    <input type="radio" id={id} name={name} value={value} onChange={onChange} checked={checked} disabled={disable} />
    <span />
    {label}
  </ItemWrapper>
);

export default RadioButton;
