import React, { FC } from 'react';
import styled, { css, DefaultTheme } from 'styled-components';

interface CloseProps {
  mini?: boolean;
  warning?: boolean;
  color?: keyof DefaultTheme['colors']['elements'];
}

const Close: FC<CloseProps> = ({ mini, warning, color = 'darkBlue' }) => (
  <Wrapper color={color} mini={mini} warning={warning} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <rect x="18.1406" y="4" width="2" height="20" transform="rotate(45 18.1406 4)" />
    <rect x="4" y="5.41431" width="2" height="20" transform="rotate(-45 4 5.41431)" />
  </Wrapper>
);

export default Close;

const Wrapper = styled.svg<CloseProps>`
  width: 24px;
  height: 24px;

  ${(props) =>
    props.mini &&
    css`
      width: 12px;
      height: 12px;
    `}

  ${(props) =>
    props.warning &&
    css`
      rect {
        fill: ${({ theme }) => theme.colors.elements.warning};
      }
    `}

    ${(props) => props.color && `fill: ${props.theme.colors.elements[props.color]};`}
`;
