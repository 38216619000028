import React, { createContext, useContext } from 'react';

import NotificationsService from './NotificationsService';

const notificationService = new NotificationsService();

const NotificationsContext = createContext<NotificationsService>(notificationService);

type NotificationsProviderProps = { children: React.ReactNode };

export const NotificationsProvider: React.FC<NotificationsProviderProps> = ({ children }) => (
  <NotificationsContext.Provider value={notificationService}>{children}</NotificationsContext.Provider>
);

export const useNotifications = (): NotificationsService => useContext(NotificationsContext);
