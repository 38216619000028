import React from 'react';

const Info = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="8" cy="8" r="7.5" stroke="currentColor" />
    <path
      d="M7.34766 4.09602H8.64766V2.82202H7.34766V4.09602ZM7.37366 12H8.63466V5.42202H7.37366V12Z"
      fill="currentColor"
    />
  </svg>
);

export default Info;
