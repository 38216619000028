import React from 'react';
import { IconProfile } from '../Icons';
import { useAuthStore } from '../../features/Auth';

export const ProfileIcon = () => {
  const store = useAuthStore();
  const photo = store.profile?.photo;

  return photo ? <img src={photo} alt="Аватар пользователя" /> : <IconProfile />;
};
