import React from 'react';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

interface LinkProps {
  className?: string;
  children?: React.ReactNode;
  to: string;
  leftIcon?: React.ReactNode;
  rightIcon?: React.ReactNode;
}

const ReactLink: React.FunctionComponent<LinkProps> = ({ className, children, to, leftIcon, rightIcon }) => (
  <Link className={className} to={to}>
    {leftIcon}
    {children}
    {rightIcon}
  </Link>
);

const StyledLink = styled(ReactLink)`
  display: inline-block;
  border-bottom: 1px solid transparent;
  text-decoration: none;

  color: ${(props) => props.theme.colors.font.blue};
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;

  transition: 0.2s ease-out;

  &:hover {
    border-bottom: 1px solid ${(props) => props.theme.colors.font.blue};
  }

  &.button {
    font-weight: 600;
    font-size: 18px;
    color: #004379;
    background-color: ${({ theme }) => theme.colors.buttonSecondary.lightBlueDefault};
    border-radius: ${({ theme }) => theme.borderRadius};
    padding: 16px;
    transition: background-color 0.2s ease-out, box-shadow 0.2s ease-out;

    &:hover {
      border-bottom: 1px solid transparent;
      background-color: #b6cceb;
      box-shadow: 0px 4px 10px rgb(182 204 235 / 25%);
    }
  }

  svg {
    vertical-align: middle;
  }

  ${(props) =>
    props.rightIcon &&
    css`
      svg {
        margin-left: 10px;
      }
    `}

  ${(props) =>
    props.leftIcon &&
    css`
      svg {
        margin-right: 10px;
      }
    `}
`;

export default StyledLink;
