import React, { Suspense, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import ru from 'date-fns/locale/ru';
import { registerLocale } from 'react-datepicker';
import { routes } from './routes';
import { useAuthStore } from './features/Auth';
import { useRecaptchaLoader } from './features/ReCaptcha';
import { authRoutes } from './routes/Auth';
import Loader from './components/Loader';
import { useNotifications } from './features/Notifications';
import { Modal } from './modals';
import { BodyBig } from './components/Typography';

const App = observer(() => {
  const store = useAuthStore();
  const recaptchaLoader = useRecaptchaLoader();
  const notifications = useNotifications();

  registerLocale('ru', ru);

  useEffect(() => {
    recaptchaLoader.loadReCaptcha();
  }, [recaptchaLoader]);

  if (!store.initialized) {
    return <Loader />;
  }

  return (
    <Router>
      <Suspense fallback={<Loader />}>
        <Switch>
          {store.profile?.role
            ? routes[store.profile?.role].map((route, index) => (
                <Route key={index} path={route.path} exact={route.exact} component={route.component} />
              ))
            : authRoutes.map((route, index) => (
                <Route key={index} path={route.path} exact={route.exact} component={route.component} />
              ))}
          <Redirect to="/" />
        </Switch>
        <Modal
          title="Кажется что-то пошло не так"
          showModal={notifications.showNotification}
          handleCloseModal={notifications.closeNotification}
        >
          <BodyBig>{notifications.notificationMessage}</BodyBig>
        </Modal>
      </Suspense>
    </Router>
  );
});

export default App;
