import React, { FC } from 'react';
import styled from 'styled-components';
import { IconDownload } from './Icons';

const Wrapper = styled.button`
  border: none;
  outline: none;
  background: transparent;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.font.blue};
  cursor: pointer;
  transition: opacity 0.3s linear;

  &:hover {
    opacity: 0.5;
  }

  svg,
  span {
    margin-right: 8px;
  }
`;

const ReportIcon = styled.span`
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.tests.green};
`;

interface DownloadProps {
  label?: string;
  variant?: 'new' | 'downloadIcon' | 'default';
  type?: 'button' | 'submit';
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
}

const Download: FC<DownloadProps> = ({ label = 'Скачать .xls', variant = 'default', type = 'button', onClick }) => {
  return (
    <Wrapper type={type} onClick={onClick}>
      {variant === 'downloadIcon' && <IconDownload />}
      {variant === 'new' && <ReportIcon />}
      {label}
    </Wrapper>
  );
};

export default Download;
