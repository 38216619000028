import styled from 'styled-components';

export const EyeButton = styled.div``;

export const EyeButtonInner = styled.div`
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.bg.extraLightBlue};
  cursor: pointer;
  svg {
    color: ${({ theme }) => theme.colors.elements.blueDefault};
  }
`;
