import React, { lazy } from 'react';
import { IconExit, IconHome, IconTest } from '../components/Icons';
import LogOut from '../features/Auth/LogOut';
import { LOGOUT_PATH } from './const';
import { Route } from './index';

const UserHome = lazy(() => import('../pages/User/Home'));
const UserTests = lazy(() => import('../pages/User/Tests'));
const SurveyProxy = lazy(() => import('../pages/User/SurveyProxy'));
const MultiSurveyHub = lazy(() => import('../pages/User/MultiSurveyHub'));
const SurveyQuestionnaire = lazy(() => import('../pages/User/SurveyQuestionnaire'));
const SurveyInstruction = lazy(() => import('../pages/User/SurveyInstruction'));
const SurveyFillup = lazy(() => import('../pages/User/SurveyFillup'));
const MultySurveyQuestionnaire = lazy(() => import('../pages/User/MultySurveyQuestionnaire'));
const SubSurveyInstruction = lazy(() => import('../pages/User/MiltiSurveySubSurveys/SubSurveyInstruction'));
const SubSurveyFillup = lazy(() => import('../pages/User/MiltiSurveySubSurveys/SubSurveyFillup'));
const UserProfile = lazy(() => import('../pages/User/UserProfile/UserProfile'));
const AccesCheckerProxy = lazy(() => import('../features/AccessRightsChecker/ProxyComponent'));

export const routes: Route[] = [
  {
    path: '/',
    exact: true,
    component: () => <UserHome />,
    icon: <IconHome />,
    name: 'Главная',
    permissions: ['canAccessDashboard'],
  },
  {
    path: '/profile',
    component: () => <UserProfile />,
  },
  {
    path: '/tests/:id',
    component: () => <SurveyProxy />,
  },
  {
    path: '/tests',
    component: () => <UserTests />,
    icon: <IconTest />,
    name: 'Тесты',
  },
  {
    path: LOGOUT_PATH,
    component: () => <LogOut />,
    icon: <IconExit />,
    hideOnMobile: true,
    name: 'Выйти',
  },
  {
    path: '/t/:token',
    component: () => <AccesCheckerProxy />,
  },
];

export const singleTestRoutes: Route[] = [
  {
    path: '/tests/:id/questionnaire',
    component: SurveyQuestionnaire,
  },
  {
    path: '/tests/:id/instruction',
    component: SurveyInstruction,
  },
  {
    path: '/tests/:id/fillup',
    component: SurveyFillup,
  },
];

export const groupTestRoutes: Route[] = [
  {
    path: '/tests/:id/hub',
    component: MultiSurveyHub,
    exact: true,
  },
  {
    path: '/tests/:id/q/:questionnaireId',
    component: MultySurveyQuestionnaire,
    exact: true,
  },
  {
    path: '/tests/:id/sub/:subSurveyId/instruction',
    component: SubSurveyInstruction,
    exact: true,
  },
  {
    path: '/tests/:id/sub/:subSurveyId/fillup',
    component: SubSurveyFillup,
    exact: true,
  },
];
