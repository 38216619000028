import styled, { css, FlattenInterpolation, ThemeProps, DefaultTheme } from 'styled-components';
import { mediaQuery } from 'utils/themeUtils';
import blueTabletTemplateBg from 'assets/images/banner-tablet-bg-blue-template.svg';
import blue2TabletTemplateBg from 'assets/images/banner-tablet-bg-blue2-template.svg';
import darkBlueTabletTemplateBg from 'assets/images/banner-tablet-bg-darkblue-template.svg';
import pinkTabletTemplateBg from 'assets/images/banner-tablet-bg-pink-template.svg';
import orangeTabletTemplateBg from 'assets/images/banner-tablet-bg-orange-template.svg';
import whiteTabletTemplateBg from 'assets/images/banner-tablet-bg-white-template.svg';
import blueMobileTemplateBg from 'assets/images/banner-mobile-bg-blue-template.svg';
import blue2MobileTemplateBg from 'assets/images/banner-mobile-bg-blue2-template.svg';
import darkBlueMobileTemplateBg from 'assets/images/banner-mobile-bg-darkblue-template.svg';
import pinkMobileTemplateBg from 'assets/images/banner-mobile-bg-pink-template.svg';
import orangeMobileTemplateBg from 'assets/images/banner-mobile-bg-orange-template.svg';
import whiteMobileTemplateBg from 'assets/images/banner-mobile-bg-white-template.svg';
import { BannerTemplateEnum } from 'services/api';

const templateMap: Record<BannerTemplateEnum, FlattenInterpolation<ThemeProps<DefaultTheme>>> = {
  [BannerTemplateEnum.Blue1]: css`
    color: #004379;
    background-color: #86d0f5;
    background-image: url(${blueTabletTemplateBg});

    ${mediaQuery('xs')} {
      background-image: url(${blueMobileTemplateBg});
    }
  `,
  [BannerTemplateEnum.Pink]: css`
    color: #ffffff;
    background-color: #ff88ad;
    background-image: url(${pinkTabletTemplateBg});

    ${mediaQuery('xs')} {
      background-image: url(${pinkMobileTemplateBg});
    }
  `,
  [BannerTemplateEnum.Orange]: css`
    color: #f6004a;
    background-color: #ffb78e;
    background-image: url(${orangeTabletTemplateBg});

    ${mediaQuery('xs')} {
      background-image: url(${orangeMobileTemplateBg});
    }
  `,
  [BannerTemplateEnum.Blue2]: css`
    color: #004379;
    background-color: #86d0f5;
    background-image: url(${blue2TabletTemplateBg});

    ${mediaQuery('xs')} {
      background-image: url(${blue2MobileTemplateBg});
    }
  `,
  [BannerTemplateEnum.DarkBlue]: css`
    color: #ffffff;
    background-color: #004379;
    background-image: url(${darkBlueTabletTemplateBg});

    ${mediaQuery('xs')} {
      background-image: url(${darkBlueMobileTemplateBg});
    }
  `,
  [BannerTemplateEnum.White]: css`
    color: #004379;
    background-color: #ffffff;
    background-image: url(${whiteTabletTemplateBg});

    ${mediaQuery('xs')} {
      background-image: url(${whiteMobileTemplateBg});
    }
  `,
};

export const RootWrapper = styled.div<{ variant?: BannerTemplateEnum }>`
  width: 100%;
  height: 198px;
  display: inline-block;
  background-repeat: no-repeat;
  border-radius: 8px;
  padding: 16px;

  background-position: right bottom;
  padding-right: 30%;

  ${mediaQuery('xs')} {
    height: 160px;
    background-position: right bottom;
    padding-right: 16px;
  }

  ${({ variant = BannerTemplateEnum.Blue1 }) => templateMap[variant]}
`;

export const Title = styled.h6`
  color: inherit;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;

  ${mediaQuery('xs')} {
    font-size: 14px;
  }
`;

export const Text = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;

  background-color: #ffffff75;
  border-radius: 4px;
  padding: 4px;

  ${mediaQuery('xs')} {
    font-size: 10px;
  }
`;
