import styled from 'styled-components';
import { mediaScreen } from '../../utils/themeUtils';

export const FormRow = styled.div`
  &:not(:last-child) {
    margin-bottom: 32px;
  }
`;

export const BtnRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  button {
    width: 100%;

    &:first-child {
      margin-right: 32px;

      ${mediaScreen('sm')} {
        margin: 0;
        margin-top: 16px; /* because of column-reverse */
      }
    }
  }

  ${mediaScreen('sm')} {
    flex-direction: column-reverse;
  }
`;
