import React, { useCallback, useMemo } from 'react';
import { Poll } from 'services';
import { EditableButton, EditableRow } from './styled';
import { TableMainProps } from '../../TableMain/types';
import { CardContainer, CardEntry, CardItemList, CardText, CardTitle } from '../../Card';

type Props<T> = Pick<
  TableMainProps<T>,
  | 'columns'
  | 'isPreviewMode'
  | 'isEditingCard'
  | 'onClickEditCard'
  | 'onClickDeleteCard'
  | 'onRowClick'
  | 'rowClickTitle'
> & {
  data: T;
};

export function Card<T extends Partial<Poll>>({
  columns,
  data,
  isPreviewMode,
  isEditingCard,
  onClickEditCard,
  onClickDeleteCard,
  onRowClick,
  rowClickTitle,
}: Props<T>) {
  const handleDelete = useCallback(() => {
    if (onClickDeleteCard && data.id) onClickDeleteCard(data.id);
  }, [data.id, onClickDeleteCard]);

  const handleEdit = useCallback(() => {
    if (onClickEditCard && data.id) onClickEditCard(data.id);
  }, [data.id, onClickEditCard]);

  const handleRowClick = useCallback(() => {
    if (onRowClick && data.id) onRowClick(data.id);
  }, [onRowClick, data.id]);

  const isPollDone = useMemo(() => !data.endTime, [data]);

  const pollsList = useMemo(() => {
    return columns.map((col) => {
      if (col.key === 'editColumn') return;
      let valueJSX: React.ReactText | JSX.Element | undefined | false;
      const dataKey = col.key as keyof typeof data;

      if (!data[dataKey] && col.key !== 'downloadReport') {
        valueJSX = '---';
      } else {
        valueJSX = col.render
          ? col.render(data[dataKey] ?? data[col.dataIndex as keyof typeof data], data)
          : String(data[dataKey]);
      }

      return (
        <CardEntry key={col.key}>
          <CardTitle>{col.title}</CardTitle>
          <CardText>{valueJSX}</CardText>
        </CardEntry>
      );
    });
  }, [columns, data]);

  return (
    <CardContainer>
      <CardItemList>{pollsList}</CardItemList>

      {onRowClick && isPollDone && (
        <div>
          <EditableButton onClick={handleRowClick} color="primary">
            {rowClickTitle}
          </EditableButton>
        </div>
      )}

      {!isPreviewMode && isEditingCard && (
        <EditableRow>
          <EditableButton onClick={handleEdit} color="primary">
            Редактировать
          </EditableButton>
          <EditableButton role="button" onClick={handleDelete} color="danger">
            Удалить
          </EditableButton>
        </EditableRow>
      )}
    </CardContainer>
  );
}
