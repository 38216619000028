import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';

import { observer } from 'mobx-react-lite';
import { Modal } from '../index';
import { Button, Input, Select } from '../../components';
import { BtnRow, FormRow } from './styled';
import { ClientConsultant } from '../../services';
import { ModalPhotoProps, Poll } from './types';

const ModalEditStaff: React.FC<ModalPhotoProps> = observer((props) => {
  const { showModal, closeModal, title, editableStaff, pollsData, onChangeStaff } = props;
  const [currentStaffPolls, setCurrentStaffPolls] = useState<Poll[]>([]);

  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { register, handleSubmit, reset } = useForm({});

  const preparePolls = useCallback((data: ClientConsultant['polls']) => {
    return data.map((item) => {
      return {
        value: item.parentId,
        label: item.survey,
      };
    });
  }, []);

  useEffect(() => {
    if (editableStaff.polls) setCurrentStaffPolls(preparePolls(editableStaff.polls));
  }, [editableStaff.polls]);

  const onClearForm = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      event.preventDefault();
      reset();
      closeModal();
    },
    [closeModal, reset],
  );

  const pollsSelectOptions = useMemo(
    () =>
      pollsData.map((poll) => {
        return { value: poll.id, label: poll.survey };
      }),
    [pollsData],
  );

  const onSubmit = useCallback(
    (content) => {
      onChangeStaff({ ...content, polls: currentStaffPolls }, Number(editableStaff.id));
      setCurrentStaffPolls([]);
      reset();
      closeModal();
    },
    [currentStaffPolls, onChangeStaff, closeModal, editableStaff.id],
  );

  const onPollsChange = useCallback((data) => setCurrentStaffPolls(data), []);

  return (
    <Modal showModal={showModal} handleCloseModal={closeModal} title={title}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormRow>
          <Input
            label="ФИО"
            placeholder="ФИО"
            defaultValue={editableStaff.fullName}
            type="text"
            name="fullName"
            ref={register()}
          />
        </FormRow>
        <FormRow>
          <Input
            label="Телефон"
            placeholder="Телефон"
            defaultValue={editableStaff.phone}
            type="text"
            name="phone"
            ref={register()}
          />
        </FormRow>
        <FormRow>
          <Select
            label="Выберите методику"
            placeholder="Выберите методику"
            value={currentStaffPolls}
            options={pollsSelectOptions}
            isMulti
            name="polls"
            onChange={onPollsChange}
            menuPosition="fixed"
          />
        </FormRow>
        <BtnRow>
          <Button view="secondary" onClick={onClearForm}>
            Отменить
          </Button>
          <Button type="submit">Сохранить</Button>
        </BtnRow>
      </form>
    </Modal>
  );
});

export default ModalEditStaff;
