import React, { FC } from 'react';
import styled from 'styled-components';
import { BodyBig } from '../components/Typography';

export interface Modalprops {
  error: Error | null;
}

const ErrorModal: FC<Modalprops> = ({ error }) => {
  return (
    <Wrapper className={error ? 'active' : ''}>
      <ModalContent onClick={(e) => e.stopPropagation()}>
        <ModalTitle>Произошла ошибка</ModalTitle>
        <BodyBig>{error?.message}</BodyBig>
      </ModalContent>
    </Wrapper>
  );
};

export default ErrorModal;

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  align-items: center;
  justify-content: center;
  background-color: rgba(20, 21, 22, 0.5);
  z-index: ${({ theme }) => theme.zindex.menu};

  display: none;

  &.active {
    display: flex;
  }
`;

const ModalContent = styled.div`
  position: relative;
  max-width: 624px;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.bg.white};
  border-radius: 32px;
  z-index: 9999;
  padding: 56px 90px 96px 90px;
`;

const ModalTitle = styled.p`
  font-size: 48px;
  font-weight: 400;
  line-height: 56px;
  color: ${({ theme }) => theme.colors.elements.warning};
  margin-bottom: 48px;
`;
