import React, { ReactChildren, useMemo } from 'react';
import Table from 'rc-table';
import CSS from 'csstype';
import styled from 'styled-components';
import TestExpandIcon from '../TestExpandIcon';
import { BodyRow, EditableBodyRow, HeaderRow, TableMainWrapper } from './styled';
import { TableMainProps } from './types';
import { CardsSm } from '../Cards/CardsSm/CardsSm';
import { mediaQuery } from '../../utils/themeUtils';

interface IRowProps {
  children: ReactChildren;
  className: string;
  'data-row-key': number;
  onClick: (event: MouseEvent) => void;
  style: CSS.Properties;
}

const CardViewWrapper = styled.div`
  display: none;
  ${mediaQuery('md')} {
    display: block;
  }
`;

const TableViewWrapper = styled.div`
  ${mediaQuery('md')} {
    display: none;
  }
`;

export function TableMain<T>({
  columns,
  data,
  expandedRow = undefined,
  onRowClick,
  withEditColumn,
  isPreviewMode,
  isEditingCard,
  onClickEditCard,
  onClickDeleteCard,
  hideCardSm, // dont show mobile version table
  rowClickTitle,
  rowKey = 'id',
}: TableMainProps<T>) {
  const components = useMemo(
    () => ({
      header: {
        row: HeaderRow,
      },

      body: {
        row: withEditColumn
          ? EditableBodyRow
          : (props: IRowProps) => {
              return (
                <BodyRow
                  {...props}
                  pointer={!!onRowClick}
                  onClick={() => {
                    if (onRowClick) {
                      onRowClick(props['data-row-key']);
                    }
                  }}
                />
              );
            },
      },
    }),
    [withEditColumn, onRowClick],
  );

  return (
    <TableMainWrapper>
      {!hideCardSm && (
        <CardViewWrapper>
          <CardsSm
            columns={columns}
            data={data}
            isPreviewMode={isPreviewMode}
            isEditingCard={isEditingCard}
            onClickEditCard={onClickEditCard}
            onClickDeleteCard={onClickDeleteCard}
            onRowClick={onRowClick}
            rowClickTitle={rowClickTitle}
          />
        </CardViewWrapper>
      )}
      <TableViewWrapper>
        <Table
          columns={columns}
          data={data}
          components={components}
          rowKey={rowKey}
          expandable={
            expandedRow
              ? {
                  expandRowByClick: false,
                  expandIcon: (props) => <TestExpandIcon {...props} />,
                  expandIconColumnIndex: 1,
                  expandedRowRender: (props) => expandedRow(props),
                }
              : undefined
          }
        />
      </TableViewWrapper>
    </TableMainWrapper>
  );
}
