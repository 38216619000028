import React, { FC } from 'react';
import { Container, Content, Navbar } from './styles';
import { Navigation } from '../../features';
import { PageProps } from './types';

export const Page: FC<PageProps> = ({ defaultMenuClose, children }) => {
  return (
    <Container>
      <Navbar>
        <Navigation defaultClose={defaultMenuClose} />
      </Navbar>
      <Content>{children}</Content>
    </Container>
  );
};
