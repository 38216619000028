import React from 'react';

const Users = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="10" cy="7" r="3.5" stroke="currentColor" />
    <path d="M3 21V16.7143C3 14.5714 4.4 11 10 11C15.6 11 17 14.5714 17 16.7143V21" stroke="currentColor" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.9986 9.45416C15.8859 9.11599 16.5163 8.25716 16.5163 7.25111C16.5163 6.24506 15.8859 5.38624 14.9986 5.04806V4.00003C16.4465 4.37266 17.5163 5.68695 17.5163 7.25111C17.5163 8.54307 16.7864 9.66456 15.7165 10.2257C17.3274 10.4848 18.4635 11.1487 19.2298 11.9959C20.2108 13.0803 20.5339 14.4076 20.5339 15.4034V19H19.5339V15.4034C19.5339 14.6009 19.2696 13.5305 18.4882 12.6667C17.8206 11.9287 16.7356 11.2976 14.9986 11.1437V9.45416ZM14.1666 3.89429C14.1642 3.89429 14.1619 3.89429 14.1595 3.89429C14.1571 3.89429 14.1547 3.89429 14.1523 3.89429H14.1666Z"
      fill="currentColor"
    />
  </svg>
);
export default Users;
