import React, { ComponentType, VFC } from 'react';
import { PageBanner } from './PageBanner';
import { SideBarMenuBanner } from './SideBarMenuBanner';
import { UnauthorizedPageBanner } from './UnauthorizedPageBanner';
import { BannerProps, GenericBannersProps } from './types';
import { useBannersContext } from './context';

const componentMap: Record<BannerProps['type'], ComponentType<GenericBannersProps>> = {
  page: PageBanner,
  sidebar: SideBarMenuBanner,
  unauthorizedPage: UnauthorizedPageBanner,
};

export const Banner: VFC<BannerProps> = (props) => {
  const { type } = props;
  const { banners } = useBannersContext();

  const Component = componentMap[type];

  return <Component banners={banners} />;
};
