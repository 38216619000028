import styled from 'styled-components';
import { mediaQuery } from '../../utils/themeUtils';
import { IconRightChevron } from '../Icons';
import { InputErrorProps } from './types';

export const WrapperForm = styled.form`
  padding-left: 35px;
  display: flex;
  flex-direction: column;
  ${mediaQuery('sm')} {
    padding: 0;
  }
`;
export const ControlItem = styled.label`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 450px;
  white-space: nowrap;
  cursor: pointer;
  margin: 24px 0 16px 0;
  ${mediaQuery('sm')} {
    margin-top: 16px;
    white-space: normal;
  }

  button {
    margin-left: 32px;
  }
`;
export const ControlRow = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
  ${mediaQuery('sm')} {
    margin-top: 8px;
    flex-wrap: wrap;
    row-gap: 8px;
  }
`;
export const SelectWrap = styled.div`
  width: 100%;
  max-width: 348px;
  margin-right: 32px;
  .Select__control.Select__control--menu-is-open + div {
    z-index: 2;
  }
`;
export const CreatableSelectWrap = styled(SelectWrap)`
  width: 100%;
  max-width: 763px;
  margin-bottom: 32px;
  margin-right: 0;
  ${mediaQuery('sm')} {
    max-width: none;
  }
`;
export const CopyLink = styled.button`
  border: none;
  outline: none;
  background: transparent;
  cursor: ${({ disabled }) => (disabled ? 'wait' : 'pointer')};
  display: flex;
  align-items: center;
  color: ${({ theme, disabled }) => (disabled ? theme.colors.font.disable : theme.colors.font.blue)};
  font-size: 16px;
  margin: 0 20px 20px 0;
  transition: opacity 0.3s linear;

  &:hover {
    opacity: ${({ disabled }) => (disabled ? 1 : 0.5)};
  }

  svg {
    margin-right: 12px;
  }
  ${mediaQuery('sm')} {
    margin: 0;
  }
`;

export const EmailPreview = styled.span`
  font-size: 14px;
  border-radius: 24px;
  background-color: ${(props) => props.theme.colors.elements.blueDefault};
  color: ${(props) => props.theme.colors.font.white};
  padding: 4px 12px;
  margin: 0 8px 8px 0;
`;

export const InputError = styled.p<InputErrorProps>`
  margin: ${({ isUnderField }) => (isUnderField ? '5px 0 0' : '0 0 0 10px')};
  color: ${({ theme }) => theme.colors.elements.warning};
`;

export const EmailsCounter = styled.span`
  font-size: 32px;
  color: ${({ theme }) => theme.colors.font.secondary};
`;

export const StyledIconChevron = styled(IconRightChevron)`
  margin-left: 10px;
`;

export const EmailPreviewsWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  max-height: 65px;
  align-items: flex-start;
`;
