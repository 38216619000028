import React from 'react';

const RightArrow = () => {
  return (
    <svg width="18" height="8" viewBox="0 0 18 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 4H16.5M16.5 4L13 0.5M16.5 4L13 7.5" stroke="#004379" />
    </svg>
  );
};

export default RightArrow;
