import React, { FC } from 'react';
import styled from 'styled-components';
import { Link, Space } from '.';
import { Header } from './Typography';
import { getColor, mediaQuery } from '../utils/themeUtils';
import RightChevron from './Icons/RightChevron';

interface TableHeaderProps {
  title: string;
  count?: number;
  showLink?: boolean;
  goToHref: string;
  isPreviewMode?: boolean;
}

const Wrapper = styled.div`
  margin-bottom: 32px;

  ${mediaQuery('sm')} {
    margin-bottom: 0;
    ${Link} {
      display: block;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
    }
  }
`;

const TitleText = styled.span`
  font-size: 32px;
  ${mediaQuery('sm')} {
    font-size: 20px;
    line-height: 48px;
  }
`;

const CountText = styled.span`
  ${mediaQuery('sm')} {
    font-size: 20px;
    line-height: 32px;
    color: ${getColor(({ font }) => font.secondary)};
  }
`;

const IconWrapper = styled.div`
  display: none;
  ${mediaQuery('sm')} {
    display: inline;
    padding-left: 8px;
    svg {
      width: 12px;
      height: 12px;
    }
  }
`;

const TableHeader: FC<TableHeaderProps> = ({ title, count, showLink, goToHref, isPreviewMode }) => {
  return (
    <Wrapper>
      <Space justify="space-between" align="center">
        <Header variant={isPreviewMode ? 'h2' : 'h1'} color="blue">
          <TitleText>{title}</TitleText> <CountText>{count}</CountText>
        </Header>
        {showLink && (
          <Link to={goToHref}>
            Посмотреть всех{' '}
            <IconWrapper>
              <RightChevron />
            </IconWrapper>
          </Link>
        )}
      </Space>
    </Wrapper>
  );
};

export default TableHeader;
