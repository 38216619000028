import React from 'react';
import ReactDOM from 'react-dom';
import 'reset-css';
import './assets/index.css';
import './assets/fonts/Roboto/stylesheet.css';
import './assets/fonts/MuseoSansCyrl/stylesheet.css';
import { ThemeProvider } from 'styled-components';
import { BannersServiceProvider } from 'features/Banners/context';
import { defaultTheme } from './theme';
import { AuthStateProvider } from './features/Auth';
import App from './App';
import { autoBindCSRFtoGlobalAxios } from './utils/autoBindCSRFtoGlobalAxios';
import { NotificationsProvider } from './features/Notifications';
import { ProjectSettingsProvider } from './features/ProjectSettings';

autoBindCSRFtoGlobalAxios();

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={defaultTheme}>
      <NotificationsProvider>
        <AuthStateProvider>
          <ProjectSettingsProvider>
            <BannersServiceProvider>
              <App />
            </BannersServiceProvider>
          </ProjectSettingsProvider>
        </AuthStateProvider>
      </NotificationsProvider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);
