import React from 'react';

const Colleague = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="10.5" y="12.5" width="3" height="2" rx="0.5" stroke="currentColor" />
    <path
      d="M10.2886 13.4531C10.5388 13.5699 10.8363 13.4617 10.9531 13.2114C11.0699 12.9612 10.9617 12.6637 10.7114 12.5469L10.2886 13.4531ZM3 7V6.5C2.72386 6.5 2.5 6.72386 2.5 7H3ZM21 7H21.5C21.5 6.72386 21.2761 6.5 21 6.5V7ZM13.2886 12.5469C13.0383 12.6637 12.9301 12.9612 13.0469 13.2114C13.1637 13.4617 13.4612 13.5699 13.7114 13.4531L13.2886 12.5469ZM20.4229 9.76932L20.6343 10.2224L20.4229 9.76932ZM3.57712 9.76932L3.36567 10.2224L3.57712 9.76932ZM10.7114 12.5469L3.78856 9.31623L3.36567 10.2224L10.2886 13.4531L10.7114 12.5469ZM3.5 8.86314V7H2.5V8.86314H3.5ZM3 7.5H21V6.5H3V7.5ZM20.5 7V8.86314H21.5V7H20.5ZM20.2114 9.31623L13.2886 12.5469L13.7114 13.4531L20.6343 10.2224L20.2114 9.31623ZM20.5 8.86314C20.5 9.0574 20.3875 9.23408 20.2114 9.31623L20.6343 10.2224C21.1624 9.97596 21.5 9.44592 21.5 8.86314H20.5ZM3.78856 9.31623C3.61252 9.23408 3.5 9.0574 3.5 8.86314H2.5C2.5 9.44592 2.83756 9.97596 3.36567 10.2224L3.78856 9.31623Z"
      fill="currentColor"
    />
    <path d="M4 10V17.5C4 18.0523 4.44772 18.5 5 18.5H19C19.5523 18.5 20 18.0523 20 17.5V10" stroke="currentColor" />
    <path d="M9.5 7V6C9.5 5.44772 9.94772 5 10.5 5H13.5C14.0523 5 14.5 5.44772 14.5 6V7" stroke="currentColor" />
  </svg>
);

export default Colleague;
