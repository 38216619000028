import React, { FC } from 'react';
import { IconClose } from '../../components/Icons';
import SanitazedHTML from '../../features/SanitazedHTML';
import { ModalClose, ModalContent, ModalScrollableWrap, ModalTitle, Wrapper } from './styled';

interface ModalPrivacyPolicyProps {
  policyHTML?: string;
  showModal: boolean;
  handleCloseModal: () => void;
}

const ModalPrivacyPolicy: FC<ModalPrivacyPolicyProps> = ({ showModal, handleCloseModal, policyHTML, children }) => {
  return (
    <Wrapper onClick={handleCloseModal} className={showModal ? 'active' : ''}>
      <ModalContent onClick={(e) => e.stopPropagation()}>
        <ModalTitle>Политика обработки персональных данных</ModalTitle>

        <ModalClose type="button" onClick={handleCloseModal}>
          <IconClose color="secondary" />
        </ModalClose>

        {children}
        <ModalScrollableWrap>{policyHTML && <SanitazedHTML htmlString={policyHTML} />}</ModalScrollableWrap>
      </ModalContent>
    </Wrapper>
  );
};

export default ModalPrivacyPolicy;
