import React from 'react';
import { Props } from 'react-select';
import AsyncSelect from 'react-select/async';
import styled from 'styled-components';
import { mediaQuery } from '../utils/themeUtils';

type ReactSelectProps = Omit<Props, 'value'>;

interface OptionType {
  label: string;
  value?: number;
}

interface SearchSelectProps extends ReactSelectProps {
  className?: string;
  selectedSearchOption: OptionType | null;
  handleSearchSelectChange: () => void;
  loadSearchSelectOption: () => void;
}

const ReactSelect: React.FC<SearchSelectProps> = ({
  className,
  selectedSearchOption,
  handleSearchSelectChange,
  loadSearchSelectOption,
  ...restProps
}) => (
  <AsyncSelect
    className={className}
    classNamePrefix="Select"
    isSearchable={true}
    isFocused={true}
    placeholder="Поиск.."
    value={selectedSearchOption}
    onChange={handleSearchSelectChange}
    loadOptions={loadSearchSelectOption}
    isClearable={true}
    {...restProps}
  />
);

const StyledSearchSelect = styled(ReactSelect)`
  flex-grow: 1;

  .Select {
    &__control {
      border-radius: ${(props) => props.theme.borderRadius};
      min-height: 56px;
      ${mediaQuery('sm')} {
        min-height: 35px;
      }

      &--is-focused {
        border-color: ${(props) => props.theme.colors.elements.blueDefault};
      }
    }

    &__value-container {
      padding: 2px 16px;
    }

    &__menu {
      border-radius: 24px;
      margin-top: 16px;
      margin-bottom: 16px;
    }

    &__menu-list {
      padding-top: 20px;
      padding-bottom: 20px;
    }

    &__option {
      padding: 12px 32px;
    }
  }
`;

export default StyledSearchSelect;
