import React, { FC } from 'react';
import styled from 'styled-components';

const ScrollWrapper = styled.div<{ bottomPadded?: number }>`
  overflow-x: auto;
  overflow-y: hidden;
  width: 100%;
  ${(props) =>
    props.bottomPadded
      ? `padding-bottom: ${props.bottomPadded}px;
  margin-bottom: -${props.bottomPadded}px;`
      : ''}

  & .Select__menu {
    & .Select__menu-list {
      max-height: 190px;
    }
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  //min-width: fit-content;
  min-width: 0;
  width: 100%;
`;

const HorisontalScrollWrapper: FC<{ children: React.ReactNode; bottomPadded?: number }> = ({
  children,
  bottomPadded,
}) => (
  <ScrollWrapper bottomPadded={bottomPadded}>
    <Wrapper>{children}</Wrapper>
  </ScrollWrapper>
);

export default HorisontalScrollWrapper;
