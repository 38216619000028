import React, { FC } from 'react';
import styled, { css, DefaultTheme } from 'styled-components';
import { Tooltip } from 'antd';
import { IconInfo } from './Icons';
import { InfinityIcon } from '.';
import { defaultTheme } from '../theme';

type ProgressLineChartProps = {
  max: number;
  value: number;
  label?: string;
  color: keyof DefaultTheme['colors']['tests'];
  alignSelfStart?: boolean;
  unlimited?: boolean;
  showInfoTooltip?: boolean;
  tooltipText?: string;
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const ProgressRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Label = styled.span`
  min-width: 20%;
  color: ${({ theme }) => theme.colors.font.secondary};
  flex-shrink: 0;
  margin-right: 12px;
`;

const Progress = styled.div<{ color: keyof DefaultTheme['colors']['tests']; max: number; value: number }>`
  width: 100%;
  height: 8px;
  border: none;
  margin: calc(1em - 8px) 0;
  background-color: ${(props) => props.theme.colors.elements.divider};
  border-radius: 4px;
  overflow: hidden;

  > div {
    background: ${({ color, theme }) => theme.colors.tests[color]};
    border-radius: 4px;
    width: ${({ max, value }) => ((value / max) * 100).toFixed(2)}%;
    height: 100%;
  }
`;

const ProgressValues = styled.div<Pick<ProgressLineChartProps, 'alignSelfStart'>>`
  display: flex;
  align-items: center;
  align-self: flex-end;

  color: ${({ theme }) => theme.colors.font.secondary};

  span {
    color: ${({ theme }) => theme.colors.font.main};
  }

  svg {
    margin-left: 6px;
  }

  ${(props) =>
    props.alignSelfStart &&
    css`
      align-self: flex-start;
      padding-left: 12px;
    `}
`;

const HoverInfo = styled.div`
  position: absolute;
  z-index: ${({ theme }) => theme.zindex.tooltip};
  display: flex;
  align-items: center;
  justify-content: center;
  top: 100%;
  right: 0;
  width: 155px;
  height: 32px;
  font-weight: 300;
  font-size: 12px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.elements.darkBlue};
  color: ${({ theme }) => theme.colors.font.white};
  padding: 8px;
  margin-top: 4px;
`;

const IconWrap = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  &:not(:hover) ${HoverInfo} {
    display: none;
  }
`;

const Value = styled.span`
  white-space: nowrap;
`;

const ProgressLineChart: FC<ProgressLineChartProps> = ({
  label,
  color,
  value,
  max,
  alignSelfStart,
  unlimited,
  showInfoTooltip,
  tooltipText,
}) => {
  return (
    <Wrapper>
      <ProgressRow>
        {label && <Label>{label}</Label>}
        <Progress color={color} value={Math.min(value, max)} max={max === 0 || unlimited ? Infinity : max}>
          <div />
        </Progress>
      </ProgressRow>

      <ProgressValues alignSelfStart={alignSelfStart}>
        <Value>{value}</Value>/<Value>{unlimited ? <InfinityIcon /> : max}</Value>
        {showInfoTooltip && (
          <Tooltip placement="top" title={tooltipText} color={defaultTheme.colors.elements.darkBlue}>
            <IconWrap>
              <IconInfo />
            </IconWrap>
          </Tooltip>
        )}
      </ProgressValues>
    </Wrapper>
  );
};

export default ProgressLineChart;
