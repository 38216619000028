import React, { forwardRef } from 'react';
import styled, { css } from 'styled-components';
import checkedImg from '../assets/images/checked.png';

interface CheckboxProps {
  className?: string;
  children?: React.ReactNode;
  name?: string;
  checked?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  $error?: boolean;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  id?: string;
}

const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>((props, ref) => {
  const { className, children, id, ...inputProps } = props;

  const handleClick: React.MouseEventHandler<HTMLInputElement> = (e) => {
    if (inputProps.readOnly) e.preventDefault();
  };

  return (
    <label className={className} htmlFor={id}>
      <input id={id} ref={ref} type="checkbox" onClick={handleClick} {...inputProps} />
      {children}
    </label>
  );
});

const StyledCheckbox = styled(Checkbox)`
  display: flex;
  align-items: start;
  color: ${(props) => (props.$error ? props.theme.colors.elements.warning : props.theme.colors.font.secondary)};
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 16px;

  input {
    box-sizing: border-box;
    appearance: none;
    outline: none;
    flex-shrink: 0;

    background-color: ${(props) => props.theme.colors.elements.white};
    border: 1px solid
      ${(props) => (props.$error ? props.theme.colors.elements.warning : props.theme.colors.elements.border)};
    border-radius: 4px;
    width: 24px;
    height: 24px;

    ${({ children }) =>
      children &&
      css`
        margin-right: 12px;
      `}

    ${({ readOnly }) =>
      readOnly &&
      css`
        cursor: auto;
      `}

    transition: border-color 0.2s ease-out;

    &:checked {
      background-color: ${(props) => props.theme.colors.elements.blueDefault};
      border-color: ${(props) => props.theme.colors.elements.blueDefault};

      background-image: url(${checkedImg});
      background-repeat: no-repeat;
      background-position: center center;
    }
  }

  &:hover {
    input {
      border-color: ${(props) => props.theme.colors.elements.blueDefault};
    }
  }
`;

export default StyledCheckbox;
