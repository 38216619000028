import styled from 'styled-components';
import ContentWrapper from './ContentWrapper';

const Page = styled.div`
  display: flex;
  ${ContentWrapper} {
    padding-bottom: 0;
  }
`;

export default Page;
