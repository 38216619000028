import React from 'react';
import styled from 'styled-components';
import { ErrorText, ContentWrapper } from '../components';

const ErrorWrapper = styled(ContentWrapper)`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 48px;
  line-height: 1;
`;

type ErrorBoundaryProps = Record<string, unknown>;

type ErrorBoundaryState = {
  hasError: boolean;
};

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch() {
    this.setState({
      hasError: true,
    });
  }

  render() {
    if (this.state.hasError) {
      return (
        <ErrorWrapper>
          <ErrorText>Что-то пошло не так. Попробуйте обновить страничку или свяжитесь с администратором</ErrorText>
        </ErrorWrapper>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
