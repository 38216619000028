import React, { FC, useState } from 'react';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';

import { observer } from 'mobx-react-lite';
import { AxiosError } from 'axios';
import { Modal } from '.';
import { Button, MessageInfo, InputPassword, ErrorsBlock } from '../components';
import storeProfile from '../servicesProfile';
import { UserPassword } from '../services';
import { useAuthStore } from '../features/Auth';

interface ModalPhotoProps {
  showModal: boolean;
  closeModal: () => void;
  title?: string;
}

interface IUserPassword {
  password: string;
  newPassword: string;
  confirmPassword: string;
}

const FormRow = styled.div`
  &:not(:last-child) {
    margin-bottom: 32px;
  }
`;

const BtnRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  button {
    width: 100%;
  }
`;

const InputError = styled.p`
  margin-bottom: 4px;
  color: ${({ theme }) => theme.colors.elements.warning};

  &.success {
    color: ${({ theme }) => theme.colors.tests.green};
  }
`;

const ModalNewPassword: FC<ModalPhotoProps> = observer(({ showModal, closeModal, title }) => {
  const authStore = useAuthStore();

  const isLoading = storeProfile.fetching;
  const [error, setError] = useState<AxiosError<Record<string, string | string[]>> | null>(null);

  const [finished, setFinished] = useState(false);
  const errorMessages = error?.response?.data;

  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { register, handleSubmit, reset, errors, watch } = useForm<IUserPassword>({});

  const newPasswordValue = watch('newPassword');

  const onSubmit = (data: UserPassword) => {
    const { password, newPassword } = data;

    storeProfile
      .changePassword({ password, newPassword })
      .then(() => {
        reset();
        setFinished(true);
        authStore.logout();
      })
      .catch((err) => setError(err));
  };

  return (
    <Modal showModal={showModal} handleCloseModal={closeModal} title={title}>
      {!isLoading ? (
        <form onSubmit={handleSubmit(onSubmit)}>
          {errorMessages && (
            <FormRow>
              <ErrorsBlock errors={errorMessages} />
            </FormRow>
          )}

          <FormRow>
            {finished && !error && <MessageInfo variant="success" message="Пароль изменён!" />}

            <InputPassword
              name="password"
              placeholder="Старый пароль"
              autoComplete="current-password"
              disabled={isLoading}
              ref={register({ required: true })}
            />
            {errors.password && <InputError>Обязательное поле!</InputError>}
          </FormRow>

          <FormRow>
            <InputPassword
              name="newPassword"
              placeholder="Новый пароль"
              autoComplete="new-password"
              disabled={isLoading}
              ref={register({ required: true, minLength: 8 })}
            />
            {errors.newPassword && <InputError>Пароль должен быть не менее 8 символов!</InputError>}
          </FormRow>

          <FormRow>
            <InputPassword
              name="confirmPassword"
              placeholder="Подтвердите новый пароль"
              autoComplete="new-password"
              disabled={isLoading}
              ref={register({ required: true, validate: { sameAsPassword: (value) => value === newPasswordValue } })}
            />
            {errors.confirmPassword && <InputError>Должен совпадать с новым паролем!</InputError>}
          </FormRow>

          <BtnRow>
            <Button type="submit">Сохранить</Button>
          </BtnRow>
        </form>
      ) : (
        <div>Смена пароля...</div>
      )}
    </Modal>
  );
});

export default ModalNewPassword;
