import React, { FC, useCallback, useMemo, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { Link, LinkProps } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useMediaQuery } from 'hooks/useMediaQuery';
import { IconDown, IconProfile, LeftIcon } from '../components/Icons';
import { useAuthStore } from './Auth';
import { Button, Dropdown } from '../components';
import { ModalContact, ModalNewPassword, ModalNotification, ModalPhoto } from '../modals';
import { LogoutButton } from '../components/LogoutButton/LogoutButton';
import { ButtonPurchase } from '../components/ButtonPurchase/ButtonPurchase';
import { useProjectSettingsStore } from './ProjectSettings';

const Wrapper = styled.div<{ reverse?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: ${(props) => (props.reverse ? 'row-reverse' : 'row')};
  padding: 18px 0;
`;

const ToBackWrap = styled.div`
  cursor: pointer;

  a {
    display: flex;
    align-items: center;
    column-gap: 10px;
    font-size: 16px;
    text-decoration: none;
    color: ${({ theme }) => theme.colors.elements.darkBlue};
    transition: color 0.2s linear;

    &:hover {
      color: ${({ theme }) => theme.colors.elements.darkBluePressed};
    }
  }
`;

const UserInfo = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.elements.darkBlue};
`;

const UserName = styled.div`
  display: flex;
  align-items: center;
  margin-right: 20px;
`;

const Avatar = styled.div`
  width: 52px;
  height: 52px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.bg.white};
  border-radius: 50%;
  overflow: hidden;

  img {
    width: 52px;
    height: 52px;
    object-fit: cover;
  }
`;

const MobileHeaderButtonsContainer = styled.div`
  display: flex;
  column-gap: 10px;

  ${Button} {
    min-width: 32px;
    padding: 0 16px;
  }

  button:first-of-type {
    padding: 8px 20px 4px 20px;
  }
  button:last-of-type {
    font-size: 16px;
    padding-left: 32px;
    padding-right: 32px;
  }
`;

interface PageHeaderProps {
  toBack?: LinkProps['to'];
  withoutPersoneSection?: boolean;
  reverse?: boolean;
}

type ShowModalType = 'avatar' | 'password' | 'feedback' | 'notifications';

const PageHeader: FC<PageHeaderProps> = observer((props) => {
  const { toBack, withoutPersoneSection, reverse } = props;
  const store = useAuthStore();
  const settingsStore = useProjectSettingsStore();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.mediaQuery.sm);

  const fullName = store.profile?.fullName;
  const photo = store.profile?.photo;

  const [showModal, setShowModal] = useState<ShowModalType | null>(null);

  const onCloseModal = () => setShowModal(null);

  const options = useMemo(
    () => [
      { label: 'Изменить изображение', onClick: () => setShowModal('avatar') },
      { label: 'Изменить пароль', onClick: () => setShowModal('password') },
      { label: 'Связаться с администратором', onClick: () => setShowModal('feedback') },
      { label: 'Настроить оповещения', onClick: () => setShowModal('notifications') },
    ],
    [],
  );

  const salesLink = useMemo(() => settingsStore.projectSettings.salesLink, [settingsStore.projectSettings.salesLink]);

  const renderModals = useCallback(() => {
    return !isSmallScreen ? (
      <>
        {showModal === 'avatar' && <ModalPhoto closeModal={onCloseModal} />}
        <ModalNewPassword
          showModal={showModal === 'password'}
          closeModal={onCloseModal}
          title="Придумайте новый пароль"
        />
        <ModalContact
          showModal={showModal === 'feedback'}
          closeModal={onCloseModal}
          title="Связаться с администратором"
        />
        <ModalNotification
          showModal={showModal === 'notifications'}
          closeModal={onCloseModal}
          title="Настройка оповещений"
        />
      </>
    ) : null;
  }, [isSmallScreen, showModal]);

  return (
    <Wrapper reverse={reverse}>
      {toBack && (
        <ToBackWrap>
          <Link to={toBack}>
            <LeftIcon /> Назад
          </Link>
        </ToBackWrap>
      )}

      {isSmallScreen ? (
        <MobileHeaderButtonsContainer>
          {salesLink && <ButtonPurchase to={salesLink} />}
          <LogoutButton />
        </MobileHeaderButtonsContainer>
      ) : (
        !withoutPersoneSection && (
          <Dropdown options={options}>
            <UserInfo>
              <UserName>
                {fullName} <IconDown />
              </UserName>
              <Avatar>{photo ? <img src={photo} alt="Аватар пользователя" /> : <IconProfile />}</Avatar>
            </UserInfo>
          </Dropdown>
        )
      )}

      {renderModals()}
    </Wrapper>
  );
});

export default PageHeader;
