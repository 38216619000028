import React, { FC, useMemo, useState } from 'react';
import styled from 'styled-components';
import { Controller, useForm } from 'react-hook-form';
import { observer } from 'mobx-react-lite';
import { AxiosError } from 'axios';

import { Modal } from '.';
import { Button, ErrorsBlock, MessageInfo, Switch } from '../components';
import { useAuthStore } from '../features/Auth';
import storeProfile from '../servicesProfile';
import { mediaQuery, mediaScreen } from '../utils/themeUtils';

const notificationVariants = {
  notificationPromotionEmail: 'Хочу получать уведомления о тестах на почту',
  notificationPromotionSystem: 'Хочу получать уведомления об акция в системе',
  notificationSaleEmail: 'Хочу получать уведомления об акция в на почту',
  notificationSaleSystem: 'Хочу получать уведомления о скидках в системе',
  notificationTestEmail: 'Хочу получать уведомления о скидках на почту',
};

const ListNotification = styled.ul`
  margin-bottom: 32px;
  ${mediaQuery('sm')} {
    flex-wrap: wrap;
  }
`;

const BtnRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  button {
    width: 100%;
  }
`;

const ControlItem = styled.label`
  display: flex;
  align-items: center;
  justify-content: space-between;
  ///white-space: nowrap;
  cursor: pointer;
  padding: 24px 0 16px 0;

  button {
    margin-left: 32px;
  }

  ${mediaScreen('md')} {
    max-width: 450px;
  }
  ${mediaScreen('lg')} {
    max-width: 450px;
  }

  ${mediaQuery('sm')} {
    max-width: none;
    width: 100%;
  }
`;

interface ModalNotificationProps {
  showModal: boolean;
  closeModal: () => void;
  title?: string;
}

type NotificationsType =
  | 'notificationPromotionEmail'
  | 'notificationPromotionSystem'
  | 'notificationSaleEmail'
  | 'notificationSaleSystem'
  | 'notificationTestEmail';

const ModalNotification: FC<ModalNotificationProps> = observer(({ showModal, closeModal, title }) => {
  const authStore = useAuthStore();
  const profile = authStore.profile;

  const [finished, setFinish] = useState(false);
  const [error, setError] = useState<undefined | AxiosError<Record<string, string[]>>>();
  const errorMessages = (error && error?.response?.data) ?? { error: error?.message ? [error?.message] : [] };

  const defaultValues = useMemo(
    () =>
      Object.keys(notificationVariants).reduce((obj, item) => {
        if (profile) {
          obj[item] = profile[item as NotificationsType] as boolean;
        }
        return obj;
      }, {} as Record<string, boolean>),
    [profile],
  );

  const listSwitches = useMemo(
    () =>
      Object.entries(notificationVariants).map((item) => {
        return { name: item[0], label: item[1] };
      }),
    [],
  );

  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { register, handleSubmit, control } = useForm({ defaultValues });

  const onSubmit = (data: Record<NotificationsType, boolean>) => {
    setFinish(false);
    storeProfile
      .changeProfile(data)
      .then(() => {
        setFinish(true);
      })
      .catch((err) => {
        setFinish(true);
        setError(err);
      });
  };

  return (
    <Modal showModal={showModal} handleCloseModal={closeModal} title={title}>
      <form onSubmit={handleSubmit(onSubmit)}>
        {finished && !error && <MessageInfo variant="success" message="Изменения сохранены!" />}
        <ListNotification>
          {listSwitches.map((item) => (
            <li key={item.label}>
              <ControlItem>
                {item.label}

                <Controller
                  control={control}
                  name={item.name}
                  render={(props) => (
                    <Switch
                      name={item.name}
                      onChange={(e) => props.onChange(e.target.checked)}
                      ref={register()}
                      checked={props.value as boolean}
                    />
                  )}
                />
              </ControlItem>
            </li>
          ))}
        </ListNotification>

        <ErrorsBlock errors={errorMessages} />

        <BtnRow>
          <Button type="submit">Сохранить</Button>
        </BtnRow>
      </form>
    </Modal>
  );
});

export default ModalNotification;
