import React, { FC, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { mediaQuery } from '../utils/themeUtils';
import CheckIcon from './Icons/CheckIcon';

const Wrapper = styled.div<Pick<NotificationProps, 'variant'>>`
  display: flex;
  align-items: center;
  border-radius: 8px;
  font-size: 20px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.font.white};
  padding: 16px;
  ${mediaQuery('sm')} {
    font-size: 16px;
    line-height: 18px;
    padding: 8px;
  }

  svg {
    width: 21px;
    height: 16px;
    margin-right: 18px;
  }

  ${(props) =>
    props.variant === 'success' &&
    css`
      background-color: #6ad187;
    `}

  ${(props) =>
    props.variant === 'error' &&
    css`
      background-color: ${({ theme }) => theme.colors.elements.warning};
    `}
`;

interface NotificationProps {
  message: string;
  variant: 'error' | 'success';
  timeout?: number;
  onHide?: () => void;
}

const MessageInfo: FC<NotificationProps> = ({ message, variant, timeout = 3000, onHide }) => {
  const [isOpen, setIsOpen] = useState(true);

  useEffect(() => {
    if (timeout === Infinity) return;

    const timer = setTimeout(() => {
      onHide?.();
      setIsOpen(false);
    }, timeout);

    return () => clearTimeout(timer);
  }, [onHide, timeout]);

  return (
    <>
      {isOpen ? (
        <Wrapper variant={variant}>
          <CheckIcon color="white" />
          {message}
        </Wrapper>
      ) : null}
    </>
  );
};

export default MessageInfo;
