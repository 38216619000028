import { action, makeObservable, observable } from 'mobx';

import { AxiosError } from 'axios';
import { ProjectSettings, Profile } from '../../services';
import { coreApi } from '../../utils/coreApi';

type NullableError = AxiosError<Record<string, string[] | string>> | null;

type NullableSettings = Profile | null;

export default class ProjectSettingsStore {
  fetching = false;

  error: NullableError = null;
  profile: NullableSettings = null;

  projectSettings: ProjectSettings = {};

  constructor() {
    makeObservable(this, {
      projectSettings: observable,
      getProjectSettings: action,
      setProjectSettings: action,
    });
  }

  getProjectSettings = () => {
    this.setError(null);
    this.setFetching(true);
    coreApi
      .coreSettingsRead()
      .then((res) => this.setProjectSettings(res.data))
      .catch((err) => this.setError(err))
      .finally(() => this.setFetching(false));
  };

  setError(value: NullableError): void {
    this.error = value;
  }

  setFetching(value: boolean): void {
    this.fetching = value;
  }

  setProjectSettings(value: ProjectSettings): void {
    this.projectSettings = value;
  }
}
