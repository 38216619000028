import axios, { AxiosRequestConfig } from 'axios';
import { getCookie } from './functions';

export const autoBindCSRFtoGlobalAxios = () => {
  axios.interceptors.request.use((request: AxiosRequestConfig) => {
    /* eslint-disable  @typescript-eslint/no-unsafe-member-access */
    request.headers['X-CSRFToken'] = getCookie('csrftoken');
    return request;
  });
};
