import React from 'react';
import styled, { DefaultTheme } from 'styled-components';

interface CheckIconProps {
  color?: keyof DefaultTheme['colors']['elements'];
}

const StyledCheckIcon = styled.svg<CheckIconProps>`
  ${(props) => props.color && `fill: ${props.theme.colors.elements[props.color]};`}
`;

const CheckIcon: React.FC<CheckIconProps> = ({ color = 'darkBlue' }) => {
  return (
    <StyledCheckIcon color={color} viewBox="0 0 21 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.7072 1.70718L7.00008 15.4143L0.292969 8.70718L1.70718 7.29297L7.00008 12.5859L19.293 0.292969L20.7072 1.70718Z"
      />
    </StyledCheckIcon>
  );
};

export default CheckIcon;
