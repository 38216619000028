import React from 'react';

const Exit = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 12.5H19M19 12.5L17.5 11M19 12.5L17.5 14" stroke="currentColor" />
    <path
      d="M16 9V5C16 3.89543 15.1046 3 14 3H5C3.89543 3 3 3.89543 3 5V19.5C3 20.6046 3.89543 21.5 5 21.5H14C15.1046 21.5 16 20.6046 16 19.5V16"
      stroke="currentColor"
    />
  </svg>
);

export default Exit;
