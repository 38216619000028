import styled from 'styled-components';
import { mediaScreen } from '../../utils/themeUtils';

export const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  align-items: center;
  justify-content: center;
  background-color: rgba(20, 21, 22, 0.5);
  z-index: ${({ theme }) => theme.zindex.menu};

  display: none;

  &.active {
    display: flex;
  }
`;

export const ModalContent = styled.div`
  position: relative;
  max-height: 88vh;
  padding: 32px;
  background-color: ${({ theme }) => theme.colors.bg.white};
  border-radius: 32px;
  display: flex;
  flex-direction: column;

  ${mediaScreen('sm')} {
    height: 100%;
    width: 100vw;
    margin: 18px 20px 0 20px;
    padding: 20px;
    border-radius: 16px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  ${mediaScreen('md')} {
    max-width: 1000px;
  }
  ${mediaScreen('lg')} {
    max-width: 1248px;
  }
`;

export const ModalTitle = styled.p`
  padding-top: 12px;
  font-size: 32px;
  font-weight: 400;
  line-height: 48px;
  text-align: center;
  color: ${({ theme }) => theme.colors.font.blue};
  margin-bottom: 32px;

  ${mediaScreen('sm')} {
    padding-top: 4px;
    text-align: left;
    line-height: 27px;
    font-size: 20px;
    max-width: 300px;
  }
`;

export const ModalClose = styled.button`
  border: none;
  outline: none;
  background: transparent;
  cursor: pointer;

  position: absolute;
  top: 36px;
  right: 36px;

  svg {
    width: 16px;
    height: 16px;
  }

  ${mediaScreen('sm')} {
    top: 20px;
    right: 20px;
  }
`;

export const ModalScrollableWrap = styled.section`
  overflow-y: auto;
  padding: 0 144px;

  p {
    margin-block-start: 1em;
    margin-block-end: 1em;
    word-break: break-word;
  }

  ${mediaScreen('sm')} {
    padding: 0 0 28px 0;
  }

  div {
    padding-right: 10px;
    ${mediaScreen('sm')} {
      max-height: none;
    }
  }
`;
