import { useMemo, useState } from 'react';
import { ApiError } from '../types/common';

export function useApiErrorMessages(): [Record<string, string[]> | null, (error?: ApiError) => void] {
  const [error, setError] = useState<undefined | ApiError>();
  const errorMessages = useMemo(() => {
    if (!error) return null;
    let messages = error.response?.data;
    if (Array.isArray(messages)) messages = messages.reduce((acc, item) => ({ ...acc, ...item }), {});
    return messages ?? { error: error.message ? [error?.message] : [] };
  }, [error]);

  return [errorMessages, setError];
}
