import React, { useCallback, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import { Form, FormItem, Input, Link, Button, MessageInfo } from '../../components';
import { Header } from '../../components/Typography';
import { useAuthStore } from './store';
import { useRecaptchaLoader } from '../ReCaptcha';
import useQuery from './hooks';
import { mediaScreen } from '../../utils/themeUtils';

type Inputs = {
  email: string;
};

const ButtonsContainer = styled(FormItem)`
  display: flex;
  flex-direction: row-reverse;

  justify-content: space-between;
  align-items: center;

  ${mediaScreen('sm')} {
    flex-direction: column;

    ${Button} {
      width: 100%;
    }
    ${Link} {
      margin-top: 36px;
    }
  }
`;

const RecoveryForm = observer(() => {
  const authStore = useAuthStore();
  const isLoading = authStore.fetching;
  const urlParams = useQuery();
  const defaultEmail = useMemo(() => {
    return urlParams.get('email') || '';
  }, [urlParams]);

  const recaptchaLoader = useRecaptchaLoader();

  const [successMsg, setSuccess] = useState<string | null>(null);
  const [errorMsg, setError] = useState<string | null>(null);

  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { register, handleSubmit, errors } = useForm<Inputs>();

  const onSubmit = useCallback(
    async (data: Inputs) => {
      try {
        setSuccess(null);
        const recaptcha = await recaptchaLoader.executeReCaptcha('submit');

        if (!recaptcha) return;

        await authStore.recoveryPassword({ ...data, recaptcha });
        setSuccess('Спасибо! Ваш запрос отправлен.');
      } catch (error) {
        setError('Пользователь с таким email не найден!');
      }
    },
    [authStore, recaptchaLoader],
  );

  const clearErrMsg = useCallback(() => {
    setError(null);
  }, []);

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      {successMsg && <MessageInfo variant="success" message={successMsg} />}
      <FormItem>
        <Header variant="h1" color="blue">
          Восстановить пароль
        </Header>
      </FormItem>

      <FormItem>
        <Input
          defaultValue={defaultEmail}
          type="email"
          name="email"
          placeholder="Email"
          autoComplete="email"
          $error={Boolean(errors.email)}
          ref={register({ required: true })}
        />
      </FormItem>

      <FormItem>{errorMsg && <MessageInfo variant="error" message={errorMsg} onHide={clearErrMsg} />}</FormItem>

      <ButtonsContainer>
        <Button type="submit" disabled={isLoading}>
          {isLoading ? 'Отправляем письмо...' : 'Отправить'}
        </Button>
        <Link to="/login">Я вспомнил пароль</Link>
      </ButtonsContainer>
    </Form>
  );
});

export default RecoveryForm;
