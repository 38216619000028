import React from 'react';
import styled from 'styled-components';
import { ClockIcon } from '../../../components/Icons';

const Wrapper = styled.p`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.elements.warning};
  font-size: 14px;
  font-weight: 300;

  svg {
    margin-right: 6px;
  }
`;

const TimeLimit = () => {
  return (
    <Wrapper>
      <ClockIcon />
      Превышен временной лимит
    </Wrapper>
  );
};

export default TimeLimit;
