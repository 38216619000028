import styled, { css } from 'styled-components';
import { mediaQuery } from 'utils/themeUtils';
import { QuestionItemDirection } from './types';

export const ItemWrapper = styled.li<{ direction: QuestionItemDirection }>`
  ${(props) =>
    props.direction === 'column' &&
    css`
      display: flex;
      align-items: center;
      justify-content: space-between;
    `}
  ${mediaQuery('sm')} {
    display: grid;
    grid-auto-rows: auto;
    grid-row-gap: 8px;
    background-color: white;
    padding: 21px 16px;
    border-radius: 12px;
  }
`;

export const ItemContent = styled.div`
  margin-right: 32px;

  ${mediaQuery('sm')} {
    width: 100%;
    margin-right: 0;
  }
`;

export const ItemNumber = styled.span`
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.font.secondary};
  margin-bottom: 8px;
`;

export const ItemText = styled.p`
  font-weight: 300;
  font-size: 18px;
  line-height: 28px;
  color: ${({ theme }) => theme.colors.font.header};
  word-break: break-word;
`;
