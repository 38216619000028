import React from 'react';
import styled from 'styled-components';
import { mediaQuery } from '../utils/themeUtils';
import { IconClose, IconFilter } from './Icons';

interface FiltersButtonProps {
  className?: string;
  isOpen: boolean;
  count: number;
  onClick: () => void;
}

const Counter = styled.span`
  position: absolute;
  top: -8px;
  left: -8px;
  background-color: ${(props) => props.theme.colors.bg.extraLightBlue};
  border-radius: 50%;
  width: 24px;
  padding: 4.5px 0;
  color: ${(props) => props.theme.colors.font.blue};
`;

const StyledFiltersButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  outline: none;
  cursor: pointer;
  position: relative;
  background-color: ${(props) => props.theme.colors.elements.darkBlue};
  border-radius: ${(props) => props.theme.borderRadius};
  width: 56px;
  height: 56px;
  color: ${(props) => props.theme.colors.font.white};
  transition: 0.2s ease-out;

  &:hover {
    background-color: ${(props) => props.theme.colors.elements.darkBlueHover};
  }

  &:focus {
    background-color: ${(props) => props.theme.colors.elements.darkBluePressed};
  }

  ${mediaQuery('sm')} {
    width: 32px;
    height: 32px;

    svg {
      width: 18px;
      height: 18px;
    }
  }
`;

const FiltersButton: React.FunctionComponent<FiltersButtonProps> = ({ isOpen, onClick, count }) => {
  return (
    <StyledFiltersButton type="button" onClick={onClick}>
      {!isOpen && count > 0 && <Counter>{count}</Counter>}

      {isOpen ? <IconClose color="white" /> : <IconFilter />}
    </StyledFiltersButton>
  );
};

export default FiltersButton;
