import React from 'react';
import styled, { ThemeConsumer } from 'styled-components';
import { RenderExpandIconProps } from 'rc-table/lib/interface';
import { IconRightChevron } from './Icons';

function TestExpandIcon<T>(props: RenderExpandIconProps<T>) {
  return (
    <ExpandButton expanded={props.expanded} onClick={(event) => props.onExpand(props.record, event)}>
      <ThemeConsumer>{(theme) => <IconRightChevron color={theme.colors.elements.darkBlue} />}</ThemeConsumer>
    </ExpandButton>
  );
}

export default TestExpandIcon;

type ExpandButtonProps = {
  expanded: boolean;
};

const ExpandButton = styled.button<ExpandButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.colors.bg.extraLightBlue};
  border: none;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  cursor: pointer;

  transform: rotate(${({ expanded }) => (expanded ? '-90deg' : '90deg')});

  &:hover {
    background: ${({ theme }) => theme.colors.buttonSecondary.hover};
  }

  transition: 0.2s ease-out;
`;
