import React, { useState } from 'react';
import styled from 'styled-components';
import { CircleChart } from '../../../components';
import { UserStat } from '../../../services';
import { mediaQuery } from '../../../utils/themeUtils';
import { CircleChartWrapper } from '../../../components/CircleChart';
import { H2 } from '../../../components/Typography/Header';
import { ExpandButton } from '../../../components/ExpandButton';

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  ${mediaQuery('md')} {
    width: 100%;
  }
  ${mediaQuery('xs')} {
    flex-direction: column;
    width: 100%;
  }
`;

const StatsBlock = styled.div<{ expanded: boolean }>`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.bg.white};
  border-radius: 24px 0 0 24px;
  padding: 40px 24px 40px 40px;
  ${mediaQuery('sm')} {
    padding: 24px 18px 24px;
    border-radius: 24px 0 0 24px;
  }
  ${mediaQuery('xs')} {
    border-radius: ${(props) => (props.expanded ? '24px 24px 0 0' : '24px')};
  }
`;

const StatsRow = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 26px;
  column-gap: 20px;
  ${mediaQuery('sm')} {
    column-gap: 10px;
  }
  ${mediaQuery('xs')} {
    column-gap: 5px;
  }
  ${CircleChartWrapper} {
    width: 146px;
    min-width: 130px;
    ${mediaQuery('md')} {
      width: 100%;
      max-width: 160px;
      min-width: 115px;
    }
    ${mediaQuery('xs')} {
      max-width: 130px;
      min-width: auto;
    }
  }
`;

const TotalTests = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  min-width: 134px;
  font-size: 56px;
  line-height: 64px;
  background-color: ${({ theme }) => theme.colors.elements.darkBlue};
  border-radius: 0 24px 24px 0;
  color: ${({ theme }) => theme.colors.font.white};
  padding: 24px;

  span {
    font-size: 14px;
    opacity: 0.5;
    margin-top: 24px;
    line-height: 20px;
    ${mediaQuery('md')} {
      margin-top: 0;
    }
    ${mediaQuery('sm')} {
      font-size: 14px;
      line-height: 35px;
      opacity: 0.5;
      margin-top: 0;
    }
  }

  ${mediaQuery('md')} {
    padding: 40px;
    min-width: 170px;
  }

  ${mediaQuery('sm')} {
    font-size: 30px;
    line-height: 30px;
    padding: 12px 24px 8px;
    min-width: 135px;
  }
  ${mediaQuery('xs')} {
    border-radius: 0 0 24px 24px;
  }
`;

const TogglerTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  button {
    display: none;
    ${mediaQuery('xs')} {
      display: flex;
    }
  }
`;

interface CurrentUserTestsStatsProps {
  data: UserStat | null;
}

const UserTestsStats: React.FC<CurrentUserTestsStatsProps> = ({ data }) => {
  const [showStats, setShowStats] = useState(true);
  const showStatsToggle = () => setShowStats((prevState) => !prevState);

  return (
    <Wrapper>
      <StatsBlock expanded={showStats}>
        <TogglerTitle>
          <H2>Статистика по тестам</H2>
          <ExpandButton onClick={showStatsToggle} expanded={showStats} />
        </TogglerTitle>
        {showStats && (
          <StatsRow>
            <CircleChart
              value={data?.done ?? 0}
              max={data?.total ?? 0}
              size={146}
              stroke={10}
              label="Пройдено"
              color="lightBlue"
            />
            <CircleChart
              value={data?.inProgress ?? 0}
              max={data?.total ?? 0}
              size={146}
              stroke={10}
              label="В процессе"
              color="pink"
            />
            <CircleChart
              value={data?.remaining ?? 0}
              max={data?.total ?? 0}
              size={146}
              stroke={10}
              label="Осталось"
              color="green"
            />
          </StatsRow>
        )}
      </StatsBlock>

      {showStats && (
        <TotalTests>
          {data?.total}
          <span>Тестов всего</span>
        </TotalTests>
      )}
    </Wrapper>
  );
};

export default UserTestsStats;
