import React from 'react';
import styled from 'styled-components';

import selectSVG from '../assets/images/select.svg';
import Input from './Input';
import { mediaQuery } from '../utils/themeUtils';

const Wrapper = styled.div`
  display: flex;
  column-gap: 16px;
  ${mediaQuery('sm')} {
    column-gap: 8px;
    margin-top: 16px;
  }
  label {
    position: relative;
    width: 100%;

    input[type='date']::-webkit-calendar-picker-indicator {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: auto;
      height: auto;
      color: transparent;
      background: transparent;
    }

    input[type='date'] {
      background: url(${selectSVG}) no-repeat 90% 50%;
    }
  }
`;

interface DateProps {
  dateInterval?: {
    dateStart: string;
    dateEnd: string;
  };
  dateChangeHandlers?: {
    setDateStart: (dateString: string) => void;
    setDateEnd: (dateString: string) => void;
  };
}

const DateInterval: React.FC<DateProps> = ({ dateInterval, dateChangeHandlers }) => {
  return (
    <Wrapper>
      <Input
        type="date"
        name="dateFrom"
        label="С"
        variant="small"
        value={dateInterval?.dateStart}
        onChange={(e) => dateChangeHandlers?.setDateStart(e.target.value)}
        required
      />
      <Input
        type="date"
        name="dateTo"
        label="До"
        variant="small"
        value={dateInterval?.dateEnd}
        onChange={(e) => dateChangeHandlers?.setDateEnd(e.target.value)}
        required
      />
    </Wrapper>
  );
};

export default DateInterval;
