import { makeAutoObservable } from 'mobx';

import axios, { AxiosError } from 'axios';
import { Profile, CoreApi, UserPassword } from '../services';

type NullableError = AxiosError<Record<string, string[]>> | null;

type ProfileStoreProps = {
  api: CoreApi;
};

export default class ProfileStore {
  fetching = false;
  error: NullableError = null;

  private api: CoreApi;

  constructor({ api }: ProfileStoreProps) {
    this.api = api;

    makeAutoObservable(this);
  }

  setError(value: NullableError): void {
    this.error = value;
  }

  setFetching(value: boolean): void {
    this.fetching = value;
  }

  uploadAvatar(newAvatar: File): Promise<void> {
    this.setError(null);
    this.setFetching(true);

    const form = new FormData();
    form.append('photo', newAvatar);

    return axios
      .post('/api/core/profile/upload_photo/', form, { headers: { 'content-type': 'multipart/form-data' } })
      .then(() => {
        this.setFetching(false);
      }, this.rejecter);
  }

  deleteAvatar(): Promise<void> {
    this.setError(null);
    this.setFetching(true);

    return this.api.coreProfilePhoto().then(() => {
      this.setFetching(false);
    }, this.rejecter);
  }

  changePassword(newPassword: UserPassword): Promise<void> {
    this.setError(null);
    this.setFetching(true);

    return this.api.coreAuthChangePassword({ data: newPassword }).then(() => {
      this.setFetching(false);
    }, this.rejecter);
  }

  private rejecter = (err: NullableError) => {
    this.setFetching(false);
    this.setError(err);
    throw err;
  };

  changeProfile(data: Profile): Promise<void> {
    this.setError(null);
    this.setFetching(true);

    return this.api.coreProfilePartialUpdate({ data }).then(
      () => {
        this.setFetching(false);
      },
      (err) => {
        this.setFetching(false);
        this.setError(err);
      },
    );
  }
}
