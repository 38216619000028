import React from 'react';
import styled, { ThemeConsumer } from 'styled-components';
import { IconRightChevron } from './Icons';

type ExpandButtonProps = {
  expanded: boolean;
  onClick: () => void;
};

export const ExpandButton: React.FC<ExpandButtonProps> = (props) => {
  const { expanded, onClick } = props;

  return (
    <Button expanded={expanded} onClick={onClick}>
      <ThemeConsumer>{(theme) => <IconRightChevron color={theme.colors.elements.darkBlue} />}</ThemeConsumer>
    </Button>
  );
};

const Button = styled.button<ExpandButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.colors.bg.extraLightBlue};
  border: none;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  cursor: pointer;
  flex-shrink: 0;

  transform: rotate(${({ expanded }) => (expanded ? '-90deg' : '90deg')});

  &:hover {
    background: ${({ theme }) => theme.colors.buttonSecondary.hover};
  }

  transition: 0.2s ease-out;
`;
