import React, { forwardRef } from 'react';
import styled, { css } from 'styled-components';

interface SwitchProps {
  className?: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  name: string;
  defaultChecked?: boolean;
  checked: boolean;
}

const Switch = forwardRef<HTMLInputElement, SwitchProps>(
  ({ className, onChange, name, defaultChecked, checked }, ref) => (
    <div className={className}>
      <input
        type="checkbox"
        onChange={onChange}
        name={name}
        id={name}
        ref={ref}
        defaultChecked={defaultChecked}
        checked={checked}
      />
      <Toggle checked={checked} onClick={() => onChange} />
    </div>
  ),
);

const Toggle = styled.span<{ checked: boolean }>`
  cursor: pointer;
  text-indent: -9999px;
  width: 36px;
  height: 14px;
  background: #eaeaea;
  display: block;
  border-radius: 100px;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    width: 20px;
    height: 20px;
    background: #bdbdbd;
    border-radius: 90px;
    transition: 0.3s;
  }
  ${(props) =>
    props.checked &&
    css`
      background: #d6dfe4;
      &:after {
        transform: translate(100%, -50%);
        background-color: ${({ theme }) => theme.colors.elements.blueDefault};
      }
    `}
`;

const StyledSwitch = styled(Switch)`
  input {
    display: none;
  }
`;

export default StyledSwitch;
