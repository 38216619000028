import React, { FC } from 'react';
import styled, { DefaultTheme } from 'styled-components';

interface ProfileProps {
  color?: keyof DefaultTheme['colors']['elements'];
}

const StyledProfile = styled.svg<ProfileProps>`
  ${(props) => props.color && `stroke: ${props.theme.colors.elements[props.color]};`}
`;

const Profile: FC<ProfileProps> = ({ color = 'darkBlue' }) => (
  <StyledProfile
    color={color}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="12" cy="6" r="4.5" />
    <path d="M18 23V20C18 16.6863 15.3137 14 12 14V14C8.68629 14 6 16.6863 6 20V23" />
  </StyledProfile>
);

export default Profile;
