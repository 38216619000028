import React from 'react';

const Select = () => {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx={12} cy={12} r={12} fill="#E9F0FA" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.29297 10.7072L7.70718 9.29297L12.0001 13.5859L16.293 9.29297L17.7072 10.7072L12.0001 16.4143L6.29297 10.7072Z"
        fill="#006EB6"
      />
    </svg>
  );
};

export default Select;
