import styled, { css } from 'styled-components';
import { mediaQuery } from 'utils/themeUtils';
import { QuestionItemDirection } from '../QuestionItem/types';

export const ItemControl = styled.div<{ direction: QuestionItemDirection }>`
  display: flex;
  align-items: center;
  column-gap: 26px;
  ${(props) =>
    props.direction === 'row' &&
    css`
      display: flex;
      align-items: center;
      justify-content: space-between;
      column-gap: 10px;
      ${mediaQuery('sm')} {
        display: flex;
        flex-direction: column;
        row-gap: 8px;
      }
    `}
`;
