import { useCallback, useState } from 'react';
import { Banner } from 'services/api';

export const useBannerAutoplaySpeed = (banners: Banner[]) => {
  const [firstBanner] = banners;
  const [autoplaySpeed, setAutoplaySpeed] = useState(
    firstBanner?.switchingTime ? firstBanner?.switchingTime * 1000 : undefined,
  );

  const handleBefogeChange = useCallback(
    (_: number, nextSlide: number) => {
      const nextBanner = banners[nextSlide];
      if (nextBanner?.switchingTime) setAutoplaySpeed(nextBanner?.switchingTime * 1000);
    },
    [banners],
  );

  return [autoplaySpeed, handleBefogeChange] as const;
};
