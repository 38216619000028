import styled, { css, FlattenSimpleInterpolation } from 'styled-components';
import blueTemplateBg from 'assets/images/banner-170x340-bg-blue-template.svg';
import blue2TemplateBg from 'assets/images/banner-170x340-bg-blue2-template.svg';
import darkBlueTemplateBg from 'assets/images/banner-170x340-bg-darkblue-template.svg';
import pinkTemplateBg from 'assets/images/banner-170x340-bg-pink-template.svg';
import orangeTemplateBg from 'assets/images/banner-170x340-bg-orange-template.svg';
import whiteTemplateBg from 'assets/images/banner-170x340-bg-white-template.svg';
import { BannerTemplateEnum } from 'services/api';

const templateMap: Record<BannerTemplateEnum, FlattenSimpleInterpolation> = {
  [BannerTemplateEnum.Blue1]: css`
    color: #004379;
    background-color: #86d0f5;
    background-image: url(${blueTemplateBg});
    background-position: center bottom;
  `,
  [BannerTemplateEnum.Pink]: css`
    color: #ffffff;
    background-color: #ff88ad;
    background-image: url(${pinkTemplateBg});
    background-position: center bottom;
  `,
  [BannerTemplateEnum.Orange]: css`
    color: #f6004a;
    background-color: #ffb78e;
    background-image: url(${orangeTemplateBg});
    background-position: center bottom;
  `,
  [BannerTemplateEnum.Blue2]: css`
    color: #004379;
    background-color: #86d0f5;
    background-image: url(${blue2TemplateBg});
    background-position: center bottom;
  `,
  [BannerTemplateEnum.DarkBlue]: css`
    color: #ffffff;
    background-color: #004379;
    background-image: url(${darkBlueTemplateBg});
    background-position: center bottom;
  `,
  [BannerTemplateEnum.White]: css`
    color: #004379;
    background-color: #ffffff;
    background-image: url(${whiteTemplateBg});
    background-position: center bottom;
  `,
};

export const RootWrapper = styled.div<{ variant?: BannerTemplateEnum }>`
  width: 170px;
  height: 340px;
  background-repeat: no-repeat;
  border-radius: 8px;
  padding: 16px;
  ${({ variant = BannerTemplateEnum.Blue1 }) => templateMap[variant]}
`;

export const Title = styled.h6`
  color: inherit;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
`;

export const Text = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 11px;

  background-color: #ffffff75;
  border-radius: 4px;
  padding: 4px;
`;
