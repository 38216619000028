import React from 'react';
import Modal from 'react-modal';
import styled from 'styled-components';

interface ModalProps {
  className?: string;
  showModal: boolean;
  handleCloseModal: () => void;
}

const ReactModal: React.FunctionComponent<ModalProps> = ({ className, children, showModal, handleCloseModal }) => (
  <Modal className={className} overlayClassName="overlay" isOpen={showModal} onRequestClose={handleCloseModal}>
    {children}
  </Modal>
);

const StyledModal = styled(ReactModal)`
  left: 50%;
  border: none;
  border-radius: 6px;
  max-width: 320px;
  width: calc(100% - 30px);
  padding: 30px;
  transform: translateX(-50%);

  .overlay {
    background-color: rgba(0, 0, 0, 0.4);
    zindex: 9999;
  }
`;

export default StyledModal;
