import React, { useState } from 'react';
import styled from 'styled-components';
import { Card, DateInterval, ErrorsBlock, Link, ProgressLineChart, SemicircleChart, Space } from '.';
import { BodyBig } from './Typography';
import { IconRightChevron } from './Icons';
import { ErrorMessages } from '../types/common';
import { TestsSwitch } from '../features/Customer/components';
import { mediaQuery } from '../utils/themeUtils';
import { H2 } from './Typography/Header';
import { ExpandButton } from './ExpandButton';

const CardStyled = styled(Card)`
  padding: 32px 48px;
  width: 100%;
  ${mediaQuery('md')} {
    padding: 30px;
  }
  ${mediaQuery('sm')} {
    padding: 20px;
  }
`;

const TestsListWrap = styled.div`
  margin-top: 32px;
`;

const TestItem = styled.div`
  &:not(:last-child) {
    margin-bottom: 30px;
  }
`;

const TestTitle = styled(BodyBig)`
  margin-bottom: 12px;
`;

const SemicircleTestsList = styled(TestsListWrap)`
  display: flex;
  flex-direction: column;

  ${TestItem}:not(:last-child) {
    margin-bottom: 20px;
  }
`;

const StyledSemicircle = styled(SemicircleChart)`
  &:not(:last-child) {
    margin-right: 12px;
  }
  ${mediaQuery('md')} {
    font-size: 14px;
  }
`;

const ProgressRow = styled.div`
  display: flex;
  justify-content: space-around;
  ${mediaQuery('sm')} {
    padding-top: 12px;
    padding-bottom: 12px;
  }
`;

const MoreLink = styled(Link)`
  ${mediaQuery('sm')} {
    font-size: 14px;
  }
`;

const SpoilerButton = styled.div`
  display: none;
  ${mediaQuery('sm')} {
    display: block;
  }
`;

const HeaderMoreLink = styled.div`
  ${mediaQuery('sm')} {
    display: none;
  }
`;

const FooterMoreLink = styled.div`
  display: none;
  ${mediaQuery('sm')} {
    display: block;
  }
`;

const TestsStatsHeading = styled(H2)`
  margin-bottom: 0;
`;

interface IData {
  id?: number;
  inProgress?: number;
  numberOfRegistrations?: number;
  pollsDone?: number;
  registrations?: number;
  registrationsRemaining?: number;
  survey?: string;
  surveyAttempts?: number;
  surveyAttemptsRemaining?: number;
  unlimitedAttempts?: boolean;
  unlimitedRegistrations?: boolean;
}

interface StatisticsProps {
  errorMessages?: ErrorMessages | null;
  data?: IData[];
  hideLink?: boolean;
  dateInterval?: {
    dateStart: string;
    dateEnd: string;
  };
  dateChangeHandlers?: {
    setDateStart: (dateString: string) => void;
    setDateEnd: (dateString: string) => void;
  };
}

// eslint-disable-next-line sonarjs/cognitive-complexity
const TestsStats: React.FC<StatisticsProps> = ({ errorMessages, data, hideLink, dateInterval, dateChangeHandlers }) => {
  const [isLine, setIsLine] = useState(true);
  const [showStats, setShowStats] = useState(true);
  const showStatsToggle = () => setShowStats((prevState) => !prevState);

  if (errorMessages) {
    return <ErrorsBlock errors={errorMessages} />;
  }

  if (data && data.length === 0) {
    return null;
  }

  return (
    <CardStyled>
      <Space justify="space-between" align="center">
        <Space align="center">
          <TestsStatsHeading>Методики</TestsStatsHeading>
          {showStats && <TestsSwitch isLine={isLine} setIsLine={setIsLine} />}
        </Space>
        <div>
          <SpoilerButton>
            <ExpandButton onClick={showStatsToggle} expanded={showStats} />
          </SpoilerButton>

          {!hideLink && (
            <HeaderMoreLink>
              <MoreLink to="/tests" rightIcon={<IconRightChevron />}>
                Посмотреть все
              </MoreLink>
            </HeaderMoreLink>
          )}
        </div>
      </Space>

      {showStats && <DateInterval dateInterval={dateInterval} dateChangeHandlers={dateChangeHandlers} />}

      {isLine && showStats && (
        <TestsListWrap>
          {data?.map((item) => (
            <TestItem key={item.id}>
              <TestTitle color="header">{item.survey}</TestTitle>
              <ProgressLineChart
                unlimited={item.unlimitedRegistrations}
                value={item.numberOfRegistrations ?? 0}
                max={item.surveyAttempts ?? 0}
                label="Регистраций"
                color="lightBlue"
                showInfoTooltip={true}
                tooltipText={`Всего приобретено: ${item.surveyAttempts ?? 0}`}
              />
              <ProgressLineChart
                unlimited={item.unlimitedAttempts}
                value={item.pollsDone ?? 0}
                max={item.surveyAttempts ?? 0}
                label="Пройдено"
                color="green"
                showInfoTooltip={true}
                tooltipText={`Всего приобретено: ${item.surveyAttempts ?? 0}`}
              />
            </TestItem>
          ))}
        </TestsListWrap>
      )}

      {!isLine && showStats && (
        <SemicircleTestsList>
          {data?.map((item) => (
            <TestItem key={item.id}>
              <TestTitle color="header">{item.survey}</TestTitle>
              <ProgressRow>
                <StyledSemicircle
                  value={item.numberOfRegistrations ?? 0}
                  max={item.surveyAttempts ?? 0}
                  color="#86D0F5"
                  label="Регистраций"
                />
                <StyledSemicircle
                  value={item.pollsDone ?? 0}
                  max={item.surveyAttempts ?? 0}
                  color="#FF88AD"
                  label="Пройдено"
                />
                <StyledSemicircle
                  value={item.surveyAttemptsRemaining ?? 0}
                  max={item.surveyAttempts ?? 0}
                  color="#86F5A5"
                  label="Осталось"
                />
              </ProgressRow>
            </TestItem>
          ))}
        </SemicircleTestsList>
      )}

      {!hideLink && showStats && (
        <FooterMoreLink>
          <MoreLink to="/tests" rightIcon={<IconRightChevron />}>
            Посмотреть все
          </MoreLink>
        </FooterMoreLink>
      )}
    </CardStyled>
  );
};

export default TestsStats;
