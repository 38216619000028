import React, { FC, useCallback, useState } from 'react';
import { Controller, UnpackNestedValue, useForm } from 'react-hook-form';
import { CreatableSelect, Input, MessageInfo, Select, Switch } from '../index';
import { ModalTwoButtons } from '../../modals';
import { IconLink } from '../Icons';
import { UserAccessAccessTypeEnum, PollsApi, UserAccess } from '../../services';
import { emailValidator, getEmailsFromString } from '../../utils/functions';
import {
  WrapperForm,
  ControlItem,
  ControlRow,
  SelectWrap,
  CopyLink,
  CreatableSelectWrap,
  EmailsCounter,
  InputError,
} from './styles';
import { TestAccessInputs, TestAccessSettingsProps } from './types';
import { EmailListPreview } from '../EmailListPreview';

const pollsApi = new PollsApi();

const OPTIONS = [
  { value: UserAccessAccessTypeEnum.ByEmail, label: 'Ограничения по почте' },
  { value: UserAccessAccessTypeEnum.ByDomain, label: 'Ограничение по домену' },
];

const resolverTestAccess = (data: UnpackNestedValue<TestAccessInputs>) => {
  const errors: Record<string, unknown> = {};
  if (data.accessType.value === UserAccessAccessTypeEnum.ByDomain && !data.domain) {
    errors.domain = {
      message: 'domain required',
    };
  }
  if (data.accessType.value === UserAccessAccessTypeEnum.ByEmail && (!data.emails || data.emails.length === 0)) {
    errors.emails = {
      message: 'emails required',
    };
  }
  return {
    values: Object.keys(errors).length ? {} : data,
    errors,
  };
};

const createLink = (token: string) => `${window.location.origin}/t/${token}`;
const copyToClipboard = (text: string) => navigator.clipboard.writeText(text);

export const TestAccessSettings: FC<TestAccessSettingsProps> = ({ id, userCanDownloadReport }) => {
  const [isSuccess, setIsSuccess] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [showEmailsModal, setShowEmailModal] = useState(false);

  // eslint-disable-next-line @typescript-eslint/unbound-method
  const {
    register,
    handleSubmit,
    errors,
    control,
    watch,
    formState,
    setError,
    setValue,
    trigger,
  } = useForm<TestAccessInputs>({
    defaultValues: {
      canAccessDashboard: false,
      userCanViewReport: false,
      accessType: OPTIONS[0],
      domain: '',
    },
    resolver: resolverTestAccess,
  });

  const watchAccessType = watch('accessType');
  const emailsArray = watch('emails');
  const emailsLength = emailsArray?.length;

  const onSubmit = useCallback(
    async (formData: TestAccessInputs) => {
      setLoading(true);

      const data: UserAccess = {
        clientLimit: id!,
        domain: formData.domain,
        userCanViewReport: formData.userCanViewReport,
        canAccessDashboard: formData.canAccessDashboard,
        accessType: formData.accessType.value as UserAccessAccessTypeEnum,
        emails: formData.emails?.map((item) => item.label),
      };

      try {
        const {
          data: { token },
        } = await pollsApi.pollsUserAccessesCreate({ data });

        if (!token) return;

        const link = createLink(token);
        await copyToClipboard(link);
        setIsSuccess(true);
      } catch (error) {
        setError('submit', { message: 'Что-то пошло не так, попробуйте ещё раз' });
      } finally {
        setLoading(false);
      }
    },
    [id, setError],
  );

  const resetSuccessFlag = useCallback(() => {
    setIsSuccess(false);
  }, []);

  return (
    <WrapperForm onSubmit={handleSubmit(onSubmit)}>
      <ControlItem>
        Создавать для тестируемых личный кабинет
        <Controller
          control={control}
          name="canAccessDashboard"
          render={(props) => (
            <Switch
              name={`${props.name}${id!}`}
              onChange={(e) => props.onChange(e.target.checked)}
              ref={props.ref}
              checked={props.value as boolean}
            />
          )}
        />
      </ControlItem>
      {userCanDownloadReport && (
        <ControlItem>
          Пользователи получают отчёт
          <Controller
            control={control}
            name="userCanViewReport"
            render={(props) => {
              return (
                <Switch
                  name={`${props.name}${id!}`}
                  onChange={(e) => props.onChange(e.target.checked)}
                  ref={props.ref}
                  checked={props.value as boolean}
                />
              );
            }}
          />
        </ControlItem>
      )}
      <ControlRow>
        <SelectWrap>
          <Controller
            control={control}
            name="accessType"
            as={Select}
            className="small"
            placeholder="Выберите тип доступа"
            options={OPTIONS}
            isClearable={false}
            position="fixed"
          />
        </SelectWrap>

        {watchAccessType.value === UserAccessAccessTypeEnum.ByDomain && (
          <Input
            type="text"
            name="domain"
            placeholder="Доменное имя"
            variant="small"
            ref={register({ required: true })}
          />
        )}
        {formState.errors.domain && <InputError>Введите доменное имя!</InputError>}

        {watchAccessType.value === UserAccessAccessTypeEnum.ByEmail && (
          <EmailListPreview
            showError={Boolean(formState.errors.emails)}
            emails={emailsArray?.map(({ value }) => value) ?? []}
            onClick={() => {
              setShowEmailModal(true);
            }}
          />
        )}
      </ControlRow>

      <ControlRow>
        <CopyLink disabled={isLoading} type="submit">
          <IconLink />
          Скопировать ссылку
        </CopyLink>

        {isSuccess && <MessageInfo message="Ссылка успешно скопирована!" variant="success" onHide={resetSuccessFlag} />}
        {errors.submit && <InputError>{errors.submit.message}</InputError>}
      </ControlRow>
      <ModalTwoButtons
        title={
          <>
            Email <EmailsCounter>{emailsLength ?? '0'}</EmailsCounter>
          </>
        }
        showModal={showEmailsModal}
        handleCloseModal={() => setShowEmailModal(false)}
        floatWidth={true}
        secondaryBtnText="Отменить"
        handleSecondaryBtnClick={(e) => {
          e.preventDefault();
          setValue('emails', []);
          setShowEmailModal(false);
        }}
        mainBtnText="Сохранить"
        handleMainBtnClick={(e) => {
          e.preventDefault();
          setShowEmailModal(false);
        }}
      >
        <CreatableSelectWrap>
          <Controller
            control={control}
            name="emails"
            as={CreatableSelect}
            isValidNewOption={emailValidator}
            className="small"
            isMulti={true}
            placeholder="Адреса электронной почты"
            rules={{ required: true }}
            onCreateOption={(inputValue: string) => {
              const newEmails = getEmailsFromString(inputValue).map((item) => ({
                value: item,
                label: item,
              }));
              setValue('emails', [...(emailsArray ?? []), ...newEmails]);
              trigger();
            }}
          />
          {formState.errors.emails && <InputError isUnderField>Введите хотя бы один email!</InputError>}
        </CreatableSelectWrap>
      </ModalTwoButtons>
    </WrapperForm>
  );
};
