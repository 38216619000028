import React from 'react';
import clsx from 'clsx';
import { IconClose } from '../../components/Icons';
import { ModalClose, ModalContent, ModalTitle, Wrapper, MobileTopLine } from './styled';

export interface Modalprops {
  title?: string | React.ReactNode;
  children?: React.ReactNode;
  showModal: boolean;
  handleCloseModal: () => void;
  floatWidth?: boolean;
}

const Modal: React.FC<Modalprops> = ({ title, showModal, handleCloseModal, children, floatWidth }) => {
  return (
    <Wrapper onClick={handleCloseModal} className={clsx(showModal && 'active')}>
      <ModalContent floatWidth={floatWidth} onClick={(e) => e.stopPropagation()}>
        <MobileTopLine />
        <ModalTitle>{title}</ModalTitle>

        <ModalClose type="button" onClick={() => handleCloseModal()}>
          <IconClose color="secondary" />
        </ModalClose>

        {children}
      </ModalContent>
    </Wrapper>
  );
};

export default Modal;
