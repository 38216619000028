import React, { CSSProperties } from 'react';
import { defaultTheme } from '../../theme';

type Props = Pick<CSSProperties, 'width' | 'height' | 'fill'>;

const LeftIcon = ({ width = 8, height = 12, fill = defaultTheme.colors.font.secondary }: Props) => (
  <svg width={width} height={height} fill={fill} viewBox="0 0 8 12" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.29282 11.707L7.70703 10.2928L3.41414 5.99992L7.70703 1.70703L6.29282 0.292818L0.585711 5.99992L6.29282 11.707Z"
    />
  </svg>
);

export default LeftIcon;
