import { makeAutoObservable } from 'mobx';

export default class NotificationsService {
  public showNotification = false;
  public notificationMessage = '';

  constructor() {
    makeAutoObservable(this);
  }

  notify(message: string) {
    this.setMessage(message);
    this.setShowNotification(true);
  }

  closeNotification = () => {
    this.setShowNotification(false);
  };

  setShowNotification(value: boolean) {
    this.showNotification = value;
  }

  setMessage(message: string) {
    this.notificationMessage = message;
  }
}
