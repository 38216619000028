import styled from 'styled-components';
import { mediaQuery } from '../utils/themeUtils';

const ContentWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.colors.bg.extraLightBlue};
  padding: 0 32px;
  max-width: 100%;

  ${mediaQuery('sm')} {
    row-gap: 16px;
    padding: 0 20px 20px 20px;
  }
`;

export default ContentWrapper;
