import React, { FC } from 'react';
import styled from 'styled-components';
import { CheckIcon, IconClose } from './Icons';
import { QuestionChoice } from '../services';
import { AnswerControlProps } from '../types/common';

const ColoredAnswerControl: FC<AnswerControlProps> = ({ decision, onAnswer, choices, disable }) => {
  let positive: QuestionChoice = { text: '' };
  let negative: QuestionChoice = { text: '' };
  choices.forEach((choice) => {
    if (choice.text === 'true') {
      positive = choice;
    } else if (choice.text === 'false') {
      negative = choice;
    }
  });
  return (
    <>
      <ItemButtonColored
        disabled={disable}
        isPositive
        decision={decision}
        choice={positive.id}
        onClick={() => {
          onAnswer(positive.id);
        }}
      >
        <CheckIcon color="answerCheck" />
      </ItemButtonColored>

      <ItemButtonColored
        disabled={disable}
        decision={decision}
        choice={negative.id}
        onClick={() => {
          onAnswer(negative.id);
        }}
      >
        <IconClose mini warning />
      </ItemButtonColored>
    </>
  );
};

export default ColoredAnswerControl;

type ButtonProps = {
  isPositive?: boolean;
  decision?: number | null;
  choice?: QuestionChoice['id'];
  isSelected?: boolean;
};

export const ItemButtonColored = styled.button<ButtonProps>`
    border: none;
    outline: none;
    cursor: pointer;
    width: 88px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 32px;
    background-color: ${({ theme, decision, isPositive, choice }) => {
      // if no decision or it missmatches button type
      if (decision === undefined || decision === null || decision !== choice) {
        return theme.colors.bg.extraLightBlue;
      }
      return isPositive ? theme.colors.tests.green : theme.colors.tests.pink;
    }};
    margin-right: 16px; 

    &:active, &:hover {
      background-color: ${({ theme, isPositive }) => (isPositive ? theme.colors.tests.green : theme.colors.tests.pink)};
    }
    
    &:disabled {
      background-color: ${({ theme }) => theme.colors.elements.disable};
    }

    svg {
      width: 21px;
      height: 16px;
    }

    &:last-child {
      margin-right: 0;
    }
  }
`;
