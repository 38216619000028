import React, { ComponentPropsWithRef, forwardRef, useMemo } from 'react';
import sanitaize from 'sanitize-html';

interface SanitazedHTMLProps extends ComponentPropsWithRef<'div'> {
  htmlString: string;
}

const SanitazedHTML = forwardRef<HTMLDivElement, SanitazedHTMLProps>(({ htmlString, ...restProps }, ref) => {
  const cleanHTML = useMemo(
    () =>
      sanitaize(htmlString, {
        allowedTags: sanitaize.defaults.allowedTags.concat(['img']),
        allowedAttributes: {
          ...sanitaize.defaults.allowedAttributes,
          '*': ['style'],
        },
      }),
    [htmlString],
  );
  // eslint-disable-next-line react/no-danger
  return <div ref={ref} dangerouslySetInnerHTML={{ __html: cleanHTML }} {...restProps} />;
});

export default SanitazedHTML;
