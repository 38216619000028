import React, { FC } from 'react';
import styled, { DefaultTheme } from 'styled-components';

interface DownProps {
  color?: keyof DefaultTheme['colors']['elements'];
}

const StyledDown = styled.svg<DownProps>`
  ${(props) => props.color && `stroke: ${props.theme.colors.elements[props.color]};`}
`;

const Down: FC<DownProps> = ({ color = 'secondary' }) => (
  <StyledDown color={color} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7 10L12 15L17 10" strokeWidth="2" />
  </StyledDown>
);

export default Down;
