import styled from 'styled-components';

export const BtnRow = styled.div`
  display: grid;
  grid-row-gap: 8px;

  button {
    width: 100%;
  }
`;
