import React from 'react';
import styled from 'styled-components';
import { ReactComponent as LogoShopping } from '../../assets/images/shopping.svg';
import { getColor } from '../../utils/themeUtils';

const StyledButton = styled.a`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 20px;
  border: 1px solid ${getColor(({ elements }) => elements.darkBlue)};
  background-color: transparent;
  color: ${getColor(({ elements }) => elements.darkBlue)};
  border-radius: 8px;
  transition: background-color 0.1s linear;
  svg > path {
    fill: ${getColor(({ elements }) => elements.darkBlue)};
  }
  &:hover {
    background-color: #b6cceb;
  }
`;

export const ButtonPurchase = (props: { to: string }) => (
  <StyledButton href={props.to} title="Купить тесты" target="_blank">
    <LogoShopping />
  </StyledButton>
);
