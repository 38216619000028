import styled from 'styled-components';

export const TableMainWrapper = styled.div`
  border-spacing: 0;
  width: 100%;

  table {
    width: 100%;
  }
  td:not(:first-child),
  td p {
    white-space: normal;
    word-break: break-word;
    margin-bottom: 0;
  }
`;

export const HeaderRow = styled.tr`
  text-align: left;

  & th {
    background-color: ${(props) => props.theme.colors.elements.blueDefault};
    padding: 16px 16px;

    color: ${(props) => props.theme.colors.font.white};
    font-size: 12px;
    font-weight: 600;

    &:first-child {
      border-top-left-radius: ${(props) => props.theme.borderRadius};
      border-bottom-left-radius: ${(props) => props.theme.borderRadius};
      padding-left: 32px;
    }

    &:last-child {
      border-top-right-radius: ${(props) => props.theme.borderRadius};
      border-bottom-right-radius: ${(props) => props.theme.borderRadius};
      padding-right: 32px;
    }
  }
`;

export const BodyRow = styled.tr<{ pointer: boolean }>`
  ${({ pointer }) => (pointer ? 'cursor: pointer' : '')};

  &:not(:last-child) {
    border-bottom: 1px solid ${(props) => props.theme.colors.elements.divider};
  }

  & td {
    color: ${(props) => props.theme.colors.font.header};
    font-size: 14px;
    font-weight: 300;
    padding: 16px 16px;
    vertical-align: middle;

    &:first-child {
      padding-left: 32px;
      font-size: 16px;
    }

    &:last-child {
      padding-right: 32px;
    }
  }
`;

export const EditableBodyRow = styled(BodyRow)`
  & td:last-child :first-child {
    visibility: hidden;
  }
  &:hover td:last-child :first-child {
    visibility: visible;
  }
`;
