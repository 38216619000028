import React, { useCallback, useState, VFC } from 'react';
import { observer } from 'mobx-react-lite';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import axios from 'axios';

import { useAuthStore } from './store';
import { useRecaptchaLoader } from '../ReCaptcha';
import { Button, Form, FormItem, Input, Link, MessageInfo } from '../../components';
import { Header } from '../../components/Typography';
import useQuery from './hooks';
import { User } from '../../services';
import InputPassword from '../../components/InputPassword';
import { mediaQuery } from '../../utils/themeUtils';

const ButtonsContainer = styled(FormItem)`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;

  ${mediaQuery('xs')} {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    row-gap: 18px;
  }
`;

const LoginForm: VFC = observer(() => {
  const authStore = useAuthStore();
  const recaptchaLoader = useRecaptchaLoader();
  const query = useQuery();
  const token = query.get('token');
  const history = useHistory();

  const [errorMsg, setError] = useState<string | null>(null);

  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { register, handleSubmit, errors } = useForm<User>();

  const onSubmit = useCallback(
    async (loginData: User) => {
      try {
        const recaptcha = await recaptchaLoader.executeReCaptcha('submit');

        if (!recaptcha) return;

        const { surveyId } = await authStore.login({ ...loginData, recaptcha, token: token ?? undefined });
        if (surveyId) history.push(`/tests/${surveyId}`);
      } catch (error) {
        if (axios.isAxiosError(error)) {
          setError('Неверный пароль или email');
        } else {
          setError('Вы не прошли проверку на робота, попробуйте еще раз');
        }
      }
    },
    [history, recaptchaLoader, authStore, token],
  );

  const clearErrMsg = useCallback(() => {
    setError(null);
  }, []);

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <FormItem>
        <Header variant="h1">Вход</Header>
      </FormItem>

      {errorMsg && <MessageInfo variant="error" message={errorMsg} onHide={clearErrMsg} />}
      <FormItem>
        <Input
          type="email"
          name="email"
          placeholder="Email"
          autoComplete="email"
          $error={Boolean(errors.email)}
          ref={register({ required: true })}
        />
      </FormItem>

      <FormItem>
        <InputPassword
          name="password"
          placeholder="Пароль"
          autoComplete="current-password"
          ref={register({ required: true })}
        />
      </FormItem>

      <ButtonsContainer>
        <Button type="submit" disabled={authStore.fetching}>
          Войти
        </Button>
        <Link to="/recovery">Я не помню пароль</Link>
      </ButtonsContainer>
    </Form>
  );
});

export default LoginForm;
