import React from 'react';
import styled, { DefaultTheme } from 'styled-components';

interface BodyProps {
  className?: string;
  children: React.ReactNode;
  paragraph?: boolean;
  color?: keyof DefaultTheme['colors']['font'];
}

const BodyTypography: React.FC<BodyProps> = ({ className, children, paragraph }) =>
  paragraph ? <p className={className}>{children}</p> : <span className={className}>{children}</span>;

const BodyTypographyColor = styled(BodyTypography)`
  ${(props) => props.color && `color: ${props.theme.colors.font[props.color]};`}
`;

export const BodyBig = styled(BodyTypographyColor)`
  font-size: 20px;
  line-height: 28px;
`;

export const BodyQuestion = styled(BodyTypographyColor)`
  font-size: 18px;
  font-weight: 300;
  line-height: 28px;
`;

const Body = styled(BodyTypographyColor)`
  font-size: 16px;
  line-height: 24px;
`;

export const BodySmall = styled(BodyTypographyColor)`
  font-size: 14px;
  line-height: 20px;
`;

export default Body;
