import React from 'react';

const Test = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="5.5" y="3.5" width="13" height="17" rx="1.5" stroke="currentColor" />
    <path d="M7.5 7L8.5 8L10 6" stroke="currentColor" />
    <path d="M11.5 7H17" stroke="currentColor" />
    <path d="M7.5 12L8.5 13L10 11" stroke="currentColor" />
    <path d="M11.5 12H17" stroke="currentColor" />
    <path d="M7.5 17L8.5 18L10 16" stroke="currentColor" />
    <path d="M11.5 17H17" stroke="currentColor" />
  </svg>
);

export default Test;
