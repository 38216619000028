import React, { lazy } from 'react';
import { IconExit, IconGear, IconHome } from '../components/Icons';
import { LOGOUT_PATH } from './const';
import LogOut from '../features/Auth/LogOut';
import { Route } from './index';

const AdminHome = lazy(() => import('../pages/Admin/Home'));
const AccesCheckerProxy = lazy(() => import('../features/AccessRightsChecker/ProxyComponent'));

export const routes: Route[] = [
  {
    path: '/',
    exact: true,
    component: () => <AdminHome />,
    icon: <IconHome />,
    name: 'Главная',
  },
  {
    path: '/admin',
    icon: <IconGear />,
    name: 'Админка',
    nonVirtual: true,
  },
  {
    path: LOGOUT_PATH,
    component: () => <LogOut />,
    icon: <IconExit />,
    hideOnMobile: true,
    name: 'Выйти',
  },
  {
    path: '/t/:token',
    component: () => <AccesCheckerProxy />,
  },
];
